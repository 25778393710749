import {
    ObjectOrReference,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import { DataForm } from "./data";
import { APIObject, ObjectReference } from "./api-object";
import { Program } from "./program";
import { Organization } from "./organization";
import { Country } from "./country";
import { Product } from "./product";

export class IntakeSettings extends APIObject {
    owner!: ObjectOrReference<Organization | Program>;
    hcp!: ObjectOrReference<DataForm>;
    patient?: OptionalObjectOrReference<DataForm>;
    family?: OptionalObjectOrReference<DataForm>;
    allow_patient!: boolean;
    allow_family!: boolean;
    hosted_intake!: boolean;
    initialize(data?: any, patch: boolean = false): void {
        this.setMember(data, patch, "owner");
        this.setMember(data, patch, "hcp", DataForm);
        this.setMember(data, patch, "patient", DataForm);
        this.setMember(data, patch, "family", DataForm);
        this.setMember(data, patch, "allow_patient");
        this.setMember(data, patch, "allow_family");
        this.setMember(data, patch, "hosted_intake");
    }
}

export class Intake extends APIObject {
    type: string = "";
    organization!: ObjectReference; // The organization that owns this intake
    programs!: Program[]; // The programs available for this intake config
    logo?: string; // read-only, optional - URL of the logo file
    url?: string; // read-only, optional - URL to return to on logo click
    bgcolor?: string; // optional - intake header background hex color code
    textcolor?: string; // optional - intake header text hex color code
    settings!: IntakeSettings;
    countries!: Country[];
    products!: Product[];

    initialize(data?: any, patch: boolean = false) {
        this._readOnly.push(
            ...[
                "logo",
                "url",
                "bgcolor",
                "textcolor",
                "organization",
                "programs",
                "countries",
                "products",
            ],
        );
        super.initialize(data, patch);
        this.setMember(data, patch, "organization", ObjectReference);
        this.setMember(data, patch, "programs", Program, true);
        this.setMember(data, patch, "logo");
        this.setMember(data, patch, "url");
        this.setMember(data, patch, "bgcolor");
        this.setMember(data, patch, "textcolor");
        this.setMember(data, patch, "settings", IntakeSettings);
        this.setMember(data, patch, "countries", Country, true);
        this.setMember(data, patch, "products", Product, true);
    }
}
