<mat-table [dataSource]="list.items">
    <ng-container matColumnDef="task">
        <mat-header-cell *matHeaderCellDef>Task Name</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment; let index = index"
            (keydown)="onKeyPress($event)"
            (keypress)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
        >
            @if (!isReference(assignment)) {
                <div class="flex stack">
                    <div class="flex" [class.nested]="isNested">
                        <div
                            [class.pointer]="!assignment.completed"
                            *ngIf="!isCaseClosed && taskEnabledOrCompleted(assignment)"
                        >
                            <mat-checkbox
                                [disabled]="
                                    !taskEnabled(assignment) ||
                                    (viewOnly &&
                                        asObject(assignment).assignee?.id !==
                                            currentAccount?.id)
                                "
                                [checked]="assignment.completed"
                                *ngIf="!assignment.completed"
                                (click)="
                                    asObject(assignment).assignee?.id ===
                                        currentAccount?.id &&
                                        assignment.localTimezonePending &&
                                        handleClick($event, assignment)
                                "
                            ></mat-checkbox>
                        </div>

                        @if (!isNested && assignmentHasFrequency(assignment)) {
                            <button
                                mat-icon-button
                                (click)="
                                    $event.stopPropagation();
                                    toggleFrequencyTable(index, assignment)
                                "
                            >
                                <mat-icon>
                                    {{
                                        expandedRow?.id === assignment?.id ?
                                            "expand_less"
                                        :   "expand_more"
                                    }}
                                </mat-icon>
                            </button>
                        }
                        <div
                            class="flex center"
                            [matBadge]="matBadge(assignment)"
                            matBadgeColor="accent"
                            matBadgeOverlap="false"
                            matBadgePosition="after"
                            style="padding-right: 0.5rem"
                        >
                            <span
                                [class.task-action]="taskEnabled(assignment)"
                                [class.disabled]="
                                    !asObject(assignment).localTimezonePending
                                "
                                (click)="onActionLinkClick(assignment, $event)"
                                (keydown)="onKeyPress($event)"
                                (keypress)="onKeyDown($event)"
                                (keyup)="onKeyUp($event)"
                            >
                                {{ getTaskAction(assignment) }}
                            </span>
                            &nbsp;
                            <span>
                                {{ getTaskTitle(assignment) }}
                            </span>
                        </div>
                    </div>
                    <p class="secondary small workflow-name" *ngIf="showWorkflowName">
                        Workflow: {{ getWorkflowName(assignment) }}
                    </p>
                    <p
                        class="secondary small workflow-name"
                        *ngIf="showTaskGroupName(assignment)"
                    >
                        Task Group: {{ assignment.parent.displayName }}
                    </p>
                </div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment"
            (click)="canEditTask(assignment) && editTask($event, assignment)"
        >
            @if (!isReference(assignment)) {
                <div
                    class="flex center"
                    [matTooltip]="statusTooltip(assignment)"
                    [class.completed-icon]="!!assignment.completed"
                >
                    <mat-icon class="status-icon">
                        {{ statusDetails(assignment).icon }}
                    </mat-icon>

                    {{ statusDetails(assignment).value }}
                </div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="due_date">
        <mat-header-cell *matHeaderCellDef>Trigger</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment"
            (click)="
                canEditTask(assignment) &&
                    editFrequency(assignment, true, 'Edit Triggers')
            "
            (keydown)="onKeyPress($event)"
            (keypress)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
            [class.pointer]="canEditTask(assignment)"
            [class.notAllowed]="assignment.completed || !canEditTask(assignment)"
            class="flex center"
        >
            @if (!isReference(assignment)) {
                <div>{{ dueByColumnValue(assignment) }}</div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="frequency">
        <mat-header-cell *matHeaderCellDef>Frequency</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment"
            (click)="canEditTask(assignment) && editFrequency(assignment)"
            (keydown)="onKeyPress($event)"
            (keypress)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
            class="frequency-cell"
            [class.pointer]="canEditTask(assignment)"
            [class.notAllowed]="!canEditTask(assignment)"
        >
            @if (!isReference(assignment)) {
                <div>{{ frequencyValue(assignment) }}</div>
                <div class="small">
                    <em>{{ frequencySubTitle(assignment) }}</em>
                </div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef>Assignee</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment"
            [class.notAllowed]="!canAssignTask(assignment)"
        >
            @if (
                !isReference(assignment) &&
                (isSummaryTask(assignment) || assigneeUnavailable(assignment))
            ) {
                {{ organization?.displayName }}
            } @else if (!isReference(assignment) && !isDocusignTask(assignment)) {
                <mat-select
                    [(ngModel)]="assignment.assignee"
                    placeholder="Unassigned"
                    [disabled]="
                        !canAssignTask(assignment) || !!assignment.completed || viewOnly
                    "
                    (selectionChange)="handleAssigneeChange($event, assignment)"
                >
                    <mat-option
                        [value]="undefined"
                        *ngIf="assignment.assignee && !isPhysician"
                    >
                        Unassign
                    </mat-option>
                    <mat-option
                        *ngFor="let assignee of getAvailableAssignees(assignment)"
                        [value]="assignee.account"
                    >
                        {{ assignee.account.name }}
                    </mat-option>
                </mat-select>
            } @else if (isDocusignTask(assignment)) {
                {{ docusignAssignee(assignment)?.name }}
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
        <mat-cell *matCellDef="let assignment" class="overflow">
            <button
                *ngIf="isObject(assignment) && !isSummaryTask(assignment)"
                mat-icon-button
                [matMenuTriggerFor]="overflow"
                [matMenuTriggerData]="{ assignment: assignment }"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <mat-menu #overflow="matMenu">
        <ng-template matMenuContent let-assignment="assignment">
            <button
                mat-menu-item
                *ngIf="!isSummaryTask(assignment)"
                [disabled]="!canViewTask(assignment)"
                (click)="canViewTask(assignment) && viewTask($event, assignment)"
            >
                View
            </button>
            <button
                mat-menu-item
                *ngIf="!isSummaryTask(assignment)"
                [disabled]="!canEditTask(assignment)"
                (click)="canEditTask(assignment) && editTask($event, assignment)"
            >
                Edit Properties
            </button>
            @if (
                !isSummaryTask(assignment) &&
                !isDocusignTask(assignment) &&
                !!asAssignment(assignment).completed
            ) {
                <button
                    mat-menu-item
                    [disabled]="!canReopenTask(assignment)"
                    (click)="completeTaskWithConfirmation(assignment)"
                >
                    Reopen Task
                </button>
            } @else if (!isSummaryTask(assignment) && !isDocusignTask(assignment)) {
                <button
                    mat-menu-item
                    [disabled]="!canCompleteTask(assignment)"
                    (click)="
                        canCompleteTask(assignment) &&
                            completeTaskWithConfirmation(assignment)
                    "
                >
                    Mark Completed
                </button>
            }
            <button
                mat-menu-item
                *ngIf="!isSummaryTask(assignment) && !isDocusignTask(assignment)"
                [disabled]="
                    !asObject(assignment).localTimezonePending ||
                    asObject(assignment).completed ||
                    viewOnly ||
                    assigneeIsCurrentUser(assignment) ||
                    !canSendReminder(assignment)
                "
                (click)="sendReminder($event, assignment)"
            >
                Send Reminder
            </button>
            <button
                mat-menu-item
                *ngIf="isDocusignTask(assignment)"
                [disabled]="
                    asObject(assignment).completed ||
                    viewOnly ||
                    !docusignEnvelope(assignment)
                "
                (click)="
                    sendDocusignNow(
                        $event,
                        assignment,
                        undefined,
                        !!docusignEnvelope(assignment)?.sent
                    )
                "
            >
                @if (docusignEnvelope(assignment)?.sent) {
                    Resend Docusign®
                } @else {
                    Send Now
                }
            </button>
            <button
                *ngIf="hasNotification(assignment)"
                mat-menu-item
                (click)="markNotificationAsRead(assignment)"
            >
                Mark Read
            </button>
        </ng-template>
    </mat-menu>
    <mat-header-row
        [class.none]="isNested"
        *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row
        #tableRow
        [id]="row.id"
        *matRowDef="let row; columns: displayedColumns; let index = index"
    ></mat-row>
</mat-table>
