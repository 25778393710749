<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div class="wrapper flex" *ngIf="mode == ObjectViewMode.Edit">
        <detail-tab-group [class.nested]="!!dialogReference">
            <detail-tab label="Properties">
                <div class="content-card">
                    <div class="title">Properties</div>
                    <div class="settings-section-content flex stack">
                        <mat-form-field class="flexible header-field">
                            <mat-label>Change Program Name</mat-label>
                            <input matInput formControlName="name" />
                            <mat-hint>
                                If not set, the program will be identified by the
                                specified drug name.
                            </mat-hint>
                        </mat-form-field>
                        <mat-form-field class="flexible header-field">
                            <mat-label>Change Drug Name</mat-label>
                            <fa-icon matSuffix [icon]="drugNameIcon"></fa-icon>
                            <input matInput formControlName="drug_name" />
                            <mat-error>Drug Name cannot be empty</mat-error>
                        </mat-form-field>
                        <mat-form-field class="flexible header-field">
                            <mat-label>Change Unique Treatment Number</mat-label>
                            <mat-icon matSuffix>health_and_safety</mat-icon>
                            <input matInput formControlName="ind" />
                            <mat-error
                                *ngIf="formGroup.controls['ind'].hasError('pattern')"
                            >
                                Unique Treatment Number can only contain numbers (0-9).
                            </mat-error>
                            <mat-error
                                *ngIf="formGroup.controls['ind'].hasError('uniqueIND')"
                            >
                                This Unique Treatment number is already in use.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="flexible header-field">
                            <mat-label>Change Program Slug</mat-label>
                            <input matInput formControlName="slug" />
                            <mat-hint>
                                This is used in the URL for the intake form.
                            </mat-hint>
                            <mat-error>Slug cannot be empty</mat-error>
                        </mat-form-field>
                        <mat-form-field class="full" *ngIf="isOrgAdmin">
                            <mat-label>
                                Program Inquiry Response Time (Optional)
                            </mat-label>
                            <input
                                matInput
                                placeholder="Program Inquiry Response Time"
                                formControlName="response_time"
                            />
                            <mat-hint>{{ responseTimeHint }}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="settings-section-content flex row">
                        <div class="column flexible flex stack">
                            <div class="title-1">Intake</div>
                            <div class="intake-domain flex">
                                <button mat-icon-button [disabled]="true">
                                    <mat-icon>email</mat-icon>
                                </button>
                                {{ intakeAddress }}
                            </div>
                            <div class="intake-domain flex">
                                <button mat-icon-button [disabled]="true">
                                    <mat-icon>public</mat-icon>
                                </button>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    [href]="intakeWeb"
                                >
                                    {{ intakeWeb }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </detail-tab>
            <detail-tab label="Access">
                <div class="content-card">
                    <div class="access-header flex">
                        <div class="title">Administrators</div>
                        <div class="spacer"></div>
                        <div class="header-actions">
                            <button
                                mat-flat-button
                                class="brand-medium"
                                (click)="addAdministrator($event)"
                            >
                                <mat-icon>open_in_new</mat-icon>
                                Add Program Administrator
                            </button>
                        </div>
                    </div>
                    <mat-table
                        [dataSource]="administrators"
                        matSort
                        (matSortChange)="onSortChange($event)"
                    >
                        <ng-container matColumnDef="account-name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let admin">
                                {{ admin.displayName }}
                                <span
                                    *ngIf="isOrganizationAdmin(admin)"
                                    class="admin-tag super-small secondary"
                                >
                                    Organization Administrator
                                </span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <mat-header-cell
                                *matHeaderCellDef
                                class="overflow"
                            ></mat-header-cell>
                            <mat-cell *matCellDef="let admin" class="overflow">
                                <button
                                    mat-icon-button
                                    *ngIf="!isOrganizationAdmin(admin)"
                                    (click)="removeAdministrator($event, admin)"
                                >
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="no-records">
                            <mat-footer-cell
                                *matFooterCellDef
                                [attr.colspan]="displayedColumns.length"
                                class="error not-found"
                            >
                                No administrators found.
                            </mat-footer-cell>
                        </ng-container>
                        <mat-header-row
                            *matHeaderRowDef="displayedColumns"
                        ></mat-header-row>
                        <mat-row
                            *matRowDef="let row; columns: displayedColumns"
                        ></mat-row>
                        <mat-footer-row
                            *matFooterRowDef="['no-records']"
                            [class.hidden-footer]="!!administrators.length"
                        ></mat-footer-row>
                    </mat-table>
                </div>
            </detail-tab>
            <detail-tab label="Messaging">
                <div class="content-card" *ngIf="!!fullObject">
                    <template-list
                        [owner]="this.object"
                        [inCard]="true"
                    ></template-list>
                </div>
            </detail-tab>
            <detail-tab label="Documents">
                <ng-template detail-tab-label>
                    <div class="flex">
                        <span class="flexible">Documents</span>
                        <mat-icon
                            *ngIf="!!tabErrors['Documents']"
                            class="fixed"
                            [ngClass]="tabErrors['Documents'].severity"
                            [matTooltip]="tabErrors['Documents'].message"
                        >
                            warning
                        </mat-icon>
                    </div>
                </ng-template>
                <div class="content-card">
                    <medalink-document-repository
                        [repository]="fullObject"
                        [organization]="fullObject!.organization"
                        repositoryName="Program"
                    ></medalink-document-repository>
                </div>
            </detail-tab>
            <detail-tab label="Countries">
                <div class="content-card">
                    <program-country-admin
                        [program]="fullObject"
                    >                    
                    </program-country-admin>
                </div>
            </detail-tab>
        </detail-tab-group>
    </div>
    <div class="wrapper flex stack flexible" *ngIf="mode == ObjectViewMode.Create">
        <mat-form-field class="full">
            <mat-label>Drug Name</mat-label>
            <fa-icon matSuffix [icon]="drugNameIcon"></fa-icon>
            <input matInput placeholder="Drug Name" formControlName="drug_name" />
        </mat-form-field>
        <mat-form-field class="full">
            <mat-label>Unique Treatment Number</mat-label>
            <mat-icon matSuffix>health_and_safety</mat-icon>
            <input matInput placeholder="IND Number" formControlName="ind" />
            <mat-error *ngIf="formGroup.controls['ind'].hasError('required')">
                Unique Treatment Number cannot be empty
            </mat-error>
            <mat-error *ngIf="formGroup.controls['ind'].hasError('pattern')">
                Unique Treatment Number can only contain numbers (0-9).
            </mat-error>
            <mat-error *ngIf="formGroup.controls['ind'].hasError('uniqueIND')">
                This Unique Treatment number is already in use.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full">
            <mat-label>Program Name (optional)</mat-label>
            <input matInput placeholder="Program Name" formControlName="name" />
            <mat-hint>
                If not set, the program will be identified by the specified drug name.
            </mat-hint>
        </mat-form-field>
        <mat-form-field class="full">
            <mat-label>Program Inquiry Response Time (Optional)</mat-label>
            <input
                matInput
                placeholder="Program Inquiry Response Time"
                formControlName="response_time"
            />
            <mat-hint>{{ responseTimeHint }}</mat-hint>
        </mat-form-field>
    </div>
</object-form>
