import { Organization } from "src/services/models/organization";
import { Role } from "src/services/models/role";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    inject,
} from "@angular/core";
import { ObjectAdminComponent } from "../../../common/components/object-admin.component";
import {
    Document,
    DocumentOwner,
    DocumentRepository,
    DocumentTypeDefinition,
} from "../../../services/models/document";
import { DocumentService } from "../../../services/program.services";
import { DocumentComponent } from "./document.component";
import {
    ObjectViewEntryPoint,
    ObjectViewMode,
    TabError,
} from "../../../common/components/object.component";
import { Observable, of, Subscription } from "rxjs";
import { Inquiry } from "../../../services/models/inquiry";
import { ConfirmDialog } from "../../../common/components/confirm.dialog";
import { Case } from "../../../services/models/case";
import { UntypedFormControl } from "@angular/forms";
import { Sort } from "@angular/material/sort";
import { debounceTime, filter } from "rxjs/operators";
import { TabChangeEvent } from "src/services/component.services";
import { Assignment } from "src/services/models/assignment";
import { RequestFilter } from "src/common/utilities/request";
import { ObjectOrReference, ObjectReference } from "src/services/models/api-object";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatFooterRowDef, MatTable } from "@angular/material/table";
import { MatDialogConfig } from "@angular/material/dialog";
import { AppNotification } from "src/services/models/appNotification";
import { AppNotificationService } from "src/services/notification.services";

export type DocumentTabChangeMetadata = {
    name?: string;
    type?: string;
    upload?: boolean;
    assignment?: Assignment;
    viewOnly?: boolean;
    share?: boolean;
    document?: Document;
    references?: { [key: string]: any };
};
@Component({
    selector: "document-repository",
    templateUrl: "./document-repository.component.html",
    styleUrls: ["./document-repository.component.scss"],
})
export class DocumentRepositoryComponent extends ObjectAdminComponent<Document> {
    @Input() displayMode: "repo" | "task" = "repo";
    @Input() notifications: AppNotification[] = [];
    @Input() headerTitle: string = "Documents";

    objectView = DocumentComponent;
    get displayedColumns(): string[] {
        if (this.isCaseRepository) {
            return [
                "badges",
                "alias_name",
                "document_type",
                "source",
                "shared",
                "uploaded_at",
                "actions",
            ];
        } else {
            return [
                "badges",
                "alias_name",
                "document_type",
                "source",
                "uploaded_at",
                "actions",
            ];
        }
    }
    taskColumns = ["task_name", "file_name", "assigned_to"];
    // document tasks have not been implmented as of (02/08/2022)
    @Input() documentTasks!: any[];
    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
    @ViewChild(MatFooterRowDef, { static: true }) footerDef?: MatFooterRowDef;
    @ViewChild(MatTable, { static: true }) table?: MatTable<Document>;
    @ViewChild("search") searchElement?: ElementRef;
    @ViewChild("filter") filterElement?: ElementRef;

    @Output() setCaseError: EventEmitter<TabError> = new EventEmitter(true);
    private _documentToOpen?: TabChangeEvent;

    @Input()
    get documentToOpen(): TabChangeEvent | undefined {
        return this._documentToOpen;
    }

    set documentToOpen(value: TabChangeEvent | undefined) {
        this._documentToOpen = value;
        if (value?.data) {
            this.openDocumentFromProp(value.data);
        }
    }
    protected repo_?: DocumentRepository;
    get repository(): DocumentRepository | undefined {
        return this.repo_;
    }
    @Input() set repository(v: DocumentRepository | undefined) {
        if (v?.id !== this.repo_?.id) {
            this.repo_ = v;
            this.list.refresh();
            this.getRedactionWarnings();
        }
    }
    getRedactionWarnings() {
        const ids: string[] = [];
        if (this.repository instanceof Case) {
            if (this.repository?.id && this?.repository?.shared?.id) {
                ids.push(this.repository.id);
                ids.push(this.repository.shared.id);
            }
        } else if (this.repository?.id) {
            ids.push(this.repository?.id);
        }

        if (!ids.length || !this.redactionEnabled) return;

        this.service.getRepoRedactionMessage(ids).subscribe((res) => {
            const { severity } = res;

            if (
                !this.hideRedactionWarnings &&
                (severity === "error" || severity === "warning")
            ) {
                this.setCaseError.emit(res);
            } else this.setCaseError.emit(undefined);
        });
    }

    get isCaseRepository(): boolean {
        return this.repository?.type == Case.object_type;
    }

    protected owner_?: DocumentOwner;
    get owner(): DocumentOwner | undefined {
        return this.owner_;
    }
    @Input() set owner(v: DocumentOwner | undefined) {
        this.owner_ = v;
    }

    protected sources_: DocumentRepository[] = [];
    get sources(): DocumentRepository[] {
        return this.sources_;
    }
    @Input() set sources(v: DocumentRepository[]) {
        this.sources_ = v;
    }
    @Input() viewOnly = false;
    @Input() isShared: boolean = false;
    @Input() canAdd: boolean = false;
    @Input() infiniteScroll: boolean = true;
    @Input() inCard: boolean = false;
    @Input() redactionEnabled: boolean = false;
    @Input() caseOrg?: ObjectOrReference<Organization>;

    editing?: Document;
    alias?: string;
    documentType?: string;
    searchTermControl: UntypedFormControl = new UntypedFormControl();
    filterControl: UntypedFormControl = new UntypedFormControl();
    showSearch: boolean = false;
    showFilter: boolean = false;
    showDeletedChecked: UntypedFormControl = new UntypedFormControl(null);

    get defaultFilters(): string[] {
        if (this.repository?.type == "program.case")
            return ["shared", "internal", "type.all"];
        return ["type.all"];
    }

    get isInternalUser(): boolean {
        if (this.repository instanceof Inquiry || this.repository instanceof Case)
            return this.repository.isPharmaStaff(this.currentAccount);
        return false;
    }
    get hideRedactionWarnings(): boolean {
        let hideExternal = true;
        let hideInternal = true;

        if (this.caseOrg instanceof Organization) {
            hideExternal = !!this.caseOrg.settings?.settings?.redaction?.hideExternal;
            hideInternal = !!this.caseOrg.settings?.settings?.redaction?.hideInternal;
        }
        return this.isInternalUser ? hideInternal : hideExternal;
    }

    protected tabChangeProps?: DocumentTabChangeMetadata;

    protected tabGroupSubscription?: Subscription;
    protected appNotificationService: AppNotificationService;

    constructor(
        protected service: DocumentService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
        this.appNotificationService = inject(AppNotificationService);
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.tabGroupSubscription?.unsubscribe();
    }
    @Output() documentToOpenUsed = new EventEmitter<void>();

    protected openDocumentFromProp(data?: DocumentTabChangeMetadata) {
        this.tabChangeProps = data;
        const { name, upload, document } = data ?? {};
        if (name || document) {
            let doc =
                document ||
                (this.list.items.find(
                    (d: ObjectOrReference<Document>) =>
                        d instanceof Document && d.displayName === name,
                ) as Document | undefined);

            if (doc instanceof ObjectReference) {
                this.service.resolveReference(doc).subscribe((document) => {
                    doc = document;
                    this.documentClicked(null as any, doc);
                });
            } else this.documentClicked(null as any, doc);
        } else if (upload && data?.references) {
            let objectName = "Upload Files";
            let task =
                data?.references["task.workflow"]?.reference ?? data?.assignment?.task;

            if (task?.name) {
                objectName = objectName + `: ${task.name}`;
            }

            const doc = this.newObject();
            this.editObject({} as MouseEvent, doc, true, false, objectName);
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => this.updateList(term));

        this.filterControl.valueChanges.subscribe((v: string[]) => {
            if (!v) {
                this.setFilterDefault();
            } else if (
                v.includes("type.all") &&
                this.filterControl.value.length > 1 &&
                !this.isEqualToDefaultFilter(v)
            ) {
                this.filterControl.setValue(
                    this.filterControl.value.filter((f: any) => f !== "type.all"),
                );
            }
            this.changeDetection.detectChanges();
        });

        this.resetFilters();
        this.getRedactionWarnings();
    }
    isEqualToDefaultFilter(arr: string[]): boolean {
        return arr.every((a) => this.defaultFilters.includes(a));
    }
    setFilterDefault() {
        this.filterControl.setValue(this.defaultFilters);
    }
    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }
    get isFilterDefault(): boolean {
        const value: string[] = this.filterControl.value?.slice() ?? [];
        const filters: string[] = this.defaultFilters.slice();
        value.sort((a, b) => a.localeCompare(b));
        filters.sort((a, b) => a.localeCompare(b));
        if (value.join(",") !== filters.join(",")) return false;
        return !this.showFilter;
    }
    resetSearchTerm(event?: MouseEvent): void {
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
    }
    resetFilters(event?: MouseEvent): void {
        this.filterControl.setValue(this.defaultFilters);
        this.showFilter = false;
        this.updateList();
    }
    toggleSearch(event: MouseEvent): void {
        this.cancelEdit();
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
        this.autoToggleFilter();
    }
    toggleFilter(event: MouseEvent): void {
        this.terminateEvent(event);
        this.cancelEdit();
        this.showFilter = !this.showFilter;
        if (this.showFilter)
            setTimeout(() => this.filterElement?.nativeElement.focus());
        this.autoToggleSearch();
    }
    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }
    autoToggleFilter(): void {
        if (!this.filterControl.value || this.isFilterDefault) {
            this.filterControl.setValue(this.defaultFilters);
            this.showFilter = false;
        }
    }
    onFocusOut(event: any): void {
        this.autoToggleFilter();
        this.autoToggleSearch();
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}

    isDocumentShared(doc: Document): boolean {
        return doc.repository?.type == Inquiry.object_type;
    }

    canEditDocument(doc: Document): boolean {
        return (
            this.currentAccount?.id == doc.uploaded_by?.id || // The user uploaded the document
            this.currentAccount?.hasRole("object.admin", doc.owner) || // The user is an administrator for the document owner
            this.currentAccount?.hasRole("object.admin", this.repository) || // The user is an administrator for the repository
            (this.repository instanceof Inquiry &&
                this.repository.isAdmin(this.currentAccount) &&
                !this.viewOnly) || // The user is an administrator for the inquiry
            (this.repository instanceof Case &&
                this.repository.isAdmin(this.currentAccount)) || // The user is an administrator for the case
            this.currentAccount?.id == this.repository?.id || // This is the user's repository
            (this.repository instanceof Case &&
                this.currentAccount?.hasRole("object.edit", this.repository.shared)) || //user has edit role on the inquiry
            (this.repository instanceof Case &&
                this.currentAccount?.hasRole("object.edit", this.repository)) || //user has edit role on the case
            false
        );
    }
    viewDocument(event: MouseEvent, doc: Document): void {
        this.cancelEdit();
        this.trigger?.closeMenu();
        this.viewObject(event, doc, true);
    }
    editDocument(event: MouseEvent, doc: Document): void {
        this.trigger?.closeMenu();
        this.terminateEvent(event);
        this.alias = doc.alias;
        this.documentType = doc.file_type;
        this.editing = doc;
    }
    deleteDocument(event: MouseEvent, doc: Document): void {
        this.cancelEdit();
        this.trigger?.closeMenu();
        this.deleteObject(event, doc);
    }
    cancelEdit(event?: MouseEvent): void {
        this.terminateEvent(event);
        this.alias = undefined;
        this.documentType = undefined;
        this.editing = undefined;
    }
    saveDocument(event: MouseEvent, doc: Document): void {
        this.terminateEvent(event);
        doc.alias = this.alias == "" ? undefined : this.alias;
        doc.file_type = this.documentType;
        this.service
            .update(doc)
            .subscribe((updated: Document | undefined) => doc.update(updated));
        this.alias = undefined;
        this.editing = undefined;
    }
    canViewDocument(doc: ObjectOrReference<Document>): boolean {
        return (
            doc instanceof Document &&
            (doc.file_format == "application/pdf" ||
                !!doc.file_format?.startsWith("image/"))
        );
    }

    isRedactable(doc: ObjectOrReference<Document>): boolean /* NOSONAR */ {
        return (
            doc instanceof Document &&
            (doc.file_format == "application/pdf" ||
                !!doc.file_format?.startsWith("image/"))
        );
    }

    documentClicked(event: MouseEvent, doc?: Document): void {
        this.cancelEdit();
        if (doc) this.clearNotification(doc);

        if (
            doc &&
            (this.needsRedaction(doc) || this.isRedactable(doc)) &&
            this.canEditDocument(doc)
        ) {
            this.editObject(event, doc, true);
        } else if (doc && this.canViewDocument(doc)) {
            this.viewObject(event, doc, true);
        } else if (doc) this.downloadDocument(event, doc);
    }

    editObject(
        event: MouseEvent,
        object?: Document,
        asDialog: boolean = false,
        viewOnly: boolean = false,
        objectName?: string,
    ): ObjectViewEntryPoint<Document> | undefined {
        const instance = super.editObject(
            event,
            object,
            asDialog,
            viewOnly,
        ) as DocumentComponent;
        if (objectName) {
            instance.objectName = objectName;
        }
        instance.dialogReference?.afterClosed().subscribe(() => {
            if (this.documentToOpen) {
                this.documentToOpenUsed.emit();
            }
        });

        let repository = this.repository!;
        if (this.tabChangeProps) {
            if (this.tabChangeProps.assignment)
                instance.assignment = this.tabChangeProps?.assignment;
            if (this.tabChangeProps.share && this.repository?.type == "program.case") {
                repository = (this.repository as Case).shared;
            }
        }
        if (this.owner && this.repository && !viewOnly)
            instance.initializeUploader(this.owner, repository, this.sources);

        if (object) {
            this.clearNotification(object);
        }
        return instance;
    }

    protected objectDialogConfiguration(
        object: Document,
        mode: ObjectViewMode,
    ): MatDialogConfig {
        let config = super.objectDialogConfiguration(object, mode);
        if (mode != ObjectViewMode.Create) {
            config = {
                ...(config || {}),
                maxWidth: "100%",
            };
        } else {
            config = {
                ...(config || {}),
                minWidth: "50%",
                disableClose: true,
                autoFocus: "dialog",
                hasBackdrop: true,
            };
        }
        return config;
    }

    onSortChange(event: Sort): void {
        this.cancelEdit();
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    downloadDocument(event: MouseEvent, document: Document): void {
        this.terminateEvent(event);
        this.trigger?.closeMenu();
        this.service.download(document);
    }
    canShareDocument(document: Document): boolean {
        return (
            !!(this.currentAccount?.orgs
                .map(o => o.id)
                .includes(document.owner.id)
            ) && 
            !this.viewOnly
        )
    }
    shareDocument(event: MouseEvent, document: Document): void {
        this.cancelEdit();
        this.terminateEvent(event);
        this.trigger?.closeMenu();
        this.confirmShare(document).subscribe((confirm: boolean) => {
            if (confirm && this.repository instanceof Case) {
                let newRepo: DocumentRepository;
                if (this.isDocumentShared(document)) {
                    newRepo = this.repository;
                } else {
                    newRepo = this.repository.shared;
                }

                document.repository = newRepo.asReference;
                this.service.update(document).subscribe();
            }
        });
    }

    confirmShare(doc: Document): Observable<boolean> {
        let msg = "";
        const repository = doc.repository;
        const isSharing = repository?.type === Case.object_type;
        let acctRoles = this.currentAccount?.rolesForObject(repository) ?? [];

        const hasProviderRoles = !!acctRoles
            .map((ar: Role) => ar.role)
            .filter((role: string) => role.indexOf("provider.") != -1).length;

        const hasPatientRoles = !!acctRoles
            .map((ar: Role) => ar.role)
            .filter((role: string) => role.indexOf("patient.") != -1).length;
        // Determine the subject based on roles
        const subject =
            hasProviderRoles || hasPatientRoles ?
                "pharmaceutical company"
            :   "requesting physician";

        const baseMsg = `Are you sure you want to ${isSharing ? "share" : "unshare"} this document with external contacts?`;

        const actionMsg =
            isSharing ?
                `<br>This will send a copy of the document to the ${subject}.`
            :   ` This will revoke access from the ${subject}.`;

        msg = baseMsg + actionMsg;

        return this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: msg,
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed();
    }

    get isAdmin(): boolean {
        if (this.repository instanceof Case || this.repository instanceof Inquiry)
            return this.repository.isAdmin(this.currentAccount);
        return !!this.currentAccount?.hasRole("object.admin", this.repository);
    }

    canDelete(document: Document): boolean {
        const isUploader = this.currentAccount?.id === document.uploaded_by?.id;
        return !this.isShared && (isUploader || this.isAdmin) && !this.viewOnly;
    }
    get canUpload() {
        return !this.viewOnly;
    }
    documentTypeDisplay(type: string): string {
        const documentType = Document.documentTypes.find(
            (dt: DocumentTypeDefinition) => dt.type == type,
        );
        return documentType?.displayName ?? "Unknown Type";
    }
    documentTypeGroups(): (string | undefined)[] {
        return Document.documentTypes
            .map((dt: DocumentTypeDefinition) => dt.group)
            .filter(
                (
                    value: string | undefined,
                    index: number,
                    list: (string | undefined)[],
                ) => list.indexOf(value) === index,
            );
    }
    documentTypesForGroup(group: string | undefined): DocumentTypeDefinition[] {
        return Document.documentTypes
            .filter((dt: DocumentTypeDefinition) => dt.group == group)
            .sort((a: DocumentTypeDefinition, b: DocumentTypeDefinition) => {
                if (a.displayName < b.displayName) return -1;
                if (a.displayName > b.displayName) return 1;
                return 0;
            });
    }
    documentTypes(): DocumentTypeDefinition[] {
        return Document.documentTypes;
    }
    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        if (this.repository?.type == "program.case")
            filters["repo"] =
                this.repository.id + "," + (this.repository as Case).shared.id;
        else filters["repo"] = this.repository?.id ?? "";

        if (this.filterControl?.value) {
            filters["filter"] = this.filterControl.value.join(",");
        }
        return filters;
    }
    isToday(d: Date): boolean {
        const today = new Date();
        return d.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
    }

    protected onObjectUpdated(o: Document, change_set?: any): void {
        super.onObjectUpdated(o, change_set);
        this.checkForRedactionWarning();
    }
    protected onObjectDeleted(o: Document): void {
        super.onObjectDeleted(o);
        this.checkForRedactionWarning();
    }
    protected onObjectCreated(o: Document): void {
        super.onObjectCreated(o);
        this.checkForRedactionWarning();
    }

    protected checkForRedactionWarning(): void {
        this.getRedactionWarnings();
    }
    redactionProcessing(doc: ObjectOrReference<Document>): boolean {
        return doc instanceof Document && !!doc.attributes?.redaction_processing;
    }
    needsRedaction(doc: ObjectOrReference<Document>): boolean {
        return doc instanceof Document && !!doc.attributes?.detected_phi?.length;
    }
    redactionError(doc: ObjectOrReference<Document>): boolean {
        return doc instanceof Document && !!doc.attributes?.redaction_error;
    }

    hasNotification(document: Document): boolean {
        const notification = this.getNotificationForDocument(document);

        return !!notification;
    }
    matBadge(document: Document) {
        const hasNotification = this.hasNotification(document);
        if (!hasNotification) return undefined;
        return "!";
    }
    getNotificationForDocument(document: Document) {
        return this?.notifications?.find((n) => n.object.id === document?.id);
    }

    clearNotification(document: Document) {
        const notification = this.getNotificationForDocument(document);
        if (!notification) return;
        this.notifications = this.notifications.filter((n) => n.id !== notification.id);
        this.appNotificationService.clear(notification).subscribe();
    }

    rerunRedaction(event: MouseEvent, doc: Document): void {
        this.terminateEvent(event);
        this.trigger?.closeMenu();
        if (!doc) {
            console.error("Document is null or undefined");
            return;
        }
        if (
            doc?.id &&
            this.redactionEnabled &&
            this.canEditDocument(doc) &&
            this.isRedactable(doc)
        ) {
            if (!doc.attributes) {
                doc.attributes = {};
            }
            doc.attributes.detected_phi = [];
            delete doc.attributes.redaction_error;
            doc.initial_redaction_completed = false;
            doc.attributes.redaction_processing = true;
            // Invoke the runRedaction method
            this.service.runRedaction([doc.id]).subscribe({
                next: () => {
                    if (doc.id) {
                        this.service.retrieve(doc.id);
                    }
                },
                error: (err) => {
                    console.error("Error re-running redaction", err);
                    doc.attributes.redaction_processing = false;
                },
            });
        }
    }

    shouldShowRerunRedaction(doc: Document): boolean {
        // 2594, 2441 allow re-processing of documents that have errors or are stuck in processing
        return (
            this.redactionEnabled && this.isRedactable(doc) && this.canEditDocument(doc)
        );
    }
}
