import {
    Component,
    Input,
    forwardRef,
    OnInit,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable, of } from "rxjs";
import { debounceTime, filter, tap, startWith, map } from "rxjs/operators";
import {
    DropDownAutoCompleteValidator,
    InstantErrorStateMatcher,
} from "src/common/utilities/validators";
import { NamedObject } from "src/services/models/api-object";

export interface Hint {
    text: string;
    condition: boolean;
    class: string;
}

@Component({
    selector: "autocomplete-search",
    templateUrl: "./autocomplete-search.component.html",
    styleUrls: ["./autocomplete-search.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ObjectAutocompleteSearchComponent),
            multi: true,
        },
    ],
})
export class ObjectAutocompleteSearchComponent<T extends NamedObject>
    implements ControlValueAccessor, OnInit, OnChanges
{
    @Input() class = "flexible";
    @Input() label?: string;
    @Input() placeholder: string = "Search";
    @Input() options: Observable<T[]> = of([]);
    @Input() displayProperty: string = "";
    @Input() customFilter?: (option: T, filterValue: string) => boolean;
    @Input() formControl = new FormControl<T | string | null>(null);
    @Input() hint?: Hint;
    @Input() showName = false;

    displayFn(item: T | undefined): string {
        return item?.displayName ? item.displayName : "";
    }

    loading = true;
    filteredOptions: Observable<T[]> = of([]);
    @Input()
    defaultOptions: T[] = [];

    ngOnInit() {
        this.options
            .pipe(
                tap((options) => {
                    this.loading = false;
                    this.defaultOptions = options;
                }),
            )
            .subscribe();

        this.filteredOptions = this.setupAutocompleteSearch();

        if (!this.formControl.hasAsyncValidator(DropDownAutoCompleteValidator)) {
            this.formControl.addAsyncValidators(DropDownAutoCompleteValidator);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options || changes.defaultOptions) {
            this.filteredOptions = this.setupAutocompleteSearch();
        }
    }

    setupAutocompleteSearch() {
        return this.formControl.valueChanges.pipe(
            debounceTime(300),
            filter((search) => typeof search === "string"),
            startWith(""),
            map((search) => {
                if (!search) return this.defaultOptions;
                return this.filter(search as string, this.defaultOptions);
            }),
        );
    }

    private filter(searchValue: string, options: T[]) {
        const filterValue = searchValue.toLowerCase();
        if (this.customFilter) {
            return options.filter((option) => this.customFilter!(option, filterValue));
        }

        return options.filter(
            (option) =>
                option?.displayName?.toLowerCase().includes(filterValue) ??
                option.name?.includes(filterValue),
        );
    }

    get invalidAutoComplete() {
        return this.formControl.errors?.invalidAutoComplete;
    }

    onChange = (options: T[]) => {};
    onTouched = () => {};
    writeValue(options: T[]): void {} // NOSONAR
    registerOnChange(fn: (options: T[]) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    errorMatcher = new InstantErrorStateMatcher();
}
