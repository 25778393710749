import { Component } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
    selector: "docusign-envelope-status",
    template: ``,
})
export class DocusignEnvelopeStatusComponent {
    constructor(protected route: ActivatedRoute) {
        this.route.queryParams.subscribe((params: Params) => {
            const envelopeId = params["envelopeId"];
            const status = params["status"];
            console.log(`Envelope ${envelopeId} has status ${status}`);

            const msg = {
                source: "dsResponse",
                hash: window.location.hash || "",
                href: window.location.href || "",
            };

            if (window.parent && window.parent.opener /* NOSONAR */) {
                window.parent.opener.postMessage(msg, "*"); /* NOSONAR */
            } else if (window.opener) {
                window.opener.postMessage(msg, "*"); /* NOSONAR */
            } else {
                window.parent.postMessage(msg, "*"); /* NOSONAR */
            }
        });
    }
}
