<div class="container">
    <div class="types-header flex">
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search margin-right"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Data Forms</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Forms"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Create Form
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="display_name">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="flex stack types-name"
            >
                Name
            </mat-header-cell>
            <mat-cell
                *matCellDef="let form"
                (click)="canEdit(form) && editObject($event, form, true)"
                class="flex stack types-name"
            >
                <div>{{ form.displayName }}</div>
                <div
                    *ngIf="!!form?.display_name || form.internal_name"
                    class="secondary super-small"
                >
                    {{ form.name ?? form.internal_name }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="owner_type">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="flex stack repo-chip"
            >
                Repository
            </mat-header-cell>
            <mat-cell
                *matCellDef="let form"
                (click)="canEdit(form) && editObject($event, form, true)"
                class="flex stack repo-chip"
            >
                <mat-chip *ngIf="!form.owner" color="primary" selected>
                    {{ getFormRepoName(form) }}
                </mat-chip>
                <mat-chip *ngIf="!!form.owner" color="accent" selected>
                    {{ getFormRepoName(form) }}
                </mat-chip>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="owner_id">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="flex stack types-chip"
            >
                Owner
            </mat-header-cell>
            <mat-cell
                *matCellDef="let form"
                (click)="canEdit(form) && editObject($event, form, true)"
                class="flex stack types-chip"
            >
                <mat-chip-set>
                    <mat-chip *ngIf="!form.owner" color="primary" selected>
                        {{ getFormOwnerName(form) }}
                    </mat-chip>
                    <mat-chip *ngIf="!!form.owner" color="accent" selected>
                        {{ getFormOwnerName(form) }}
                    </mat-chip>
                </mat-chip-set>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let form" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ form: form }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="loading">Loading forms...</span>
                <span *ngIf="!loading">No data forms found.</span>
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-form="form">
                <button mat-menu-item (click)="viewObject($event, form, true)">
                    View
                </button>
                <button
                    mat-menu-item
                    (click)="canEdit(form) && editObject($event, form, true)"
                    [disabled]="!canEdit(form)"
                >
                    Edit
                </button>
                <button
                    mat-menu-item
                    (click)="canCreate(form) && duplicateObject($event, form, true)"
                    [disabled]="!canCreate(form)"
                >
                    Duplicate
                </button>
                <button
                    mat-menu-item
                    (click)="canDelete(form) && deleteObject($event, form)"
                    [disabled]="!canDelete(form)"
                >
                    Delete
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
