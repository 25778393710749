import {
    APIObject,
    NamedObject,
    ObjectOrReference,
    ObjectReference,
} from "./api-object";
import { Organization } from "./organization";
import { DerivedPermission, Role } from "./role";

export class AccountReference extends ObjectReference {
    is_active?: boolean;
    is_invited?: boolean;
    invite_expired?: boolean;

    initialize(data?: any, patch: boolean = false): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "is_active");
        this.setMember(data, patch, "is_invited");
        this.setMember(data, patch, "invite_expired");
    }
}

export class Account extends NamedObject {
    static object_type: string = "iam.account";
    email!: string;
    password?: string; // write-only, optional
    is_staff!: boolean; // read_only
    last_login!: Date; // read_only
    is_locked!: boolean; // read_only
    is_active!: boolean; // read_only
    is_invited?: boolean; // read_only
    invite_expired?: boolean; // read_only
    first_name!: string;
    last_name!: string;
    phone?: string; // optional
    roles!: Role[];
    config?: string; // validation only - envrypted configuration values
    completed_walkthrough?: boolean;
    send_invite!: boolean; // write-only, optional
    is_local_user?: boolean; // read-only
    status?: string; // optional
    settings!: AccountSettings; // the settings for the organization
    derived_permissions!: DerivedPermission[]; // read-only
    countries?: string[]; // optional
    country_permission?: string; // optional
    program_id_permission?: string; // optional

    get displayName(): string | undefined {
        if (!this.first_name || !this.last_name) return undefined;
        return this.first_name + " " + this.last_name;
    }
    get role_count(): number {
        return this.roles.length;
    }
    get asReference(): ObjectReference {
        return new AccountReference(this);
    }

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["password", "phone", "send_invite", "status", "countries", "country_permission", "program_id_permission"]);
        this._readOnly.push(
            ...[
                "is_staff",
                "last_login",
                "is_locked",
                "is_active",
                "is_local_user",
                "is_invited",
                "invite_expired",
                "derived_permissions",
            ],
        );
        super.initialize(data, patch);
        this.setMember(data, patch, "email");
        this.setMember(data, patch, "is_staff");
        this.setMember(data, patch, "last_login", Date);
        this.setMember(data, patch, "is_locked");
        this.setMember(data, patch, "is_active");
        this.setMember(data, patch, "first_name");
        this.setMember(data, patch, "last_name");
        this.setMember(data, patch, "phone");
        this.setMember(data, patch, "roles", Role, true);
        this.setMember(data, patch, "config");
        this.setMember(data, patch, "completed_walkthrough");
        this.setMember(data, patch, "send_invite");
        this.setMember(data, patch, "is_local_user");
        this.setMember(data, patch, "is_invited");
        this.setMember(data, patch, "invite_expired");
        this.setMember(data, patch, "status");
        this.setMember(data, patch, "settings", AccountSettings);
        this.setMember(data, patch, "derived_permissions");
        this.setMember(data, patch, "countries");
        this.setMember(data, patch, "country_permission");
        this.setMember(data, patch, "program_id_permission");
        if (!this.settings) {
            this.settings = {} as AccountSettings;
        }
    }

    hasDerivedPermission(permission: string, object: APIObject): boolean {
        return !!this.derived_permissions.find(
            (p: DerivedPermission) =>
                p.object_id === object.id &&
                p.permission.toLowerCase() === permission.toLowerCase(),
        );
    }
    hasRole(role: string, object?: APIObject): boolean {
        if (!object)
            return !!this.roles.find(
                (r: Role) => r.role.toLowerCase().indexOf(role?.toLowerCase()) != -1,
            );
        else
            return !!this.roles.find(
                (r: Role) =>
                    r.object?.id === object?.id &&
                    r.role.toLowerCase().indexOf(role.toLowerCase()) != -1,
            );
    }
    objectsForRole<T extends APIObject>(
        role: string,
        type?: string,
    ): ObjectOrReference<T>[] {
        return this.roles
            .filter((r: Role) => r.role.toLowerCase().indexOf(role.toLowerCase()) != -1)
            .map((r: Role) => r.object as T)
            .filter(
                (obj: ObjectOrReference<T>) => !type || type.toLowerCase() === obj.type,
            );
    }
    rolesForObject(obj?: APIObject): Role[] {
        return obj ? this.roles.filter((r: Role) => r.object?.id === obj.id) : [];
    }
    rolesForObjectType(type: string): Role[] {
        return this.roles.filter((r: Role) => r.object?.type === type);
    }

    get isSystemAdministrator(): boolean {
        return this.is_staff;
    }

    get completedWalkthrough(): boolean {
        return !!this.completed_walkthrough;
    }
    isViewOnlyOnOrg(org?: ObjectOrReference<Organization>) {
        return org ? this.hasRole("object.view", org) : false;
    }

    get orgs(): ObjectOrReference<Organization>[] {
        return (
            this?.roles
                .map((r: Role) => r.object)
                .filter(
                    (r: ObjectOrReference<APIObject>) => r.type === "iam.organization",
                )
                .map(
                    (r: ObjectOrReference<APIObject>) =>
                        r as ObjectOrReference<Organization>,
                )
                .filter(
                    (org: ObjectOrReference<Organization>) =>
                        !this.isViewOnlyOnOrg(org),
                ) || []
        );
    }
}

export class AccountSettings extends APIObject {
    static object_type: string = "iam.accountsettings";

    email_notifications?: boolean;
    initialize(data?: any, patch: boolean = false): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "email_notifications");
    }
}
