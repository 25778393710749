import { Component, Inject, inject } from "@angular/core";
import {
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { APIListResult, ProgramReference } from "src/services/models/api-object";
import { Organization } from "src/services/models/organization";
import { ProgramService } from "src/services/program.services";
import { SessionService } from "src/services/session.service";
export type ExportCaseDataDialogProps = {
    organization: Organization;
};
@Component({
    selector: "export-case-data-dialog",
    templateUrl: "./exportCaseData.component.html",
})
export class ExportCaseDataDialog {
    get data(): ExportCaseDataDialogProps {
        return this.data_;
    }
    protected session: SessionService;
    protected formBuilder: UntypedFormBuilder;

    programService: ProgramService;
    programs?: ProgramReference[] = [];
    control = new FormControl<ProgramReference[]>([]);

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data_: ExportCaseDataDialogProps,
        protected dialogRef: MatDialogRef<ExportCaseDataDialog>,
    ) {
        this.session = inject(SessionService);
        this.session.onLogout.subscribe(() => this.dialogRef.close(false));

        this.formBuilder = inject(UntypedFormBuilder);

        this.programService = inject(ProgramService);
        if (data_.organization?.id) {
            this.programService
                .list({ organization: data_.organization.id, use_reference: "True" })
                .subscribe((programs: APIListResult<ProgramReference>) => {
                    //this only needs the id and name of the program
                    this.programs = programs as ProgramReference[];
                });
        }
    }
    get formControl() {
        return this.control as UntypedFormControl;
    }
    submit(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        this.dialogRef.close(this.control.value);
    }
}
