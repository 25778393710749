import { Component, OnInit, ViewChild, inject } from "@angular/core";
import { Location } from "@angular/common";
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { catchError, concatMap, filter, finalize, map, of } from "rxjs";

import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { SessionComponent } from "src/services/components/session.component";
import { OptionalObjectOrReference } from "src/services/models/api-object";
import { DataForm } from "src/services/models/data";
import { Intake } from "src/services/models/intake";
import { Country } from "src/services/models/country";
import { Program, ProgramCountry } from "src/services/models/program";
import {
    CompoundDataTypeFactory,
    DataFieldService,
    DataFormFieldFactory,
    DataFormService,
    DataTypeService,
} from "src/services/data.services";
import {
    CountryService,
    IntakeService,
    InquiryService,
    ProgramService,
    ProductService,
    ProgramCountryService,
} from "src/services/program.services";
import { Product } from "src/services/models/product";
import { contrast, getDefaultBgColor } from "src/common/utilities/utilities";

enum IntakeStep {
    Role = 0,
    CountryProduct = 1,
    Intake = 2,
    Legal = 3,
    Submitted = 4,
    Error = 5,
}
type SelectionObject = { value: string; display: string };

@Component({
    selector: "intake",
    templateUrl: "./intake.component.html",
    styleUrls: ["./intake.component.scss"],
})
export class IntakeComponent extends SessionComponent implements OnInit {
    intakeRoles: SelectionObject[] = [
        { value: "provider.physician", display: "Healthcare Provider" },
        { value: "patient", display: "Patient" },
        { value: "patient.caregiver", display: "Family Member" },
    ];

    @ViewChild(DataFormComponent) formComponent?: DataFormComponent;

    ObjectViewMode = ObjectViewMode;
    protected intakeService: IntakeService;
    protected inquiryService: InquiryService;
    protected snackBar!: MatSnackBar;
    readonly currentYear: number = new Date().getFullYear();
    private readonly countryService = inject(CountryService);
    private readonly programService = inject(ProgramService);

    _currentStep: IntakeStep = IntakeStep.Role;
    activateRouteURL: string;
    availableCountries: Country[] = [];
    countryProductGroup: FormGroup;
    intake?: Intake;
    lastStep?: IntakeStep;
    legalGroup: FormGroup;
    loaded: boolean = false;
    logo?: string;
    bgColor?: string = getDefaultBgColor();
    textColor?: string = "#ffffff";
    orgSlug: string | undefined | null;
    productOptions: Product[] = [];
    role?: string;
    selectedForm: OptionalObjectOrReference<DataForm>;
    selectedProgram?: Program;
    steps = IntakeStep;
    templateMedaLogoColor: string = "brightness(100%)";

    get templateLogoSource(): string | undefined {
        if (!this.logo && this.intake?.logo)
            // random will force the browser to load image from the server instead of the cache
            // only way for logo updates to be rendered immediately in the intake component without backend changes
            this.logo =
                this.intakeService.session.environment.services +
                "/media/" +
                this.intake?.logo +
                "?random+=" +
                Math.random();
        return this.logo;
    }
    get templateBgColor(): string | undefined {
        let bgColor = this.bgColor;
        if (this.intake?.bgcolor) {
            bgColor = this.intake.bgcolor;
            this.templateMedaLogoColor =
                contrast(this.intake.bgcolor) === "light" ? "brightness(0%)" : (
                    "brightness(100%)"
                );
        }
        return bgColor;
    }
    get templateTextColor(): string | undefined {
        return this.intake?.textcolor ? this.intake?.textcolor : this.textColor;
    }
    get currentStep(): IntakeStep {
        return this._currentStep;
    }
    set currentStep(step: IntakeStep) {
        if (step != this._currentStep) {
            this.lastStep = this._currentStep;
            this._currentStep = step;
            if (step == IntakeStep.Role) this.lastStep = undefined;
        }
    }
    get physicianLink(): string {
        return (
            window.location.origin +
            "/" +
            this.route.snapshot.url.join("/") +
            "?role=provider"
        );
    }

    get availableRoles(): SelectionObject[] {
        return this.intakeRoles.filter((role: SelectionObject) => {
            return (
                (role.value == "provider" && this.intake?.settings.hcp) ||
                (role.value == "patient" &&
                    this.intake?.settings.allow_patient &&
                    this.intake?.settings.patient) ||
                (role.value == "family" &&
                    this.intake?.settings.allow_family &&
                    this.intake?.settings.family)
            );
        });
    }

    get responseTime() {
        return this?.selectedProgram?.response_time;
    }
    get allowPatientIntake(): boolean {
        return !!this.intake?.settings.allow_patient && !!this.intake?.settings.patient;
    }
    get allowFamilyIntake(): boolean {
        return !!this.intake?.settings.allow_family && !!this.intake?.settings.family;
    }
    get hasCountry(): boolean {
        return !!this.countryControl?.value;
    }
    get hasProduct(): boolean {
        return !!this.productControl?.value;
    }
    get countryControl(): FormControl<Country> {
        return this.countryProductGroup.get("country") as FormControl<Country>;
    }
    get productControl(): FormControl<Product> {
        return this.countryProductGroup.get("product") as FormControl<Product>;
    }
    constructor(
        protected route: ActivatedRoute,
        protected fb: UntypedFormBuilder,
        protected location: Location,
        protected router: Router,
    ) {
        super();
        this.intakeService = inject(IntakeService);
        this.inquiryService = inject(InquiryService);
        this.snackBar = inject(MatSnackBar);

        /* Ensure factories necessary for intake are available */
        inject(DataTypeService);
        inject(DataFieldService);
        inject(DataFormService);
        inject(DataFormFieldFactory);
        inject(CompoundDataTypeFactory);
        inject(ProgramCountryService);
        inject(ProductService);

        this.activateRouteURL = this.route.snapshot.url[0].path;

        this.session.onMessage.subscribe((msg: string) => {
            this.snackBar.open(msg, undefined, { duration: 2500 });
        });
        this.countryProductGroup = this.fb.group({
            country: [undefined],
            product: [undefined],
        });

        this.legalGroup = this.fb.group({
            share_auth: [false, Validators.required],
            acknowledge_phi: [false, Validators.required],
            comm_consent: [false, Validators.required],
        });
    }
    private subscribeToCheckboxChanges(): void {
        const shareAuth = this.legalGroup.get("share_auth");
        const acknowledgePhi = this.legalGroup.get("acknowledge_phi");
        const commConsent = this.legalGroup.get("comm_consent");
        if (!shareAuth || !acknowledgePhi || !commConsent) return;

        shareAuth?.valueChanges.subscribe((value) => {
            if (value) {
                shareAuth.setValue(new Date(), { emitEvent: false });
            }
        });

        acknowledgePhi?.valueChanges.subscribe((value) => {
            if (value) {
                acknowledgePhi.setValue(new Date(), { emitEvent: false });
            }
        });

        commConsent.valueChanges.subscribe((value) => {
            if (value) {
                commConsent.setValue(new Date(), { emitEvent: false });
            }
        });
    }
    ngOnInit(): void {
        this.subscribeToCheckboxChanges();
        const routeParams = this.route.snapshot.paramMap;
        let organizationSlug = routeParams.get("organizationSlug");
        this.orgSlug = organizationSlug;

        let role = this.route.snapshot.queryParamMap.get("role");
        if (role == "provider") role = "provider.physician";
        if (role == "family") role = "patient.caregiver";

        if (organizationSlug) {
            this.intakeService
                .config(organizationSlug)
                .pipe(finalize(() => (this.loaded = true)))
                .subscribe({
                    next: (intake: Intake | undefined) => {
                        this.intake = intake;
                        const selectedRole = this.availableRoles.find(
                            (r: SelectionObject) => r.value == role,
                        );
                        this.role = selectedRole?.value;
                        this.availableCountries = intake?.countries ?? [];
                    },
                });
        }

        this.setupCountryProductListeners();
    }
    setupCountryProductListeners(): void {
        this.countryControl.valueChanges.subscribe((country: Country) =>
            this.updateProductOptions(country),
        );

        this.productControl.valueChanges
            .pipe(filter((v) => !!v))
            .subscribe((product: Product) => {
                this.setProviderIntakeForm();
            });
    }

    setProviderIntakeForm(): void {
        const countryId = this.countryControl.value?.id;
        const productId = this.productControl.value?.id;
        if (!countryId || !productId) return;
        this.intakeService.form(countryId, productId).subscribe({
            next: (forms) => {
                setTimeout(() => {
                    if (forms && forms.length > 0) this.selectedForm = forms[0];
                    else this.selectedForm = this.intake?.settings.hcp;
                }, 3000);
            },
            error: () => {
                this.selectedForm = this.intake?.settings.hcp;
            },
        });
    }

    setRole(role: string): void {
        this.role = role;
        if (this.role == "provider.physician") {
            this.selectedForm = undefined; // Don't show a form until we determine whether the country/product form exists
            this.currentStep = IntakeStep.CountryProduct;
            return;
        }
        if (this.role == "patient") this.selectedForm = this.intake?.settings.patient;
        if (this.role == "patient.caregiver")
            this.selectedForm = this.intake?.settings.family;
        this.currentStep = IntakeStep.Intake;
    }

    handleCountryFlagError(country: Country): void {
        country.flag_url = this.countryService.fallbackFlagUrl;
    }

    programCountries: ProgramCountry[] = [];
    updateProductOptions(country: Country): void {
        if (!this.intake?.organization?.id || !country.id) return;
        this.productOptions = 
            this.intake?.products.filter(p => p.published) ?? [];
        if (this.productControl.value) {
            this.selectedForm = undefined; // Don't show a form until we determine whether the country/product form exists
            this.productControl.reset();
        }
        if (!this.productOptions.length) {
            // if no products for the selected country are avaialbe.
            // skip product selection and proceed to intake form
            this.currentStep = IntakeStep.Intake;
        }
    }

    get legalValid(): boolean {
        return (
            !!this.legalGroup.get("share_auth")?.value &&
            !!this.legalGroup.get("acknowledge_phi")?.value &&
            !!this.legalGroup.get("comm_consent")?.value
        );
    }

    onBackButton(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.currentStep == IntakeStep.Submitted) {
            this.currentStep = IntakeStep.Legal;
        } else if (this.currentStep == IntakeStep.Legal) {
            this.currentStep = IntakeStep.Intake;
        } else if (this.currentStep == IntakeStep.Intake) {
            if (this.role == "provider.physician") {
                this.currentStep = IntakeStep.CountryProduct;
            } else {
                this.currentStep = IntakeStep.Role;
            }
        } else if (this.currentStep == IntakeStep.CountryProduct) {
            this.currentStep = IntakeStep.Role;
        }
    }
    onNextButton(event: MouseEvent): void {
        this.terminateEvent(event);
        if (
            this.currentStep != IntakeStep.Submitted &&
            this.currentStep != IntakeStep.Error
        ) {
            this.lastStep = this.currentStep;
        }
        if (this.currentStep == IntakeStep.CountryProduct) {
            this.currentStep = IntakeStep.Intake;
        } else if (this.currentStep == IntakeStep.Intake) {
            this.currentStep = IntakeStep.Legal;
        } else if (this.currentStep == IntakeStep.Legal) {
            this.loaded = false;
            this.currentStep = IntakeStep.Submitted;
            this.submitIntake();
        }
    }

    submitIntake(): void {
        this.formComponent?.formSubmitted
            .pipe(
                concatMap((result?: DataForm) => {
                    if (result instanceof DataForm) {
                        const data = {
                            organization:
                                this.intake?.organization.asReference.serialize(),
                            "request.role": this.role,
                            "request.source": "hosted",
                            "request.country":
                                this.countryControl?.value?.asReference.serialize(),
                            "request.product":
                                this.productControl?.value?.asReference.serialize(),
                            "request.form": result.serialize(),
                            "request.consent.communication":
                                this.legalGroup.get("comm_consent")?.value,
                            "request.consent.phi":
                                this.legalGroup.get("acknowledge_phi")?.value,
                            "request.consnet.share":
                                this.legalGroup.get("share_auth")?.value,
                        };

                        return this.inquiryService.intake(data).pipe(
                            map(() => undefined),
                            catchError((err: any) => of(err)),
                        );
                    }
                    return of(result);
                }),
            )
            .subscribe((error: any) => {
                if (error) this.currentStep = IntakeStep.Error;
                else this.currentStep = IntakeStep.Submitted;
                this.loaded = true;
            });

        // actually initiate the submission
        this.formComponent?.onSave();
    }
}
