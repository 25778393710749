import { Account } from "./account";
import {
    APIObject,
    NamedObject,
    ObjectOrReference,
    ObjectReference,
} from "./api-object";
import { FileItem } from "ng2-file-upload";
import { Inquiry } from "./inquiry";
import { Case } from "./case";

export class DocusignAccount extends NamedObject {
    static readonly object_type: string = "docusign.docusignaccount";
    is_default!: boolean;
    requires_reauthorization!: boolean; // read-only

    token?: DocusignToken; // non-model field

    initialize(data?: any, patch: boolean = false): void {
        this._readOnly.push(...["token", "requires_reauthorization"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "is_default");
        this.setMember(data, patch, "token");
        this.setMember(data, patch, "requires_reauthorization");
    }
}

export class DocusignToken extends APIObject {
    static readonly object_type: string = "docusign.docusigntoken";
    email?: string; // optional
    authorization?: string; // write-only
    accounts!: DocusignAccount[]; // read-only
    requires_authorization!: boolean; // read-only
    state?: string;

    initialize(data?: any, patch: boolean = false): void {
        this._optional.push(...["email"]);
        this._readOnly.push(...["accounts", "requires_authorization"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "email");
        this.setMember(data, patch, "authorization");
        this.setMember(data, patch, "accounts", DocusignAccount, true);
        this.setMember(data, patch, "requires_authorization");
        this.setMember(data, patch, "state");

        if (!this.accounts) this.accounts = [];
        this.accounts.forEach((account: DocusignAccount) => (account.token = this));
    }
}

// The DocusignTab object is deprecated due to change of the docusign implementation and should not be used in new code.
export class DocusignTab extends APIObject {
    static readonly object_type: string = "docusign.docusigntab";
    document?: ObjectReference;
    label?: string;
    page?: number;
    x?: number;
    y?: number;

    fileItem?: FileItem; // non-model field, used to enable tab specification prior to uploaded a file

    initialize(data?: any, patch: boolean = false): void {
        this._readOnly.push(...["fileItem"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "document", ObjectReference);
        this.setMember(data, patch, "label");
        this.setMember(data, patch, "page");
        this.setMember(data, patch, "x");
        this.setMember(data, patch, "y");
        this.setMember(data, patch, "fileItem");
    }
}

export class DocusignSigner extends APIObject {
    static readonly object_type: string = "docusign.docusignsigner";
    signer!: ObjectOrReference<Account>;
    order!: number;
    completed?: Date;

    initialize(data?: any, patch: boolean = false): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "signer", ObjectReference);
        this.setMember(data, patch, "order");
        this.setMember(data, patch, "completed", Date);
    }
}

export class DocusignEnvelope extends APIObject {
    static readonly object_type: string = "docusign.docusignenvelope";
    repository!: ObjectOrReference<Case | Inquiry>; // Case for internal signatures or Inquiry for shared signatures
    signers!: DocusignSigner[];
    account!: ObjectOrReference<DocusignAccount>; // a DocusignAccount reference that will be used to send the envelope
    sent?: Date; // whether the envelope has been sent
    requires_reauthorization?: boolean; // read-only

    initialize(data?: any, patch: boolean = false): void {
        this._readOnly.push(...["sent", "requires_reauthorization"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "repository", ObjectReference);
        this.setMember(data, patch, "signers", DocusignSigner, true);
        this.setMember(data, patch, "account", ObjectReference);
        this.setMember(data, patch, "sent", Date);
        this.setMember(data, patch, "requires_reauthorization");
    }
}
