import { Component, Inject } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { forkJoin, of } from "rxjs";

import { ObjectComponent } from "src/common/components/object.component";
import { Assignment } from "src/services/models/assignment";
import { AssignmentService } from "src/services/program.services";
import { ObjectOrReference, ObjectReference } from "src/services/models/api-object";
import {
    IsDateValidator,
    setValuesAndValidators,
} from "src/common/utilities/validators";

@Component({
    selector: "delegate-assignment",
    templateUrl: "./frequency-assignment.dialog.html",
    styleUrls: ["./assignment.component.scss"],
})
export class FrequencyAssignmentDialog extends ObjectComponent<Assignment> {
    autosave = false;
    commiting = false;
    defaultTimeUnit: string = "Week";
    dialogTitle?: string = "Task Frequency";
    frequencyEndTypeControl = new FormControl<"repeat_forever" | "end_repeat_date">(
        "repeat_forever",
        Validators.required,
    );
    frequencyTypeControl = new FormControl<"one_time" | "recurring">(
        "one_time",
        Validators.required,
    );
    otherAssignments: ObjectOrReference<Assignment>[] = [];
    timeUnits: string[] = ["Week", "Month", "Year"];
    triggerControl = new FormControl<"due_date" | "dependencies" | "none">("none");
    triggerEdit = false;

    get minDateForDueDate() {
        return new Date();
    }
    get triggerValue() {
        return this.triggerControl.value;
    }
    get frequencyEndTypeValue() {
        return this.frequencyEndTypeControl.value;
    }
    get frequencyTypeValue() {
        return this.frequencyTypeControl.value;
    }
    get dependencyOptions() {
        let dependantIds = this.getAllDependants().map(d => d.id)
        return this.otherAssignments
            .filter((a): a is Assignment => !!a)
            .filter((a) => 
                !!a.parent && 
                !dependantIds.includes(a.id)
            ) ?? []
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<FrequencyAssignmentDialog>,
        protected service: AssignmentService,
    ) {
        super(service);
        this.dialogRef = dialogRef;
        this.dialogTitle = data.dialogTitle;
        this.otherAssignments = data.otherAssignments;
        this.object = data.object;
        this.triggerEdit = data.triggerEdit;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            due_date: [],
            dependencies: [],
            frequency_amount: [],
            frequency_unit: [],
            frequency_end_date: [],
            assignee: [],
        });
    }

    protected onCommitSuccess(v: Assignment | undefined): boolean {
        this.dialogRef.close(v);
        return super.onCommitSuccess(v);
    }

    protected setObject(v?: Assignment | undefined): void {
        super.setObject(v);

        if (this.otherAssignments.length) {
            const dependencies = this.otherAssignments.map((a) => {
                if (a instanceof ObjectReference) {
                    return this.service.resolveReference(a);
                }
                return of(a);
            });

            forkJoin(dependencies).subscribe((assignments) => {
                this.otherAssignments = assignments
                    .filter((a): a is Assignment => !!a)
                    .filter((a) => a.id !== this.object?.id)
            });
        }

        const triggerType =
            v?.due_date ? "due_date"
            : v?.dependencies.length ? "dependencies"
            : "none";
        this.triggerControl.setValue(triggerType);
        this.setTriggerInputs(triggerType);

        let frequencyType: "one_time" | "recurring" = "one_time";
        this.assignmentHasFrequency(v) ?
            (frequencyType = "recurring")
        :   (frequencyType = "one_time");
        this.frequencyTypeControl.setValue(frequencyType);
        this.setFrequencyInputs(frequencyType, false);

        const frequencyEndType =
            v?.frequency_end_date ? "end_repeat_date" : "repeat_forever";
        this.frequencyEndTypeControl.setValue(frequencyEndType);
        this.setFrequencyEndTypeInputs(frequencyEndType, false);
    }

    // This gets all of the current "fullObject"'s dependants, along with their dependant's dependants, etc. etc.
    getAllDependants(){
        let dependantTasks = (ass: Assignment): ObjectOrReference<Assignment>[] => {
            return this.otherAssignments.filter(
                (a: ObjectOrReference<Assignment>) =>
                    a instanceof Assignment &&
                    !!a.dependencies.find(
                        (b: ObjectOrReference<Assignment>) => b.id === ass?.id,
                    ),
            );
        }

        let deps = dependantTasks(this.fullObject as Assignment)
        let used = deps.map(d => d.id)

        let i = 0
        while (i < deps.length){
            let new_deps = dependantTasks(deps[i] as Assignment)
            new_deps.forEach((d) => {
                if (!used.includes(d.id)){
                    used.push(d.id)
                    deps.push(d)
                }
            })
            i++
        }
        return deps
    }

    assignmentHasFrequency(assignment?: ObjectOrReference<Assignment>) {
        return (
            assignment instanceof Assignment &&
            !!assignment.frequency_amount &&
            !!assignment.frequency_unit
        );
    }

    onCancel(): void {
        this.dialogRef.close(this.object);
    }

    setFrequencyInputs(v: "one_time" | "recurring", updateValues = false) {
        const controls = ["frequency_amount", "frequency_unit"];
        let values = null;
        let validators = null;

        switch (v) {
            case "recurring":
                validators = {
                    frequency_amount: [Validators.required, Validators.min(1)],
                    frequency_unit: [Validators.required],
                };
                break;
            case "one_time":
                if (updateValues) {
                    values = { frequency_amount: null, frequency_unit: null };
                    this.frequencyEndTypeControl.setValue("repeat_forever");
                    this.setFrequencyEndTypeInputs("repeat_forever", true);
                }
                break;
        }

        setValuesAndValidators(this.formGroup, controls, values, validators);
    }

    setFrequencyEndTypeInputs(
        v: "repeat_forever" | "end_repeat_date",
        updateValues = false,
    ) {
        const controls = ["frequency_end_date"];
        let values = null;
        let validators = null;

        switch (v) {
            case "repeat_forever":
                if (updateValues) {
                    values = { frequency_end_date: null };
                }
                break;
            case "end_repeat_date":
                validators = {
                    frequency_end_date: [IsDateValidator, Validators.required],
                };
                break;
        }

        setValuesAndValidators(this.formGroup, controls, values, validators);
    }

    setTriggerInputs(v: "due_date" | "dependencies" | "none", updateValues = false) {
        const controls = ["due_date", "dependencies"];
        let values = null;
        let validators = null;

        switch (v) {
            case "due_date":
                if (updateValues) {
                    values = { dependencies: [] };
                }
                validators = { due_date: [IsDateValidator, Validators.required] };
                break;
            case "dependencies":
                if (updateValues) {
                    values = { due_date: null };
                }
                validators = { dependencies: [ Validators.required ] };
                break;
            case "none":
                if (updateValues) {
                    values = { due_date: null, dependencies: [] };
                }
                // No validators needed for 'none', so leave validators as null
                break;
        }

        setValuesAndValidators(this.formGroup, controls, values, validators);
    }

    taskDisplayName(assignment: ObjectOrReference<Assignment>): string {
        return (
            (assignment instanceof Assignment ?
                assignment.task.displayName
            :   assignment.displayName) ?? ""
        );
    }
}
