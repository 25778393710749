import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
    inject,
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { debounceTime, filter, merge, Observable, of, switchMap, tap } from "rxjs";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { RequestFilter } from "src/common/utilities/request";
import { CaseComponent } from "src/program/components/case/case.component";
import { CompoundDataTypeFactory } from "src/services/data.services";
import {
    ObjectReference,
    ObjectOrReference,
    APIListResult,
} from "src/services/models/api-object";
import { AppNotification } from "src/services/models/appNotification";
import { Assignment } from "src/services/models/assignment";
import { Case } from "src/services/models/case";
import { DataForm } from "src/services/models/data";
import {
    AppNotificationService,
    MessageService,
} from "src/services/notification.services";
import { CaseService, InquiryService } from "src/services/program.services";
import { Document } from "src/services/models/document";
import { Inquiry } from "src/services/models/inquiry";
import { InquiryComponent } from "src/program/components/inquiry/inquiry.component";
import { Message } from "src/services/models/message";
import { RelativeDatePipe } from "src/common/utilities/relative-date.pipe";
import { TranslateService } from "@ngx-translate/core";
import { LocalizedDatePipe } from "src/common/utilities/localized-date.pipe";

type CasePropertyToOpen =
    | "formNotificationToOpen"
    | "documentNotificationToOpen"
    | "messageToOpen"
    | "assignmentToOpen";

type InquiryPropertyToOpen = "documentNotificationToOpen" | "messageToOpen";

@Component({
    selector: "appnotification-drawer",
    templateUrl: "./appnotification-drawer.component.html",
    styleUrl: "./appnotification-drawer.component.scss",
})
export class AppnotificationDrawerComponent extends ObjectAdminComponent<AppNotification> {
    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
    @Output() notificationCount = new EventEmitter<number>();
    @Output() closeDrawer = new EventEmitter<void>();
    @Input() sideNavOpened: boolean = true;

    unreadGroup = new FormGroup({
        showUnread: new FormControl<boolean>(false),
    });

    private itemSubscriptions: ObjectOrReference<AppNotification>[] = [];

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.list?.listChanged.subscribe(() => {
            this.onListChange();
        });
    }

    onListChange() {
        this.list.items.forEach((item) => {
            if (this.itemSubscriptions.includes(item)) return;
            item.objectUpdated.subscribe(() => {
                this.updateNotificationCount();
            });
            (item as AppNotification).object?.objectUpdated.subscribe(() => {
                this.updateNotificationCount();
            });
            this.itemSubscriptions.push(item);
        });
    }

    updateNotificationCount() {
        let id = String(this.currentAccount?.id);
        this.service.unreadCount(id).subscribe((count) => {
            this.unreadCount = count as number;
            this.notificationCount.emit(this.unreadCount);
        });
    }

    relativeDatePipe: RelativeDatePipe;
    localizedDatePipe: LocalizedDatePipe;
    caseService: CaseService;
    inquiryService: InquiryService;
    unreadCount: number = 0;
    constructor(
        protected service: AppNotificationService,
        protected changeDetection: ChangeDetectorRef,
        protected elementRef: ElementRef,
    ) {
        super(service, changeDetection, 10, "appnotification-drawer");

        this.list.refresh();
        this.updateNotificationCount();

        this.caseService = inject(CaseService);
        this.inquiryService = inject(InquiryService);

        let translateService = inject(TranslateService);
        this.localizedDatePipe = new LocalizedDatePipe(translateService);
        this.relativeDatePipe = new RelativeDatePipe(this.localizedDatePipe);

        inject(MessageService);
        inject(CompoundDataTypeFactory);

        merge(this.service.objectCreated, this.service.objectUpdated)
            .pipe(
                filter(
                    (v) =>
                        (v?.object as AppNotification)?.account?.id ===
                        this?.currentAccount?.id,
                ),
                debounceTime(2500),
                tap(() => this.updateNotificationCount()),
            )
            .subscribe();
    }

    @HostListener("document:mousedown", ["$event"])
    onDocumentClick(event: MouseEvent) {
        const targetElement = event.target as HTMLElement;

        // Close the drawer if the click is outside the drawer, but not if the click is on the notifications button
        // (the button has its own logic that will conflict with this event listener)
        if (
            !this.elementRef.nativeElement.contains(targetElement) &&
            targetElement.parentElement?.outerText !== "notifications"
        ) {
            this.closeDrawer.emit();
        }
    }

    loadMoreNotifications() {
        this.list.increasePageSizeSearch();
    }

    updateShowUnread() {
        this.list.refresh();
    }

    protected filter(filters: RequestFilter): RequestFilter {
        const showUnread = this.unreadGroup.controls.showUnread.value;
        const filter = super.filter(filters);

        filter["account"] = this.currentAccount?.id || "0";
        if (showUnread) filter["is_read"] = "False";

        return filter;
    }

    protected postSearch(items: AppNotification[]): AppNotification[] {
        this.updateNotificationCount();
        return items;
    }

    handleScroll(e: Event) {
        // due to how the material drawer works, the scroll event is only emitted on the parent component
        // so this function will have to be called by its parent unless its standalone
        const element = e.target as HTMLElement;
        const atBottom =
            element?.scrollHeight - element.scrollTop <= element?.clientHeight;

        if (atBottom) {
            this.loadMoreNotifications();
        }
    }

    markAllAsRead(event: MouseEvent) {
        this.terminateEvent(event);

        let id = String(this.currentAccount?.id);
        this.service.readAll(id).subscribe();
    }

    handleItemClick(
        event: MouseEvent,
        notification: ObjectOrReference<AppNotification>,
    ) {
        this.terminateEvent(event);

        if (notification instanceof ObjectReference) {
            notification = this.asObject(notification);
        }

        if (notification.object.type === "program.discussionentry") {
            this.handleDiscussionEntry(notification);
        } else if (notification.object.type === "program.assignment") {
            this.handleAssignment(notification);
        } else if (notification.object.type === "program.dataform") {
            this.handleDataForm(notification);
        } else if (notification.object.type === "program.document") {
            this.handleDocument(notification);
        } else if (notification.object.type === "notifications.message") {
            this.handleMessage(notification);
        } else {
            console.error(
                "Unhandled notification object type: ",
                notification.object.type,
            );
        }

        this.closeDrawer.emit();

        //clicking on a notification in the drawer, shold automatically mark it as read
        this.service.clear(notification).subscribe(() => {
            this.list.refresh();
        });
        return;
    }

    get canHaveMore() {
        return this.list.count !== this.list.items.length;
    }

    getIcon(notification: AppNotification | ObjectReference) {
        if (
            notification instanceof ObjectReference ||
            !notification.object ||
            !notification.object.type
        ) {
            return "info";
        }

        if (notification.object.type === "program.discussionentry") {
            return "chat";
        } else if (notification.object.type === "program.dataform") {
            return "description";
        } else if (notification.object.type === "program.document") {
            return "folder";
        } else if (notification.object.type === "program.assignment") {
            return "task";
        } else if (notification.object.type === "notifications.message") {
            return "mail";
        }

        return "info";
    }

    badgeForMessage(notification: AppNotification) {
        if (!notification.isRead) {
            return "!";
        }
        return undefined;
    }

    toolTipForNotification(notification: AppNotification) {
        if (!notification.isRead) {
            return "Unread notification";
        }
        return "";
    }

    isObjectReference(o: any) {
        return o instanceof ObjectReference;
    }

    getChipColor(notification: AppNotification) {
        const relativeDate = this.relativeDatePipe.transform(notification.created_at);
        switch (relativeDate) {
            case "Last 24 Hours":
                return "primary";
            case "Yesterday":
                return "accent";
            case "Last 7 Days":
                return notification.isRead ? undefined : "warn";
            case "This Month":
                return notification.isRead ? undefined : "warn";
            default:
                return undefined;
        }
    }

    private handleDiscussionEntry(notification: AppNotification) {
        this.caseService
            .retrieve(notification?.repository?.id || "0")
            .subscribe((v) => {
                if (!v) return;

                const comp = ObjectAdminComponent.showObject<Case>(
                    v,
                    CaseComponent,
                    ObjectViewMode.Edit,
                ) as CaseComponent;

                comp.toDiscussion = true;
            });
    }

    private handleAssignment(notification: AppNotification) {
        const assignment = notification.object as Assignment;
        this.caseService
            .list({
                inquiry: notification?.repository?.id || "0",
                access: this.currentAccount?.id || "0",
            })
            .subscribe((v) => {
                const cases = v as Case[];
                if (!cases) return;

                const comp = ObjectAdminComponent.showObject<Case>(
                    cases[0],
                    CaseComponent,
                    ObjectViewMode.Edit,
                ) as CaseComponent;

                comp.assignmentToOpen = assignment;
                this.list.refresh();
                this.updateNotificationCount();
            });
    }

    private handleDataForm(notification: AppNotification) {
        let obs: Observable<APIListResult<Inquiry | Case>> | undefined = undefined;
        const dataForm = notification.object as DataForm;

        if (notification.repository?.type === "program.inquiry") {
            obs = this.getObservableForCaseOrInquiry(notification.repository?.id);
        } else if (notification.repository?.type === "program.case") {
            obs = this.getObservableForCase(notification.repository?.id);
        }

        this.subscribeToObservable(
            obs,
            "formNotificationToOpen" as CasePropertyToOpen,
            dataForm,
        );
    }

    private handleDocument(notification: AppNotification) {
        let obs: Observable<APIListResult<Inquiry | Case>> | undefined = undefined;
        const document = notification.object as Document;

        if (notification.repository?.type === "program.inquiry") {
            obs = this.getObservableForCaseOrInquiry(notification.repository.id);
        } else if (notification.repository?.type === "program.case") {
            obs = this.getObservableForCase(notification.repository.id);
        }

        this.subscribeToObservable(obs, "documentNotificationToOpen", document);
    }

    private handleMessage(notification: AppNotification) {
        let obs: Observable<APIListResult<Inquiry | Case>> | undefined = undefined;
        const message = notification.object as Message;

        if (notification.repository?.type === "program.inquiry") {
            obs = this.getObservableForCaseOrInquiry(notification.repository?.id);
        } else if (notification.repository?.type === "program.case") {
            obs = this.getObservableForCase(notification.repository?.id);
        }

        this.subscribeToObservable(obs, "messageToOpen", message);
    }

    private getObservableForCaseOrInquiry(
        id: string | undefined,
    ): Observable<APIListResult<Case | Inquiry>> {
        //for objects stored under an inquiry since currently there is no way to tell if the inquiry got converted to a case
        // if there is no case, it will return the inquiry
        return this.caseService
            .list({
                inquiry: id || "0",
                access: this.currentAccount?.id || "0",
            })
            .pipe(
                switchMap((result) => {
                    result = result as Case[];
                    if (result.length > 0) {
                        return of(result);
                    } else {
                        return this.inquiryService.list({
                            id: id || "0",
                            access: this.currentAccount?.id || "0",
                        });
                    }
                }),
            );
    }

    private getObservableForCase(id: string | undefined) {
        return this.caseService.list({
            id: id || "0",
            access: this.currentAccount?.id || "0",
        });
    }

    private subscribeToObservable(
        obs: Observable<APIListResult<Inquiry | Case>> | undefined,
        propertyToOpen: CasePropertyToOpen | InquiryPropertyToOpen,
        openValue: any,
    ) {
        if (!obs) return;

        obs.subscribe((v) => {
            if (v instanceof Array) {
                const object = v[0] as Inquiry | Case;
                if (!object) return;

                if (object instanceof Inquiry) {
                    const comp = ObjectAdminComponent.showObject<Inquiry>(
                        object,
                        InquiryComponent,
                        ObjectViewMode.Edit,
                    ) as InquiryComponent;

                    comp[propertyToOpen as InquiryPropertyToOpen] = openValue;
                } else {
                    const comp = ObjectAdminComponent.showObject<Case>(
                        object,
                        CaseComponent,
                        ObjectViewMode.Edit,
                    ) as CaseComponent;

                    comp[propertyToOpen] = openValue;
                }
            }
        });
    }

    localizeDate(date: Date, format = "medium") {
        return this.localizedDatePipe.transform(date, format) ?? "";
    }
}
