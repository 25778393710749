import { APIObject, NamedObject, ObjectReference } from "./api-object";

export type DataFieldDefinition = {
    field?: string;
    displayName?: string;
    type: string;
    order?: number;
};
export type DataType = {
    type: string;
    displayName: string;
};
export interface SystemObjectSettings {
    hide_all: boolean;
    hide_system_workflow: boolean;
    hide_system_form: boolean;
    hide_system_role: boolean;
    hide_system_case_status: boolean;
}
interface OrganizationSettingsJson {
    system_object_settings: SystemObjectSettings;
    [key: string]: any;
}
export class OrganizationSettings extends APIObject {
    static readonly object_type: string = "iam.organizationsettings";
    template_logo?: string; // optional - URL of the file
    template_bgcolor?: string; // optional - intake header background hex color code
    template_textcolor?: string; // optional - intake header text hex color code
    contact_address?: string; // optional - address to use in template branding
    contact_phone?: string; // optional - phone number to use in template branding
    contact_email?: string; // optional - email address to use in template branding
    data_types?: DataFieldDefinition[]; // optional - definition of data types
    settings?: OrganizationSettingsJson; // any settings or flags that the organization has specified
    shipment_form?: ObjectReference;
    adverse_event?: string; // where users will be redirected when they click Report Safety Event in case.
    webhook_url?: string;
    webhook_enabled?: boolean;
    static readonly DataTypes: DataType[] = [
        { type: "text.short", displayName: "Short Text" },
        { type: "text.long", displayName: "Long Text" },
        { type: "number", displayName: "Number" },
        { type: "date", displayName: "Date" },
    ];

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(
            ...[
                "template_logo",
                "contact_address",
                "contact_phone",
                "contact_email",
                "adverse_event",
                "settings",
                "webhook_url",
            ],
        );
        super.initialize(data, patch);
        this.setMember(data, patch, "template_logo");
        this.setMember(data, patch, "template_bgcolor");
        this.setMember(data, patch, "template_textcolor");
        this.setMember(data, patch, "contact_address");
        this.setMember(data, patch, "contact_phone");
        this.setMember(data, patch, "contact_email");
        this.setMember(data, patch, "data_types");
        this.setMember(data, patch, "adverse_event");
        this.setMember(data, patch, "shipment_form", ObjectReference);
        this.setMember(data, patch, "settings");
        this.setMember(data, patch, "webhook_url");
        this.setMember(data, patch, "webhook_enabled");

        const defaultSettings = {
            hide_all: false,
            hide_system_workflow: false,
            hide_system_form: false,
            hide_system_role: false,
            hide_system_case_status: false,
        } satisfies SystemObjectSettings;

        if (!this.settings) {
            this.settings = {
                system_object_settings: defaultSettings,
            };
        } else if (!this.settings.system_object_settings) {
            this.settings.system_object_settings = defaultSettings;
        }
    }

    static displayNameForDataType(type: string): string | undefined {
        return OrganizationSettings.DataTypes.find((t: DataType) => t.type == type)
            ?.displayName;
    }

    setting(setting: string, prop?: string): any {
        const settingGroup = this.settings ? this.settings[setting] : {};
        if (!prop) return settingGroup;
        return settingGroup && prop in settingGroup ? settingGroup[prop] : undefined;
    }
}

export class Organization extends NamedObject {
    static readonly object_type: string = "iam.organization";
    slug!: string;
    cors_domains!: string[]; // read-only
    settings?: OrganizationSettings; // the settings for the organization
    program_count!: number; // read-only
    entitlements?: any; // any entitlements that the org has (only editable by system admins)

    initialize(data?: any, patch: boolean = false) {
        this._readOnly.push(...["cors_domains", "program_count"]);
        this._optional.push(...["entitlements"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "slug");
        this.setMember(data, patch, "cors_domains", undefined, true);
        this.setMember(data, patch, "settings", OrganizationSettings);
        this.setMember(data, patch, "program_count");
        this.setMember(data, patch, "entitlements");
    }

    entitlement(entitlement: string): any {
        return this.entitlements ? this.entitlements[entitlement] : { enabled: false };
    }
    isEntitlementEnabled(entitlement: string, unset: boolean = false): boolean {
        const props = this.entitlement(entitlement);
        if (!!props && "enabled" in props) return !!props["enabled"];
        return unset;
    }
    setting(setting: string, prop?: string): any {
        return this.settings?.setting(setting, prop);
    }

    get hideAllSystemObjects(): boolean {
        return this.setting("system_object_settings", "hide_all");
    }

    get hideSystemWorkflows(): boolean {
        return (
            this.setting("system_object_settings", "hide_system_workflow") ||
            this.hideAllSystemObjects
        );
    }
    get hideSystemCaseStatuses(): boolean {
        return (
            this.setting("system_object_settings", "hide_system_case_status") ||
            this.hideAllSystemObjects
        );
    }

    get hideSystemRoles(): boolean {
        return (
            this.setting("system_object_settings", "hide_system_role") ||
            this.hideAllSystemObjects
        );
    }

    get hideSystemForms(): boolean {
        return (
            this.setting("system_object_settings", "hide_system_form") ||
            this.hideAllSystemObjects
        );
    }
}
