import { NamedObject, ObjectReference } from "./api-object";
import { Organization } from "./organization";
import { Account } from "./account";
import { Program } from "./program";
import { Case } from "./case";
import { Inquiry } from "./inquiry";
import { DocusignEnvelope } from "./docusign";

export type DocumentTypeDefinition = {
    type: string;
    displayName: string;
    group?: string;
};

/**
 * Document Object
    Represents any document uploaded into the system.  Documents are uploaded into repositories, either a Program, Case,
    or Inquiry.  Documents in a Program repository will be accessible to any Case or Inquiry assigned to that Program.
    Documents uploaded to a Case or Inquiry repository are visible only in the scope of the specific case they're uploaded to.
    Documents in an Inquiry repository are considered to be shared with any Case derived from that Inquiry.
    When uploading a document to a repository, if the repository is a case, the owner of the document should be set
    to the owner of the repository (typically an organization).  If a document is uploaded to an inquiry, it's owner should most
    likely be set to the account that is uploading it.
    * @param owner ObjectReference - Account or Organization - the owner of the document
    * @param repository  ObjectReference - Case or Inquiry or Program or Organization - the repository that the document is part of
    * @param uploaded_by ObjectReference - Account - may be undefined from server, but must be set when created - the account that
    * @param uploaded_at - Date - read-only - when the document was initially uploaded
    * @param size number - read-only - the file size of the document
    * @param file string - readOnly - URL of file, read-only (write is handled by file uploader) - the url to the file
    * @param alias string - optional - file name alias
    * @param file_type string - optional - a file type identifier
    * @param file_format string - read-only - a file format identifier
    * @param deleted boolean - whether or not this document was deleted
 */

export class Document extends NamedObject {
    static object_type: string = "program.document";
    owner!: ObjectReference; // Account or Organization - the owner of the document
    repository?: ObjectReference; // Case or Inquiry or Program or Organization - the repository that the document is part of
    uploaded_by?: ObjectReference; // Account - may be undefined from server, but must be set when created - the account that uploaded the document
    uploaded_at!: Date; // read-only - when the document was initially uploaded
    size!: number; // read-only - the file size of the document
    file!: string; // URL of file, read-only (write is handled by file uploader) - the url to the file
    alias?: string; // optional - file name alias
    file_type?: string; // optional - a file type identifier
    file_format?: string; // read-only - a file format identifier
    deleted!: boolean; // whether or not the document was deleted
    attributes?: any; // JSON; attributes of the document, including whether there was PHI detected in the file and it's composition
    redactions?: any; // write-only, JSON: the redactions to perform on the file
    shared?: boolean; // read-only - for case documents, whether they are read-only or not
    initial_redaction_completed?: boolean; // optional - for case documents, whether the initial redaction has been completed

    get displayName(): string | undefined {
        return this.alias ?? super.displayName;
    }

    static documentTypes: DocumentTypeDefinition[] = [
        {
            type: "patient.generic",
            displayName: "Generic Patient Record",
            group: "Patient",
        },
        { type: "patient.mri", displayName: "MRI Report", group: "Patient" },
        { type: "patient.lab", displayName: "Lab Work", group: "Patient" },
        {
            type: "physician.license",
            displayName: "Medical License",
            group: "Provider",
        },
        {
            type: "physician.cv",
            displayName: "Curriculum Vitae (CV)",
            group: "Provider",
        },
        { type: "general.contract", displayName: "Contracts", group: "General" },
        { type: "program.review", displayName: "Review Document", group: "Program" },
        {
            type: "general.regulatory",
            displayName: "Regulatory Authority Form",
            group: "General",
        },
        {
            type: "physician.irb",
            displayName: "Internal Review Board Document",
            group: "Provider",
        },
        {
            type: "shipping.request",
            displayName: "Shipping Request",
            group: "Shipping",
        },
        {
            type: "shipping.receipt",
            displayName: "Shipping Receipt",
            group: "Shipping",
        },
        { type: "patient.safety", displayName: "Safety Report", group: "Patient" },
        {
            type: "program.general",
            displayName: "Program Information",
            group: "Program",
        },
        { type: "patient.results", displayName: "Real World Data", group: "Patient" },
        { type: "file.pdf", displayName: "PDF", group: "File" },
        { type: "file.jpg", displayName: "JPG", group: "File" },
    ];

    initialize(data?: any, patch: boolean = false) {
        this._readOnly.push(
            ...["uploaded_at", "size", "file", "file_format", "shared"],
        );
        this._optional.push(...["alias", "file_type"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "repository", ObjectReference);
        this.setMember(data, patch, "uploaded_by", ObjectReference);
        this.setMember(data, patch, "uploaded_at", Date);
        this.setMember(data, patch, "size");
        this.setMember(data, patch, "file");
        this.setMember(data, patch, "alias");
        this.setMember(data, patch, "file_type");
        this.setMember(data, patch, "file_format");
        this.setMember(data, patch, "deleted");
        this.setMember(data, patch, "attributes");
        this.setMember(data, patch, "redactions");
        this.setMember(data, patch, "shared");
        this.setMember(data, patch, "initial_redaction_completed");
    }

    get repositoryType(): string {
        switch (this.repository?.type) {
            case "program.program":
                return "Program";
            case "iam.organization":
                return "Organization";
            case "iam.account":
                return "Account";
            case "program.inquiry":
                return "Shared Case Documents";
            case "program.case":
                return "Internal Case Documents";
            case "docusign.docusignenvelope":
                return "Docusign Envelope";
            default:
                return "Unknown";
        }
    }
}

export type DocumentOwner = Account | Organization | ObjectReference;
export type DocumentRepository =
    | Program
    | Case
    | Inquiry
    | Organization
    | Account
    | DocusignEnvelope
    | ObjectReference;

//TODO: Add the rest of the document types for medalink as the other document tickets are done
export type MedalinkDocumentRepository = Program | Organization | ObjectReference;
