import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { SearchableListComponent } from "src/common/components/searchable-list.component";
import { Organization } from "src/services/models/organization";
import { Product } from "src/services/models/product";
import { ProductService } from "src/services/program.services";
import { ProductComponent } from "./product.component";
import { ObjectViewEntryPoint } from "src/common/components/object.component";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { mergeMap, of } from "rxjs";
import { RequestFilter } from "src/common/utilities/request";

@Component({
    selector: "product-list",
    templateUrl: "./product-list.component.html",
    styleUrl: "../../../admin/components/organization/organization.component.scss",
})
export class ProductListComponent extends SearchableListComponent<Product> {
    objectView = ProductComponent;

    constructor(
        protected service: ProductService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "product-list");
    }

    get products() {
        return this.list.items as Product[];
    }

    protected _organization?: Organization;
    @Input() set organization(v: Organization | undefined) {
        this._organization = v;
        this.list.refresh();
    }
    get organization(): Organization | undefined {
        return this._organization;
    }
    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["owner"] = this.organization?.id ?? "";
        
        return filters
    }
    editObject(
        event: MouseEvent | undefined,
        object?: Product | undefined,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<Product> | undefined {
        const instance = super.editObject(event, object, asDialog, viewOnly);
        (instance as ProductComponent).organization = this.organization;

        return instance;
    }
    deleteProduct(object: Product, event?: MouseEvent) {
        if (event) {
            this.terminateEvent(event);
        }

        const action = object.published ? "close" : "re-open";
        const secondaryText =
            object.published ? "This will remove the product from any programs." : "";

        const updated = { ...object } as Product;
        updated.published = !object.published;

        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        `Are you sure you want to ${action} ${object.displayName} ? ` +
                        secondaryText,
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) =>
                    confirm ? this.service.patch(object, updated) : of(null),
                ),
            )
            .subscribe((o) => {
                if (o) {
                    object.update({ ...o });
                }
            });
    }
}
