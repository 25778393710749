import { TeamMember } from "./../../../services/models/team";
import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/overlay";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    QueryList,
    ViewChild,
    ViewChildren,
    inject,
} from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { Inquiry } from "../../../services/models/inquiry";
import { Case } from "../../../services/models/case";
import { DiscussionEntry } from "../../../services/models/discussion";
import {
    CaseService,
    DiscussionEntryFactory,
    DiscussionService,
    InquiryService,
} from "../../../services/program.services";
import { UntypedFormControl } from "@angular/forms";
import { filter, map } from "rxjs/operators";

import { AppNotification } from "src/services/models/appNotification";
import { AppNotificationService } from "src/services/notification.services";
import { isElementInViewport } from "src/common/utilities/utilities";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { RequestFilter } from "src/common/utilities/request";

@Component({
    selector: "discussion",
    templateUrl: "./discussion.component.html",
    styleUrls: ["./discussion.component.scss"],
})
export class DiscussionComponent extends ObjectAdminComponent<DiscussionEntry> {
    @ViewChild("start") startOfList?: ElementRef;
    @ViewChild("messageList") messageList?: ElementRef;
    @ViewChildren("messages") messages?: QueryList<ElementRef>;
    protected scrollPosition: number = 0;
    loading = false;
    parent_!: Case | Inquiry;

    @Input()
    notifications: AppNotification[] = [];
    @Input() viewOnly = false;
    @Input() set parent(v: Case | Inquiry) {
        this.parent_ = v;
    }

    @Input() staffWithCaseAccess: TeamMember[] = [];
    get parent(): Case | Inquiry {
        return this.parent_;
    }
    get canSendMessage(): boolean {
        return this.parent.isEditor(this.currentAccount);
    }

    messageText: UntypedFormControl = new UntypedFormControl();
    protected caseService: CaseService;
    protected inquiryService: InquiryService;
    protected appNotificationService: AppNotificationService;
    protected discussionService: DiscussionService;

    constructor(
        protected service: DiscussionService,
        protected changeDetection: ChangeDetectorRef,
        protected scrollDispatcher: ScrollDispatcher,
    ) {
        super(service, changeDetection, -1);
        this.discussionService = inject(DiscussionService);
        this.caseService = inject(CaseService);
        this.inquiryService = inject(InquiryService);
        this.appNotificationService = inject(AppNotificationService);

        this.list.ordering = [{ field: "created_at", ascending: false }];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);

        if (this?.parent?.id && this.parent instanceof Case) {
            filters["discussion"] = `${this.parent.id},${this.parent.shared.id}`;
        } else if (this?.parent?.id && this.parent instanceof Inquiry) {
            filters["discussion"] = `${this.parent.id}`;
        } else {
            filters["discussion"] = "0";
        }

        return filters;
    }

    onScroll(event: Event, threshold = 50): void {
        this.terminateEvent(event);

        this.clearInViewNotifications();
    }
    isSender(entry: DiscussionEntry): boolean {
        return entry.account?.id === this.currentAccount?.id;
    }

    customToolbar = [
        //to customize rte features, just remove the features you dont want here
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        ["link"],
        ["clean"],
    ];
    // how to add custom key presses to the RTE
    bindings = {
        customEnter: {
            key: "enter",
            handler: () => this.handleEnterKey(),
        },
    };

    handleEnterKey(): void {
        if (this.messageText.value?.length) this.sendMessage();
    }
    sendMessage(): void {
        this.loading = true;
        const entry = DiscussionEntryFactory.makeObject<DiscussionEntry>(
            {
                account: this.currentAccount,
                message: this.messageText.value,
            },
            DiscussionEntry.object_type,
        ) as DiscussionEntry;
        let obs = undefined;
        if (this.parent && this.parent instanceof Case && entry) {
            obs = this.caseService.addDiscussionEntry(this.parent, entry);
        } else if (this.parent && entry) {
            const inquiry =
                this.parent instanceof Inquiry ? this.parent : this.parent.shared;
            obs = this.inquiryService.addDiscussionEntry(inquiry, entry);
        }
        if (obs) {
            this.messageText.reset();
            obs.subscribe(() => {
                this.loading = false;
            });
        } else this.loading = false;
    }

    onNewEntry() {
        const last = this.messages?.last;
        if (last) {
            last.nativeElement.scrollIntoView({
                behavior: "smooth",
            });
        }
    }

    getNotificationForEntry(id: string) {
        return this?.notifications?.find((n) => n.object.id === id);
    }
    clearInViewNotifications() {
        this.messages?.forEach((e) => {
            const inView = isElementInViewport(e.nativeElement);

            if (inView) {
                const id = e.nativeElement.getAttribute("data-id");
                const notification = this.getNotificationForEntry(id);
                if (notification) {
                    this.appNotificationService.clear(notification).subscribe();
                }
            }
        });
    }
    get discussionEntries() {
        return this.list.items as DiscussionEntry[];
    }

    get discussion() {
        return this.list.items as DiscussionEntry[];
    }
    loadMoreEntries() {
        // Load more entries
        this.list.increasePageSizeSearch();
    }
    protected postSearch(items: DiscussionEntry[]): DiscussionEntry[] {
        const v = super.postSearch(items);
        this.clearInViewNotifications();

        return v;
    }
}
