<data-filter
    *ngIf="showFilter"
    [dateFilterConfigs]="dateFilterConfigurations"
    [dynamicOptions]="{
        program: programs,
        physician: physicians,
        institution: institutions,
        shipmentType: shipmentTypes
    }"
    [filterDefinitions]="filterDefinitions"
    [filterFormGroup]="filterFormGroup"
    [filterTitle]="'Shipments'"
    [getOptionValue]="getOptionValue"
    [getOptionDisplay]="getOptionDisplay"
    (cancelFilter)="cancel($event)"
    (clearAllFilters)="clearAllFilters($event)"
    (resetFilter)="resetFilter($event)"
></data-filter>

<div class="list-container" style="margin-bottom: 1rem">
    <div class="list-header flex">
        <div class="title" [id]="list.listTitle">Shipments</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <ng-container>
                <mat-form-field
                    color="primary"
                    *ngIf="!isSearchEmpty"
                    class="list-search"
                >
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <mat-label>Search Shipments</mat-label>
                    <input
                        matInput
                        #search
                        placeholder="Enter at least 3 characters to search"
                        [formControl]="searchTermControl"
                        (focusout)="onFocusOut($event)"
                    />
                    <button
                        matSuffix
                        type="button"
                        mat-icon-button
                        (click)="resetSearchTerm($event)"
                        *ngIf="!!searchTermControl.value"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <button
                    mat-flat-button
                    class="brand-medium"
                    *ngIf="isSearchEmpty"
                    (click)="toggleSearch($event)"
                    matTooltip="Search Shipments"
                >
                    <mat-icon>search</mat-icon>
                    Search
                </button>
            </ng-container>
            <ng-container>
                <button
                    [disabled]="!list.hasLoaded || !list.list.items.length"
                    mat-flat-button
                    class="brand-medium"
                    *ngIf="!this.showFilter"
                    (click)="toggleFilter($event)"
                    matTooltip="Filter Shipments"
                >
                    <mat-icon>filter_list</mat-icon>
                    Filter
                </button>
            </ng-container>
        </div>
    </div>

    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="reference_identifier">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Shipment #
            </mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                [ngClass]="{ clickable: isObject(shipment) }"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    {{ shipment.reference_identifier }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="case_reference">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Case #</mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                [ngClass]="{ clickable: isObject(shipment) }"
                (click)="openCase(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    {{ shipment.case_reference }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="case_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Case Name
            </mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    {{ shipment.case_name || "" }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="program_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Program</mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment) && asObject(shipment).program_name">
                    {{ shipment.program_name }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="physician">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Physician
            </mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    {{ shipment.physician }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="physician_institution">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Institution
            </mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    {{ shipment.physician_institution }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="request_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Shipment Type
            </mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    <mat-chip-set>
                        <mat-chip
                            *ngIf="!isResupply(shipment)"
                            color="primary"
                            selected
                        >
                            Initial
                        </mat-chip>
                        <mat-chip *ngIf="isResupply(shipment)" color="accent" selected>
                            Resupply
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_requested">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Date Requested
            </mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    {{ shipment.date_requested | localizedDate }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_received">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Date Received
            </mat-header-cell>
            <mat-cell
                *matCellDef="let shipment"
                (click)="editForm(shipment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div
                    *ngIf="isReference(shipment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(shipment)">
                    {{ shipment.date_received | localizedDate }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="columns.length"
                class="warn not-found"
            >
                <span *ngIf="list.hasLoaded">No shipments found.</span>
                <span *ngIf="!list.hasLoaded">
                    <mat-progress-spinner
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                    Loading...
                </span>
            </mat-footer-cell>
        </ng-container>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"></mat-row>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
