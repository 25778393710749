<div class="container">
    <div class="flex">
        <div class="title" [id]="list.listTitle">Products</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search margin-right"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Products</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Products"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Create Product
            </button>
        </div>
    </div>

    <div *ngIf="list.hasLoaded" class="card-grid">
        <mat-card *ngFor="let product of products" class="example-card card-row">
            <div class="card-column">
                <div class="card-row">
                    <div class="card-column card-header" matColumnDef="display_name">
                        Name
                    </div>
                    <div class="card-column card-header" matColumnDef="published">
                        Status
                    </div>
                </div>
                <div class="card-row">
                    <div
                        class="card-column card-text-content"
                        matColumnDef="display_name"
                    >
                        {{ product.displayName }}
                    </div>
                    <div class="card-column card-text-content" matColumnDef="published">
                        <mat-chip *ngIf="product.published" color="accent" selected>
                            Active
                        </mat-chip>
                        <mat-chip *ngIf="!product.published" color="warn" selected>
                            Closed
                        </mat-chip>
                    </div>
                </div>
            </div>
            <div class="card-column card-button">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ product: product }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #overflow="matMenu">
                    <ng-template matMenuContent let-account="account">
                        <button
                            mat-menu-item
                            (click)="editObject($event, product, true)"
                        >
                            Edit
                        </button>
                        <button mat-menu-item (click)="deleteProduct(product, $event)">
                            @if (product.published) {
                                Close
                            } @else {
                                Re-Open
                            }
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </mat-card>
    </div>

    <div *ngIf="list.loadingProgress" class="error not-found text-center">
        <span *ngIf="list.searching && !list.hasLoaded">
            <mat-progress-spinner
                diameter="20"
                class="inline"
                mode="indeterminate"
            ></mat-progress-spinner>
            Loading...
        </span>
        <span *ngIf="list.initialized && !list.list.items.length">
            No Products found.
        </span>
    </div>

    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
