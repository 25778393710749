<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div class="wrapper flex stack flexible" *ngIf="mode == ObjectViewMode.Create">
        <mat-form-field class="full">
            <mat-label>Organization Name</mat-label>
            <mat-icon matSuffix>business</mat-icon>
            <input matInput placeholder="Organization Name" formControlName="name" />
        </mat-form-field>
    </div>
    <div class="wrapper flex stack flexible" *ngIf="mode != ObjectViewMode.Create">
        <div
            [class.mat-dialog-content]="!!dialogReference"
            [class.remove-styling]="!!dialogReference"
        >
            <mat-tab-group
                color="primary"
                class="margin-top"
                [class.nested]="!!dialogReference"
            >
                <mat-tab
                    label="Staff"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template matTabContent>
                        <div class="content-card">
                            <staff-list
                                *ngIf="fullObject"
                                [organization]="fullObject"
                                [isHcpStaff]="isHcpStaff"
                                [teamOwner]="fullObject"
                            ></staff-list>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Properties"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template matTabContent>
                        <div *ngIf="object">
                            <organization-properties
                                [isHcpStaff]="!!isHcpStaff"
                                [object]="object"
                                [autosave]="autosave"
                                [mode]="mode"
                            ></organization-properties>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Programs"
                    *ngIf="
                        (isOrganizationAdministrator ||
                            isSystemAdministrator ||
                            isProgramAdministrator ||
                            isProgramCountryAdministrator) &&
                        !isHcpStaff
                    "
                >
                    <ng-template matTabContent>
                        <div class="content-card">
                            <program-list
                                *ngIf="fullObject"
                                [organization]="fullObject"
                            ></program-list>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Products"
                    *ngIf="
                        (isOrganizationAdministrator || isSystemAdministrator) &&
                        !isHcpStaff
                    "
                >
                    <ng-template matTabContent>
                        <div class="content-card">
                            <product-list [organization]="fullObject"></product-list>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Tools"
                    *ngIf="
                        (isOrganizationAdministrator || isSystemAdministrator) &&
                        !isHcpStaff
                    "
                >
                    <ng-template matTabContent>
                        <div class="content-card">
                            <div class="title">Data Export</div>
                            <div class="container">
                                <div class="row flex full">
                                    <button
                                        mat-flat-button
                                        color="accent"
                                        class="brand-medium"
                                        [disabled]="exporting"
                                        (click)="exportCaseData($event)"
                                    >
                                        Export Program Case Data
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="content-card" *ngIf="redactionEntitled">
                            <div class="title">Redaction</div>
                            <div class="container flex stack">
                                <mat-checkbox
                                    class="redaction-option"
                                    name="disableRedaction"
                                    ngDefaultControl
                                    [formControl]="disableRedaction"
                                >
                                    Temporarily disable AI-assisted Redaction
                                    <span
                                        matTooltip="When checked, documents uploaded to this organization will not automatically be analyzed for PHI and no redaction hints will be presented"
                                        [matTooltipPosition]="'below'"
                                        matTooltipClass="org-tooltip-redaction"
                                    >
                                        <mat-icon
                                            fontIcon="help"
                                            style="font-size: 18px; overflow: unset"
                                        ></mat-icon>
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox
                                    class="redaction-option"
                                    name="hideExternalRedaction"
                                    ngDefaultControl
                                    [formControl]="hideExternalRedaction"
                                >
                                    Hide redaction warnings from external users
                                </mat-checkbox>
                                <mat-checkbox
                                    class="redaction-option"
                                    name="hideInternalRedaction"
                                    ngDefaultControl
                                    [formControl]="hideInternalRedaction"
                                >
                                    Hide redaction warnings from internal users
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Documents"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template mat-tab-label>
                        <mat-icon
                            *ngIf="!!tabErrors['Documents']"
                            class="fixed"
                            [ngClass]="tabErrors['Documents'].severity"
                            [matTooltip]="tabErrors['Documents'].message"
                        >
                            warning
                        </mat-icon>
                        Documents
                    </ng-template>

                    <div class="content-card">
                        <document-repository
                            [caseOrg]="fullObject"
                            [repository]="object"
                            [owner]="object"
                            [canAdd]="true"
                            (setCaseError)="setTabError('Documents', $event)"
                            [redactionEnabled]="
                                (fullObject?.isEntitlementEnabled('redaction') &&
                                    redactionAIAssisted) ||
                                false
                            "
                        ></document-repository>
                    </div>
                </mat-tab>
                <mat-tab
                    label="Messaging"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template matTabContent>
                        <div class="content-card" *ngIf="!!fullObject">
                            <template-list
                                [owner]="fullObject"
                                [inCard]="true"
                            ></template-list>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Data" *ngIf="canUseFormBuilder">
                    <ng-template matTabContent>
                        <div class="content-card no-padding">
                            <data-admin [repository]="this.fullObject!"></data-admin>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Entitlements" *ngIf="isSystemAdministrator">
                    <ng-template matTabContent>
                        <div class="content-card">
                            <div class="container">
                                <div class="title">Entitlements / Restrictions</div>
                                <div class="container flex stack">
                                    <mat-checkbox
                                        name="enableRedaction"
                                        ngDefaultControl
                                        [formControl]="enableRedaction"
                                    >
                                        Enable Redaction
                                    </mat-checkbox>
                                    <mat-checkbox
                                        name="enableRedaction"
                                        ngDefaultControl
                                        [formControl]="enableFormBuilder"
                                    >
                                        Enable Form Builder
                                    </mat-checkbox>
                                    <mat-checkbox
                                        name="inquiryOnly"
                                        ngDefaultControl
                                        [formControl]="inquiryOnly"
                                    >
                                        Allow Inquiries Only
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</object-form>
