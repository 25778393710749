<div class="container">
    <div class="programs-header flex">
        <div class="title">Countries</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Countries</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Countries"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
                [disabled]="!canCreate()"
            >
                <mat-icon>open_in_new</mat-icon>
                Add Country
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="country__display_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell
                *matCellDef="let programCountry"
                (click)="canEdit(programCountry) && editObject($event, programCountry, true)"
            >
                <img
                    [src]="programCountry.country.flag_url"
                    alt="({{ programCountry.country.code }})"
                    class="country-flag-img"
                    (error)="handleCountryFlagError(programCountry)"
                />
                {{ programCountry.country.display_name }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell
                *matCellDef="let programCountry"
                (click)="canEdit(programCountry) && editObject($event, programCountry, true)"
            >
                <mat-chip-set>
                    @if (programCountry.status == "open") {
                        <mat-chip color="accent" selected>
                            {{ programCountry.status | titlecase }}
                        </mat-chip>
                    } @else if (programCountry.status == "closed") {
                        <mat-chip color="warn" selected>
                            {{ programCountry.status | titlecase }}
                        </mat-chip>
                    } @else {
                        <mat-chip color="primary" selected>
                            {{ programCountry.status | titlecase }}
                        </mat-chip>
                    }
                </mat-chip-set>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let programCountry" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ programCountry: programCountry }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                No countries found.
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-programCountry="programCountry">
                <button
                    mat-menu-item
                    (click)="editObject($event, programCountry, true)"
                    [disabled]="!canEdit(programCountry)"
                >
                    Edit
                </button>
                <button
                    mat-menu-item
                    (click)="
                        isOrganizationAdministrator &&
                            deleteObject($event, programCountry)
                    "
                    [disabled]="!canCreate()"
                >
                    Remove Country
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
</div>
