import { Component, inject, OnInit } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { TeamMemberService, TeamService } from "src/services/program.services";
import { map, Observable, switchMap } from "rxjs";
import { Team, TeamMember } from "src/services/models/team";
import { Role, RoleDefinition } from "src/services/models/role";
import { Account } from "src/services/models/account";
import { ObjectFactory } from "src/services/models/api-object";
import { AccountService, CapabilityService } from "src/services/iam.services";
import { Capability } from "src/services/models/capability";
import { ProgramCountry } from "src/services/models/program";

@Component({
    selector: "program-country-staff",
    templateUrl: "./program-country-staff.component.html",
    styleUrls: ["./program.component.scss"],
})
export class ProgramCountryStaffComponent
    extends ObjectComponent<TeamMember>
    implements OnInit
{
    availableStaff: TeamMember[] = [];
    currentStaff: TeamMember[] = [];
    private teamService = inject(TeamService);
    private accountService = inject(AccountService);
    showNewUserControls = false;
    private capabilitiesService = inject(CapabilityService);
    capabilitiesList$!: Observable<Capability[]>;

    autosave = true;
    mode = ObjectViewMode.Edit;
    private _isSubmitProcessing: boolean = false;
    get submitProcessing(): boolean {
        return this._isSubmitProcessing;
    }
    get publicCheckboxToolTip() {
        return "By making this user public they will be visible to external users. If you want this user to have tasks assigned to them or have messages sent to them, leave this box checked.";
    }
    get availableRoles(): RoleDefinition[] {
        return Role.roles.filter((role) => role.type === "object");
    }

    constructor(protected service: TeamMemberService) {
        super(service);
    }

    ngOnInit(): void {
        this.capabilitiesList$ = this.getCapabilitiesList$();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.updateStaffLists();
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            team: [null, Validators.required],
            account: [null, Validators.required],
            permission: [null, Validators.required],
            private: [null, Validators.required],
            role: [null],
            first_name: [null],
            last_name: [null],
            email: [null],
            capabilities: [null],
        });
    }

    protected setObject(v?: TeamMember): void {
        super.setObject(v);

        if (this.mode == ObjectViewMode.Create) {
            this.objectName = "Add Staff";
            this.formGroup.get("role")?.setValue("member");
        } else if (this.mode == ObjectViewMode.Edit) {
            this.objectName =
                "Edit " + (this.object?.displayName ?? "Program Country Staff");
            this.formGroup
                .get("capabilities")!
                .setValue(this.formGroup.get("capabilities")!.value[0]);
            
            this.formGroup
                .get("role")!
                .setValue(this.formGroup.get("role")!.value ?? "member");

            const perm = this.availableRoles.find(
                r => r.value == this.formGroup.get("permission")!.value.role
            )
            this.formGroup
                .get("permission")!
                .setValue(perm);
        } else {
            this.objectName =
                "View " + (this.object?.displayName ?? "Program Country Staff");
        }
    }

    protected updateAvailableStaff(): void {
        // Retrieve all staff (team members) who do not already inherit access to this ProgramCountry
        // Program-staff and Organization-staff will already inherit access and therefore should not be included in this list
        // Should retrieve all country-staff of teams that belong to this ProgramCountry's organization (excluding this ProgramCountry's team)
        const teamTypesFilter = ["country-staff"];
        const owners: string[] = [];

        if (this.fullObject?.team instanceof Team) {
            this.fullObject.team.organization?.id &&
                owners.push(this.fullObject.team.organization.id);
        }

        this.teamService
            .list({ owned: owners.join(","), team_types: teamTypesFilter.join(",") })
            .pipe(
                map(
                    (organizationOwnedCountryStaffTeams) =>
                        organizationOwnedCountryStaffTeams as Team[],
                ),
                map((organizationOwnedCountryStaffTeams) =>
                    organizationOwnedCountryStaffTeams.map((el) => el.id!),
                ),
                switchMap((organizationOwnedCountryStaffTeamIds) =>
                    this.service
                        .list({ team: organizationOwnedCountryStaffTeamIds.join(",") })
                        .pipe(map((teamMembers) => teamMembers as TeamMember[])),
                ),
                map((teamMembers) => {
                    // Create list of distinct accounts that are not already staff of this ProgramCountry
                    const availableStaff = Array.from(
                        new Map(
                            teamMembers
                                .filter(
                                    (el) =>
                                        !this.currentStaff.some(
                                            (current) =>
                                                current.account.id === el.account.id,
                                        ),
                                )
                                .map((teamMember) => [
                                    teamMember.account.id,
                                    teamMember,
                                ]),
                        ).values(),
                    );
                    return availableStaff;
                }),
            )
            .subscribe((availableStaff) => {
                this.availableStaff = availableStaff;
            });
    }

    protected updateStaffLists(): void {
        if (this.object instanceof TeamMember) {
            this.service
                .list({ team: this.object?.team?.id! })
                .pipe(map((teamMembers) => teamMembers as TeamMember[]))
                .subscribe((teamMembers) => {
                    this.currentStaff = teamMembers;
                    this.updateAvailableStaff();
                });
        }
    }

    protected getCapabilitiesList$(): Observable<Capability[]> {
        const owners: string[] = ["0"];
        if (this.fullObject?.team instanceof Team) {
            this.fullObject.team.organization?.id &&
                owners.push(this.fullObject.team.organization.id);
        }
        return this.capabilitiesService.list({ org: owners.join(",") }).pipe(
            map((capabilities) => capabilities as Capability[]),
            map((capabilities: Capability[]) =>
                capabilities.slice().sort((a, b) => {
                    const aName = a.display_name ?? a.name ?? "";
                    const bName = b.display_name ?? b.name ?? "";
                    return aName.localeCompare(bName);
                }),
            ),
        );
    }

    protected accountChangeHandler(value: string): void {
        const controls = ["first_name", "last_name", "email"];

        if (value === "newUser") {
            this.setValidators(controls, {
                first_name: Validators.required,
                last_name: Validators.required,
                email: [Validators.required, Validators.email],
            });
            this.showNewUserControls = true;
            return;
        }

        this.setValidators(controls, null);
        this.showNewUserControls = false;
    }

    private setValidators(
        controls: string[],
        validators: { [key: string]: any } | null,
    ): void {
        controls.forEach((controlName) => {
            const control = this.formGroup.get(controlName);
            if (control) {
                control.setValidators(validators ? validators[controlName] : null);
                control.updateValueAndValidity();
            }
        });
    }

    private wrapInArray<T>(value: T | T[]): T[] {
        return Array.isArray(value) ? value : [value];
    }

    onSave(): void {
        this._isSubmitProcessing = true;
        const capabilities = this.formGroup.get("capabilities")?.value
        if(!!capabilities){
            this.formGroup
                .get("capabilities")
                ?.setValue(this.wrapInArray(capabilities));
        }
        if (this.formGroup.get("account")!.value == "newUser"){
            this.onSaveNewUser()
        } else {
            const permission = ObjectFactory.makeObject<Role>(
                {
                    id: this.fullObject?.permission?.id,
                    object: (this.fullObject?.team as Team).owner as ProgramCountry,
                    role: this.formGroup.get("permission")!.value,
                    account: this.formGroup.get("account")?.value?.asReference
                },
                Role.object_type,
            )
            this.formGroup.get("permission")?.setValue(permission);
            super.onSave();
        } 
    }

    protected onSaveNewUser() {
        const account = ObjectFactory.makeObject<Account>(
            {
                email: this.formGroup.get("email")!.value,
                first_name: this.formGroup.get("first_name")!.value,
                last_name: this.formGroup.get("last_name")!.value,
                send_invite: true,
            },
            Account.object_type,
        );

        this.accountService.create(account).subscribe((account) => {
            const permission = ObjectFactory.makeObject<Role>(
                {
                    object: (this.fullObject?.team as Team).owner as ProgramCountry,
                    role: this.formGroup.get("permission")!.value,
                    account: account?.asReference
                },
                Role.object_type,
            )

            this.formGroup.get("account")?.setValue(account);
            this.formGroup.get("permission")?.setValue(permission);

            super.onSave();
        });
    }

    protected onCommitSuccess(v: TeamMember | undefined): boolean {
        this._isSubmitProcessing = false;
        return super.onCommitSuccess(v);
    }
}
