<div *ngIf="!list.hasLoaded">
    <div class="spinner">
        <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
    </div>
    <div class="spinner-message">Loading Checklist ...</div>
</div>

<ng-container *ngIf="list.items.length === 1">
    <span class="task-group-description" *ngIf="isObject(list.items[0])">
        {{ asObject(list.items[0]).task.description }}
    </span>
    <div *ngIf="isReference(list.items[0])" referenceWrapper [primary]="true"></div>
    <task-table
        [notifications]="notifications"
        [viewOnly]="viewOnly"
        [case]="case"
        [group]="list.items[0]"
        [firstUncompletedTask]="firstUncompletedTask"
    ></task-table>
</ng-container>
<ng-container *ngIf="list.items.length > 1">
    <mat-vertical-stepper
        #checklistStepper
        *ngIf="list.hasLoaded"
        [selectedIndex]="selected || 0"
    >
        <mat-step *ngFor="let group of list.items; index as i">
            <ng-template matStepLabel>
                <span *ngIf="isObject(group)">{{ asObject(group).task.name }}</span>
                <div *ngIf="isReference(group)" referenceWrapper [primary]="true"></div>
            </ng-template>
            <ng-template matStepContent>
                <span class="task-group-description" *ngIf="isObject(group)">
                    {{ asObject(group).task.description }}
                </span>
                <div *ngIf="isReference(group)" referenceWrapper [primary]="true"></div>
                <task-table
                    [notifications]="notifications"
                    [viewOnly]="viewOnly"
                    [case]="case"
                    [group]="group"
                    [firstUncompletedTask]="firstUncompletedTask"
                ></task-table>
            </ng-template>
        </mat-step>
    </mat-vertical-stepper>
</ng-container>
