import { NamedObject, ObjectOrReference } from "./api-object";
import { Organization } from "./organization";

export class APIKey extends NamedObject {
    static object_type: string = "api.apikey";
    api_key!: string;
    owner!: ObjectOrReference<Organization>;
    revoked_at?: Date;

    initialize(data?: any, patch: boolean = false) {
        this._readOnly.push(...["api_key", "revoked_at"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "api_key");
        this.setMember(data, patch, "owner", Organization);
        this.setMember(data, patch, "revoked_at", Date);
    }
}
