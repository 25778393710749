import {
    AbstractControl,
    FormGroup,
    FormControl,
    FormGroupDirective,
    NgForm,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { GenerateUniqueIdentifier } from "./utilities";
import * as moment from "moment";
import { ErrorStateMatcher } from "@angular/material/core";

type ValidationErrors = { [key: string]: any };

export function EmailValidator(control: AbstractControl): ValidationErrors | null {
    /* ** OLD Regex does not catch emails addresses enclosed by <...> pair (MED-1302) - kept for comparison, will remove after more testing
     *  if (!control.value || !control.value.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/))
     */
    if (
        !control.value ||
        control.value?.length >= 255 ||
        !control.value.match(
            /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
        )
    )
        return { invalidEmailAddress: true };
    return null;
}
export function OptionalEmailValidator(
    control: AbstractControl,
): ValidationErrors | null {
    /* ** OLD Regex does not catch emails addresses enclosed by <...> pair (MED-1302) - kept for comparison, will remove after more testing
     *  if (!!control.value && !control.value.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/))
     */
    if (
        !!control.value &&
        control.value !== "" &&
        (control.value?.length >= 255 ||
            !control.value.match(
                /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
            ))
    )
        return { invalidEmailAddress: true };
    return null;
}

export function PositiveIntegerValidator(
    control: AbstractControl,
): ValidationErrors | null {
    const num = Number(control.value);
    if (num < 0 || num > 32767 || !Number.isInteger(num))
        return { positiveInteger: false };
    return null;
}

export function NonZeroPositiveIntegerValidator(
    control: AbstractControl,
): ValidationErrors | null {
    const num = Number(control.value);
    if (num <= 0 || num > 32767 || !Number.isInteger(num))
        return { positiveInteger: false };
    return null;
}

export function ConfirmPasswordValidator(
    passwordControl: string,
    confirmControl: string,
): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
        if (group instanceof UntypedFormGroup) {
            const password = group.controls[passwordControl];
            const confirm = group.controls[confirmControl];
            if ((password.dirty || confirm.dirty) && password.value !== confirm.value)
                AddFormControlError(confirm, "unequal", "The passwords must match.");
        }
        return null;
    };
}

export function UniqueNameValidator(
    nameControl: string,
    identifierControl: string,
    siblings: string[],
): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
        if (group instanceof UntypedFormGroup) {
            const name = group.controls[nameControl];
            const identifier = group.controls[identifierControl];
            const value =
                identifier?.value || GenerateUniqueIdentifier(name?.value, siblings);
            if (siblings.find((n: string) => n === value))
                AddFormControlError(
                    identifier,
                    "inuse",
                    "An item with that identifier is already defined.  Please select a unique identifier.",
                );
        }
        return null;
    };
}

export function RequiredIfValidator(predicate: () => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.parent && predicate() ? Validators.required(control) : null;
    };
}

export function AddFormControlError(
    control: AbstractControl,
    errorName: string,
    errorValue: any,
): void {
    const errors = control?.errors ?? {};
    errors[errorName] = errorValue;
    control?.setErrors(errors);
}

export function RemoveFormControlError(
    control: AbstractControl,
    errorName: string,
): void {
    if (control?.errors && control?.errors[errorName]) {
        delete control.errors[errorName];
        if (Object.keys(control.errors).length === 0) control.setErrors(null);
    }
}

/**
 * Validator for autocomplete drop down where the available options are a collection of observables. Requires the user to select an option that exisits in the available options. Generates an error called invalidAutoComplete.
 */
export async function DropDownAutoCompleteValidator(
    control: AbstractControl,
): Promise<ValidationErrors | null> {
    const selection: any = control.value;
    // if option selected is NOT an option in the dropdown, generate a form error
    // valid type of selection will be an observable
    if (typeof selection === "string") {
        return { invalidAutoComplete: true };
    }

    return null;
}

export function IsDateValidator(control: AbstractControl): ValidationErrors | null {
    let { value }: any = control;
    if (value instanceof Date || !value || moment.isMoment(value)) {
        return null;
    }
    if (value instanceof String || typeof value === "string") {
        try {
            Date.parse(value as string);
            return null;
        } catch {}
    }

    return { invalidDate: true };
}

export function DateIsFutureValidator(
    control: AbstractControl,
): ValidationErrors | null {
    let { value }: any = control;
    if (!value) return null; // value has to empty or a date
    if (moment.isMoment(value)) value = value.toDate();
    if (value instanceof Date) {
        if (value.getTime() >= new Date().getTime()) {
            return null; // date is today or in the future
        }
    }
    return { dateIsNotFuture: true };
}

export function DateIsPastValidator(control: AbstractControl): ValidationErrors | null {
    let { value }: any = control;
    if (!value) return null;
    if (moment.isMoment(value)) value = value.toDate();
    if (value instanceof Date) {
        if (value.getTime() <= new Date().getTime()) {
            return null; // is today or in the past.
        }
    }

    return { dateIsNotPast: true };
}

export function notOnlyWhitespace(control: AbstractControl): ValidationErrors | null {
    if ((control.value || "").trim().length === 0) {
        return { whitespace: true };
    }
    return null;
}

export class InstantErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl,
        form: FormGroupDirective | NgForm | null,
    ): boolean {
        return control && control.invalid && (control.dirty || control.touched);
    }
}
export function conditionalUrlValidator(enforceHttps: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const url = control.value;
        if (!url) {
            return null;
        }
        const invalidUrl =
            enforceHttps ?
                "The provided URL is invalid or does not use HTTPS."
            :   "The provided URL is invalid. Make sure to include the protocol (http:// or https://).";
        const pattern = enforceHttps ? /^https:\/\/.*/ : /^(http|https):\/\/.*/;
        if (!pattern.test(url)) {
            return {
                invalidUrl,
            };
        }

        try {
            const parsedUrl = new URL(url);
            if (enforceHttps) {
                if (parsedUrl.protocol !== "https:") {
                    return { invalidProtocol: "Only HTTPS is allowed." };
                }
            } else {
                if (parsedUrl.protocol !== "http:" && parsedUrl.protocol !== "https:") {
                    return { invalidProtocol: "Only HTTP or HTTPS is allowed." };
                }
            }
            return null;
        } catch (e) {
            return { invalidUrl };
        }
    };
}

export function setValuesAndValidators(
    formGroup: FormGroup,
    controls: string[],
    values: { [key: string]: any } | null,
    validators: { [key: string]: any } | null,
): void {
    controls.forEach((controlName) => {
        const control = formGroup.get(controlName);
        if (control) {
            if (
                values &&
                values[controlName] !== undefined &&
                values[controlName] !== control.value
            ) {
                control.setValue(values[controlName]);
                control.markAsDirty();
            }
            control.setValidators(validators ? validators[controlName] : null);
            control.updateValueAndValidity();
        }
    });
}
