<div class="titleContainer">
    <div class="titleRow">
        <h2 *ngIf="step">{{ step }}.</h2>
        <h2 class="title" *ngIf="repository">
            Add {{ repository.displayName }} Documents
        </h2>
    </div>
</div>

<div class="column uploader drop-zone-container">
    <div
        *ngIf="uploader"
        ng2FileDrop
        class="drop-zone"
        [class.over]="fileOver"
        (fileOver)="fileOver = $event"
        (onFileDrop)="handleFileDrop($event)"
        [uploader]="uploader"
    >
        <div class="flex stack center drop-items">
            <mat-icon class="upload-icon">cloud_upload</mat-icon>
            <!--Files will upload as soon as they are selected-->
            <input
                #fileInput
                type="file"
                ng2FileSelect
                [uploader]="uploader"
                (onFileSelected)="uploader.uploadAllOneRequest()"
                hidden
                multiple
            />
            <div class="uploader-text">
                <p>Drag & drop your files here</p>
                <p>OR</p>
            </div>
            <button
                mat-flat-button
                color="primary"
                class="file-button"
                (click)="terminateEvent($event); fileInput.click()"
            >
                Browse Files
            </button>
        </div>
    </div>
</div>

<div class="content">
    <mat-form-field class="full">
        <mat-icon matPrefix>search</mat-icon>
        <input
            matInput
            type="text"
            [formControl]="searchTermControl"
            placeholder="Search"
            [errorStateMatcher]="errorStateMatcher"
        />
        <mat-error *ngIf="searchTermControl.hasError('minlength')">
            Please enter at least 3 characters.
        </mat-error>
    </mat-form-field>

    <h4 *ngIf="pendingUploads.length">Pending Uploads</h4>
    <div class="documentContainer pending flex" *ngFor="let item of pendingUploads">
        <div class="flex stack flexible card-content">
            <div class="file-details">
                <span class="file-name">
                    {{ item.file.name }}
                </span>
            </div>
            <div class="progress-bar" *ngIf="item.progress !== 100">
                <span class="bar" [style]="'width: ' + item.progress + '%'"></span>
            </div>
            <span class="file-size">
                {{ item.file.size | fileSize }}
            </span>
            <span *ngIf="item.progress !== 100">{{ item.progress }}%</span>
        </div>
    </div>

    <h4 *ngIf="repositoryName">{{ repositoryName }} Documents ({{ list.count }})</h4>
    <div class="listContainer">
        <div class="error not-found">
            <span *ngIf="list.searching && !list.hasLoaded">
                <mat-progress-spinner
                    diameter="20"
                    class="inline"
                    mode="indeterminate"
                ></mat-progress-spinner>
                Loading...
            </span>
            <span *ngIf="list.hasLoaded && !list.list.items.length">
                No documents found.
            </span>
        </div>

        <div
            class="documentContainer between border"
            *ngFor="let document of documents"
        >
            <div class="doc">
                <p class="name">{{ document.displayName }}</p>
                <div class="secondary flex">
                    <p *ngIf="document.uploaded_by">
                        Uploaded By {{ document.uploaded_by.name }} |
                    </p>
                    <p *ngIf="document.uploaded_by">
                        {{ document.uploaded_at | date: "mediumDate" }} |
                    </p>
                    <p *ngIf="document.size">
                        {{ document.size | fileSize }}
                    </p>
                </div>

                <div class="secondary flex">
                    <p *ngIf="isInherited(document)">
                        Inherited From {{ inheritedText(document) }}.
                    </p>
                    <p *ngIf="!isInherited(document)">Apart of this repository.</p>
                </div>
            </div>

            <div class="actionsContainer">
                <button
                    mat-icon-button
                    (click)="viewObject($event, document, true)"
                    matTooltip="View"
                >
                    <mat-icon color="primary">visibility</mat-icon>
                </button>
                <div
                    [matTooltip]="getDeleteTooltip(document)"
                    [class.disabled]="isInherited(document)"
                >
                    <button
                        color="warn"
                        mat-icon-button
                        [disabled]="isInherited(document)"
                        (click)="deleteObject($event, document)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
