import { Inquiry } from "./../../../services/models/inquiry";
import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, inject } from "@angular/core";
import { catchError, EMPTY, filter, finalize, first, Subscription, tap } from "rxjs";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import {
    RequestFilter,
    WebsocketMessageType,
    WebsocketObjectAction,
} from "src/common/utilities/request";
import {
    CompoundDataTypeFactory,
    DataFieldValueService,
    DataFormService,
} from "src/services/data.services";
import { AccountService } from "src/services/iam.services";
import { APIListResult } from "src/services/models/api-object";
import { Case } from "src/services/models/case";
import { DataForm } from "src/services/models/data";
import { CaseService } from "src/services/program.services";

@Component({
    templateUrl: "./case-summary.component.html",
    selector: "case-summary",
    styleUrls: ["./case.component.scss"],
})
export class CaseSummary extends ObjectAdminComponent<DataForm> {
    @Input() showExportButton = true;
    private _repository?: Case | Inquiry;
    @Input() set repository(c: Case | Inquiry | undefined) {
        this._repository = c;
        this.updateForms();
    }
    get repository(): Case | Inquiry | undefined {
        return this._repository;
    }

    dataForms?: DataForm[];
    datePipe: DatePipe;
    caseService: CaseService;
    accountService: AccountService;
    constructor(
        service: DataFormService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection);
        inject(DataFieldValueService);
        inject(CompoundDataTypeFactory);
        this.accountService = inject(AccountService);
        this.datePipe = inject(DatePipe);
        this.caseService = inject(CaseService);
        this.list.ordering = [{ field: "created_at", ascending: true }];
    }

    filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        let owned = this.repository?.id ?? "0";
        if (this.repository instanceof Case) owned += "," + this.repository?.shared.id;

        filters["owned"] = owned;
        filters["is_template"] = "False";
        filters["version"] = "1";
        return filters;
    }
    updateForms(): void {
        let owned = this.repository?.id ?? "0";
        if (this.repository instanceof Case) owned += "," + this.repository?.shared.id;
        this.service
            .list({
                owned: owned,
                is_template: "False",
                version: "1",
            })
            .subscribe(
                (dataForms: APIListResult<DataForm>) =>
                    (this.dataForms = dataForms as DataForm[]),
            );
    }

    exportAsPdf(event: MouseEvent) {
        this.terminateEvent(event);
        if (!this.dataForms || !this.repository || !this.showExportButton) return;

        const date = this.datePipe
            .transform(new Date(), "mediumDate")
            ?.replaceAll(", ", "_")
            .replaceAll(" ", "_");

        const ids = this.dataForms.map((df: DataForm) => df.id!);
        const reference = this.repository.reference_identifier;
        const caseName = this.repository.displayName?.replaceAll(" ", "_") ?? "unnamed";
        const fileName = `${reference}_${caseName}_Summary_Forms_${date}.pdf`;

        (this.service as DataFormService)
            .exportAsPdf(fileName, ids, `Case ${reference} Summary`)
            .subscribe();
    }
    exporting = false;
    exportFullCase(event: MouseEvent): void {
        this.terminateEvent(event);
        if (
            !this.repository ||
            !this.showExportButton ||
            !(this.repository instanceof Case)
        )
            return;

        if (this.exporting) return;

        this.exporting = true;
        const date = this.datePipe
            .transform(new Date(), "mediumDate")
            ?.replaceAll(", ", "_")
            .replaceAll(" ", "_");

        const reference = this.repository.reference_identifier;
        const caseName = this.repository.displayName?.replaceAll(" ", "_") ?? "unnamed";
        const fileName = `${reference}_${caseName}_${date}.zip`;
        this.snackbar.open(
            "Your download may take a few minutes. You can find it in your downloads once completed.",
            undefined,
            { duration: 10000 },
        );

        this.caseService
            .export(this.repository, fileName)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    this.snackbar.open(
                        "An error occurred while exporting the case. Please try again later.",
                        undefined,
                        { duration: 5000 },
                    );
                    this.exporting = false;
                    return EMPTY;
                }),
            )
            .subscribe();
    }
    export(event: MouseEvent): void {
        if (this.repository instanceof Case) this.exportFullCase(event);
        else if (this.repository) this.exportAsPdf(event);
        else this.terminateEvent(event);
    }

    get viewMode() {
        return ObjectViewMode.View;
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.exportSubscription?.unsubscribe();
        this.exportSubscription = undefined;
    }

    exportSubscription?: Subscription;
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.exportSubscription = this.accountService.objectEvent
            .pipe(
                filter(
                    (msg) =>
                        msg.object?.id === this.currentAccount?.id &&
                        msg.action === WebsocketObjectAction.EXPORT &&
                        this.exporting,
                ),
            )
            .subscribe(() => {
                this.exporting = false;
            });
    }
}
