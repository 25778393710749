import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
    inject,
} from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Sort } from "@angular/material/sort";
import { debounceTime, filter } from "rxjs";

import {
    DateFilterConfig,
    FilterDefinition,
} from "src/common/components/data-filter/data-filter.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { setDateDisableControls } from "src/common/utilities/date-control-utils";
import { RequestFilter } from "src/common/utilities/request";
import { convertDate } from "src/common/utilities/utilities";
import { DelegateAssignmentDialog } from "src/program/components/assignment/delegate-assignment.dialog";
import { CaseComponent } from "src/program/components/case/case.component";
import {
    APIListResult,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
} from "src/services/models/api-object";
import { Assignment } from "src/services/models/assignment";
import { Case } from "src/services/models/case";
import {
    AssignmentService,
    CaseService,
    StatusFactory,
} from "src/services/program.services";
import { MessageService } from "src/services/notification.services";

@Component({
    selector: "todos",
    templateUrl: "./todos.component.html",
    styleUrls: ["./todos.component.scss"],
})
export class TodosComponent extends ObjectAdminComponent<Assignment> {
    @ViewChild("search") searchElement?: ElementRef;
    @ViewChild("filter") filterElement?: MatSelect;

    private _initialAssignees: ObjectReference[] = [];
    private _initialCaseNames: string[] = [];
    protected caseService: CaseService;

    assigneeSort?: "asc" | "desc" | "";
    assignedToControl = new FormControl();
    betweenEndDate = new FormControl();
    betweenStartDate = new FormControl();
    caseNameControl = new FormControl();
    dateAfter = new FormControl();
    dateBefore = new FormControl();
    dateFilterConfigurations: DateFilterConfig[] = [
        {
            betweenStartDateControlName: "betweenStartDate",
            betweenEndDateControlName: "betweenEndDate",
            dateBeforeControlName: "dateBefore",
            dateAfterControlName: "dateAfter",
        },
    ];
    dataSource: any;
    filterControl: UntypedFormControl = new UntypedFormControl();
    filterDefinitions: FilterDefinition[] = [];
    filterFormGroup: FormGroup;
    searchTermControl: UntypedFormControl = new UntypedFormControl();
    showFilter: boolean = false;
    showSearch: boolean = false;
    sortEvent?: Sort;
    statusControl = new FormControl();

    get assignees() {
        if (!this._initialAssignees.length) {
            //filters out duplicate assignees or todos w/ no assignees
            this._initialAssignees = this.list.items
                .filter(
                    (a: ObjectOrReference<Assignment>) =>
                        a instanceof Assignment &&
                        !!a.assignee &&
                        a.assignee?.id !== this.currentAccount?.id,
                )
                .map((a: ObjectOrReference<Assignment>) => a as Assignment)
                .filter(
                    (v: Assignment, i: number, a: Assignment[]) =>
                        a.findIndex(
                            (v2: Assignment) => v2?.assignee?.id === v?.assignee?.id,
                        ) === i,
                )
                .map((a) => a.assignee!);

            this._initialAssignees.unshift(
                new ObjectReference({
                    id: "null",
                    name: "Unassigned",
                    displayName: "Unassigned",
                }),
            );
            this._initialAssignees.unshift(
                new ObjectReference({
                    id: this.currentAccount?.id,
                    name: "You",
                    displayName: "You",
                }),
            );
        }
        return this._initialAssignees;
    }

    get caseNames() {
        return this.cases;
    }

    get defaultFilter() {
        return {
            assignedTo: [],
            caseName: [],
            status: [],
        };
    }

    get displayedColumns(): string[] {
        return ["case", "task__name", "assignee", "assigned_on", "status"];
    }

    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    get title(): string {
        return "Open To Dos";
    }

    get today() {
        return new Date().setHours(0, 0, 0, 0);
    }

    get todoStatuses() {
        return ["New", "Pending", "Due Soon", "OverDue"];
    }

    //As of 10/20/2022 the only part of the site that uses this component is the Open ToDos table on the dashboard
    constructor(
        protected service: AssignmentService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "todo_title");
        this.caseService = inject(CaseService);
        this.filterFormGroup = new FormGroup({
            assignedTo: this.assignedToControl,
            betweenEndDate: this.betweenEndDate,
            betweenStartDate: this.betweenStartDate,
            dateAfter: this.dateAfter,
            dateBefore: this.dateBefore,
            caseName: this.caseNameControl,
            status: this.statusControl,
        });
        inject(MessageService);
        inject(StatusFactory);
        this.setupFilterDefinitions();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => {
                this.todoStatuses.includes(term) ?
                    this.list.refresh()
                :   this.updateList(term);
            });
        this.filterFormGroup.valueChanges
            .pipe(debounceTime(400))
            .subscribe(() => this.list.refresh());
        this.updateList(null); // Must be null instead of undefined else the list processor will not call it
    }

    ngOnInit() {
        setDateDisableControls([
            {
                betweenStartDate: this.betweenStartDate,
                betweenEndDate: this.betweenEndDate,
                dateAfter: this.dateAfter,
                dateBefore: this.dateBefore,
            },
        ]);
    }

    assigneeIsMe(assignment: Assignment): boolean {
        return (
            assignment.assignee?.id === this.currentAccount?.id && !!assignment.assignee
        );
    }

    cancel(event: MouseEvent) {
        this.terminateEvent(event);
        this.showFilter = false;
        this.filterFormGroup.markAsUntouched();
        this.filterFormGroup.markAsPristine();
        this.filterFormGroup.reset();
    }

    canComplete(assignment: Assignment): boolean /* NOSONAR */ {
        return (
            assignment.assignee?.id === this.currentAccount?.id && !!assignment.assignee
        );
    }

    canDelegate(assignment: Assignment): boolean /* NOSONAR */ {
        return (
            assignment.assignee?.id === this.currentAccount?.id && !!assignment.assignee
        );
    }

    clearAllFilters(event: MouseEvent) {
        this.terminateEvent(event);
        this.filterFormGroup.reset();
        this.filterFormGroup.markAllAsTouched();
    }

    completeTask(event: MouseEvent, assignment: Assignment): void {
        assignment.completed = new Date();
        this.service.update(assignment).subscribe();
    }

    delegateTask(event: MouseEvent, assignment: Assignment): void {
        const newTask = ObjectFactory.makeObject<Assignment>(assignment) as Assignment;
        if (newTask) newTask.assigned_by = this.currentAccount?.asReference;
        this.dialog.open(DelegateAssignmentDialog, {
            data: {
                service: this.service,
                object: newTask,
                case: undefined,
            },
            minWidth: "50%",
            disableClose: true,
            hasBackdrop: true,
        });
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);

        // If the search bar contains "you" then we want to filter by the current user
        if (filters.contains?.toLowerCase() == "you"){
            filters.contains = "" + this.currentAccount?.displayName
        }

        if (this.assignedToControl.value) {
            const assignees: string[] = this.assignedToControl.value
                .filter((f: string) => f?.startsWith("id:"))
                .map((a: string) => a?.replace("id:", ""));

            filters["assignee"] = assignees.join(",");
        }
        filters["account"] = this.currentAccount?.id ?? "0";
        filters["pending"] = "true";
        filters["completed"] = "false";
        filters["status"] = "open";
        filters["never_pending"] = "false";
        filters["todo"] = "true";

        if (this.caseNameControl?.value?.length) {
            filters["case"] = this.caseNameControl.value.join(",");
        }
        if (this.statusControl?.value) {
            filters["to_do_status"] = this.statusControl.value.join(",");
        }
        let betweenStartDate = this.betweenStartDate.value;
        let betweenEndDate = this.betweenEndDate.value;
        if (betweenStartDate instanceof Date && betweenEndDate instanceof Date) {
            betweenStartDate = convertDate(betweenStartDate).join("-");
            betweenEndDate = convertDate(betweenEndDate, true).join("-");

            filters["date_between"] = `${betweenStartDate},${betweenEndDate}`;
        }

        let beforeDate = this.dateBefore.value;
        if (beforeDate instanceof Date) {
            beforeDate = convertDate(beforeDate).join("-");
            filters["date_before"] = beforeDate;
        }

        let afterDate = this.dateAfter.value;
        if (afterDate instanceof Date) {
            afterDate = convertDate(afterDate).join("-");
            filters["date_after"] = afterDate;
        }

        return filters;
    }

    flipFilter(): void {
        if (!this.filterControl.value || this.filterControl.value.length === 0) {
            this.filterControl.reset();
            this.showFilter = false;
        }
    }

    flipSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.reset();
            this.showSearch = false;
        }
    }

    getOptionValue(option: any, controlName: string): any {
        if (controlName === "assignedTo") {
            return `id:${option.id}`;
        } else if (option?.id) {
            return option.id;
        }

        return option;
    }

    getOptionDisplay(option: any): string {
        if (option?.name) {
            return option.name;
        }
        return option.displayName ? option.displayName : option;
    }

    isDueSoon(assignment: Assignment): boolean {
        return assignment.due_date?.setHours(0, 0, 0, 0) == this.today;
    }

    isNew(assignment: Assignment): boolean {
        return this.isToday(assignment.assigned_on!);
    }

    isOverdue(assignment: Assignment): boolean {
        if (assignment?.due_date) {
            return assignment.due_date.setHours(0, 0, 0, 0) < this.today;
        }
        return assignment.isOverdue;
    }

    isPending(assignment: Assignment): boolean {
        return (
            !this.isOverdue(assignment) &&
            !this.isDueSoon(assignment) &&
            !this.isNew(assignment)
        );
    }

    isToday(d: Date): boolean {
        const today = new Date();
        const td = new Date(d);
        return td.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
    }
    isOptionDisabled(option: any): boolean {
        // Disable the option if it is the only assignee and matches the current account id
        return !!(
            this.assignees &&
            option.id &&
            this.assignees.length === 1 &&
            option.id === this.currentAccount?.id
        );
    }
    onFocusOut(event: any): void {
        this.flipFilter();
        this.flipSearch();
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}

    onSortChange(event: Sort): void {
        // onSortChange will trigger a list refresh so postSearch
        // will be called with the current sortEvent
        this.sortEvent = event;
        if (event.direction) {
            // status isn't a DB field and is determined by the assigned on field
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    resetFilter(event: MouseEvent) {
        this.terminateEvent(event);
        this.filterFormGroup.reset();
    }

    resetFilters(event?: MouseEvent): void {
        this.filterControl.reset();
        this.showFilter = false;
        this.updateList();
        this.flipSearch();
    }

    resetSearchTerm(event?: MouseEvent): void {
        this.searchTermControl.reset();
        this.showSearch = false;
        this.updateList(null);
        this.flipFilter();
    }

    setupFilterDefinitions(): void {
        this.filterDefinitions = [
            {
                displayName: "Assigned To",
                controlName: "assignedTo",
                placeholder: "Select one or more assignees",
            },
            {
                displayName: "Case Name",
                controlName: "caseName",
                placeholder: "Select one or more cases",
            },
            {
                displayName: "Status",
                controlName: "status",
                placeholder: "Select one or more statuses",
            },
        ];
    }

    showTask(event: MouseEvent, assignment: Assignment): void {
        if (assignment.workflow?.type == "program.case") {
            this.caseService
                .retrieve(assignment.workflow.id!)
                .subscribe((c: Case | undefined) => {
                    if (c) {
                        const instance = ObjectAdminComponent.showObject<Case>(
                            c,
                            CaseComponent,
                            ObjectViewMode.Edit,
                        ) as CaseComponent;
                        if (assignment.pending) {
                            //MED-1272 - any open to do will take you to action area
                            instance.assignmentToOpen = assignment;
                        } else {
                            instance.showTask(assignment);
                        }
                    } else {
                        // We shouldn't get here, but should probably handle this error case
                    }
                });
        } else if (assignment.workflow?.type == "program.inquiry") {
            this.caseService
                .list({
                    inquiry: assignment.workflow?.id ?? "0",
                })
                .subscribe((list: APIListResult<Case>) => {
                    const cases = list as Case[];
                    if (!cases.length) {
                        console.error(
                            "Unable to find case to open for assignment: ",
                            assignment,
                        );
                        return;
                    }
                    let caseToOpen = cases[0];

                    const instance = ObjectAdminComponent.showObject<Case>(
                        caseToOpen,
                        CaseComponent,
                        ObjectViewMode.Edit,
                    ) as CaseComponent;
                    if (assignment.pending) {
                        //MED-1272 - any open to do will take you to action area
                        instance.assignmentToOpen = assignment;
                    } else {
                        instance.showTask(assignment);
                    }
                });
        }
    }

    toggleFilter(event: MouseEvent): void {
        this.showFilter = !this.showFilter;
        if (this.showFilter && !this?.cases?.length) {
            this.getCases();
        }
        if (this.showFilter) setTimeout(() => this.filterElement?.focus());
        this.flipSearch();
    }

    cases: ObjectOrReference<Case>[] = [];
    getCases() {
        this.service.getCaseNames().subscribe((res) => (this.cases = res));
    }
    toggleSearch(event: MouseEvent): void {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
        this.flipFilter();
    }
}
