import { WorkflowInstance } from "./../../../services/models/workflow";
import { ObjectView } from "./../../../common/components/object-admin.component";
import { Assignment, AssignmentReference } from "src/services/models/assignment";
import { finalize, defaultIfEmpty, map, mergeMap, tap } from "rxjs/operators";
import { DATEPICKER_FORMATS, generateCode } from "src/common/utilities/utilities";
import { RoleDefinition } from "./../../../services/models/role";
import { Component, HostListener, Input, inject } from "@angular/core";
import {
    ObjectComponent,
    ObjectViewMode,
} from "../../../common/components/object.component";
import {
    APIListResult,
    APIObject,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
} from "../../../services/models/api-object";
import { Task, TaskType, TASK_TYPE } from "../../../services/models/task";
import {
    DocusignAccount,
    DocusignEnvelope,
    DocusignSigner,
    DocusignTab,
    DocusignToken,
} from "../../../services/models/docusign";
import { FileItem, FileUploader } from "ng2-file-upload";
import {
    FormControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AssignmentService, DocumentService } from "../../../services/program.services";
import {
    DocusignAccountFactory,
    DocusignEnvelopeService,
    DocusignService,
    DocusignSignerFactory,
    DocusignTabFactory,
} from "../../../services/docusign.service";
import { Document, DocumentRepository } from "../../../services/models/document";
import { Case } from "../../../services/models/case";
import { forkJoin, Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Account } from "../../../services/models/account";
import { ObjectAdminComponent } from "../../../common/components/object-admin.component";
import { DelegateAssignmentDialog } from "./delegate-assignment.dialog";
import {
    IsDateValidator,
    setValuesAndValidators,
} from "src/common/utilities/validators";
import { CaseTeam, TeamMember } from "src/services/models/team";
import { DataForm } from "src/services/models/data";
import { DataFormService } from "src/services/data.services";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { queryStringFromFilters } from "src/common/utilities/request";
import { defined } from "src/common/utilities/flatten";

interface FileAndDocusignTabs {
    file: FileItem;
    tabs: DocusignTab[];
}

@Component({
    selector: "assignment",
    templateUrl: "assignment.component.html",
    styleUrls: ["assignment.component.scss"],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
    ],
})
export class AssignmentComponent extends ObjectComponent<Assignment> {
    objectName = "Create To-Do";
    @Input() parentAssignments?: Assignment[];
    @Input() workflowInstance?: WorkflowInstance;

    get availableTeams(): CaseTeam[] {
        return this.case?.teams ?? [];
    }
    get availableAssignees(): TeamMember[] {
        // this will allow all members from teams that the currentAccount is a member of, but exclude private members from teams that they are not
        return ([] as TeamMember[]).concat.apply(
            [],
            this.availableTeams.map((ct: CaseTeam) => {
                const isMember = !!ct.members.find(
                    (tm: TeamMember) => tm.account.id == this.currentAccount?.id,
                );
                return ct.members.filter((tm: TeamMember) => isMember || !tm.private);
            }) || [],
        );
    }

    protected allAssignments: ObjectOrReference<Assignment>[] = [];
    protected otherAssignments_: ObjectOrReference<Assignment>[] = [];
    get otherAssignments(): ObjectOrReference<Assignment>[] {
        return this.otherAssignments_;
    }
    set otherAssignments(v: ObjectOrReference<Assignment>[]) {
        this.otherAssignments_ = v.filter(
            (a: ObjectOrReference<Assignment>) =>
                a instanceof ObjectReference || !a.completed,
        );
        this.updateDependencyValues();
    }

    _case: Case | undefined;
    get case(): Case | undefined {
        return this._case;
    }
    set case(c: Case | undefined) {
        this._case = c;
        if (c?.shared.organization?.id) {
            const ownerIds = c.id + "," + c.shared.id;
            this.dataFormService
                .list({ version: "1", owned: ownerIds, is_template: "False" })
                .pipe(
                    tap(() => this.loadingDataForms++),
                    finalize(() => this.loadingDataForms--),
                )
                .subscribe((df) => {
                    this.dataForms = df as DataForm[];
                });
        }
        this.updateAssigneeValue();
    }
    get caseTeam(): CaseTeam | undefined {
        return this.case?.caseTeam(this.currentAccount);
    }

    get taskTypes(): TaskType[] {
        return Task.TaskTypes.filter((type: TaskType) => {
            if (type.task_type == TASK_TYPE.DOCUSIGN_SIGNATURE)
                return this.docusignService.isDocusignEnabled;
            return true;
        });
    }
    get taskHint(): string | undefined {
        return this.selectedTaskType?.hint_text;
    }
    get selectedTaskType(): TaskType | undefined {
        const selectedTaskType = this.asFormGroup(this.formGroup.controls.task).controls
            ?.task_type.value;
        return this.taskTypes.find((tt: TaskType) => tt?.task_type == selectedTaskType);
    }

    get isDocusignTask(): boolean {
        return (
            this.selectedTaskType?.task_type == TASK_TYPE.DOCUSIGN_SIGNATURE &&
            this.docusignService.isDocusignEnabled
        );
    }
    get isDocusignValid(): boolean {
        return (
            !this.isDocusignTask ||
            (!!this.docusignAccount.value?.token &&
                !this.docusignAccount.value.token.requires_authorization)
        );
    }

    get formButtonText(): string {
        return this.isDocusignTask && !this.envelope ? "Create Envelope" : "Save";
    }

    _envelope?: DocusignEnvelope;
    get envelope(): DocusignEnvelope | undefined {
        return this._envelope;
    }
    set envelope(e: DocusignEnvelope | undefined) {
        this._envelope = e;
        if (e) {
            this.docusignAccount.setValue(e.account);
            this.formGroup.markAsDirty();
        }
    }

    nameForDocument(doc: ObjectOrReference<Document> | FileItem): string {
        return (
            (doc instanceof APIObject ? doc.displayName : doc.file.name) ??
            "Unnamed File"
        );
    }
    docusignAccount: UntypedFormControl = new UntypedFormControl();
    docusignSenderView?: string;
    fileOver: boolean = false;
    uploader: FileUploader;
    documentService: DocumentService;
    docusignService: DocusignService;
    dataFormService: DataFormService;
    docusignEnvelopeService: DocusignEnvelopeService;
    availableDocuments: Document[] = [];
    availableDocusignAccounts: DocusignAccount[] = [];
    cancelPolling: boolean = false;
    docusignRef: any;
    formParentDependencies: ObjectOrReference<Assignment>[] = [];

    static showAssignment(
        ass: Assignment,
        c: Case,
        service: AssignmentService,
        account?: Account,
        mode?: ObjectViewMode,
    ): AssignmentComponent {
        if (!mode) {
            mode = ObjectViewMode.View;
            const isAssignee = ass.assignee && account?.id === ass.assignee.id;
            const isAssignor = account?.id === ass.assigned_by?.id;
            const isEditor = c.isEditor(account);
            const canEdit = !c.isCaseClosed && !ass.completed;
            if (canEdit && (isAssignee || isAssignor || isEditor)) {
                mode = ObjectViewMode.Edit;
            }
        }
        const instance = ObjectAdminComponent.showObject<Assignment>(
            ass,
            AssignmentComponent,
            mode,
            {
                maxWidth: "90vw",
                maxHeight: "75vh",
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            },
        ) as AssignmentComponent;
        instance.autosave = false;
        instance.case = c;
        service
            .list({ workflow: c.id! })
            .subscribe((assignments: APIListResult<Assignment>) => {
                instance.allAssignments =
                    assignments as ObjectOrReference<Assignment>[];
                instance.otherAssignments = instance.allAssignments.filter(
                    (a: ObjectOrReference<Assignment>) => a.id !== ass.id,
                );
            });
        return instance;
    }

    get isGroupTask(): boolean {
        return (
            this.selectedTaskType?.task_type == "workflow.group" ||
            this.selectedTaskType?.task_type == "workflow.group.internal"
        );
    }

    constructor(
        protected service: AssignmentService,
        protected router: Router,
        protected location: Location,
        private dateAdapter: DateAdapter<any>,
        private translateService: TranslateService,
    ) {
        super(service);
        this.documentService = inject(DocumentService);
        this.docusignService = inject(DocusignService);
        this.docusignEnvelopeService = inject(DocusignEnvelopeService);
        this.dataFormService = inject(DataFormService);
        const culture = this.translateService.getBrowserCultureLang();
        this.dateAdapter.setLocale(culture);
        inject(DocusignAccountFactory);
        inject(DocusignTabFactory);
        inject(DocusignSignerFactory);

        this.uploader = new FileUploader({ allowedFileType: ["pdf"], url: "" }); // This is a temporary uploader, which we will change once we have all of the owner/repo information
        this.asFormGroup(
            this.formGroup.controls.task,
        ).controls?.task_type.valueChanges.subscribe((v) => {
            this.formGroup.controls.parent.setValidators(
                this.isGroupTask ? null : [Validators.required],
            );
            this.formGroup.controls.parent.updateValueAndValidity();

            if (this.isDocusignTask) {
                this.updateAvailableDocuments();
                this.updateAvailableDocusignAccounts();
                this.docusignAccount.valueChanges.subscribe(() =>
                    this.formGroup.markAsDirty(),
                );
                this.dialogReference?.updateSize("75vw");
                if (this.mode == ObjectViewMode.Edit) {
                    this.formGroup.controls.member.disable();
                } else {
                    this.formGroup.controls.member.enable();
                }
            } else if (this.mode == ObjectViewMode.Edit) {
                this.formGroup.controls.member.enable();
            }
        });
        this.triggerControl.valueChanges.subscribe((v) => {
            //An assignment can only have one trigger
            if (v === "dependencies" && this.dueDateControl?.value) {
                this.dueDateControl.reset();
            } else if (v === "due_date" && this.dependencyControl?.value) {
                this.dependencyControl.reset();
            }
        });
    }
    get dependencyControl() {
        return this.formGroup.get("dependencies");
    }
    get dueDateControl() {
        return this.formGroup.get("due_date");
    }
    get showDataForms() {
        return (
            this.asFormGroup(this.formGroup.controls.task).controls?.task_type.value ===
            TASK_TYPE.DATA
        );
    }

    dataForms?: DataForm[];
    shipmentForm?: ObjectOrReference<DataForm>;
    loadingDataForms: number = 0;

    isCounterparty(account: ObjectReference): boolean {
        return !this.case?.isMemberOfTeam(account, this.caseTeam?.capacity);
    }

    get canEdit(): boolean {
        return (
            !this.fullObject?.completed &&
            this.mode == ObjectViewMode.Edit &&
            (this.currentAccount?.id == this.fullObject?.assigned_by?.id ||
                !!this.case?.isEditor(this.currentAccount)) &&
            (!this.isDocusignTask || !this.envelope?.sent)
        );
    }
    get isAssignee(): boolean {
        return this.currentAccount?.id == this.fullObject?.assignee?.id;
    }

    get dependencies(): ObjectOrReference<Assignment>[] {
        return this._dependencies(this.fullObject);
    }
    get dependantTasks(): ObjectOrReference<Assignment>[] {
        return this.otherAssignments.filter(
            (a: ObjectOrReference<Assignment>) =>
                a instanceof Assignment &&
                !!a.dependencies.find(
                    (b: ObjectOrReference<Assignment>) => b.id === this.object?.id,
                ),
        );
    }
    get availableDependencies(): ObjectOrReference<Assignment>[] {
        const deps = this.otherAssignments
            .filter(
                (a: ObjectOrReference<Assignment>) =>
                    a instanceof Assignment &&
                    a.task.taskType != TASK_TYPE.WORKFLOW_GROUP &&
                    a.task.taskType != TASK_TYPE.SUMMARY,
            )
            .filter(
                (a: ObjectOrReference<Assignment>) =>
                    !this.isDependant(a, this.fullObject),
            );
        return deps;
    }
    _dependencies(
        task?: ObjectOrReference<Assignment>,
    ): ObjectOrReference<Assignment>[] {
        return this.allAssignments.filter(
            (a: ObjectOrReference<Assignment>) =>
                task instanceof Assignment &&
                task?.dependencies.find(
                    (b: ObjectOrReference<Assignment>) => b.id == a.id,
                ),
        );
    }
    isDependantTask(a: ObjectOrReference<Assignment>): boolean {
        return !!this.dependantTasks.find(
            (b: ObjectOrReference<Assignment>) => b.id == a.id,
        );
    }
    isDependant(
        task: ObjectOrReference<Assignment>,
        of?: Assignment,
        visited?: ObjectOrReference<Assignment>[],
    ): boolean {
        visited = visited ?? [];
        visited.push(task);
        const deps = this._dependencies(task);
        return (
            deps
                .map((b: ObjectOrReference<Assignment>) => {
                    if (b.id == of?.id) return true;
                    if (
                        visited?.find(
                            (t: ObjectOrReference<Assignment>) => t.id == b.id,
                        )
                    )
                        return false;
                    return this.isDependant(b, of, visited);
                })
                .filter((value: boolean) => !!value).length > 0
        );
    }

    completeTask(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.fullObject) {
            this.fullObject.completed = new Date();
            this.fullObject.completed_by = this.currentAccount?.asReference;
            this.service
                .update(this.fullObject)
                .subscribe((a: Assignment | undefined) => {
                    this.object = a;
                    this.dialogReference?.close(a);
                });
        }
    }
    delegateTask(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.fullObject && this.fullObject instanceof Assignment) {
            const newTask = ObjectFactory.makeObject<Assignment>(
                this.fullObject,
            ) as Assignment;
            if (newTask) {
                newTask.assigned_by = this.currentAccount?.asReference;
                this.dialog
                    .open(DelegateAssignmentDialog, {
                        data: {
                            service: this.service,
                            object: newTask,
                            case: this.case,
                            caseTeam: this.case?.caseTeam(this.currentAccount),
                        },
                        minWidth: "50%",
                        disableClose: true,
                        hasBackdrop: true,
                    })
                    .afterClosed()
                    .subscribe((a: Assignment) => {
                        if (a && this.dialogReference) this.dialogReference.close(a);
                    });
            }
        }
    }
    get documentSources(): DocumentRepository[] {
        let sources = this.currentAccount ? [this.currentAccount.asReference] : [];
        if (this.case?.isPharmaStaff(this.currentAccount)) {
            if (this.case?.shared.organization)
                sources = [...sources, this.case.shared.organization];
            if (this.case?.shared.program)
                sources = [...sources, this.case.shared.program];
        }
        if (
            this.case?.owner &&
            !sources.find((ref: ObjectReference) => ref.id === this.case?.owner.id)
        )
            sources = [...sources, this.case.owner];
        return sources;
    }
    linkDocusignAccount(event: MouseEvent): void {
        // generate random string
        const state = generateCode(16) + "|" + this.currentAccount?.id;
        const tree = this.router.createUrlTree(["docusign", "authorization"]);
        const path = this.location.prepareExternalUrl(tree.toString());
        const url = window.location.origin + path;
        const params = {
            response_type: "code",
            scope: "signature",
            state: state,
            client_id: this.service.session.environment.docusignClientId,
            redirect_uri: url,
        };
        const authUrl =
            [
                this.service.session.environment.docusignRoot,
                this.service.session.environment.docusignAuthorizationService,
            ].join("/") + queryStringFromFilters(params);
        this.docusignRef = window.open(authUrl, "_blank");
        this.pollForToken(state);
    }
    pollForToken(state: string, count: number = 0, time: number = 5000): void {
        if (!this.cancelPolling)
            setTimeout(() => this.onTokenPoll(state, ++count, time), time);
    }
    getOrCreateToken(state: string) {
        const params = JSON.parse(localStorage.getItem("docusign")!);
        if (params) localStorage.removeItem("docusign");

        const code = params ? params["code"] : undefined;
        const pstate = params ? params["state"] : undefined;
        if (code && pstate === state) {
            const token = new DocusignToken({
                state: state,
                authorization: code,
            });
            return this.docusignService.create(token);
        }

        return this.docusignService.find(state);
    }
    onTokenPoll(state: string, count: number, time: number): void {
        if (count <= 24) {
            this.getOrCreateToken(state).subscribe((token?: DocusignToken) => {
                if (token?.accounts.length) {
                    // remove any existing accounts with the same email address as this token
                    const accounts = this.availableDocusignAccounts.filter(
                        (account: DocusignAccount) =>
                            account.token?.email !== token.email,
                    );
                    accounts.push(...token.accounts);
                    this.availableDocusignAccounts = accounts;
                    const defaultAccount = token.accounts.find(
                        (account: DocusignAccount) => account.is_default,
                    );
                    if (defaultAccount) {
                        this.docusignAccount.setValue(defaultAccount);
                        this.formGroup.markAsDirty();
                    }
                    if (this.docusignRef) this.docusignRef.close();
                } else this.pollForToken(state, count, time);
            });
        } else {
            this.setError("Error authenticating with the DocuSign service");
        }
    }
    // This function replaces the ng2FileUpload directive on the upload input
    uploadFile(event: any): void {
        const options = this.uploader.options;
        this.uploader.addToQueue(event.target.files, options, "");
        // we need to reset the input so that the same file can be uploaded again
        event.target.value = "";
    }
    get minDateForDueDate() {
        return new Date();
    }
    dataFormControl = new FormControl();
    triggerControl = new FormControl<"due_date" | "dependencies" | "none">("none");

    reocurringTrigger = new FormControl(false);
    timeUnits: string[] = ["Week", "Month", "Year"];
    defaultTimeUnit: string = "Week";
    get triggerValue() {
        return this.triggerControl.value;
    }
    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            task: this.formBuilder.group({
                task_type: [null, Validators.required],
                name: [null, Validators.required],
                description: [null],
            }),
            form_name: [null],
            member: [null],
            reference: [null],
            dependencies: [[]], // JT - Even though we've removed dependencies from the create view, we still need it in the edit view, so this has to remain
            due_date: [null, [IsDateValidator]],
            parent: [null],
            frequency_unit: [],
            frequency_amount: [],
            frequency_end_date: [null],
        });
    }
    protected getIsValid(): boolean {
        return super.getIsValid() && this.isDocusignValid;
    }

    // Adds workflow group dependencies to the form dependencies when a new workflow group is selected
    onWorkflowSelect() {
        let dependencies = this.formGroup
            .get("dependencies")
            ?.value.map((a: any) =>
                this.otherAssignments?.find((b: any) => a.id == b.id),
            );
        const oldParentDeps = this.formParentDependencies.map((a: any) =>
            this.otherAssignments?.find((b: any) => a.id == b.id),
        );
        let newParentDeps = this.formGroup.controls.parent.value;

        newParentDeps =
            typeof newParentDeps === "string" ?
                this.parentAssignments?.find((a: any) => a.id == newParentDeps)
                    ?.dependencies
            :   newParentDeps.dependencies;

        newParentDeps = newParentDeps.map((a: any) =>
            this.otherAssignments?.find((b: any) => a.id == b.id),
        );

        dependencies = dependencies
            .filter((a: any) => !oldParentDeps.includes(a))
            .concat(newParentDeps);

        this.formParentDependencies = newParentDeps;

        this.formGroup.get("dependencies")?.setValue(dependencies);
    }

    onTaskTypeSelect() {
        if (this.selectedTaskType?.task_type == "workflow.group") {
            this.formGroup.get("parent")?.setValue(null);
        } else if (this.parentAssignments?.length == 1) {
            this.formGroup.get("parent")?.setValue(this.parentAssignments[0].id);
            this.onWorkflowSelect();
        }
    }

    protected precommitTransform(v: any): any {
        // add any references necessary for the assignment
        let references: { [key: string]: any }[] = this.fullObject?.references ?? [];
        const data_form_id = this.dataFormControl.value;
        if (v.references?.length) {
            references.push(...v.references);
        }

        if (data_form_id && this?.dataForms?.length) {
            const dataForm = this.dataForms.find((f) => f.id === data_form_id);
            const reference: { [key: string]: any } = {
                item_type: "form",
                reference: dataForm?.asReference,
            };
            // remove the old form reference
            references = references.filter(
                (r: { [key: string]: any }) => r.item_type !== "form",
            );
            references.push(reference);
        }
        if (v.task.taskType == TASK_TYPE.CREATE_SHIPMENT && this.shipmentForm) {
            const reference: { [key: string]: any } = {};
            reference.item_type = "*ref:shipment.form";
            reference.reference = this.shipmentForm.asReference;
            references.push(reference);
        }
        v.references = references;

        if (this.mode == ObjectViewMode.Edit) {
            if (v?.task?.name != this.fullObject?.task.name)
                v.display_name = v?.task?.name;
            if (v?.task?.description != this.fullObject?.task.description)
                v.description = v?.task?.description;
            delete v.task; // Can't update a task in edit mode
            delete v.parent; // can't update the parent task in edit mode
        } else {
            v.task.owner = this.case?.owner;

            const parent = this.parentAssignments?.find((a) => a.id === v?.parent);
            if (parent) v.parent = parent?.asReference;
        }

        if (this.workflowInstance) {
            v.workflow_instance = this.workflowInstance;
        }

        if (this.isDocusignTask && this.case && v.member) {
            const assignee = v.member.account;

            const aSide =
                this.case.isPharmaStaff(this.currentAccount) ? "pharma" : "provider";
            const bSide = this.case.isPharmaStaff(assignee) ? "pharma" : "provider";

            const repository =
                aSide == bSide ? this.case.asReference : this.case.shared.asReference;
            v.workflow = repository;
        } else v.workflow = this.case?.shared;
        if (v.member) {
            v.assignee = v.member.account;
            if (v.assignee?.id != this.fullObject?.assignee?.id)
                v.assigned_by = this.currentAccount?.asReference;
        } else {
            v.assignee = undefined;
            v.assigned_by = undefined;
        }

        return super.precommitTransform(v);
    }
    protected commit(v: any): Observable<Assignment | undefined> {
        if (this.isDocusignTask && this.envelope) {
            v.references = this.fullObject?.references;
            let reference = v.references?.find(
                (ar: AssignmentReference) => ar.item_type == "docusign.envelope",
            );
            if (!reference) {
                reference = ObjectFactory.makeObject<AssignmentReference>(
                    {
                        item_type: "docusign.envelope",
                        reference: this.envelope.asReference,
                    },
                    "program.assignmentreference",
                ) as AssignmentReference;
                v.references = defined([...(v.references || []), reference]);
            } else {
                reference.reference = this.envelope.asReference;
            }
        }
        return super.commit(v);
    }

    createEnvelope(): void {
        // determine if it's a shared task or internal task
        if (!this.case) return;
        const assignee = this.formGroup.get("member")?.value?.account;
        let signers: DocusignSigner[] = [];

        let repository = this.case.asReference;
        if (assignee) {
            const aSide =
                this.case.isPharmaStaff(this.currentAccount) ? "pharma" : "provider";
            const bSide = this.case.isPharmaStaff(assignee) ? "pharma" : "provider";
            // Create the envelope, and attach it to the assignment as a reference
            if (aSide != bSide) repository = this.case.shared.asReference;
            const signer = ObjectFactory.makeObject<DocusignSigner>(
                {
                    signer: assignee,
                    order: 1,
                },
                DocusignSigner.object_type,
            ) as DocusignSigner;
            signers = [signer];
        }

        const envelope = ObjectFactory.makeObject<DocusignEnvelope>(
            {
                repository: repository,
                signers: signers,
                account: this.docusignAccount.value,
            },
            DocusignEnvelope.object_type,
        );
        this.docusignEnvelopeService
            .create(envelope)
            .subscribe((env: DocusignEnvelope | undefined) => {
                this.editDocusignEnvelope(env);
            });
    }

    onSave(): void {
        if (this.isDocusignTask && !this.envelope) {
            this.createEnvelope();
        } else {
            super.onSave();
        }
    }

    teamForMember(member: TeamMember): CaseTeam | undefined {
        return this.availableTeams.find(
            (ct: CaseTeam) =>
                !!ct.members.find(
                    (tm: TeamMember) => tm.account.id == member?.account.id,
                ),
        );
    }
    organizationForMember(member: TeamMember): string {
        return (
            this.teamForMember(member)?.team.organization.displayName ??
            "Unknown Institution"
        );
    }
    roleDisplayForMember(member: TeamMember): string {
        const value = [];
        const roles =
            member?.role.split("|").filter((r: string) => r != "member") || [];
        for (const r of roles) {
            const definition = TeamMember.DefinedRoles.find(
                (rd: RoleDefinition) => rd.value == r,
            );
            if (definition) value.push(definition.display);
        }
        return value.join(", ");
    }
    memberContext(member: TeamMember): string {
        const context = this.organizationForMember(member);
        const roles = this.roleDisplayForMember(member);
        if (roles != "") return context + " (" + roles + ")";
        return context;
    }

    taskDisplayName(assignment: ObjectOrReference<Assignment>): string {
        return (
            (assignment instanceof Assignment ?
                assignment.task.displayName
            :   assignment.displayName) ?? ""
        );
    }
    dataFormVersion(dataForm: ObjectOrReference<DataForm>): number | undefined {
        const template = dataForm instanceof DataForm ? dataForm.template : undefined;
        return template instanceof DataForm ? template.version : undefined;
    }

    protected setObject(v?: Assignment) {
        if (v) {
            v.task = v?.task || ObjectFactory.makeObject<Task>({}, Task.object_type);
        }
        super.setObject(v);
        if (this.isDocusignTask) {
            this.updateDocusignEnvelope();
        }
        this.updateAssigneeValue();
        this.formGroup.get("task.name")?.setValue(this.fullObject?.displayName);
        this.formGroup
            .get("task.description")
            ?.setValue(this.fullObject?.taskDescription);
    }

    protected onCurrentAccountChanged(a: Account | undefined): void {
        super.onCurrentAccountChanged(a);
        if (a && this.isDocusignTask) {
            this.updateAvailableDocuments();
            this.updateAvailableDocusignAccounts();
        } else {
            this.availableDocuments = [];
            this.availableDocusignAccounts = [];
        }
    }

    protected showDocusignSenderView(url: string): void {
        if (url) {
            this.dialogReference?.updateSize("90vw", "80vh");
            this.docusignSenderView = url;
        }
    }

    @HostListener("window:message", ["$event"])
    onMessage(event: MessageEvent): void {
        if (event.data.source === "dsResponse") {
            if (event.data.href) {
                const href = event.data.href;
                const split = href.split("?");
                const path = split[0];
                const queryString = split.length > 1 ? split[1] : "";

                const params: { [key: string]: string } = {};
                queryString.split("&").map((p: string) => {
                    const [key, value] = p.split("=");
                    params[key] = value;
                });

                const envelopeId = path.split("/")[5];
                const status = params["event"]?.toLowerCase() ?? "cancel";

                if (status == "send" || status == "save") {
                    // Synchronize the envelope and get any new contact information
                    // We want to avoid the cache so that the back end requests new information from Docusign
                    this.docusignEnvelopeService
                        .retrieve(envelopeId, { synchronize: "True" }, true)
                        .subscribe({
                            next: (envelope: DocusignEnvelope | undefined) => {
                                this.envelope = envelope;
                                // Create the todo
                                this.dialogReference?.updateSize("75vw", "60vh");
                                this.docusignSenderView = undefined;
                                this.onSave();
                            },
                            error: () => {
                                this.dialogReference?.updateSize("75vw", "60vh");
                                this.docusignSenderView = undefined;
                                this.setError("Error synchronizing envelope");
                            },
                        });
                } else {
                    if (status == "error") {
                        // Display an error message?
                    }
                    // If we're creating a new envelope, there won't be an envelope property.
                    // In that case, delete the envelope
                    if (!this.envelope) {
                        const obj = new ObjectReference({
                            id: envelopeId,
                            type: DocusignEnvelope.object_type,
                        });
                        this.docusignEnvelopeService.destroy(obj).subscribe();
                    }

                    // Close the dialog
                    this.onCancel();
                    this.dialogReference?.updateSize("75vw", "60vh");
                    this.docusignSenderView = undefined;
                }
            }
        }
    }

    protected updateDocusignEnvelope(): void {
        if (this.isDocusignTask) {
            const reference = this.fullObject?.references?.find(
                (ar: AssignmentReference) => ar.item_type == "docusign.envelope",
            )?.reference as ObjectOrReference<DocusignEnvelope>;

            if (reference) {
                if (reference instanceof ObjectReference) {
                    if (reference.id) {
                        this.docusignEnvelopeService
                            .retrieve(reference.id)
                            .subscribe((envelope: DocusignEnvelope | undefined) => {
                                this.envelope = envelope;
                                this.updateAvailableDocusignAccounts(envelope?.account);
                            });
                    } else {
                        this.envelope = undefined;
                    }
                } else {
                    this.envelope = reference;
                    this.updateAvailableDocusignAccounts(reference?.account);
                }
            } else {
                this.envelope = undefined;
            }
        } else {
            this.envelope = undefined;
        }
    }
    editDocusignEnvelope(envelope?: DocusignEnvelope): void {
        if (envelope) {
            this.docusignEnvelopeService.getSenderView(envelope).subscribe({
                next: (url: string) => this.showDocusignSenderView(url),
            });
        }
    }

    protected updateAssigneeValue(): void {
        if (this.fullObject?.assignee) {
            const member = this.case?.isMemberOfTeam(this.fullObject?.assignee);
            this.formGroup.controls.member.setValue(member);
        } else {
            this.formGroup.controls.member.setValue(undefined);
        }
    }
    protected updateDependencyValues(): void {
        const control = this.formGroup.get("dependencies");
        if (control) {
            const dependencies = (control.value || []).map(
                (a: ObjectOrReference<Assignment>) =>
                    this.otherAssignments.find(
                        (b: ObjectOrReference<Assignment>) => b.id == a.id,
                    ) ?? a,
            );
            control.setValue(dependencies);
        }
    }

    protected taskParent(
        task: Assignment | undefined,
        assignments: Assignment[],
    ): Assignment | undefined {
        return assignments.find((a: Assignment) => a.id == task?.parent?.id);
    }
    protected getTaskOrder(
        task: Assignment | undefined,
        assignments: Assignment[],
    ): number {
        const parent = this.taskParent(task, assignments);
        const parentIndex = parent ? this.getTaskOrder(parent, assignments) * 100 : 0;
        return parentIndex + (task?.order ?? 0);
    }
    protected sortAssignments(assignments: Assignment[]): Assignment[] {
        return assignments.sort((a: Assignment, b: Assignment) => {
            return (
                this.getTaskOrder(a, assignments) - this.getTaskOrder(b, assignments)
            );
        });
    }
    protected updateAvailableDocuments(): void {
        const sources = this.documentSources;
        const obs = sources.map((repo: DocumentRepository) =>
            this.documentService.list({ repo: repo.id! }),
        );
        forkJoin(obs)
            .pipe(
                map((res: APIListResult<Document>[]) =>
                    ([] as Document[]).concat(...(res as Document[][])),
                ),
            )
            .subscribe(
                (documents: Document[]) => (this.availableDocuments = documents),
            );
    }
    selectedAccount?: DocusignAccount;
    protected updateAvailableDocusignAccounts(acct?: ObjectReference): void {
        this.docusignService
            .list({ account: this.currentAccount?.id ?? "0" })
            .pipe(
                map(
                    (results: APIListResult<DocusignToken>) =>
                        results as DocusignToken[],
                ),
                map((tokens: DocusignToken[]) =>
                    tokens.map((token: DocusignToken) => token.accounts),
                ),
                map((accounts: DocusignAccount[][]) =>
                    ([] as DocusignAccount[]).concat(...accounts),
                ),
            )
            .subscribe((accounts: DocusignAccount[]) => {
                this.availableDocusignAccounts = accounts;
                const defaultAccount = this.availableDocusignAccounts.find(
                    (account: DocusignAccount) =>
                        acct ? acct.id == account.id : account.is_default,
                );
                if ((acct || !this.docusignAccount.value) && defaultAccount) {
                    this.docusignAccount.setValue(defaultAccount);
                    this.selectedAccount = defaultAccount;
                    if (!acct) this.docusignAccount.markAsDirty();
                }
            });
    }

    deleteTask(e: Event) {
        this?.terminateEvent(e);
        const data = {
            title: `Delete Task`,
            message: `Are you sure you want to delete this task ?`,
            ok: "Delete",
        };
        this.dialog
            .open(ConfirmDialog, {
                disableClose: true,
                data,
            })
            .afterClosed()
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.service
                        .destroy(this.fullObject as Assignment)
                        .subscribe(() => this.dialogReference?.close());
                }
            });
    }

    get frequencyAmountControl() {
        return this.formGroup.get("frequency_amount");
    }
    get frequencyUnitControl() {
        return this.formGroup.get("frequency_unit");
    }

    frequencyTypeControl = new FormControl<"one_time" | "recurring">(
        "one_time",
        Validators.required,
    );

    get frequencyTypeValue() {
        return this.frequencyTypeControl.value;
    }

    frequencyEndTypeControl = new FormControl<"repeat_forever" | "end_repeat_date">(
        "repeat_forever",
        Validators.required,
    );

    get frequencyEndTypeValue() {
        return this.frequencyEndTypeControl.value;
    }

    setFrequencyInputs(v: "one_time" | "recurring", updateValues = false) {
        const controls = ["frequency_amount", "frequency_unit"];
        let values = null;
        let validators = null;

        switch (v) {
            case "recurring":
                validators = {
                    frequency_amount: [Validators.required, Validators.min(1)],
                    frequency_unit: [Validators.required],
                };
                break;
            case "one_time":
                if (updateValues) {
                    values = { frequency_amount: null, frequency_unit: null };
                    this.frequencyEndTypeControl.setValue("repeat_forever");
                    this.setFrequencyEndTypeInputs("repeat_forever", true);
                }
                break;
        }

        setValuesAndValidators(this.formGroup, controls, values, validators);
    }

    setFrequencyEndTypeInputs(
        v: "repeat_forever" | "end_repeat_date",
        updateValues = false,
    ) {
        const controls = ["frequency_end_date"];
        let values = null;
        let validators = null;

        switch (v) {
            case "repeat_forever":
                if (updateValues) {
                    values = { frequency_end_date: null };
                }
                break;
            case "end_repeat_date":
                validators = {
                    frequency_end_date: [IsDateValidator, Validators.required],
                };
                break;
        }

        setValuesAndValidators(this.formGroup, controls, values, validators);
    }

    setTriggerInputs(v: "due_date" | "dependencies" | "none", updateValues = false) {
        const controls = ["due_date", "dependencies"];
        let values = null;
        let validators = null;

        switch (v) {
            case "due_date":
                if (updateValues) {
                    values = { dependencies: [] };
                }
                validators = { due_date: [IsDateValidator, Validators.required] };
                break;
            case "dependencies":
                if (updateValues) {
                    values = { due_date: null };
                }
                validators = { dependencies: [Validators.required] };
                break;
            case "none":
                if (updateValues) {
                    values = { due_date: null, dependencies: [] };
                }
                // No validators needed for 'none', so leave validators as null
                break;
        }

        setValuesAndValidators(this.formGroup, controls, values, validators);
    }
}
