<div class="flex align-center" mat-dialog-title [formGroup]="formGroup">
    <div>{{ title }}</div>
    <div class="spacer"></div>
    <div class="title-1 flex inquiry-case">
        Inquiry
        <mat-slide-toggle
            class="case-toggle"
            formControlName="createAsCase"
            (change)="updateValidity()"
        >
            Case
        </mat-slide-toggle>
    </div>
</div>
<mat-dialog-content [formGroup]="formGroup">
    <div class="form-field align-center" *ngIf="organizations.length > 1">
        <h4 class="label fixed">Organization</h4>
        <mat-form-field class="full">
            <mat-label>Organization</mat-label>
            <mat-select
                formControlName="organization"
                [compareWith]="compareObjects"
                (selectionChange)="orgChange($event)"
            >
                <mat-option *ngFor="let org of organizations" [value]="org">
                    {{ org.displayName }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('organization', 'required')">
                This field is required.
            </mat-error>
        </mat-form-field>
    </div>
    <div [formGroup]="inquiryDetailsGroup">
        <mat-form-field class="full">
            <mat-label>Country</mat-label>
            <mat-select formControlName="country">
                <mat-select-trigger *ngIf="countryControl?.value">
                    <mat-icon
                        [matTooltip]="countryInvalidToolTip"
                        class="warning country-flag-img"
                        *ngIf="!createCountryValid"
                    >
                        warning
                    </mat-icon>
                    <img
                        *ngIf="countryControl.value"
                        [src]="countryControl.value.flag_url"
                        [alt]="countryControl.value.code"
                        class="country-flag-img"
                        (error)="handleCountryFlagError(countryControl.value)"
                    />
                    {{ countryControl.value.display_name }}
                </mat-select-trigger>
                <mat-option *ngFor="let country of countries" [value]="country">
                    <img
                        [src]="country.flag_url"
                        [alt]="country.code"
                        class="country-flag-img"
                        (error)="handleCountryFlagError(country)"
                    />
                    {{ country?.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="full flexible" color="primary">
            <mat-label>Product</mat-label>
            <mat-select [formControl]="productControl">
                <mat-select-trigger *ngIf="productControl?.value">
                    <mat-icon
                        [matTooltip]="productInvalidToolTip"
                        class="warning country-flag-img"
                        *ngIf="!createProductValid"
                    >
                        warning
                    </mat-icon>
                    <span *ngIf="productControl?.value">
                        {{ productControl.value.displayName }}
                    </span>
                </mat-select-trigger>

                <mat-option *ngFor="let product of products" [value]="product">
                    {{ product.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="createAsCase && programControl">
            <mat-form-field color="primary" class="full flexible">
                <mat-label>Program</mat-label>
                <mat-select [formControl]="programControl">
                    <mat-select-trigger *ngIf="programControl?.value">
                        <mat-icon
                            [matTooltip]="programCountryInvalidToolTip"
                            class="warning country-flag-img"
                            *ngIf="!createProgramCountryValid"
                        >
                            warning
                        </mat-icon>
                        <span *ngIf="programControl?.value">
                            {{ programControl.value.displayName }}
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let program of programs" [value]="program">
                        {{ program.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <data-form
        class="inquiry-form"
        *ngIf="intakeForm && inquiryDetailsGroup?.valid"
        [object]="intakeForm"
        [mode]="ObjectViewMode.Create"
        [organization]="organization"
        [hideControls]="true"
    ></data-form>
    <div *ngIf="createAsCase && inquiryDetailsGroup?.valid">
        <div class="form-field align-center">
            <h4 class="label fixed">Internal Case Name</h4>
            <mat-form-field class="full">
                <mat-label>Internal Case Name</mat-label>
                <input
                    matInput
                    placeholder="Internal Case Name"
                    formControlName="name"
                />
                <mat-error *ngIf="hasError('name', 'uniqueName')">
                    A case with this name already exists. Please choose a different case
                    name.
                </mat-error>
                <mat-error *ngIf="hasError('name', 'required')">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field align-center">
            <h4 class="label fixed">Workflow</h4>
            <mat-form-field class="flexible full">
                <mat-label>Workflow</mat-label>
                <mat-select formControlName="workflow">
                    <mat-option *ngFor="let workflow of workflows" [value]="workflow">
                        {{ workflow.displayName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('workflow', 'required')">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field align-center">
            <h4 class="label fixed">Owner</h4>
            <mat-form-field class="flexible full">
                <mat-label>Owner</mat-label>
                <mat-select formControlName="primary">
                    <mat-option
                        *ngFor="let staff of avaialavlePrimaryAssignees"
                        [value]="staff"
                    >
                        {{ staff.displayName }}
                    </mat-option>
                </mat-select>
                <mat-hint>
                    The case owner will be automatically assigned administrative tasks
                    within the case workflow
                </mat-hint>
                <mat-error *ngIf="hasError('primary', 'required')">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field align-center">
            <h4 class="label fixed">Additional Administrators</h4>
            <mat-form-field class="flexible full">
                <mat-label>Additional Administrator(s) (optional)</mat-label>
                <mat-select formControlName="secondary" multiple="multiple">
                    <mat-option
                        *ngFor="let staff of availableSecondaryAssignees"
                        [value]="staff"
                        [disabled]="primary?.account?.id == staff.account.id"
                    >
                        {{ staff.displayName }}
                    </mat-option>
                </mat-select>
                <mat-hint>
                    Additional administrators will be added to the case team and receive
                    task notifications, but will not be automatically assigned to any
                    workflow tasks.
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="spacer"></div>
    <button mat-flat-button type="button" class="fixed" mat-dialog-close="">
        Cancel
    </button>
    <button
        mat-flat-button
        type="button"
        class="fixed"
        color="accent"
        [disabled]="!intakeFormGroup?.valid || !formGroup.valid || loading"
        (click)="formGroup.valid && create($event)"
    >
        {{ title }}
    </button>
</mat-dialog-actions>
