<div class="container header">
    <div class="programs-header flex">
        <div class="title" [id]="list.listTitle">Roles</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Statuses</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Statuses"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Add New Role
            </button>
        </div>
    </div>
</div>
<mat-table [dataSource]="list.items">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let capability">
            {{ capability.displayName }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
        <mat-cell *matCellDef="let capability" class="overflow">
            <button
                mat-icon-button
                [matMenuTriggerFor]="overflow"
                [matMenuTriggerData]="{ capability: capability }"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="no-records">
        <mat-footer-cell
            *matFooterCellDef
            [attr.colspan]="displayedColumns.length"
            class="warn not-found"
        >
            No Roles found.
        </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row
        *matFooterRowDef="['no-records']"
        [class.hidden-footer]="!!list.items.length"
    ></mat-footer-row>
    <mat-menu #overflow="matMenu">
        <ng-template matMenuContent let-capability="capability">
            <button mat-menu-item (click)="editObject($event, capability, true)">
                Edit
            </button>
        </ng-template>
    </mat-menu>
</mat-table>
<mat-paginator
    (page)="list.handlePageEvent($event)"
    [pageSizeOptions]="list.paginatorSizeOptions"
    [pageSize]="list.pageSize"
    [length]="list.paginatorLength"
    [disabled]="list.searching"
    [showFirstLastButtons]="true"
    [pageIndex]="list.currentPage"
></mat-paginator>
