<div
    class="app-bar"
    *ngIf="session.isAuthenticated"
    [class.mat-elevation-z4]="!showBreadcrumbs"
>
    <button mat-flat-button class="title" (click)="showDashboard($event)"></button>
    <div class="features">
        <button
            mat-flat-button
            [class.selected]="rootIsDashboard"
            (click)="showDashboard($event)"
        >
            Dashboard
        </button>
        <button
            mat-flat-button
            [class.selected]="rootIsPatients"
            (click)="showPatients($event)"
        >
            Cases
        </button>
        <button
            mat-flat-button
            [class.selected]="rootIsShipments"
            (click)="showShipments($event)"
        >
            Shipments
        </button>
    </div>
    <div class="spacer"></div>
    <div class="account-menu-trigger">
        <button
            mat-flat-button
            color="accent"
            type="button"
            (click)="createRequest($event)"
            *ngIf="canCreateRequest"
        >
            Create
        </button>
    </div>

    <div class="align-center">
        <a
            target="_blank"
            matTooltip="Help"
            mat-icon-button
            rel="noopener noreferrer"
            [href]="helpUrl"
        >
            <mat-icon>help</mat-icon>
        </a>
    </div>

    <div class="align-center">
        <button
            mat-icon-button
            matTooltip="Notifications"
            (click)="onNotificationClick()"
        >
            <mat-icon
                class="default-badge"
                [matBadge]="matBadge(notificationCount)"
                matBadgeColor="accent"
                matBadgePosition="above after"
                matBadgeOverlap="true"
                aria-hidden="false"
            >
                notifications
            </mat-icon>
        </button>
    </div>

    <div class="account-menu-trigger" [matMenuTriggerFor]="accountMenu">
        <span class="account-name">
            {{ session.currentAccount?.displayName }}
            <mat-icon class="inline">expand_more</mat-icon>
        </span>
        <mat-icon class="avatar">account_circle</mat-icon>
    </div>
    <mat-menu class="account-menu" #accountMenu [overlapTrigger]="false">
        <div class="account-header">
            <div>{{ session.currentAccount?.displayName }}</div>
            <div class="small">{{ session.currentAccount?.email }}</div>
        </div>
        <div class="divider"></div>
        <button class="account-menu-item" mat-menu-item (click)="showAccount($event)">
            Account Settings
        </button>
        <ng-container *ngIf="isAnyOrganizationAdministrator">
            <div class="divider"></div>
            <button
                class="account-menu-item"
                mat-menu-item
                (click)="showOrganization($event)"
            >
                Organization Settings
            </button>
        </ng-container>
        <ng-container
            *ngIf="
                (isProgramAdministrator || isProgramCountryAdministrator) &&
                !isAnyOrganizationAdministrator
            "
        >
            <div class="divider"></div>
            <button
                class="account-menu-item"
                mat-menu-item
                (click)="showOrganization($event)"
            >
                Program Settings
            </button>
        </ng-container>
        <ng-container *ngIf="isSystemAdministrator">
            <div class="divider"></div>
            <button class="account-menu-item" mat-menu-item (click)="showAdmin($event)">
                System Administration
            </button>
        </ng-container>
        <div class="divider"></div>
        <button
            class="account-menu-item logout"
            mat-menu-item
            (click)="session.logout()"
        >
            <mat-icon>exit_to_app</mat-icon>
            Logout
        </button>
    </mat-menu>
</div>
<breadcrumbs *ngIf="enableBreadcrumbs"></breadcrumbs>
<div class="content-wrapper" [class.withCrumbs]="showBreadcrumbs">
    <div class="content"><ng-content></ng-content></div>
    <div class="flex footer">
        <div class="version small">Version: {{ version }} ({{ build }})</div>
        <div class="spacer"></div>
        <div class="version small">
            <a href="https://medasystems.com/terms" target="_blank">Terms of Service</a>
            &nbsp;|&nbsp;
            <a href="https://medasystems.com/privacy-policy" target="_blank">
                Privacy Policy
            </a>
        </div>
        <div class="spacer"></div>
        <div class="copyright small">
            &copy;{{ currentYear }} MedaSystems, Inc. All Rights Reserved.
        </div>
    </div>
</div>
<div class="websocket-overlay" *ngIf="!session.websocketOpen">
    <div class="wrapper flex stack center reconnect">
        <div class="wrapper center third">
            Connection interrupted.
            <br />
            Attempting to reconnect...
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
</div>
