<div class="wrapper absolute flex stack">
    <div class="wrapper flexible flex stack system-view" cdkScrollable>
        <detail-tab-group>
            <detail-tab label="Organizations">
                <div class="content-card">
                    <organization-admin></organization-admin>
                </div>
            </detail-tab>
            <detail-tab label="Accounts">
                <div class="content-card">
                    <account-admin></account-admin>
                </div>
            </detail-tab>
            <detail-tab label="Tools">
                <div class="content-card">
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="migrateData($event)"
                    >
                        Migrate Data
                    </button>
                </div>
                <div class="content-card">
                    <h3 class="title">Config File Management</h3>
                    <div
                        *ngIf="!!uploader && !uploader.queue.length"
                        ng2FileDrop
                        class="drop-zone container scrollable flex center"
                        [class.over]="fileOver"
                        (fileOver)="fileOver = $event"
                        [uploader]="uploader"
                    >
                        <div class="flex stack center drop-items">
                            <mat-icon class="upload-icon">cloud_upload</mat-icon>
                            <input
                                type="file"
                                ng2FileSelect
                                [id]="elementId"
                                [uploader]="uploader"
                                hidden
                                multiple
                            />
                            <div class="flex stack center">
                                <p>Drag & drop a config file here</p>
                                <p>or</p>
                            </div>
                            <label
                                [for]="elementId"
                                class="mat-flat-button file-button"
                            >
                                Browse Files
                            </label>
                        </div>
                    </div>
                    <div
                        class="primary file-details flex center-align"
                        *ngIf="!!uploader && !!uploader.queue.length"
                    >
                        <div>{{ uploader.queue[0].file.name }}</div>
                        <div class="padded">
                            ({{ uploader.queue[0].file.size | fileSize }})
                        </div>
                        <button mat-icon-button (click)="clearUploadQueue()">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                    <div class="flex stack">
                        <mat-radio-group class="flex stack" [formControl]="mode">
                            <mat-radio-button color="accent" value="create">
                                Create System Data Objects
                            </mat-radio-button>
                            <mat-radio-button color="accent" value="update">
                                Update System Data Objects
                            </mat-radio-button>
                            <mat-radio-button color="accent" value="remove">
                                Remove System Data Objects
                            </mat-radio-button>
                        </mat-radio-group>
                        <p class="warning">
                            *NOTE: Includes and file references are not processed
                            through this interface
                        </p>
                    </div>
                    <div class="flex config-actions">
                        <button
                            mat-flat-button
                            color="accent"
                            [disabled]="!uploader?.queue?.length"
                            (click)="applyConfiguration()"
                        >
                            Apply Configuration
                        </button>
                        <button
                            mat-flat-button
                            color="primary"
                            [disabled]="!logDisplay.length"
                            *ngIf="processingConfigFile || logDisplay.length > 0"
                            (click)="clearLog()"
                        >
                            Clear Log
                        </button>
                    </div>
                    <div
                        class="container scrollable log-display"
                        *ngIf="processingConfigFile || logDisplay.length > 0"
                    >
                        <ng-container *ngFor="let log of logDisplay">
                            {{ log }}
                            <br />
                        </ng-container>
                    </div>
                </div>
            </detail-tab>
            <detail-tab label="Audit">
                <div class="content-card">
                    <admin-audit></admin-audit>
                </div>
            </detail-tab>
        </detail-tab-group>
    </div>
</div>
