import { AfterViewInit, Component, inject, Input, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { map, Observable, of } from "rxjs";

import { Account } from "src/services/models/account";
import { DerivedPermission, Role, RoleDefinition } from "src/services/models/role";
import { StaffPermissionComponent } from "./staff-permission.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { TeamMember } from "src/services/models/team";
import { TeamMemberService } from "src/services/program.services";
import { AccountService } from "src/services/iam.services";
import { SessionComponent } from "src/services/components/session.component";

@Component({
    selector: "staff-permissions-list",
    templateUrl: "./staff-permissions-list.component.html",
    styleUrls: ["./staff.component.scss"],
})
export class StaffPermissionsListComponent
    extends SessionComponent
    implements AfterViewInit
{
    @Input() accountId?: string;
    account?: Account;
    derivedPermissions: DerivedPermission[] = [];
    displayedColumns: string[] = [
        "object_display_name",
        "permission",
        "permission_type",
        "actions",
    ];
    protected dialog = inject(MatDialog);
    private teamMemberService = inject(TeamMemberService);
    private accountService = inject(AccountService);

    get availableRoles(): RoleDefinition[] {
        return Role.roles.filter((role) => role.type === "object");
    }

    constructor() {
        super();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes["accountId"]) {
            this.refreshAccount();
        }
    }

    ngAfterViewInit(): void {
        this.refreshAccount();
    }

    editPermission(event: MouseEvent, selectedPermission: DerivedPermission): void {
        this.terminateEvent(event);
        if (!this.canEditPermission) return;

        this.getSelectedTeamMember$(selectedPermission).subscribe((res) => {
            if (res.length == 0) {
                console.error("Could not find TeamMember");
                return;
            }
            this.launchStaffPermissionDialog(res[0]);
        });
    }

    protected terminateEvent(event?: Event): void {
        event?.stopPropagation();
        event?.preventDefault();
    }

    protected getSelectedTeamMember$(
        selectedPermission: DerivedPermission,
    ): Observable<TeamMember[]> {
        const permissionId = selectedPermission.source.match(/[0-9a-fA-F]{32}/)?.[0];
        if (!permissionId) {
            console.error("Could not find a permission ID in source");
            return of([]);
        }
        return this.teamMemberService
            .list({ permission: permissionId })
            .pipe(map((teamMembers) => teamMembers as TeamMember[]));
    }

    launchStaffPermissionDialog(selectedTeamMember: TeamMember): void {
        const ref = this.dialog.open(StaffPermissionComponent, {
            minWidth: "70%",
            disableClose: true,
            hasBackdrop: true,
        });
        ref.componentInstance.dialogReference = ref;
        ref.componentInstance.mode = ObjectViewMode.Edit;
        ref.componentInstance.autosave = false;
        ref.componentInstance.object = selectedTeamMember;
        ref.afterClosed().subscribe((t: TeamMember) => {
            if (t) {
                this.refreshAccount();
            }
        });
    }

    refreshAccount(): void {
        if (!this.accountId) return;
        this.accountService
            .retrieve(this.accountId, {}, true)
            .pipe(map((account) => account as Account))
            .subscribe(
                (account) => {
                    if (account instanceof Account) {
                        this.account = account;
                        this.derivedPermissions = account.derived_permissions;
                    }
                },
                (error) => console.error("Error refreshing account", error),
            );
    }
    getRoleDisplay(derivedPermission: DerivedPermission): string {
        const foundPermRole = this.availableRoles.find(
            (el) => el.value == derivedPermission.permission,
        );

        return foundPermRole?.display ?? "";
    }
    getTeamType(derivedPermission: DerivedPermission): string {
        const objectType = derivedPermission.object_type;
        switch (objectType) {
            case "iam.organization":
                return "Organization";
            case "program.program":
                return "Program";
            case "program.programcountry":
                return "Country";
        }
        return "";
    }
    isInheritedPermission(derivedPermission: DerivedPermission): boolean {
        return derivedPermission.permission_type === "inherited";
    }
    canEditPermission(derivedPermission: DerivedPermission): boolean {
        if (this.isInheritedPermission(derivedPermission)) return false;
        if (this.currentAccount?.isSystemAdministrator) return true;

        return this.isPermissionAdmin(derivedPermission);
    }
    isPermissionAdmin(derivedPermission: DerivedPermission): boolean {
        const hasDerivedPermission = this.currentAccount?.derived_permissions.some(
            (p) =>
                p.object_id === derivedPermission.object_id &&
                p.permission === "object.admin",
        );
        return hasDerivedPermission ?? false;
    }
}
