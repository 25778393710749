import { DocusignAccountFactory, DocusignService } from "src/services/docusign.service";
import { AfterViewInit, Component, Inject, inject } from "@angular/core";
import { AssignmentService } from "src/services/program.services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DocusignAccount, DocusignToken } from "src/services/models/docusign";
import { APIListResult, ObjectOrReference } from "src/services/models/api-object";
import { SessionComponent } from "src/services/components/session.component";
import { map } from "rxjs";
import { FormControl } from "@angular/forms";
import { generateCode } from "src/common/utilities/utilities";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { queryStringFromFilters } from "src/common/utilities/request";

@Component({
    selector: "reauthorize-docusign",
    templateUrl: "./reauthorize-docusign.dialog.html",
})
export class ReauthorizeDocusignDialog
    extends SessionComponent
    implements AfterViewInit
{
    protected assignmentService: AssignmentService;
    protected docusignService: DocusignService;
    protected router: Router;
    protected location: Location;

    availableDocusignAccounts: DocusignAccount[] = [];
    docusignAccount: FormControl = new FormControl();
    docusignRef: WindowProxy | null = null;
    cancelPolling: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<any>,
    ) {
        super();
        this.assignmentService = inject(AssignmentService);
        this.docusignService = inject(DocusignService);
        this.router = inject(Router);
        this.location = inject(Location);
        inject(DocusignAccountFactory);
    }

    ngAfterViewInit(): void {
        this.updateAvailableDocusignAccounts(this.data.envelope?.account);
    }

    cancel(): void {
        this.cancelPolling = true;
        this.dialogRef.close();
    }
    send(): void {
        this.cancelPolling = true;
        this.dialogRef.close(this.docusignAccount.value);
    }

    protected updateAvailableDocusignAccounts(
        acct?: ObjectOrReference<DocusignAccount>,
    ): void {
        this.docusignService
            .list({ account: this.currentAccount?.id ?? "0" })
            .pipe(
                map(
                    (results: APIListResult<DocusignToken>) =>
                        results as DocusignToken[],
                ),
                map((tokens: DocusignToken[]) =>
                    tokens.map((token: DocusignToken) => token.accounts),
                ),
                map((accounts: DocusignAccount[][]) =>
                    ([] as DocusignAccount[]).concat(...accounts),
                ),
            )
            .subscribe((accounts: DocusignAccount[]) => {
                this.availableDocusignAccounts = accounts;
                const defaultAccount = this.availableDocusignAccounts.find(
                    (account: DocusignAccount) =>
                        acct ? acct.id == account.id : account.is_default,
                );
                if ((acct || !this.docusignAccount.value) && defaultAccount) {
                    this.docusignAccount.setValue(defaultAccount);
                    if (!acct) this.docusignAccount.markAsDirty();
                }
            });
    }

    linkDocusignAccount(event: MouseEvent): void {
        // generate random string
        const state = generateCode(16) + "|" + this.currentAccount?.id;
        const tree = this.router.createUrlTree(["docusign", "authorization"]);
        const path = this.location.prepareExternalUrl(tree.toString());
        const url = window.location.origin + path;
        const params = {
            response_type: "code",
            scope: "signature",
            state: state,
            client_id: this.assignmentService.session.environment.docusignClientId,
            redirect_uri: url,
        };
        const authUrl =
            [
                this.assignmentService.session.environment.docusignRoot,
                this.assignmentService.session.environment.docusignAuthorizationService,
            ].join("/") + queryStringFromFilters(params);
        this.docusignRef = window.open(authUrl, "_blank");
        this.pollForToken(state);
    }
    pollForToken(state: string, count: number = 0, time: number = 5000): void {
        if (!this.cancelPolling)
            setTimeout(() => this.onTokenPoll(state, ++count, time), time);
    }
    getOrCreateToken(state: string) {
        const params = JSON.parse(localStorage.getItem("docusign")!);
        if (params) localStorage.removeItem("docusign");

        const code = params ? params["code"] : undefined;
        const pstate = params ? params["state"] : undefined;
        if (code && pstate === state) {
            const token = new DocusignToken({
                state: state,
                authorization: code,
            });
            return this.docusignService.create(token);
        }

        return this.docusignService.find(state);
    }
    onTokenPoll(state: string, count: number, time: number): void {
        if (count <= 24) {
            this.getOrCreateToken(state).subscribe((token?: DocusignToken) => {
                if (token?.accounts.length) {
                    // remove any existing accounts with the same email address as this token
                    const accounts = this.availableDocusignAccounts.filter(
                        (account: DocusignAccount) =>
                            account.token?.email !== token.email,
                    );
                    accounts.push(...token.accounts);
                    this.availableDocusignAccounts = accounts;
                    const defaultAccount = token.accounts.find(
                        (account: DocusignAccount) => account.is_default,
                    );
                    if (defaultAccount) {
                        this.docusignAccount.setValue(defaultAccount);
                        this.docusignAccount.markAsDirty();
                    }
                    if (this.docusignRef) this.docusignRef.close();
                } else this.pollForToken(state, count, time);
            });
        } else {
            this.setError("Error authenticating with the DocuSign service");
        }
    }
}
