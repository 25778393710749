<h3 mat-dialog-title>Reauthorize Docusign®</h3>
<div mat-dialog-content class="wrapper flex stack full">
    <p class="margin-top">
        The Docusign® authorization attached to this envelope has expired. Please
        reauthorize or select another account to send the selected Docusign® envelope.
    </p>
    <div class="row flex flexible">
        <mat-form-field class="full">
            <mat-label>Available Docusign® accounts</mat-label>
            <mat-select [formControl]="docusignAccount">
                <mat-option *ngIf="!availableDocusignAccounts.length" [value]="null">
                    No available DocuSign
                    <sup>&reg;</sup>
                    accounts.
                </mat-option>
                <mat-option
                    *ngFor="let account of availableDocusignAccounts"
                    [value]="account"
                >
                    {{ account.displayName }}
                    <span *ngIf="account.token?.requires_authorization" class="red">
                        <sup>*</sup>
                    </span>
                </mat-option>
            </mat-select>
            <mat-hint
                *ngIf="docusignAccount.value?.token?.requires_authorization"
                class="red"
            >
                * For security purposes, you must re-authenticate this DocuSign
                <sup>&reg;</sup>
                account.
            </mat-hint>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions class="row flex">
    <button mat-flat-button color="primary" (click)="linkDocusignAccount($event)">
        Link Docusign&reg; Account
    </button>
    <div class="spacer"></div>
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="accent" (click)="send()">Send</button>
</div>
