import { Component } from "@angular/core";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Status } from "src/services/models/case";
import { StatusService } from "src/services/program.services";
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { GenerateUniqueIdentifier } from "src/common/utilities/utilities";
import { ObjectFactory, ObjectOrReference } from "src/services/models/api-object";
import { forkJoin } from "rxjs";
import { defined } from "src/common/utilities/flatten";

@Component({
    selector: "status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
})
export class StatusComponent extends ObjectComponent<Status> {
    objectName = "Status";
    constructor(protected service: StatusService) {
        super(service);
    }

    protected _caseStatuses: Status[] = [];
    set caseStatuses(v: ObjectOrReference<Status>[]) {
        forkJoin(
            v.map((tv: ObjectOrReference<Status>) =>
                ObjectFactory.objectObservable(tv),
            ),
        ).subscribe(
            (statuses: (Status | undefined)[]) => {
                this._caseStatuses = defined(statuses);
                this.updateStatusNameValidator();
            }
        );
    }

    uniqueStatusNameValidator(existingNames: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const newName = control.value?.toLowerCase();
            const isValid = !existingNames.includes(newName);
            return isValid ? null : { nonUniqueStatusName: true };
        };
    }

    updateStatusNameValidator() {
        const statNames = this._caseStatuses
            .filter(item => this.object?.id !== item.id)
            .map(item => item.display_name.toLowerCase())

        this.formGroup.get('display_name')?.addValidators(
            this.uniqueStatusNameValidator(statNames)
        )
        this.formGroup.get('display_name')?.updateValueAndValidity();
    }

    get isValid(){
        return this.formGroup.valid;
    }

    get canBeDefault() {
        const doesCloseCase = !!(this.formGroup.controls["attributes"] as any)?.controls[
            "closes_case"
        ]?.value;
        return !doesCloseCase
    }

    get canCloseCase() {
        const isDefault = !!(this.formGroup.controls["attributes"] as any)?.controls[
            "is_default_inquiry_status"
        ]?.value;
        return !isDefault;
    }

    protected setObject(v?: Status): void {
        super.setObject(v);
        if (this.mode == ObjectViewMode.Create) this.objectName = "Status";
        else if (this.mode == ObjectViewMode.Edit)
            this.objectName = "Edit " + (this.fullObject?.display_name ?? "Status");
        else this.objectName = "View " + (this.object?.displayName ?? "Status");
    }
    protected onCommitError(err: any) {
        super.onCommitError(err);
        this.session.handleError(err);
    }

    onSave() {
        // If the "Default Status" box is checked, this method will convert any other user-made 
        // default statuses to non-default, before saving the new one as default. 
        if (
            this.formGroup.controls?.attributes.value?.is_default_inquiry_status
        ){
            // Find the old default status
            const oldDefault = this._caseStatuses.find(
                item => (
                    item.attributes.is_default_inquiry_status && 
                    item.owner !== null && 
                    item.id !== this.object?.id
                )
            );

            if(!oldDefault) {
                super.onSave()
                return;
            }

            // If an old default status exists, update it to be non-default
            const itemToUpdate = ObjectFactory.makeObject<Status>({
                id: oldDefault?.id,
                owner: oldDefault?.owner,
                display_name: oldDefault?.display_name,
                attributes: {
                    is_default_inquiry_status: false,
                    closes_case: oldDefault?.attributes.closes_case,
                    is_inquiry_status: oldDefault?.attributes.is_inquiry_status,
                }
            }, Status.object_type)

            // Update it in the database
            this.service
                .update(itemToUpdate)
                .subscribe();
        }
        
        super.onSave()
    }

    protected precommitTransform(v: any) {
        v.name = GenerateUniqueIdentifier(
            v?.display_name,
            this._caseStatuses?.map((s) => s?.name),
        );
        return v;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            owner: [null, Validators.required],
            display_name: [null, Validators.required],
        });
    }
}

@Component({
    selector: "status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
})
export class CaseStatusComponent extends StatusComponent {
    objectName = "Case Status";

    protected setObject(v?: Status | undefined): void {
        if (v) {
            v.attributes = {
                ...(v?.attributes || {}),
                is_inquiry_status: true,
            };
        }
        super.setObject(v);
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            id: [null],
            owner: [null, Validators.required],
            display_name: [null, Validators.required],
            attributes: this.formBuilder.group({
                is_default_inquiry_status: [null],
                closes_case: [null],
                is_inquiry_status: [null],
            }),
        });
    }
}
