<div class="container">
    <mat-table [dataSource]="derivedPermissions">
        <ng-container matColumnDef="object_display_name">
            <mat-header-cell *matHeaderCellDef>Team</mat-header-cell>
            <mat-cell
                *matCellDef="let derivedPermission"
                class="cell-flex-column-start"
            >
                <div>{{ derivedPermission.object_display_name }}</div>
                <div class="small">{{ getTeamType(derivedPermission) }}</div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="permission">
            <mat-header-cell *matHeaderCellDef>Permission</mat-header-cell>
            <mat-cell
                *matCellDef="let derivedPermission"
                class="cell-flex-column-start"
            >
                <div>{{ getRoleDisplay(derivedPermission) }}</div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="permission_type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell
                *matCellDef="let derivedPermission"
                class="cell-flex-column-start"
            >
                <div>{{ derivedPermission.permission_type | titlecase }}</div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let derivedPermission" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ derivedPermission: derivedPermission }"
                    [disabled]="isInheritedPermission(derivedPermission)"
                    *ngIf="canEditPermission(derivedPermission)"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                No permissions found.
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
            [class.country-staff-inherited-user-row]="isInheritedPermission(row)"
            *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!derivedPermissions.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-derivedPermission="derivedPermission">
                <button
                    mat-menu-item
                    (click)="editPermission($event, derivedPermission)"
                    [disabled]="!canEditPermission(derivedPermission)"
                >
                    Edit
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
</div>
