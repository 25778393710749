import { Component, Input } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { ObjectComponent } from "src/common/components/object.component";
import { ObjectOrReference } from "src/services/models/api-object";
import { Organization } from "src/services/models/organization";
import { Product } from "src/services/models/product";
import { ProductService } from "src/services/program.services";

@Component({
    selector: "product",
    templateUrl: "./product.component.html",
})
export class ProductComponent extends ObjectComponent<Product> {
    constructor(protected service: ProductService) {
        super(service);
    }

    @Input()
    organization?: ObjectOrReference<Organization>;

    protected precommitTransform(v: any) {
        v = super.precommitTransform(v);

        if (!v?.owner && this.organization) {
            v.owner = this.organization?.asReference;
            if (!v.name) {
                const name = v.display_name.split(" ").join("_").toLocaleLowerCase();
                v.name = `${this.organization.displayName?.toLocaleLowerCase()}.${name}`;
            }

            if (!v.hasOwnProperty("published")) {
                v.published = true;
            }
        }
        return v;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            display_name: [null, Validators.required],
        });
    }

    objectName = "Create Product";
    protected setObject(v?: Product | undefined): void {
        super.setObject(v);

        if (v?.id && v?.display_name) {
            this.objectName = `Edit ${v?.display_name}`;
        }
    }
}
