<div class="container">
    <div class="forms-header flex">
        <div class="title" [id]="list.listTitle">Forms</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field color="primary" *ngIf="!isSearchEmpty" class="form-search">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Forms</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Forms"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="!!templateForms.length && canCreateForm()"
                (click)="canCreateForm() && createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Add Form
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list.items" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="form-name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Form Name
            </mat-header-cell>
            <mat-cell *matCellDef="let form">
                <div
                    *ngIf="isReference(form)"
                    class="flex flexible"
                    referenceWrapper
                    [primary]="true"
                ></div>
                <div *ngIf="isObject(form)" class="flex flexible">
                    <ng-container *ngIf="editing?.id !== form?.id">
                        <div class="flex stack">
                            <div
                                class="clickable"
                                style="padding-left: 0.5rem"
                                (click)="viewObject($event, form, true)"
                                [matBadge]="matBadge(form)"
                                matBadgeColor="accent"
                                matBadgeOverlap="false"
                                matBadgePosition="before"
                                [matTooltip]="
                                    getNotificationForForm(form)?.message || ''
                                "
                            >
                                {{ form.display_name }}
                            </div>
                            <div
                                *ngIf="form?.template_version"
                                class="secondary small"
                                style="padding-left: 0.5rem"
                            >
                                Version {{ form.template_version }}
                            </div>

                            <div
                                *ngIf="isRenamed(form)"
                                class="secondary super-small"
                                style="padding-left: 0.5rem"
                            >
                                {{ form.template.displayName }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="editing?.id === form?.id">
                        <mat-form-field class="table-cell-input">
                            <mat-label>Form Name</mat-label>
                            <input
                                matInput
                                placeholder="Display Name"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="alias"
                            />
                            <mat-hint>{{ form.template.displayName }}</mat-hint>
                        </mat-form-field>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="shared">
            <mat-header-cell *matHeaderCellDef class="chip-list-column" mat-sort-header>
                Visibility
            </mat-header-cell>
            <mat-cell
                *matCellDef="let form"
                class="chip-list-column"
                (click)="!editing && viewObject($event, form, true)"
            >
                <div *ngIf="isReference(form)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(form)">
                    <mat-chip-set>
                        <mat-chip *ngIf="!form.isShared" color="secondary">
                            Internal
                        </mat-chip>
                        <mat-chip *ngIf="form.isShared" selected color="primary">
                            Shared
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="modified-at">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Last Modified
            </mat-header-cell>
            <mat-cell
                *matCellDef="let form"
                (click)="!editing && viewObject($event, form, true)"
            >
                <div *ngIf="isReference(form)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(form)">
                    <div class="stack">
                        <div>
                            {{ form.last_modified | localizedDate }}&nbsp;
                            <span class="small">
                                {{ form.last_modified | localizedDate: "mediumTime" }}
                            </span>
                        </div>
                        <div class="secondary small" *ngIf="form.last_modified_by">
                            by {{ form.last_modified_by.displayName }}
                        </div>
                        <div class="secondary small" *ngIf="!form.last_modified_by">
                            by MedaSystems
                        </div>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created-at">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
            <mat-cell
                *matCellDef="let form"
                (click)="!editing && viewObject($event, form, true)"
            >
                <div *ngIf="isReference(form)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(form)">
                    {{ form.created_at | localizedDate }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="completed">
            <mat-header-cell *matHeaderCellDef class="chip-list-column" mat-sort-header>
                Status
            </mat-header-cell>
            <mat-cell
                *matCellDef="let form"
                class="chip-list-column"
                (click)="!editing && viewObject($event, form, true)"
            >
                <div *ngIf="isReference(form)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(form)" class="flex full center">
                    <mat-chip-set class="center-chips">
                        <mat-chip
                            *ngIf="!form.is_complete && !form.is_partial"
                            class="text-center"
                        >
                            Incomplete
                        </mat-chip>
                        <mat-chip
                            *ngIf="!form.is_complete && form.is_partial"
                            selected
                            color="utility"
                            class="warning text-center"
                        >
                            Partially Complete
                        </mat-chip>
                        <mat-chip *ngIf="form.is_complete" selected color="accent">
                            Completed
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let form" class="overflow">
                <div *ngIf="isReference(form)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(form)">
                    <button
                        mat-icon-button
                        *ngIf="editing?.id !== form?.id"
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ form: form }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        *ngIf="editing?.id === form?.id"
                        (click)="updateForm(form)"
                        class="green"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="loading">Loading forms...</span>
                <span *ngIf="!loading">No data forms found.</span>
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-form="form">
                <button
                    mat-menu-item
                    [disabled]="!!editing"
                    (click)="!editing && viewObject($event, form, true)"
                >
                    View
                </button>
                <button
                    mat-menu-item
                    [disabled]="!canEdit(form)"
                    (click)="canEdit(form) && editObject($event, form, true)"
                >
                    Edit
                </button>
                <button
                    mat-menu-item
                    [disabled]="!canRename(form)"
                    (click)="canEdit(form) && renameForm(form)"
                >
                    Rename
                </button>
                <button
                    mat-menu-item
                    *ngIf="!form.isShared"
                    [disabled]="!canShare(form)"
                    (click)="canShare(form) && shareForm(form)"
                >
                    Share
                </button>
                <button
                    mat-menu-item
                    *ngIf="form.isShared"
                    [disabled]="!canUnshare(form)"
                    (click)="canUnshare(form) && unshareForm(form)"
                >
                    Unshare
                </button>
                <button mat-menu-item (click)="exportAsPdf($event, form)">
                    Download
                </button>
                <button
                    *ngIf="hasNotification(form)"
                    mat-menu-item
                    (click)="markNotificationAsRead(form)"
                >
                    Mark Read
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
