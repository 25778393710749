import { AbstractControl } from "@angular/forms";
import { Account } from "./account";
import {
    APIObject,
    NamedObject,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
} from "./api-object";
import { DocumentRepository } from "./document";
import { ObjectRepository } from "./compound";

export type DataFormAttributes = { [key: string]: any };

export class DataFieldValue extends APIObject {
    static object_type: string = "program.datafieldvalue";
    repository_id!: string;
    repository_type!: string;
    value: any;
    form_field: OptionalObjectOrReference<DataFormField>;
    field?: DataField; // TODO: this needs to move to a reference

    initialize(data: any, patch: boolean): void {
        this._optional.push(...["form_field", "field"]);
        this._references.push(...["form_field"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "repository_id");
        this.setMember(data, patch, "repository_type");
        this.setMember(data, patch, "value");
        this.setMember(data, patch, "form_field", DataFormField);
        this.setMember(data, patch, "field", DataField);
    }
}
export class CompoundDataType extends NamedObject {
    static object_type: string = "program.compounddatatype";
    order!: number;
    required!: boolean;
    multiple!: boolean;
    child!: DataType; // TODO: this needs to move to an ObjectOrReference
    display_name!: string;

    previewControl?: AbstractControl;

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["previewControl"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "id");
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "order");
        this.setMember(data, patch, "required");
        this.setMember(data, patch, "multiple");
        this.setMember(data, patch, "child", DataType);
        this.setMember(data, patch, "display_name");
    }

    duplicate(): CompoundDataType {
        const duplicate = new CompoundDataType(this);
        duplicate.id = undefined;
        return duplicate;
    }
}

export type DataTypeLookupValue = {
    value: string;
    display_name: string;

    _new?: boolean;
};

export type CompoundPropertyTypes = { [identifier: string]: string };

export class DataType extends NamedObject {
    static object_type: string = "program.datatype";
    description?: string;
    display_name!: string;
    lookup?: DataTypeLookupValue[];
    compound?: CompoundDataType[]; // TODO: this should move to ObjectOrReferences
    owner: OptionalObjectOrReference<ObjectRepository>;
    published?: boolean;
    attributes?: DataFormAttributes;
    used_in!: number; // read only; Compound Data Types using this type
    used_by!: number; // read only; Data Fields using this type

    get displayName(): string | undefined {
        return this.display_name || super.displayName;
    }

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["used_in", "used_by"]);
        this._optional.push(...["owner", "lookup", "compound", "description"]);
        this._references.push(...["owner"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "id");
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "lookup");
        this.setMember(data, patch, "compound", CompoundDataType, true);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "published");
        this.setMember(data, patch, "attributes");
        this.setMember(data, patch, "used_in");
        this.setMember(data, patch, "used_by");
    }

    // we need to include the attributes from form fields to determine the display type
    displayType(attributes: any): string {
        // handle alt displays first
        const attr = { ...this.attributes, ...attributes };
        if (attr.alt?.input && attr.alt?.show) {
            if (attr.alt.input == "input") return "text.short";
        }
        if (attr.alt?.control == "input") return "text.short";
        if (
            (this.name == "date" || this.name == "datetime") &&
            attr.alt?.control == "checkbox"
        )
            return "checkbox";
        if (this.name == "boolean" && attr.alt?.control == "checkbox")
            return "checkbox";

        if (this.name == "text.long") return "text.long";
        if (!this.owner && !!this.name?.includes("select.program")) return "program";
        if ((!this.owner && !!this?.name?.includes("select")) || !!this.isLookup)
            return "lookup";
        if (
            this.name == "date" ||
            this.name == "datetime" ||
            this.name?.startsWith("date")
        )
            return "date";
        if (this.name == "boolean") return "toggle";
        if (this.name == "instructions") return "instructions";
        if (
            this.name == "document" ||
            (this.name == "object.reference" && attr.alt?.control == "document")
        )
            return "document";
        return "text.short";
    }

    duplicate(owner?: ObjectOrReference<ObjectRepository>): DataType {
        const duplicate = new DataType(this);
        duplicate.id = undefined;
        if (owner) duplicate.owner = owner;
        duplicate.display_name = "Copy of " + this.display_name;
        duplicate.name = this.name + ".copy";
        duplicate.lookup = this.lookup?.map((lookup: DataTypeLookupValue) => ({
            value: lookup.value,
            display_name: lookup.display_name,
        }));
        duplicate.compound = this.compound?.map((compound: CompoundDataType) =>
            compound.duplicate(),
        );
        return duplicate;
    }

    get isCompound(): boolean {
        return !!this.compound?.length;
    }
    get isLookup(): boolean {
        return !!this.lookup?.length;
    }
    get isBasic(): boolean {
        return !this.isCompound && !this.isLookup;
    }
}
export class DataField extends NamedObject {
    static object_type: string = "program.datafield";
    display_name!: string;
    description!: string;
    data_type!: DataType; // TODO: this should move to object or reference
    owner: OptionalObjectOrReference<ObjectRepository>;
    published?: boolean;
    attributes?: DataFormAttributes;
    used_in!: number; // read-only; forms used by
    readonly is_control_field!: boolean;

    get displayName(): string | undefined {
        return this.display_name || super.displayName;
    }

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["used_in"]);
        this._optional.push(...["owner"]);
        this._references.push(...["owner"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "data_type", DataType);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "attributes");
        this.setMember(data, patch, "published");
        this.setMember(data, patch, "used_in");
        this.setMember(data, patch, "is_control_field");
    }

    duplicate(owner?: ObjectOrReference<ObjectRepository>): DataField {
        const duplicate = new DataField(this);
        duplicate.id = undefined;
        if (owner) duplicate.owner = owner;
        duplicate.display_name = "Copy of " + this.display_name;
        duplicate.name = this.name + ".copy";
        return duplicate;
    }
}
/**
 * Used to connect a field to form. With any input properties, ie required / multiple
 * @param id - string
 * @param prompt - string
 * @param order - Number
 * @param attributes - {}, will have all the validator info, like max string lengths etc
 * @param required - boolean
 * @param multiple - boolean
 * @param field - DataField
 * @param values - [], since fields can have multiple values. As of now most only have one
 */

export class DataFormField extends APIObject {
    static object_type: string = "program.dataformfield";
    attributes?: DataFormAttributes;
    children?: ObjectOrReference<DataFormField>[]; // non-API field
    conditions!: DataFormFieldCondition[];
    field!: DataField; // TODO: This needs to move to a reference
    fieldName?: string; // non-API field used to distinguishing between duplicated fields
    form: OptionalObjectOrReference<DataForm>;
    group: OptionalObjectOrReference<DataFormField>; // non-API field
    group_reference?: string; // write-only, for preserving hierarchy on form creation/editing
    multiple!: boolean;
    order!: number;
    previewControl?: AbstractControl; // non-API field
    prompt?: string | null;
    reference?: string; // write-only, for preserving hierarchy on form creation/editing
    required!: boolean;

    get displayName(): string {
        return (
            this.attributes?.prompt ??
            this.attributes?.alt?.display_name ??
            this.field?.display_name ??
            "Unknown"
        );
    }
    set displayName(v: string) {
        if (v != this.field.display_name) {
            if (!this.attributes) this.attributes = {};
            this.attributes.prompt = v;
        } else {
            delete this.attributes?.alt?.display_name;
            delete this.attributes?.prompt;
        }
    }
    get label(): string {
        return (
            this.prompt ??
            this.attributes?.prompt ??
            this.attributes?.label ??
            this.field.display_name
        );
    }
    set label(v: string) {
        this.prompt = v && v != "" ? v : undefined;
    }
    get placeholder(): string {
        return (
            this.attributes?.placeholder ||
            this.attributes?.alt?.display_name ||
            this.label
        );
    }
    set placeholder(v: string) {
        if (v && v != "") {
            if (!this.attributes) this.attributes = {};
            this.attributes.placeholder = v;
        } else {
            delete this.attributes?.placeholder;
        }
    }
    get instructions(): string {
        return this.attributes?.instructions;
    }
    set instructions(v: string) {
        if (v && v != "") {
            if (!this.attributes) this.attributes = {};
            this.attributes.instructions = v;
        } else {
            delete this.attributes?.instructions;
        }
    }
    get width(): string {
        let width = this.attributes?.layout?.width;
        if (width == "25%") width = "quarter";
        else if (width == "50%") width = "half";
        else if (width == "75%") width = "three-fourth";
        else if (width == "100%") width = "full";
        return width;
    }
    set width(v: string) {
        if (v && v != "" && v != "full" && v != "100%") {
            if (!this.attributes) this.attributes = {};
            if (!this.attributes.layout) this.attributes.layout = {};
            this.attributes.layout.width = v;
        } else {
            delete this.attributes?.layout?.width;
        }
    }

    initialize(data: any, patch: boolean): void {
        this._optional.push(
            ...[
                "form",
                "required",
                "multiple",
                "prompt",
                "attributes",
                "reference",
                "group_reference",
            ],
        );
        this._readOnly.push(
            ...["prompt", "values", "children", "previewControl", "formField"],
        );
        this._references.push(...["form", "group", "children"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "form", DataForm);
        this.setMember(data, patch, "group", DataFormField);
        this.setMember(data, patch, "prompt");
        this.setMember(data, patch, "order");
        this.setMember(data, patch, "required");
        this.setMember(data, patch, "multiple");
        this.setMember(data, patch, "field", DataField);
        this.setMember(data, patch, "attributes");
        this.setMember(data, patch, "reference");
        this.setMember(data, patch, "group_reference");
        this.setMember(data, patch, "conditions", DataFormFieldCondition, true);
    }

    duplicate(): DataFormField {
        const duplicate = new DataFormField(this);
        duplicate.id = undefined;
        return duplicate;
    }
}
export class DataFormFieldCondition extends APIObject {
    condition_type!: "equals" | "notEquals";
    dependent_field: OptionalObjectOrReference<DataFormField>;
    dependent_ref?: string;
    expected_value: any;
    form_field!: OptionalObjectOrReference<DataFormField>;
    form_field_ref?: string;
    type: string = "program.dataformfieldcondition";

    initialize(data: any, patch: boolean): void {
        this._references.push(...["dependent_field", "form_field"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "dependent_field", DataFormField);
        this.setMember(data, patch, "condition_type");
        this.setMember(data, patch, "expected_value");
        this.setMember(data, patch, "form_field_ref");
        this.setMember(data, patch, "dependent_ref");
        this.setMember(data, patch, "form_field", ObjectReference);
    }
}
/**
 * A template of data fields that define information to be collected form a user
 * @param name - string - name of form in dot notation
 * @param display_name - string - What is rendered for the user
 * @param description - string - basically the subtitle of the form explaining to the user what the genral form is about
 * @param region - string
 * @param form_fields - DataFormField[]
 */
export class DataForm extends NamedObject {
    static object_type: string = "program.dataform";
    attributes?: DataFormAttributes;
    description!: string;
    display_name!: string;
    form_fields!: ObjectOrReference<DataFormField>[];
    is_complete!: boolean;
    last_modified!: Date;
    last_modified_by: OptionalObjectOrReference<Account>;
    owner: OptionalObjectOrReference<DocumentRepository>; // form repositories are the same as document repositories
    published?: boolean;
    region?: string | null;
    template: OptionalObjectOrReference<DataForm>;
    template_version?: number;
    values!: ObjectOrReference<DataFieldValue>[];
    version?: number;

    get displayName(): string | undefined {
        return this.display_name || super.displayName;
    }

    initialize(data: any, patch: boolean): void {
        this._optional.push(...["region"]);
        this._readOnly.push(...["discussion", "roles"]);
        this._references.push(
            ...["form_fields", "template", "values", "owner", "last_modified_by"],
        );
        super.initialize(data, patch);
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "region");
        this.setMember(data, patch, "form_fields", DataFormField, true);
        this.setMember(data, patch, "template", DataForm);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "is_complete");
        this.setMember(data, patch, "values", DataFieldValue, true);
        this.setMember(data, patch, "attributes");
        this.setMember(data, patch, "published");
        this.setMember(data, patch, "last_modified", Date);
        this.setMember(data, patch, "last_modified_by", Account);
        this.setMember(data, patch, "version");
        this.setMember(data, patch, "template_version");
        if (!this.values) this.values = [];
    }

    duplicate(owner?: ObjectOrReference<DocumentRepository>): DataForm {
        const duplicate = new DataForm(this);
        duplicate.id = undefined;
        if (owner) duplicate.owner = owner;
        duplicate.display_name = "Copy of " + this.display_name;
        duplicate.name = this.name + ".copy";
        duplicate.created_at = new Date();
        duplicate.values = [];
        return duplicate;
    }

    get is_partial(): boolean {
        return !this.is_complete && this.values.length > 0;
    }
    get isShared(): boolean {
        return this.owner?.type == "program.inquiry";
    }

    getValue(field: string): any {
        const value = this.values.find(
            (fieldValue: ObjectOrReference<DataFieldValue>) =>
                fieldValue instanceof DataFieldValue &&
                fieldValue.field?.name === field,
        ) as DataFieldValue;
        return value?.value;
    }
}

export class DataReference extends ObjectReference {
    owner?: ObjectReference;
    internal_name?: string;

    initialize(data: any, patch: boolean): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "internal_name");
    }
}

export class DataFormReference extends DataReference {
    version?: number;

    initialize(data: any, patch: boolean): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "version");
    }
}

export class DataFormSettingsReference extends DataFormReference {
    is_intake_valid?: boolean;
    is_shipment_valid?: boolean;
    intake_errors?: string[];
    shipment_errors?: string[];
    versions?: DataFormSettingsReference[];

    initialize(data: any, patch: boolean): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "is_intake_valid");
        this.setMember(data, patch, "is_shipment_valid");
        this.setMember(data, patch, "intake_errors");
        this.setMember(data, patch, "shipment_errors");

        const versions = data["versions"] ?? [];
        this.versions = versions.map((v: any) => new DataFormSettingsReference(v));
    }
}
