import { SearchableListComponent } from "./../../../common/components/searchable-list.component";
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { mergeMap } from "rxjs/operators";
import {
    ObjectViewEntryPoint,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Organization } from "src/services/models/organization";
import { Program, ProgramCountry } from "../../../services/models/program";
import { ProgramService } from "../../../services/program.services";
import { of } from "rxjs";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";
import { ProgramDetailsComponent } from "./program-details/program-details.component";
import { ProgramAdminComponent } from "./program-admin.component";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { APIObject, ObjectOrReference } from "src/services/models/api-object";
import { FormControl } from "@angular/forms";
import { DerivedPermission } from "src/services/models/role";

@Component({
    selector: "program-list",
    templateUrl: "./program-list.component.html",
    styleUrls: ["./program-list.component.scss"],
})
export class ProgramListComponent extends SearchableListComponent<Program> {
    objectView = ProgramDetailsComponent;
    tabSubtitle(object: Program): string | undefined {
        return object.organization.displayName + " Program";
    }
    get programs() {
        return this.list.items as Program[];
    }
    protected _organization?: Organization;
    @Input() set organization(v: Organization | undefined) {
        this._organization = v;
        this.list.refresh();
    }
    get organization(): Organization | undefined {
        return this._organization;
    }

    showDeleted = new FormControl(false);
    constructor(
        protected service: ProgramService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "program-list");

        this.showDeleted.valueChanges.subscribe(() => this.list.refresh());
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }
    get isOrganizationAdministrator(): boolean {
        return (
            !!this.currentAccount?.hasRole("object.admin", this.organization) ||
            !!this.currentAccount?.isSystemAdministrator ||
            false
        );
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["organization"] = this._organization?.id ? this._organization.id : "0";
        filters["use_reference"] = "True";
        filters["deleted"] = this.showDeleted?.value ? "True" : "False";
        return filters;
    }

    protected canEdit(program: Program): boolean {
        const isProgramAdmin = !!this.currentAccount?.hasDerivedPermission('object.admin', program);
        // Check if it is a program country admin trying to access program country settings
        const isProgramCountryAdmin = !!this.currentAccount?.derived_permissions.find(
            (p: DerivedPermission) => p.root_program?.id == program.id && p.object_type == ProgramCountry.object_type
        );
        return isProgramAdmin || isProgramCountryAdmin || this.isOrganizationAdministrator;
    }

    editObject(
        event: MouseEvent | undefined,
        object: Program,
        asDialog: boolean = false,
        viewOnly: boolean = false,
    ): ObjectViewEntryPoint<Program> | undefined {
        if (event) this.terminateEvent(event);

        let mode = ObjectViewMode.Create;
        if (viewOnly) mode = ObjectViewMode.View;
        else if (object?.id) mode = ObjectViewMode.Edit;
        object = object ?? this.newObject();

        // Determine the view based on the object id
        const view = object?.id ? ProgramAdminComponent : ProgramDetailsComponent;

        const instance = ObjectAdminComponent.showObject<Program>(
            object,
            view,
            mode,
            asDialog ? this.objectDialogConfiguration(object, mode) : undefined,
        );
        if (object?.id && instance) {
            (instance as ProgramAdminComponent).organization = this.organization;
        } else if (instance) {
            const detailsComponent = instance as ProgramDetailsComponent;
            detailsComponent.autosave = false;
            detailsComponent.mode = ObjectViewMode.Create;
            detailsComponent.organization = this.organization;
        }
        if (asDialog && instance?.dialogReference)
            instance.dialogReference.afterClosed().subscribe((v?: any) => {
                if (v instanceof APIObject && v.id == object?.id) {
                    object?.update(v);
                }
                this.list.refresh(true);
            });
        return instance;
    }
    newObject(data?: any): Program | undefined {
        data = {
            ...data,
            organization: this.organization?.asReference,
        };
        return super.newObject(data);
    }

    protected onObjectCreated(o: ObjectOrReference<Program>): void {
        super.onObjectCreated(o);

        this.snackbar.open(`${o.displayName} created successfully`, undefined, {
            duration: 4000,
        });
    }
    protected onObjectUpdated(o: ObjectOrReference<Program>, change_set?: any): void {
        super.onObjectUpdated(o, change_set);
        this.list.refresh();
        const message = (o as Program).deleted ? "deleted" : "updated";
        this.snackbar.open(`${o.displayName} ${message} successfully`, undefined, {
            duration: 4000,
        });
    }
    closeOrOpenProgram(event: MouseEvent, program: Program) {
        this.terminateEvent(event);

        const action = program.status == "Active" ? "close" : "open";
        const message = `Are you sure you want to ${action} '${program.displayName}'?`;
        const newStatus =
            program.status == "Closed" || program.status == "Pending" ?
                "Active"
            :   "Closed";
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message,
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) => {
                    if (confirm) {
                        program.status = newStatus;
                        return this.service.update(program);
                    }
                    return of(null);
                }),
            )
            .subscribe((prog) => {
                if (prog) {
                    program.update({ ...prog });
                }
            });
    }
    reopenProgram(_e: Event, p: Program) {
        this.terminateEvent(_e);

        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        "Are you sure you want to re-open '" + p.displayName + "'?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) => {
                    if (confirm) {
                        p.deleted = false;
                        return this.service.update(p);
                    }
                    return of(null);
                }),
            )
            .subscribe((prog) => {
                if (prog) {
                    p.update({ ...prog });
                }
            });
    }
    protected objectDialogConfiguration(
        object: Program,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);

        return {
            ...config,
            width: "90vw",
            minHeight: "70vh",
            disableClose: !object?.id,
        };
    }
}
