<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div *ngIf="mode == ObjectViewMode.Edit">
        <div class="margin-top">{{ fullObject?.email }}</div>
        <mat-form-field
            *ngIf="capabilitiesList$ | async as capabilities"
            class="full margin-top"
        >
            <mat-label>Role</mat-label>
            <mat-select formControlName="capabilities">
                <mat-option
                    *ngFor="let capability of capabilities"
                    [value]="capability"
                >
                    {{ capability.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="wrapper flex stack flexible" *ngIf="mode == ObjectViewMode.Edit">
        <mat-form-field class="full margin-top">
            <mat-label>Permission</mat-label>
            <mat-select formControlName="permission" placeholder="Select a Permission">
                <mat-option *ngFor="let role of availableRoles" [value]="role">
                    {{ role.display }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="isCurrentAccount">
                You cannot modify your own permission.
            </mat-hint>
        </mat-form-field>
        <mat-checkbox
            [checked]="!formGroup.get('private')!.value"
            (change)="
                formGroup.get('private')!.setValue(!$event.checked);
                formGroup.markAsDirty()
            "
            [matTooltip]="publicCheckboxToolTip"
        >
            <mat-label>Public</mat-label>
        </mat-checkbox>
    </div>
</object-form>
