<div class="container">
    <div class="staff-header flex">
        <div class="title org-title" [id]="list.listTitle">Staff</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field color="primary" class="program-search margin-right">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Staff</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="canMigrate"
                (click)="migrateStaffTeam($event)"
            >
                <mat-icon>upgrade</mat-icon>
                Migrate Team
            </button>
            <button
                mat-flat-button
                class="org-button"
                color="accent"
                type="button"
                (click)="createObject($event, true)"
            >
                <mat-icon>add</mat-icon>
                Add Staff Member
            </button>
        </div>
    </div>

    <mat-table [dataSource]="list.items" matSort *ngIf="list.hasLoaded">
        <ng-container matColumnDef="expand">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let account">
                <button mat-icon-button *ngIf="canExpand(account)">
                    <mat-icon>
                        {{
                            isMembersRowExpanded(account) ? "expand_less" : (
                                "expand_more"
                            )
                        }}
                    </mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="account-name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let account">
                <div class="flex stack">
                    {{ account.displayName }}
                    <br />
                    {{ account.email }}
                    <div *ngIf="memberIsInherited(account)" class="small">
                        <span>{{ inheritedMemberText(account) }}</span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
            <mat-cell *matCellDef="let account">
                {{ account.capabilityDisplay }}
            </mat-cell>
        </ng-container>

        <!-- Programs Column -->
        <ng-container matColumnDef="programs">
            <mat-header-cell *matHeaderCellDef>Programs</mat-header-cell>
            <mat-cell *matCellDef="let account">
                <div class="account-program-list">
                    @if (isMembersRowExpanded(account)) {
                        <div *ngFor="let program of getPrograms(account)">
                            {{ program }}
                        </div>
                    } @else {
                        <div
                            *ngIf="
                                getPrograms(account)
                                    | formatListofDerivedData as formattedData
                            "
                        >
                            <ng-container
                                *ngFor="
                                    let item of formattedData.displayedItems;
                                    let last = last
                                "
                            >
                                {{ item }}
                                <span *ngIf="!last">,</span>
                            </ng-container>
                            <span
                                *ngIf="formattedData.remainingCount > 0"
                                style="font-weight: bold; color: #0166bd"
                            >
                                {{ formattedData.remainingCount }}+
                            </span>
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <!-- Countries Column -->
        <ng-container matColumnDef="countries">
            <mat-header-cell *matHeaderCellDef>Countries</mat-header-cell>
            <mat-cell *matCellDef="let account">
                <div class="account-program-list">
                    @if (isMembersRowExpanded(account)) {
                        <div *ngFor="let country of getCountries(account)">
                            {{ country }}
                        </div>
                    } @else {
                        <div
                            *ngIf="
                                getCountries(account)
                                    | formatListofDerivedData as formattedData
                            "
                        >
                            <ng-container
                                *ngFor="
                                    let item of formattedData.displayedItems;
                                    let last = last
                                "
                            >
                                {{ item }}
                                <span *ngIf="!last">,</span>
                            </ng-container>
                            <span
                                *ngIf="formattedData.remainingCount > 0"
                                style="font-weight: bold; color: #0166bd"
                            >
                                {{ formattedData.remainingCount }}+
                            </span>
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <!-- Permissions Column -->
        <ng-container matColumnDef="permission">
            <mat-header-cell *matHeaderCellDef>Permissions</mat-header-cell>
            <mat-cell *matCellDef="let account">
                {{ displayNameForRole(account.permission?.role ?? "") }}
            </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-column">
                Actions
            </mat-header-cell>
            <mat-cell *matCellDef="let account" class="actions-column">
                <button
                    mat-icon-button
                    *ngIf="!memberIsInherited(account)"
                    (click)="
                        canEditStaff(getFullAccount(account.account)) &&
                            editObject($event, account, true)
                    "
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    *ngIf="!memberIsInherited(account)"
                    mat-icon-button
                    (click)="terminateEvent($event)"
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ account: account }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #overflow="matMenu">
                    <ng-template matMenuContent let-account="account">
                        <button
                            mat-menu-item
                            (click)="
                                canEditStaff(account) && deleteStaff($event, account)
                            "
                            [disabled]="
                                !canEditStaff(account) ||
                                account?.account?.id === currentAccount?.id
                            "
                        >
                            Remove
                        </button>
                    </ng-template>
                </mat-menu>
            </mat-cell>
        </ng-container>

        <!-- Table Header and Row Declarations -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="toggleExpand(row)"
            [ngStyle]="{
                'background-color': isMembersRowExpanded(row) ? '#EDF2FA' : ''
            }"
        ></mat-row>
    </mat-table>

    <!-- Paginator -->
    <div *ngIf="list.loadingProgress" class="error not-found text-center">
        <span *ngIf="list.searching && !list.hasLoaded">
            <mat-progress-spinner
                diameter="20"
                class="inline"
                mode="indeterminate"
            ></mat-progress-spinner>
            Loading...
        </span>
        <span *ngIf="list.initialized && !list.list.items.length">No staff found.</span>
    </div>

    <!-- Paginator Component -->
    <mat-paginator
        [length]="list.paginatorLength"
        [pageSize]="list.pageSize"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [showFirstLastButtons]="true"
        (page)="list.handlePageEvent($event)"
    ></mat-paginator>
</div>
