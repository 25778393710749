import { Component, inject } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { ProgramCountryService, CountryService } from "src/services/program.services";
import { Program, ProgramCountry } from "src/services/models/program";
import { Country } from "src/services/models/country";
import { map } from "rxjs";
import { DataForm } from "src/services/models/data";
import { DataFormService } from "src/services/data.services";
import { Organization } from "src/services/models/organization";
import { AccountService, OrganizationService } from "src/services/iam.services";
import { DerivedPermission } from "src/services/models/role";

@Component({
    selector: "program-country",
    templateUrl: "./program-country.component.html",
    styleUrls: ["./program.component.scss"],
})
export class ProgramCountryComponent extends ObjectComponent<ProgramCountry> {
    availableCountries: Country[] = [];
    private countryService = inject(CountryService);
    get fallbackFlagUrl(): string {
        return this.countryService.fallbackFlagUrl;
    }
    titleBarFlagUrl = "";

    private accountService = inject(AccountService);
    availableForms: DataForm[] = [];
    private dataFormService = inject(DataFormService);
    programOrganization?: Organization;
    private organizationService = inject(OrganizationService);
    program?: Program;
    country?: Country;

    autosave = true;
    mode = ObjectViewMode.Edit;

    constructor(protected service: ProgramCountryService) {
        super(service);
        this.updateDashboardRole()
    }

    get organization(): Organization {
        return ((this.fullObject?.program as Program).organization as Organization)
    }

    get isProgramCountryAdministrator(): boolean {
        if (this.fullObject instanceof ProgramCountry) {
            return !!this.currentAccount?.hasDerivedPermission("object.admin", this.fullObject);
        }
        return false;
    }

    get isHcpStaff(): boolean {
        return (this.dashboardRoleDefined && !!(this.dashboardRole === "provider"));
    }

    get canUseFormBuilder(): boolean {
        return (
            (!!this.isProgramCountryAdministrator || !!this.isSystemAdministrator) &&
            !this.isHcpStaff &&
            this.organization.isEntitlementEnabled("formBuilder")
        );
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            program: [null, Validators.required],
            country: [null, Validators.required],
            status: [null, Validators.required],
            intake_form: [null],
        });
    }
    onCommitSuccess(country: ProgramCountry): boolean{
        this.snackbar.open(`${country.displayName ?? "Country"} updated successfully.`, undefined, {
            duration: 4000,
        });
        return super.onCommitSuccess(country);
    }
    onCommitError(country: ProgramCountry): void{
        this.snackbar.open(`Error: ${country.displayName ?? "Country"} could not be updated.`, undefined, {
            duration: 4000,
        });
        super.onCommitError(country);
    }
    get countryControl() {
        return this.formGroup.get("country") as FormControl<Country>;
    }
    protected setObject(v?: ProgramCountry): void {
        super.setObject(v);
        this.updateAvailableCountries();
        this.updateAvailableForms();

        if (this.mode == ObjectViewMode.Create) {
            this.objectName = "Add Country";
        } else if (this.mode == ObjectViewMode.Edit) {
            this.objectName = "Edit " + (this.object?.displayName ?? "ProgramCountry");
            this.updateTitleBarFlagUrl();
        } else {
            this.objectName = "View " + (this.object?.displayName ?? "ProgramCountry");
            this.updateTitleBarFlagUrl();
        }

        if (v?.program instanceof Program) {
            this.program = v.program;

            if (v.program.organization?.id) {
                this.organizationService
                    .retrieve(v.program.organization.id)
                    .subscribe((org) => {
                        this.programOrganization = org;
                    });
            }
        }

        if (v?.country instanceof Country) {
            this.country = v.country;
        }
    }
    updateDashboardRole(): void {
        this.accountService
            .dashboardRole(this.currentAccount)
            .subscribe(
                (role: any) => (this.dashboardRole = role ? role["role"] : "none"),
            );
    }

    updateAvailableCountries() {
        this.countryService.getCountries().subscribe(() => {
            if (this.object instanceof ProgramCountry) {
                const programId = this.object.program.id;

                this.service
                    .list({ program: programId! })
                    .pipe(
                        map(
                            (existingProgramCountries) =>
                                existingProgramCountries as ProgramCountry[],
                        ),
                        map((existingProgramCountries) =>
                            existingProgramCountries.map((el) => el.country.id),
                        ),
                    )
                    .subscribe((existingCountryIds) => {
                        this.availableCountries = this.countryService.countries.filter(
                            (el) => !existingCountryIds.includes(el.id),
                        );
                    });
            }
        });
    }

    handleCountryFlagError(country: Country) {
        if (country instanceof Country) {
            country.flag_url = this.fallbackFlagUrl;
        }
    }

    updateTitleBarFlagUrl() {
        if (this.object instanceof ProgramCountry) {
            this.titleBarFlagUrl =
                this.object.country instanceof Country && this.object.country.flag_url ?
                    this.object.country.flag_url
                :   this.fallbackFlagUrl;
        }
    }

    updateAvailableForms() {
        // Initialize the list of form owners with '0' so that 'MedaSystems' owned forms are always available
        const owners: string[] = ["0"];

        // Add this ProgramCountry as a valid form owner
        this.fullObject?.id && owners.push(this.fullObject.id);

        // Add this Program as a valid form owner
        this.fullObject?.program?.id && owners.push(this.fullObject.program.id);

        // Add the Program organization as a valid form owner
        if (this.fullObject?.program instanceof Program) {
            this.fullObject.program.organization?.id &&
                owners.push(this.fullObject.program.organization.id);
        }

        this.dataFormService
            .list({ next: "0", is_template: "True", owned: owners.join(","), use_reference: "True" })
            .subscribe((forms) => {
                this.availableForms = forms as DataForm[];
            });
    }

    getTabHelperText(tabName: string): string {
        const helperText = `When a request comes in from ${this.country?.display_name} and is assigned to the ${this.program?.displayName} program, you can specify the country-specific ${tabName} that the request should have access to.  This can be left empty if you don't have country-specific ${tabName}.`;
        return helperText;
    }
}
