import { ContentStack } from "./../../../common/components/content-stack.component";
import { AfterViewInit, Component, ViewChild, inject } from "@angular/core";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { SessionComponent } from "../../../services/components/session.component";
import { DashboardComponent } from "../../components/dashboard/dashboard.component";
import { LocationStrategy, Location } from "@angular/common";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { CaseComponent } from "src/program/components/case/case.component";
import { Case } from "src/services/models/case";
import {
    AssignmentReferenceFactory,
    AssignmentService,
    CaseService,
    StatusFactory,
    TaskFactory,
} from "src/services/program.services";
import { ActivatedRoute, Params } from "@angular/router";
import { APIListResult } from "src/services/models/api-object";
import { MatSidenav } from "@angular/material/sidenav";
import { AppnotificationDrawerComponent } from "src/notifications/appnotification-drawer/appnotification-drawer.component";

@Component({
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends SessionComponent implements AfterViewInit {
    @ViewChild(ContentStack) protected contentStack?: ContentStack;
    @ViewChild("sidenav") sideNav?: MatSidenav;

    @ViewChild(AppnotificationDrawerComponent)
    notificationDrawer?: AppnotificationDrawerComponent;

    notificationCount?: number;
    protected caseService: CaseService;
    protected assignmentService: AssignmentService;
    shouldNavigate = true;
    constructor(
        private location: LocationStrategy,
        locationService: Location,
        private activatedRoute: ActivatedRoute,
    ) {
        super();

        this.caseService = inject(CaseService);
        this.assignmentService = inject(AssignmentService);

        let queryParams: Params | { [key: string]: any } =
            this.activatedRoute.snapshot.queryParams;

        const localStorageQueryParams = localStorage.getItem("params");
        localStorage.removeItem("params");

        if (localStorageQueryParams && !Object.keys(queryParams).length) {
            queryParams = JSON.parse(localStorageQueryParams);
        }

        if (Object.keys(queryParams).length) {
            locationService.replaceState(""); //removes query param and token from url and browser history.
            this.shouldNavigate = false;
            if ("caseReference" in queryParams && "taskId" in queryParams) {
                const { caseReference, taskId } = queryParams;
                this.initializeDeepLinkFactories();
                this.caseService
                    .list({
                        access: this.currentAccount?.id ?? "0",
                        contains: caseReference,
                    })
                    .subscribe((result) => {
                        const cases = result as Case[];
                        if (!cases || cases.length > 1 || !cases[0]) {
                            this.showDashboard();
                            return;
                        } //should only be one case

                        const caseToOpen = cases[0];
                        this.assignmentService
                            .retrieve(taskId)
                            .subscribe((assignment) => {
                                if (!assignment) {
                                    this.showDashboard();
                                    return;
                                }
                                const caseComp = ObjectAdminComponent.showObject<Case>(
                                    caseToOpen,
                                    CaseComponent,
                                    ObjectViewMode.Edit,
                                ) as CaseComponent;
                                caseComp.assignmentToOpen = assignment;
                            });
                    });
            } else if ("caseReference" in queryParams) {
                const { caseReference } = queryParams;
                this.initializeDeepLinkFactories();
                this.caseService
                    .list({
                        access: this.currentAccount?.id ?? "0",
                        contains: caseReference,
                    })
                    .subscribe((result) => {
                        const cases = result as Case[];
                        if (!cases || cases.length > 1 || !cases[0]) {
                            this.showDashboard();
                            return;
                        } //should only be one case
                        const caseToOpen = cases[0];
                        const caseComp = ObjectAdminComponent.showObject<Case>(
                            caseToOpen,
                            CaseComponent,
                            ObjectViewMode.Edit,
                        ) as CaseComponent;
                        caseComp.toDiscussion = "toDiscussion" in queryParams;
                    });
            } else {
                this.shouldNavigate = true; // reset flag to true to allow init to proceed
            }
        }

        history.pushState(null, document.title, window.location.href);
        this.location.onPopState(() =>
            history.pushState(null, document.title, window.location.href),
        );
    }

    initializeDeepLinkFactories() {
        inject(StatusFactory);
        inject(TaskFactory);
        inject(AssignmentReferenceFactory);
    }

    showDashboard(): void {
        setTimeout(() => {
            this.contentStack?.push({
                type: DashboardComponent,
                breadcrumbIcon: faHome,
                breadcrumbText: "Dashboard",
            });
        });
    }

    ngAfterViewInit() {
        if (!this.shouldNavigate) return;

        this.caseService.list({ home: this.currentAccount?.id ?? "0" }).subscribe({
            next: (result: APIListResult<Case>) => {
                const cases = result as Case[];
                if (cases.length == 1) {
                    ObjectAdminComponent.showObject<Case>(
                        cases[0],
                        CaseComponent,
                        ObjectViewMode.Edit,
                    );
                    super.ngOnDestroy();
                } else {
                    this.showDashboard();
                }
            },
            error: () => this.showDashboard(),
        });
    }

    handleNotificationScroll(e: Event) {
        const element = e.target as HTMLElement;
        const atBottom =
            element?.scrollHeight - element.scrollTop <= element?.clientHeight;
        if (atBottom) {
            this.notificationDrawer?.loadMoreNotifications();
        }
    }
}
