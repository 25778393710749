import { NamedObject, ObjectReference } from "./api-object";

export interface TaskType {
    task_type: string;
    display_name: string;
    hint_text?: string;
}

export enum TASK_TYPE {
    UPLOAD_DOCUMENT = "document.upload",
    SHARE_DOCUMENT = "document.share",
    REVIEW_DOCUMENT = "document.review",
    DOCUSIGN_SIGNATURE = "signature.docusign",
    DATA = "data.complete-form",
    DATA_REVIEW = "data.review-form",
    GENERIC = "generic",
    INVITATION_SEND_MESSAGE = "invitation.send_message",
    SUMMARY = "summary",
    CREATE_SHIPMENT = "shipping.create",
    CONFIRM_SHIPMENT = "shipping.confirm",
    SEND_COMMUNICATION = "communications.send",
    CLOSE_CASE = "status.close",
    WORKFLOW_GROUP = "workflow.group",
    WORKFLOW_CHOICE = "workflow.choice",
    DISCUSSION = "discussion",
}

export class Task extends NamedObject {
    static readonly object_type: string = "program.task";
    owner?: ObjectReference; // Account or Organization, optional.  If undefined a globally accessible object
    description?: string; // optional
    task_type?: TASK_TYPE; // The type of task - one of the defined TaskTypes
    never_pending?: boolean;
    static readonly TaskTypes: TaskType[] = [
        {
            task_type: TASK_TYPE.WORKFLOW_GROUP,
            display_name: "Workflow Group",
            hint_text: "A container for other tasks",
        },
        {
            task_type: TASK_TYPE.CREATE_SHIPMENT,
            display_name: "Create Shipment",
            hint_text: "A basic task which will initiate a shipment.",
        },
        {
            task_type: TASK_TYPE.UPLOAD_DOCUMENT,
            display_name: "Upload Document",
            hint_text: "A basic task which will initiate a document upload.",
        },
        {
            task_type: TASK_TYPE.REVIEW_DOCUMENT,
            display_name: "Review Document",
            hint_text: "A basic task which will prompt a user to view a document.",
        },
        {
            task_type: TASK_TYPE.DOCUSIGN_SIGNATURE,
            display_name: "DocuSign®",
            hint_text: "Requires the assignee to sign documents prepared via DocuSign.",
        },
        {
            task_type: TASK_TYPE.CONFIRM_SHIPMENT,
            display_name: "Confirm Drug Shipment Received",
            hint_text: "A task to confirm whether the drug shipment was received.",
        },
        {
            task_type: TASK_TYPE.GENERIC,
            display_name: "Other",
            hint_text: "A basic task with a name and description, but no validation.",
        },
        {
            task_type: TASK_TYPE.SEND_COMMUNICATION,
            display_name: "Send Communication",
            hint_text: "A basic task which will initiate a communication.",
        },
        {
            task_type: TASK_TYPE.DATA,
            display_name: "Complete Form",
            hint_text: "A basic task which will let the user complete a form.",
        },
        {
            task_type: TASK_TYPE.WORKFLOW_CHOICE,
            display_name: "Workflow Decision",
            hint_text:
                "A task which will prompt the user to make a decision about how the workflow continues.",
        },
    ];

    get taskType(): TASK_TYPE {
        const type = this.task_type?.replace(".internal", "");
        return type as TASK_TYPE;
    }
    get isInternal(): boolean {
        return this.task_type?.endsWith(".internal") ?? false;
    }

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["description", "owner"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "task_type");
        this.setMember(data, patch, "never_pending");
    }
}
