import { RoleAdminComponent } from "./components/account/role-list.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ServicesModule } from "../services/services.module";
import { SystemAdminComponent } from "./components/system/system-admin.component";
import { OrganizationAdminComponent } from "./components/organization/organization-admin.component";
import { OrganizationComponent } from "./components/organization/organization.component";
import { CommonModule as EAPCommonModule } from "../common/common.module";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrganizationSettingsComponent } from "./components/organization/settings.component";
import { ProgramModule } from "../program/program.module";
import { AccountAdminComponent } from "./components/account/account-admin.component";
import { AccountComponent } from "./components/account/account.component";
import { AccountService } from "../services/iam.services";
import { FileUploadModule } from "ng2-file-upload";
import { MatSortModule } from "@angular/material/sort";
import { UpdateEmailDialog } from "./components/account/updateEmail.dialog";
import { RoleComponent } from "./components/account/role.component";
import {
    CaseStatusAdmin,
    StatusAdminComponent,
} from "./components/organization/status-admin.component";
import {
    CaseStatusComponent,
    StatusComponent,
} from "./components/organization/status.component";
import { ExportCaseDataDialog } from "./components/organization/ExportCaseDataDialog";
import { MatRadioModule } from "@angular/material/radio";
import { AdminAuditComponent } from "./components/audit/admin-audit.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AuditDetailComponent } from "./components/audit/audit-detail.component";
import { AuditRestoreComponent } from "./components/audit/audit-restore.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatChipsModule } from "@angular/material/chips";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { OrganizationProperties } from "./components/organization/org-properties/org-properties.component";
import { CapabilityAdminComponent } from "./components/organization/capability/capability-admin.component";
import { CapabilityComponent } from "./components/organization/capability/capability.component";
import { ColorPickerModule } from "ngx-color-picker";

@NgModule({
    imports: [
        ColorPickerModule,
        CommonModule,
        EAPCommonModule,
        ServicesModule,
        ScrollingModule,
        MatTabsModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        DragDropModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatSelectModule,
        FormsModule,
        ProgramModule,
        FileUploadModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatChipsModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatSlideToggleModule,
    ],
    declarations: [
        SystemAdminComponent,
        OrganizationAdminComponent,
        OrganizationComponent,
        OrganizationSettingsComponent,
        AccountAdminComponent,
        AccountComponent,
        UpdateEmailDialog,
        RoleAdminComponent,
        RoleComponent,
        StatusAdminComponent,
        StatusComponent,
        CaseStatusAdmin,
        CaseStatusComponent,
        ExportCaseDataDialog,
        AdminAuditComponent,
        AuditDetailComponent,
        AuditRestoreComponent,
        OrganizationProperties,
        CapabilityAdminComponent,
        CapabilityComponent,
    ],
    exports: [SystemAdminComponent],
    providers: [
        AccountService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class AdminModule {}
