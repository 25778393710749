import { Component, inject, Input } from "@angular/core";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Organization } from "src/services/models/organization";
import { Program, ProgramCountry } from "src/services/models/program";
import { ProgramService } from "src/services/program.services";
import { DerivedPermission } from "src/services/models/role";
import { AccountService } from "src/services/iam.services";

@Component({
    selector: "program-admin",
    templateUrl: "./program-admin.component.html",
    styleUrl: "./program-admin.component.scss",
})
export class ProgramAdminComponent extends ObjectComponent<Program> {
    accountService = inject(AccountService);
    derivedPermissions: DerivedPermission[] = [];

    constructor(service: ProgramService) {
        super(service);
        this.updateDashboardRole();
        this.updateDerivedPermissions();
    }

    protected setObject(v?: Program | undefined): void {
        super.setObject(v);
        if (v?.id) {
            this.objectName = `${this.fullObject?.displayName} | Global Program Settings`;
        }
    }

    autosave = false;
    objectName = "Global Program Settings";

    @Input() organization?: Organization;

    get isOrganizationAdministrator(): boolean {
        return !!this.currentAccount?.hasRole("object.admin", this.organization);
    }

    get isProgramAdministrator(): boolean {
        if (this.fullObject instanceof Program) {
            return !!this.derivedPermissions.find(
                (p: DerivedPermission) =>
                    p.object_id === this.fullObject?.id &&
                    p.permission.toLowerCase() === "object.admin",
            );
        }
        return false;
    }

    get isProgramCountryAdministrator(): boolean {
        if (this.fullObject instanceof Program) {
            return !!this.derivedPermissions.find(
                (p: DerivedPermission) =>
                    p.root_program?.id == this.fullObject?.id &&
                    p.object_type == ProgramCountry.object_type,
            );
        }
        return false;
    }

    get isHcpStaff(): boolean {
        return this.dashboardRoleDefined && !!(this.dashboardRole === "provider");
    }

    get canUseFormBuilder(): boolean {
        return (
            !!this.organization?.isEntitlementEnabled("formBuilder") &&
            (this.isProgramAdministrator || this.isSystemAdministrator) &&
            !this.isHcpStaff
        );
    }

    updateDerivedPermissions(): void {
        this.accountService
            .derivedPermissions(this.currentAccount)
            .subscribe(
                (permissions: any) =>
                    (this.derivedPermissions = permissions ? permissions : []),
            );
    }

    updateDashboardRole(): void {
        this.accountService
            .dashboardRole(this.currentAccount)
            .subscribe(
                (role: any) => (this.dashboardRole = role ? role["role"] : "none"),
            );
    }
    protected onCommitSuccess(v: Program | undefined): boolean {
        if (this.mode !== ObjectViewMode.Create) {
            this.snackbar.open("Program changes successfully saved.", undefined, {
                duration: 2000,
            });
        }

        return super.onCommitSuccess(v);
    }
}
