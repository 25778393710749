<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
    [hideControls]="mode != ObjectViewMode.Create"
>
    <div class="wrapper flex stack flexible" *ngIf="mode == ObjectViewMode.Create">
        <mat-form-field class="full margin-top">
            <mat-label>Country</mat-label>
            <mat-select formControlName="country" placeholder="Select a Country">
                <mat-select-trigger *ngIf="countryControl?.value">
                    <img
                        *ngIf="countryControl.value"
                        [src]="countryControl.value.flag_url"
                        alt="({{ countryControl.value.code }})"
                        class="country-flag-img"
                        (error)="handleCountryFlagError(countryControl.value)"
                    />
                    {{ countryControl.value.display_name }}
                </mat-select-trigger>
                <ng-container *ngIf="availableCountries.length">
                    <mat-option
                        *ngFor="let country of availableCountries"
                        [value]="country"
                    >
                        <img
                            [src]="country.flag_url"
                            alt="({{ country.code }})"
                            class="country-flag-img"
                            (error)="handleCountryFlagError(country)"
                        />
                        {{ country.display_name }}
                    </mat-option>
                </ng-container>
                <ng-container *ngIf="!availableCountries.length">
                    <mat-option disabled>Error - Countries list not found</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>

    @if (titleBarFlagUrl != fallbackFlagUrl) {
        <div form-title *ngIf="mode != ObjectViewMode.Create">
            <img alt="country flag" [src]="titleBarFlagUrl" class="country-flag-img" />
        </div>
    }
    <div class="wrapper flex stack flexible" *ngIf="mode != ObjectViewMode.Create">
        <div
            [class.mat-dialog-content]="!!dialogReference"
            [class.remove-styling]="!!dialogReference"
        >
            <mat-tab-group color="primary" [class.nested]="!!dialogReference">
                <mat-tab label="Properties">
                    <ng-template matTabContent>
                        <div class="content-card" *ngIf="!!object">
                            <div class="title">Properties</div>
                            <div class="container">
                                <mat-form-field class="full">
                                    <mat-label>Status</mat-label>
                                    <mat-select formControlName="status">
                                        <mat-option value="pending">Pending</mat-option>
                                        <mat-option value="open">Open</mat-option>
                                        <mat-option value="closed">Closed</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Country Intake Form</mat-label>
                                    <mat-select formControlName="intake_form">
                                        <mat-option [value]="null">None</mat-option>
                                        @if (availableForms.length) {
                                            <mat-option
                                                *ngFor="let form of availableForms"
                                                [value]="form"
                                            >
                                                {{ form.displayName }}
                                            </mat-option>
                                        } @else {
                                            <mat-option disabled>
                                                Error - No form templates found
                                            </mat-option>
                                        }
                                    </mat-select>
                                    <mat-hint>
                                        Selecting this will override the default
                                        organization or program level intake form for
                                        this country.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Staff">
                    <div class="content-card" *ngIf="!!object">
                        <program-country-staff-admin
                            [programCountry]="fullObject"
                        ></program-country-staff-admin>
                    </div>
                </mat-tab>
                <mat-tab label="Forms" *ngIf="canUseFormBuilder">
                    <ng-template matTabContent>
                        <div class="content-card">
                            <data-admin [repository]="this.fullObject!"></data-admin>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Documents">
                    <ng-template mat-tab-label>
                        <div class="flex">
                            <span class="flexible">Documents</span>
                            <mat-icon
                                *ngIf="!!tabErrors['Documents']"
                                class="fixed"
                                [ngClass]="tabErrors['Documents'].severity"
                                [matTooltip]="tabErrors['Documents'].message"
                            >
                                warning
                            </mat-icon>
                        </div>
                    </ng-template>
                    <div class="content-card">
                        <p>
                            {{ getTabHelperText("Documents") }}
                        </p>
                        <document-repository
                            [repository]="object"
                            [owner]="programOrganization"
                            [canAdd]="true"
                            (setCaseError)="setTabError('Documents', $event)"
                            headerTitle="Country Documents"
                        ></document-repository>
                        <document-repository
                            [repository]="program"
                            [owner]="programOrganization"
                            [canAdd]="true"
                            (setCaseError)="setTabError('Documents', $event)"
                            headerTitle="Program Documents (View Only)"
                            [viewOnly]="true"
                        ></document-repository>
                        <document-repository
                            [repository]="programOrganization"
                            [owner]="programOrganization"
                            [canAdd]="true"
                            (setCaseError)="setTabError('Documents', $event)"
                            headerTitle="Organization Documents (View Only)"
                            [viewOnly]="true"
                        ></document-repository>
                    </div>
                </mat-tab>
                <mat-tab label="Templates">
                    <div class="content-card" *ngIf="!!fullObject">
                        <p>
                            {{ getTabHelperText("Templates") }}
                        </p>
                        <template-list
                            [owner]="object"
                            [inCard]="true"
                            headerTitle="Country Templates"
                        ></template-list>
                        <template-list
                            [owner]="program"
                            [inCard]="true"
                            headerTitle="Program Templates (View Only)"
                            [viewOnly]="true"
                        ></template-list>
                        <template-list
                            [owner]="programOrganization"
                            [inCard]="true"
                            headerTitle="Organization Templates (View Only)"
                            [viewOnly]="true"
                        ></template-list>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <mat-dialog-actions
        *ngIf="mode != ObjectViewMode.Create"
        form-actions
        class="row flex object-actions"
    >
        <div class="spacer"></div>
        <button mat-flat-button class="fixed" type="button" mat-dialog-close>
            Close
        </button>
        <button
            mat-flat-button
            class="fixed"
            type="button"
            color="primary"
            mat-dialog-close
            (click)="onSave()"
        >
            Save
        </button>
    </mat-dialog-actions>
</object-form>
