<h3 mat-dialog-title>
    <div *ngIf="(!member && !readOnly && !wantsInvite)">Add Team Member</div>
    <div *ngIf="wantsInvite">Invite Team Member</div>
    <div *ngIf="!!member && !readOnly && !wantsInvite">Edit Team Member Access</div>
    <div *ngIf="readOnly">View Team Member</div>
    <div class="spacer"></div>
</h3>
<mat-dialog-content style="padding: 20px">
    <div class="wrapper flex stack full" *ngIf="readOnly">
        <div class="flex stack">
            <div class="view-label">Organization</div>
            <div class="view-value">
                {{ caseTeam?.team?.organization?.displayName }} {{ member?.private ? '
                (Private User)' : '' }}
            </div>
        </div>
        <div class="flex stack">
            <div class="view-label">Account</div>
            <div class="view-value">
                <div>{{ formGroup.controls.member.value?.account.displayName }}</div>
                <div class="small">{{ formGroup.controls.member.value?.email }}</div>
            </div>
        </div>
        <div class="flex stack">
            <div class="view-label">Role</div>
            <div class="view-value" *ngIf="member">{{ capabilityDisplay(member) }}</div>
        </div>
        <div class="flex stack" *ngIf="!isExternal">
            <div class="view-label">Case Permissions</div>
            <div class="view-value flex stack">
                <div>{{ accessDisplay }}</div>
                <div *ngIf="canBeInvited" class="secondary small">Not yet invited</div>
            </div>
        </div>
    </div>
    <form
        *ngIf="!member || !readOnly"
        class="wrapper flex stack full"
        [formGroup]="formGroup"
        novalidate
        (ngSubmit)="onSave()"
    >
        <mat-form-field
            appearance="outline"
            class="full input access-type"
            *ngIf="!member && !readOnly && isPharmaStaff"
        >
            <mat-label>Organization</mat-label>
            <mat-select formControlName="override">
                <mat-option *ngFor="let override of availableTeams" [value]="override">
                    {{ override.team.organization.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field
            appearance="outline"
            class="full input"
            *ngIf="!isExternal || !!member"
        >
            <mat-label>Account</mat-label>
            <mat-select formControlName="member">
                <mat-select-trigger>
                    <div>
                        {{ formGroup.controls.member.value?.account.displayName }}
                    </div>
                    <div class="small">
                        {{ formGroup.controls.member.value?.email }}
                    </div>
                </mat-select-trigger>
                <mat-option
                    *ngFor="let member of availableStaff"
                    [value]="member"
                    [disabled]="!!hasAccess(member)"
                >
                    {{ member.account.displayName }}
                    <span class="small option-spacer">{{ member.email }}</span>
                </mat-option>
                <mat-option [value]="newMember" *ngIf="!member">New User...</mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="formGroup.controls.member.value == newMember && !member">
            <div class="flex row">
                <mat-form-field appearance="outline" class="column input">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="first_name" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="column input">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="last_name" />
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="full input">
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="email" />
                <mat-error *ngIf="!!email.errors">
                    <span *ngIf="email.errors?.invalidEmailAddress">
                        Please enter a valid email address.
                    </span>
                    <span *ngIf="email.errors?.required">This field is required.</span>
                </mat-error>
            </mat-form-field>
        </ng-container>
        <mat-form-field appearance="outline" class="full input">
            <mat-label>Role</mat-label>
            <mat-select
                formControlName="capabilities"
                multiple
                [disabled]="!canEditCapabilities"
            >
                <mat-option *ngFor="let role of capabilities" [value]="role">
                    {{ role.displayName }}
                </mat-option>
            </mat-select>
            <mat-error>This field is required.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full input" *ngIf="canEditAccess">
            <mat-label>Case Permissions</mat-label>
            <mat-select
                formControlName="access"
                placeholder="Select a Permission level"
            >
                <mat-option
                    *ngFor="let access of availableAccess"
                    [value]="access.value"
                    [disabled]="!validMemberPermission(access.value)"
                >
                    {{ access.display }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="formGroup.controls.role.value == 'owner'">
                Case owners automatically receive adminstrator permissions for the case
            </mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="spacer"></div>
    <ng-container *ngIf="!readOnly">
        <button type="button" class="fixed" mat-flat-button mat-dialog-close>
            Cancel
        </button>
        <mat-spinner *ngIf="loading" mode="indeterminate" [diameter]="50"></mat-spinner>
        <button
            *ngIf="!loading"
            type="button"
            class="fixed"
            mat-flat-button
            color="accent"
            [disabled]="!valid"
            (click)="valid && onSave()"
        >
            <span *ngIf="!member && !wantsInvite">Add</span>
            <span *ngIf="!!member && !wantsInvite">Update</span>
            <span *ngIf="wantsInvite">Invite</span>
        </button>
    </ng-container>
    <button
        type="button"
        class="fixed"
        mat-flat-button
        color="accent"
        mat-dialog-close
        *ngIf="readOnly"
    >
        Done
    </button>
</mat-dialog-actions>
