<div class="container">
    <div class="flex between team-header">
        <div class="title">Team</div>
        <button
            mat-flat-button
            class="brand-medium"
            (click)="editAccess($event)"
            [disabled]="viewOnly || (!canManagePermissions && !isPhysicianAdmin)"
        >
            <mat-icon>open_in_new</mat-icon>
            <span class="add-button-text">Add Team Member</span>
        </button>
    </div>
    <mat-table [dataSource]="team">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let member">
                {{ getMemberName(member) }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="organization">
            <mat-header-cell *matHeaderCellDef>Organization</mat-header-cell>
            <mat-cell *matCellDef="let member">
                <mat-chip-set class="flex full">
                    <mat-chip
                        class="team-name-chip"
                        [color]="isInternal(member) ? 'accent' : ''"
                        selected
                    >
                        {{ organizationForMember(member) }}
                    </mat-chip>
                </mat-chip-set>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
            <mat-cell *matCellDef="let member" class="flex stack role-column">
                <div
                    [class.secondary]="isInherited(member)"
                    [class.italic]="member.private"
                >
                    {{ capabilityDisplay(member) }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="permission">
            <mat-header-cell *matHeaderCellDef>Permission</mat-header-cell>
            <mat-cell *matCellDef="let member" class="flex stack role-column">
                <div
                    [class.secondary]="isInherited(member)"
                    [class.italic]="member.private"
                >
                    <mat-icon *ngIf="isMemberOwner(member)" class="amber">
                        star
                    </mat-icon>
                    {{ roleForMember(member) }}
                </div>
                <div
                    *ngIf="
                        canBeInvited(member) &&
                        isInviteExpired(member) &&
                        !member?.is_active
                    "
                    class="secondary small"
                >
                    Invite Expired
                </div>
                <div
                    *ngIf="
                        canBeInvited(member) && isInvited(member) && !member?.is_active
                    "
                    class="secondary small"
                >
                    Invite pending
                </div>
                <div
                    *ngIf="canBeInvited(member) && !isInvited(member)"
                    class="secondary small"
                >
                    Not yet invited
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let member" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ member: member }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"></mat-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-member="member">
                <button mat-menu-item (click)="editAccess($event, member, true)">
                    View
                </button>
                <button
                    mat-menu-item
                    *ngIf="isPharmaStaff || canEditPhysicianStaff(member)"
                    (click)="canEditAccess(member) && editAccess($event, member)"
                    [disabled]="viewOnly || !canEditAccess(member)"
                >
                    Edit Access
                </button>
                <button
                    mat-menu-item
                    *ngIf="isPharmaStaff || canEditPhysicianStaff(member)"
                    (click)="canRemoveAccess(member) && removeAccess($event, member)"
                    [disabled]="
                        viewOnly ||
                        !canRemoveAccess(member) ||
                        memberIsCurrentUser(member)
                    "
                >
                    Revoke Access
                </button>
                <button
                    mat-menu-item
                    (click)="inviteMember($event, member)"
                    [disabled]="viewOnly"
                    *ngIf="canBeInvited(member)"
                >
                    {{ isInvited(member) ? "Resend Invite" : "Invite" }}
                </button>
                <button
                    mat-menu-item
                    (click)="makeOwner($event, member)"
                    *ngIf="canMakeOwner(member)"
                    [disabled]="viewOnly"
                >
                    Make Owner
                </button>
                <button
                    mat-menu-item
                    (click)="removeOwner($event, member)"
                    *ngIf="canRemoveOwner(member)"
                    [disabled]="viewOnly || memberIsCurrentUser(member)"
                >
                    Remove Ownership
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
</div>
