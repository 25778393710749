import { AdminService } from "./admin.service";
import { NgModule } from "@angular/core";
import { SessionService } from "./session.service";
import {
    AccountService,
    AccountSettingsFactory,
    APIKeyService,
    AuditService,
    CapabilityService,
    OrganizationService,
    OrganizationSettingsFactory,
    RoleService,
} from "./iam.services";
import {
    AssignmentService,
    CaseService,
    DocumentService,
    InquiryService,
    IntakeService,
    ProgramService,
    ProgramCountryService,
    PatientService,
    AssignmentGroupService,
    WorkflowService,
    TeamService,
    CaseTeamService,
    TeamMemberService,
    StatusService,
    DiscussionEntryFactory,
    TaskFactory,
    AssignmentReferenceFactory,
    StatusFactory,
    DiscussionService,
    CountryService,
    ProductService,
    WorkflowReferenceFactory,
    WorkflowInstanceService,
} from "./program.services";
import {
    AppNotificationService,
    MessageService,
    TemplateService,
} from "./notification.services";
import { ShipmentService } from "./shipping.services";
import {
    DocusignAccountFactory,
    DocusignEnvelopeService,
    DocusignService,
    DocusignSignerFactory,
    DocusignTabFactory,
} from "./docusign.service";
import { TabChangeService } from "./component.services";

import {
    DataFieldService,
    DataFieldValueService,
    DataFormService,
    DataTypeService,
    DataFormFieldFactory,
    CompoundDataTypeFactory,
    DataFormFieldConditionService,
} from "./data.services";
import { AuthenticationService } from "./auth.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TeamMemberFactory } from "./models/team";

@NgModule({
    declarations: [],
    imports: [MatSnackBarModule],
    exports: [],
    providers: [
        SessionService,
        OrganizationService,
        AccountService,
        CountryService,
        ProgramService,
        ProgramCountryService,
        InquiryService,
        TemplateService,
        MessageService,
        CaseService,
        AuditService,
        DocumentService,
        ShipmentService,
        AssignmentService,
        DocusignService,
        DocusignEnvelopeService,
        IntakeService,
        PatientService,
        AssignmentGroupService,
        WorkflowService,
        DataFormService,
        DataFieldValueService,
        DataFieldService,
        DataTypeService,
        RoleService,
        TeamService,
        CaseTeamService,
        TeamMemberService,
        AdminService,
        StatusService,
        DataFormFieldConditionService,
        AuthenticationService,
        DataFormFieldFactory,
        DiscussionEntryFactory,
        OrganizationSettingsFactory,
        CompoundDataTypeFactory,
        TaskFactory,
        AssignmentReferenceFactory,
        AppNotificationService,
        TeamMemberFactory,
        StatusFactory,
        DiscussionService,
        AccountSettingsFactory,
        DocusignAccountFactory,
        DocusignTabFactory,
        ProductService,
        APIKeyService,
        TabChangeService,
        CapabilityService,
        ProductService,
        DocusignSignerFactory,
        WorkflowReferenceFactory,
        WorkflowInstanceService,
    ],
})
export class ServicesModule {}
