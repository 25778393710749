<div class="flex align-center" mat-dialog-title>
    <div *ngIf="!isWorkflowChoice">Append Workflow</div>
    <div *ngIf="isWorkflowChoice">Workflow Decision Required</div>
    <div class="spacer"></div>
</div>
<mat-dialog-content [formGroup]="formGroup">
    <div
        *ngIf="isWorkflowChoice && data.instructions"
        class="title-1 margin-top margin-bottom"
    >
        {{ data.instructions }}
    </div>
    <mat-form-field class="full">
        <mat-label>Workflow</mat-label>
        <mat-select formControlName="workflow">
            <mat-option *ngFor="let choice of workflowChoices" [value]="choice.value">
                {{ choice.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="full">
        <mat-label>Display Name (Optional)</mat-label>
        <input matInput formControlName="name" />
        <mat-hint>
            If not specified, the workflow name
            <b>
                <span *ngIf="isWorkflowChoice && formGroup.get('workflow')?.value">
                    {{ formGroup.get('workflow')?.value.displayName }}
                </span>
            </b>
            will be displayed as the workflow section header.
        </mat-hint>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="spacer"></div>
    <button
        mat-flat-button
        type="button"
        class="fixed"
        mat-dialog-close=""
        *ngIf="!loading"
    >
        Cancel
    </button>
    <button
        mat-flat-button
        type="button"
        class="fixed"
        color="accent"
        *ngIf="!loading"
        [disabled]="!formGroup.valid || loading"
        (click)="formGroup.valid && appendWorkflow($event)"
    >
        Append
    </button>
    <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
</mat-dialog-actions>
