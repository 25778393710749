<div class="wrapper flexible flex stack">
    @if (showAll) {
        <mat-accordion class="workflow-stack">
            <mat-expansion-panel
                [expanded]="step === i"
                (opened)="step = i"
                *ngFor="let instance of list.items; index as i"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div
                            [class.title-2]="!isSubworkflow(instance)"
                            [class.title-1]="isSubworkflow(instance)"
                            [class.workflow-indent]="isSubworkflow(instance)"
                        >
                            {{ instance.displayName }}
                        </div>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div class="flex flexible">
                            <div class="spacer"></div>

                            <button
                                mat-flat-button
                                class="brand-medium"
                                (click)="createTodo($event, instance)"
                                matTooltip="Add New Assignment"
                                *ngIf="!isCaseClosed && !isPhysicianStaff"
                                [disabled]="viewOnly"
                            >
                                <mat-icon>assignment</mat-icon>
                                Create To-Do
                            </button>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <assignment-group
                    [workflow]="instance"
                    [case]="case"
                    [notifications]="notifications"
                    [viewOnly]="viewOnly"
                ></assignment-group>
            </mat-expansion-panel>
        </mat-accordion>
    } @else {
        <div class="content-card">
            <task-table
                [notifications]="notifications"
                [viewOnly]="viewOnly"
                [case]="case"
                [filterMode]="filterMode"
            ></task-table>
        </div>
    }
</div>
