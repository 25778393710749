import { CaseTeam, TeamMember } from "./team";
import {
    APIObject,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
} from "./api-object";
import { DisplayNameMap } from "../../common/utilities/utilities";

import { Account } from "./account";
import { formatDate } from "@angular/common";
import { Status } from "./case";
import { Organization } from "./organization";
import { Program, ProgramCountry } from "./program";
import { Country } from "./country";
import { Product } from "./product";

export type DataField = {
    name: string;
    displayName?: string;
    value?: any;
    owner?: string;
    creator?: string;
    type?: string;
    order?: number;
};

export type RelatedProgram = {
    program: ObjectOrReference<Program>;
    program_countries: ObjectOrReference<ProgramCountry>[];
}

/*
Inquiry Object
    An Inquiry is the initial object representing the communication between a Doctor and an Organization regarding
    access to a specific drug.  Inquiries can be generated by submission of a web-form or through processing of an
    email sent to the system.  Inquiries are triaged by individuals at the owning Organization, during which they can be
    assigned to a Program (if that information is not part of the initial intake processing), and a decision can be made
    whether to convert the inquiry into a Case, which allows for additional features in the application.  Additionally,
    communication with the Inquiry sender to request additional data/information can proceed as well, or an inquiry can
    be marked as spam.

    Initial, unprocessed inquiries will be set to a status of 'new'.  If communication is sent back to the sender, it
    will be converted to a status of 'waiting'.  Terminal statuses are 'spam' (self-apparent), 'case' (when an inquiry is
    converted to a Case object), and 'archived' (to 'close' an inquiry without any further action)

    Any data or documents attached to an inquiry are assumed to be shared with both the Organization receiving
    the Inquiry and the sender.  This persists after the Inquiry is converted to a Case.
 */
export class Inquiry extends APIObject {
    static InquiryStatus: DisplayNameMap[] = [
        { value: "new", displayName: "New" },
        { value: "spam", displayName: "Closed - Spam" },
        { value: "case", displayName: "Case Created" },
        { value: "reject", displayName: "Closed - Rejected" },
        { value: "response", displayName: "Response Sent" },
        { value: "waiting", displayName: "Waiting for Information" },
        { value: "patient", displayName: "Closed - Patient Inquiry" },
        { value: "evaluating", displayName: "Evaluating Request" },
        { value: "enrolled", displayName: "Enrolled" },
        { value: "approved", displayName: "Patient Approved" },
        { value: "declined", displayName: "Patient Declined" },
        { value: "treating", displayName: "Receiving Treatment" },
        { value: "terminated", displayName: "Treatment Terminated" },
        { value: "complete", displayName: "Treatment Completed" },
        { value: "archived", displayName: "Archived" },
    ];
    static RejectionOptions: DisplayNameMap[] = [
        { value: "unavilable", displayName: "Program Not Available" },
        { value: "outofbounds", displayName: "Outside of Program Geography" },
        { value: "risk", displayName: "Risk to Patient Outweighs Potential Benefits" },
        {
            value: "patientexcluded",
            displayName: "Patient falls into exclusion criteria",
        },
        { value: "indication", displayName: "Indication" },
        { value: "noenoughdrug", displayName: "Not enough drug supply" },
        {
            value: "otheravailable",
            displayName: "Other approved treatment options available",
        },
        { value: "other", displayName: "Other" },
    ];

    static get OpenStatuses(): DisplayNameMap[] {
        const open = [
            "new",
            "case",
            "response",
            "waiting",
            "evaluating",
            "enrolled",
            "approved",
            "treating",
        ];
        return Inquiry.InquiryStatus.filter(
            (s: DisplayNameMap) => open.indexOf(s.value) != -1,
        );
    }
    static get ClosedStatuses(): DisplayNameMap[] {
        const closed = [
            "spam",
            "declined",
            "terminated",
            "comlete",
            "archived",
            "reject",
            "patient",
        ];
        return Inquiry.InquiryStatus.filter(
            (s: DisplayNameMap) => closed.indexOf(s.value) != -1,
        );
    }

    static object_type: string = "program.inquiry";
    organization?: ObjectOrReference<Organization>; // Organization - the organization to which the inquiry is sent
    program!: OptionalObjectOrReference<Program>; // Program - the program to which the inquiry has been assigned.  May be null if that information is not provided during intake.  Must be set before converting to a case
    sender?: string; // email address - the Doctor who sent the inquiry.  This field is required in any intake method
    data!: any; // an key-value object containing any data shared between the sender and the Organization
    received_at!: Date; // read-only - when the Inquiry was initially received
    status!: string; // status value - see above - current status of the inquiry
    case_status!: Status; // status value - see above - current status of the inquiry
    reference_identifier!: number; // read-only - a likely unique number assigned to each inquiry (inherited into a case)
    rejection_reason?: string; // reason for rejection
    rejection_status?: string;
    teams?: CaseTeam[]; // read-only - a list of teams and roles associated with this inquiry   TODO: ObjectOrReference
    country?: ObjectOrReference<Country>;
    product?: ObjectOrReference<Product>;
    country_valid!: boolean;
    product_valid!: boolean;
    program_country_valid!: boolean;
    related_programs?: RelatedProgram[]; // read-only - a list of Programs and their ProgramCountries associated with the inquiry based on the program or product and country (if exist).

    get owner(): OptionalObjectOrReference<Organization> {
        return this.organization;
    }

    get displayName(): string | undefined {
        let result = this.organization?.displayName ?? "Unspcified organization";
        if (this.program) result += " | " + this.program?.displayName;
        return result + " Inquiry";
    }

    get tabSubtitle(): string | undefined {
        return formatDate(this.received_at, "medium", "en-us");
    }

    initialize(data?: any, patch: boolean = false) {
        this._readOnly.push(...["received_at", "roles", "datePipe", "teams", "related_programs"]);
        this._references.push(...["organization", "program"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "organization", Organization);
        this.setMember(data, patch, "program", Program);
        this.setMember(data, patch, "sender");
        this.setMember(data, patch, "data");
        this.setMember(data, patch, "received_at");
        this.setMember(data, patch, "status");
        this.setMember(data, patch, "case_status", Status);
        this.setMember(data, patch, "rejection_reason");
        this.setMember(data, patch, "rejection_status");
        this.setMember(data, patch, "reference_identifier");
        this.setMember(data, patch, "teams", CaseTeam, true);
        this.setMember(data, patch, "country", Country);
        this.setMember(data, patch, "product", Product);
        this.setMember(data, patch, "country_valid");
        this.setMember(data, patch, "product_valid");
        this.setMember(data, patch, "program_country_valid");
        this.setMember(data, patch, "related_programs");
    }

    isMemberOfTeam(
        account: Account | ObjectReference | undefined,
        team?: string,
    ): TeamMember | undefined {
        return this.teamMembers(team).find(
            (tm: TeamMember) => tm.account.id === account?.id,
        );
    }
    teamMembers(team?: string, role?: string): TeamMember[] {
        return ([] as TeamMember[]).concat.apply(
            [],
            this.teams
                ?.filter((ct: CaseTeam) => !team || ct.capacity === team)
                .map((ct: CaseTeam) =>
                    ct.members.filter(
                        (tm: TeamMember) => !role || tm.role.includes(role),
                    ),
                ) ?? [],
        );
    }
    teamMember(team?: string, role?: string): TeamMember | undefined {
        const members = this.teamMembers(team, role);
        return members?.length ? members[0] : undefined;
    }
    team(capacity: string): CaseTeam | undefined {
        return this.teams?.find((ct: CaseTeam) => ct.capacity == capacity);
    }
    permissions(
        account: ObjectReference | Account | undefined,
        capacity?: string,
    ): string {
        let team = this.caseTeam(account);
        if (capacity)
            team = this.teams?.find((ct: CaseTeam) => ct.capacity == capacity);
        return team?.permissionForAccount(account) ?? "object.none";
    }
    isPharmaStaff(account?: ObjectReference | Account): boolean {
        return !!this.isMemberOfTeam(account, "pharma");
    }
    isPhysicianStaff(account?: ObjectReference | Account): boolean {
        return !!this.isMemberOfTeam(account, "provider");
    }
    caseTeam(account?: ObjectReference | Account): CaseTeam | undefined {
        if (this.isPharmaStaff(account))
            return this.teams?.find((ct: CaseTeam) => ct.capacity == "pharma");
        else if (this.isPhysicianStaff(account))
            return this.teams?.find((ct: CaseTeam) => ct.capacity == "provider");
        return undefined;
    }
    isAdmin(account?: ObjectReference | Account): boolean {
        return this.permissions(account) == "object.admin";
    }
    isEditor(account?: ObjectReference | Account): boolean {
        const permissions = this.permissions(account);
        const permitted = ["object.admin", "object.edit", "organization.manager"];
        return permitted.includes(permissions);
    }
    isViewer(account?: ObjectReference | Account): boolean {
        const permissions = this.permissions(account);
        const permitted = [
            "object.admin",
            "object.edit",
            "organization.manager",
            "object.view",
        ];
        return permitted.includes(permissions);
    }
    get physician(): TeamMember | undefined {
        return this.teamMember("provider", "physician");
    }
    get physicianName(): string {
        return this.physician?.displayName ?? "Physician not Specified";
    }
    get physicianInstitution(): string {
        return (
            this.teams?.find((ct: CaseTeam) => ct.capacity == "provider")?.team
                .organization.displayName ?? "Institution not Specified"
        );
    }
    get contacts(): TeamMember[] {
        return this.teamMembers().filter((tm: TeamMember) => !tm.private) || [];
    }

    get statusDisplay(): string {
        return Inquiry.StatusDisplay(this.case_status.name);
    }

    static StatusDisplay(status: string): string {
        const found = Inquiry.InquiryStatus.find(
            (map: DisplayNameMap) => map.value === status.toLowerCase(),
        );
        return found ? found.displayName : "Unknown";
    }
}
