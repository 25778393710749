import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import {
    CapabilityService,
    OrganizationService,
    RoleService,
} from "src/services/iam.services";
import {
    APIListResult,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
} from "src/services/models/api-object";
import { SearchableListComponent } from "./../../../common/components/searchable-list.component";
import { TeamMemberService, TeamService } from "./../../../services/program.services";
import {
    ChangeDetectorRef,
    Component,
    Input,
    Pipe,
    PipeTransform,
    inject,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Organization } from "../../../services/models/organization";
import { Team, TeamMember } from "src/services/models/team";
import { Sort } from "@angular/material/sort";
import { DerivedPermission, Role } from "src/services/models/role";
import {
    ObjectViewEntryPoint,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Account, AccountReference } from "src/services/models/account";
import { RequestFilter } from "src/common/utilities/request";
import { EMPTY, forkJoin, map, mergeMap, Observable, of, switchMap } from "rxjs";
import { MatDialogConfig } from "@angular/material/dialog";
import { Capability } from "src/services/models/capability";
import { AccountComponent } from "src/admin/components/account/account.component";
import { Program } from "src/services/models/program";

type OptionalOrganizationOrReference = Organization | ObjectReference | undefined;

type TeamOwner = Organization | Program | ObjectOrReference<Organization | Program>;
const MAX_PERMISSIONS_DISPLAYED = 3;
@Component({
    selector: "staff-list",
    templateUrl: "./staff.component.html",
    styleUrls: ["./staff.component.scss"],
})
export class StaffComponent extends SearchableListComponent<TeamMember> {
    protected teamService: TeamService;
    protected roleService: RoleService;
    protected capabilityService: CapabilityService;
    private _teamOwner?: TeamOwner;

    private expandedMembers = new Set<string>();

    toggleExpand(teamMember: ObjectOrReference<TeamMember>) {
        if (!teamMember?.id || !this.canExpand(teamMember)) return;

        const id = teamMember.id;

        if (this.expandedMembers.has(id)) {
            this.expandedMembers.delete(id);
        } else {
            this.expandedMembers.add(id);
        }
    }

    isMembersRowExpanded(teamMember: ObjectOrReference<TeamMember>) {
        if (!teamMember?.id) return false;

        return this.expandedMembers.has(teamMember.id);
    }

    canExpand(teamMember: ObjectOrReference<TeamMember>) {
        return (
            this.getPrograms(teamMember as TeamMember).length >
                MAX_PERMISSIONS_DISPLAYED ||
            this.getCountries(teamMember as TeamMember).length >
                MAX_PERMISSIONS_DISPLAYED
        );
    }

    @Input()
    get teamOwner(): TeamOwner | undefined {
        return this._teamOwner;
    }

    set teamOwner(value: TeamOwner | undefined) {
        this._teamOwner = value;
        if (!this.organization) {
            if (value instanceof Organization) {
                this.organization = value;
            } else if (value instanceof Program) {
                this.organization = value.organization;
            }
        }
        this.getOwnerTeam();
    }

    get ownerIsOrganization() {
        return this.teamOwner instanceof Organization;
    }
    get ownerIsProgram() {
        return this.teamOwner instanceof Program;
    }
    get displayedColumns(): string[] {
        return [
            "expand",
            "account-name",
            "role",
            "programs",
            "countries",
            "permission",
            "actions",
        ];
    }
    @Input()
    isHcpStaff: boolean | undefined = false;

    defaultCapabilites: Capability[] = [];
    customCapabilities: Capability[] = [];
    @Input() set team(v: Team | undefined) {
        if (v !== this.team_) {
            this.team_ = v;
            if (this.team_) this.organization_ = this.team_.organization;
            this.updateList();
        }
    }
    get team(): Team | undefined {
        return this.team_;
    }
    protected team_: Team | undefined;

    teams: Team[] = [];
    @Input() set organization(v: OptionalOrganizationOrReference) {
        if (v?.id !== this.organization_?.id) {
            this.organization_ = v;

            this.getOrganizationStaffTeam();
            this.getCustomCapabilities();
        }
    }
    getCustomCapabilities() {
        this.capabilityService
            .list({ org: this?.organization?.id ?? "-1" })
            .subscribe((response) => {
                this.customCapabilities = response as Capability[];
            });
    }
    displayNameForRole(role: string) {
        return Role.roles.find((r) => r.value === role)?.display;
    }
    get organization(): OptionalOrganizationOrReference {
        return this.organization_;
    }
    protected organization_!: OptionalOrganizationOrReference;
    organizationService: OrganizationService;
    constructor(
        protected service: TeamMemberService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "staff-list");
        this.teamService = inject(TeamService);
        this.roleService = inject(RoleService);
        this.capabilityService = inject(CapabilityService);
        this.organizationService = inject(OrganizationService);
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.getCapabilities();
    }
    onSortChange(event: Sort): void {
        if (event.direction) {
            let field = event.active;
            if (event.active === "account-name") field = "account__first_name";
            else if (event.active === "permission") field = "permission__role";
            else if (event.active === "email") field = "account__email";

            this.list.ordering = [
                { field: field, ascending: event.direction == "asc" },
            ];

            if (event.active == "account-name") {
                this.list.ordering.push({
                    field: "account__last_name",
                    ascending: event.direction == "asc",
                });
            }
        } else this.list.ordering = [];
    }

    isTeamMember(object: ObjectOrReference<TeamMember>): object is TeamMember {
        return (object as TeamMember).email !== undefined;
    }

    editObject(
        event: MouseEvent | undefined,
        object?: TeamMember,
        asDialog: boolean = false,
        viewOnly: boolean = false,
    ): ObjectViewEntryPoint<TeamMember> | undefined {
        this.terminateEvent(event);
        let mode = viewOnly ? ObjectViewMode.View : ObjectViewMode.Create;
        if (object?.id) mode = ObjectViewMode.Edit;
        const instance = ObjectAdminComponent.showObject<Account>(
            object?.account,
            AccountComponent,
            mode,
            asDialog ? this.objectDialogConfiguration(object, mode) : undefined,
        );
        if (asDialog && instance?.dialogReference)
            instance.dialogReference
                .afterClosed()
                .subscribe(() => this.list.refresh(true));

        (instance as AccountComponent).objectName =
            object?.id ? "Edit " + object?.account.displayName : "Add Staff";
        (instance as AccountComponent).teamMember = object;
        (instance as AccountComponent).organization = this.organization;
        (instance as AccountComponent).teamMembers = this.list.items as TeamMember[];
        (instance as AccountComponent).capabilities = [
            ...this.defaultCapabilites,
            ...this.customCapabilities,
        ];
        (instance as AccountComponent).teamOwner = this.teamOwner;
        (instance as AccountComponent).object = object?.account;
        (instance as AccountComponent).autosave = false;
        return instance as ObjectViewEntryPoint<TeamMember> | undefined;
    }
    createObject(
        event?: MouseEvent,
        asDialog: boolean = true,
    ): ObjectViewEntryPoint<TeamMember> | undefined {
        const permissionObject =
            this.ownerIsProgram ? this.teamOwner : this.organization;

        const newObject = ObjectFactory.makeObject<TeamMember>(
            {
                team: this.team?.asReference,
                role: "member",
                derived_permissions: [],
                permission: ObjectFactory.makeObject<Role>(
                    {
                        object: permissionObject,
                        role: "object.view",
                    },
                    Role.object_type,
                ) as Role,
                account: ObjectFactory.makeObject<Account>(
                    {},
                    Account.object_type,
                ) as Account,
                private: false,
            },
            TeamMember.object_type,
        ) as TeamMember;

        return this.editObject(event, newObject, asDialog);
    }
    deleteStaff(event: MouseEvent, member: TeamMember): void {
        this.terminateEvent(event);
        if (member?.account?.id === this.currentAccount?.id) return;

        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: `Are you sure you want to remove ${member.account.displayName}'?`,
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.service.destroy(member).subscribe(() => this.updateList());
                }
            });
    }

    canEditStaff(account: Account): boolean {
        return true;
    }

    protected objectDialogConfiguration(
        object: TeamMember | undefined,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            minWidth: "75vw",
        };
    }

    get canMigrate(): boolean {
        return (
            this.isSystemAdministrator &&
            !this.team &&
            !!this.organization &&
            this.ownerIsOrganization
        );
    }

    migrateStaffTeam(event: MouseEvent): void {
        if (!this.canMigrate) return;

        // get all roles for the organization
        this.roleService
            .list({ role_object: this.organization!.id! })
            .pipe(
                switchMap((roles_: APIListResult<Role>) => {
                    let roles = roles_ as Role[];
                    if (roles.length === 0) {
                        // Create a new role if no roles are found
                        const newRole = ObjectFactory.makeObject<Role>(
                            {
                                object: this.organization?.asReference!,
                                account: this.currentAccount?.asReference!,

                                private: false,
                                role: "object.admin", //only admins can use migrateTeamStaff
                            },
                            Role.object_type,
                        ) as Role;

                        return this.roleService
                            .create(newRole)
                            .pipe(map((role) => [role as Role]));
                    }
                    return of(roles);
                }),
            )
            .subscribe((roles_) => {
                const members = (roles_ as Role[]).map((r: Role) => {
                    let role = "member";
                    if (
                        r.role.includes("organization.administrator") ||
                        r.role.includes("object.admin")
                    )
                        role = "admin";
                    else if (r.role.includes("provider.physician")) role = "physician";
                    return ObjectFactory.makeObject<TeamMember>(
                        {
                            account: r.account?.asReference,
                            role: role,
                            permission: r,
                            private: r.private,
                        },
                        TeamMember.object_type,
                    );
                });
                // create the new team
                const team = ObjectFactory.makeObject<Team>(
                    {
                        organization: this.organization!.asReference,
                        team_type: "staff",
                        members: members,
                    },
                    Team.object_type,
                );
                const obs = this.teamService.create(team);
                obs.subscribe((team: Team | undefined) => {
                    this.team = team;
                    this.list.refresh(true);
                });
            });
    }
    protected getOwnerTeam() {
        let obs: Observable<Team[]> = of([]);
        if (this.ownerIsProgram) {
            const programTeam = this.teamService.list({
                program_owned: this.teamOwner!.id!,
            });
            const orgTeam = this.teamService.list({
                org: this.organization!.id!,
            });
            obs = forkJoin([programTeam, orgTeam]).pipe(
                map(([programTeam, orgTeam]) => {
                    return [
                        ...(programTeam as Team[]),
                        ...(orgTeam as Team[]),
                    ] as Team[];
                }),
            );
        } else if (this.organization?.id) {
            const filter: RequestFilter = {};
            filter["organization"] = this.organization.id;
            filter["type"] = "staff";
            let obs = this.teamService.list(filter);
        }

        obs.subscribe((teams) => {
            this.teams = teams;
            const mainTeam = teams.find(
                (t) =>
                    t.organization?.id === this.teamOwner?.id ||
                    t.owner?.id === this.teamOwner?.id,
            );
            this.team = mainTeam;
        });
    }
    protected getOrganizationStaffTeam(): void {
        if (this.organization) {
            this.teamService
                .list({ organization: this.organization?.id ?? "0", type: "staff" })
                .pipe(
                    mergeMap((teams: APIListResult<Team>) => {
                        let team = undefined;
                        if (teams instanceof Team) team = teams;
                        else if (teams instanceof ObjectReference) team = teams;
                        else if (Array.isArray(teams)) {
                            if (teams.length) team = teams[0];
                        } else if (teams.items.length) {
                            team = teams.items[0];
                        }
                        return ObjectFactory.objectObservable(team);
                    }),
                )
                .subscribe((team: Team | undefined) => {
                    this.team = team;
                });
        }
    }

    protected getCapabilities(): void {
        if (!this.organization) return;

        let $obs: Observable<Organization | undefined>;

        if (this.organization instanceof Organization) {
            $obs = of(this.organization);
        } else {
            $obs = this.organizationService.resolveReference(this.organization);
        }
        $obs.pipe(
            switchMap((resolvedOrg) => {
                const allowSystemRoles = !resolvedOrg?.hideSystemRoles;

                if (allowSystemRoles && resolvedOrg?.id) {
                    const orgId = resolvedOrg?.id || "0";
                    const capacity = !this.isHcpStaff ? "pharma" : "physician";
                    return this.capabilityService.list({ org: orgId, capacity });
                } else return EMPTY;
            }),
        ).subscribe((capabilities: APIListResult<Capability>) => {
            this.defaultCapabilites = capabilities as Capability[];
        });
    }

    protected getFullAccount(reference: AccountReference): Account {
        return new Account();
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        let ids = "0";
        if (this.teams.length) {
            ids = this.teams.map((t) => t.id).join(",");
        } else if (this?.team?.id) {
            ids = this.team.id;
        }

        filters["team"] = ids;
        return filters;
    }

    getPrograms(account: TeamMember): string[] {
        if (!account.derived_permissions) return [];

        const programs = account.derived_permissions
            .filter((perm: any) => perm.object_type === "program.program")
            .filter((perm: any) => perm.root_organization.id === this.organization?.id)
            .map((perm: any) => perm.object_display_name);
        return programs;
    }

    getCountries(account: TeamMember): string[] {
        if (!account.derived_permissions) return [];

        const countries = account.derived_permissions
            .filter((perm: any) => perm.object_type === "program.programcountry")
            .map((perm: any) => perm.object_display_name.split("|")[1].trim())
            .filter(
                (value: string, index: number, self: string[]) =>
                    self.indexOf(value) === index,
            ); // Remove duplicates

        return countries;
    }

    get inheritedTypes() {
        if (this.teamOwner instanceof Program) {
            return [Organization.object_type];
        }

        return [];
    }

    memberIsInherited(member: TeamMember) {
        if (member.team instanceof ObjectReference) {
            console.error("Team is a reference");
        } else if (member?.team instanceof Team) {
            if (member.team.owner) {
                return this.inheritedTypes.includes(member.team.owner.type);
            } else if (member.team.organization) {
                return this.inheritedTypes.includes(member.team.organization.type);
            }
        }

        return false;
    }

    inheritedMemberText(member: TeamMember) {
        if (member?.team instanceof Team) {
            if (member.team.owner && !member.team.organization) {
                return "(Inherited from " + member.team.owner.displayName + ")";
            } else if (member.team.organization) {
                return "(Inherited from " + member.team.organization.displayName + ")";
            }
        }

        return "";
    }
}

@Pipe({
    name: "formatListofDerivedData",
})
export class FormatListofDerivedDataPipe implements PipeTransform {
    transform(items: string[]): { displayedItems: string[]; remainingCount: number } {
        if (items.length > MAX_PERMISSIONS_DISPLAYED) {
            return {
                displayedItems: items.slice(0, MAX_PERMISSIONS_DISPLAYED),
                remainingCount: items.length - MAX_PERMISSIONS_DISPLAYED,
            };
        } else {
            return {
                displayedItems: items,
                remainingCount: 0,
            };
        }
    }
}
