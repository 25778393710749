import { DataFormSelectComponent } from "./data-form-select.component";
import { SearchableListComponent } from "./../searchable-list.component";
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    inject,
} from "@angular/core";
import { DataForm, DataFormReference } from "src/services/models/data";
import {
    AssignmentService,
    DocumentService,
    ProgramCountryService,
} from "src/services/program.services";
import { Sort } from "@angular/material/sort";
import { Case } from "src/services/models/case";
import { Inquiry } from "src/services/models/inquiry";
import { ObjectViewEntryPoint, ObjectViewMode } from "../object.component";
import { Program, ProgramCountry } from "src/services/models/program";
import {
    CASE_TAB_NAMES,
    TabChangeEvent,
    TabChangeService,
} from "src/services/component.services";
import { Assignment } from "src/services/models/assignment";
import { DataFormService } from "src/services/data.services";
import { DataFormComponent } from "./data-form.component";
import { RequestFilter, WebsocketObjectAction } from "src/common/utilities/request";
import {
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
} from "src/services/models/api-object";
import { MatDialogConfig } from "@angular/material/dialog";
import { AppNotification } from "src/services/models/appNotification";
import { AppNotificationService } from "src/services/notification.services";
import { catchError, filter, finalize, NEVER, Subscription } from "rxjs";
import { AccountService } from "src/services/iam.services";
import { Document } from "src/services/models/document";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
    selector: "data-form-list",
    templateUrl: "./data-form-list.component.html",
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormListComponent extends SearchableListComponent<DataForm> {
    objectView = DataFormComponent;
    listTitle = "data-form-list";
    @Input() owner?: Case | Inquiry;
    @Input() productOptions: ObjectOrReference<Program>[] = [];
    @Input() templateForms: DataForm[] = [];
    @Input() notifications: AppNotification[] = [];
    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

    editing?: DataForm;
    alias?: string;
    programCountries: ProgramCountry[] = [];

    get isOrganizationAdministrator(): boolean {
        const org =
            this.owner instanceof Case ?
                this.owner?.shared.organization
            :   this.owner?.organization;
        return !!this.currentAccount?.hasRole("object.admin", org);
    }

    get isOrganizationManager() {
        const org =
            this.owner instanceof Case ?
                this.owner?.shared.organization
            :   this.owner?.organization;
        return (
            !!this.currentAccount?.hasRole("object.manager", org) ||
            !!this.currentAccount?.hasRole("organization.manager", org)
        );
    }
    get loading(): boolean {
        return !this.list.hasLoaded;
    }

    get formOptions(): DataForm[] {
        return this.templateForms;
    }

    assignmentService: AssignmentService;
    appNotificationService: AppNotificationService;
    programCountryService: ProgramCountryService;
    private _formToOpen?: TabChangeEvent;

    @Input()
    get formToOpen(): TabChangeEvent | undefined {
        return this._formToOpen;
    }
    @Output() formToOpenUsed = new EventEmitter<void>();

    set formToOpen(value: TabChangeEvent | undefined) {
        this._formToOpen = value;
        if (value) {
            this.handleTabSub(value);
        }
    }

    tabChangeService: TabChangeService;
    constructor(
        protected service: DataFormService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "data-form-list");
        this.assignmentService = inject(AssignmentService);
        this.appNotificationService = inject(AppNotificationService);
        this.programCountryService = inject(ProgramCountryService);
        this.tabChangeService = inject(TabChangeService);
    }

    ngOnInit() {
        this.updateProgramCountries();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.setupExportEventsHandler();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.exportSubscription) {
            this.exportSubscription.unsubscribe();
            this.exportSubscription = undefined;
        }
    }

    protected updateProgramCountries(): void {
        if (this.owner?.country?.id && this.owner?.program?.id) {
            this.programCountryService
                .list({
                    country: this.owner?.country?.id,
                    program: this.owner?.program?.id,
                })
                .subscribe((countries) => {
                    this.programCountries = countries as ProgramCountry[];
                });
        } else {
            this.programCountries = [];
        }
    }

    handleTabSub(event: TabChangeEvent) {
        const formReference = event?.data?.references?.form;
        if (!formReference) return;

        const isValidFormType = (v: DataForm | DataFormReference | ObjectReference) =>
            v.type === DataForm.object_type;

        debugger;
        const form =
            isValidFormType(formReference) ? formReference
            : isValidFormType(formReference.reference) ? formReference.reference
            : undefined;

        if (!form) console.error("Invalid form reference: ", formReference);

        const viewOnly = !!event?.data?.viewOnly;

        const dataForm = this.editObject(undefined, form, true, viewOnly);
        dataForm?.dialogReference?.afterClosed().subscribe((v) => {
            if (this.formToOpen) {
                this.formToOpenUsed.emit();
            }

            const formGroup = (dataForm as DataFormComponent).formGroup;
            if (v && formGroup.valid && event?.data?.assignment?.pending) {
                this.completeTask(event?.data?.assignment);
                if (form?.attributes?.task_reopened) {
                    form.attributes.task_reopened = false;
                    this.service.update(form).subscribe();
                }
            }
        });
    }
    completeTask(assignment: Assignment) {
        if (assignment.completed) return;
        assignment.completed = new Date();
        assignment.completed_by = this.currentAccount?.asReference;
        this.assignmentService.update(assignment).subscribe(() => {
            this.tabChangeService.changeTab(CASE_TAB_NAMES.CHECK_LIST, {
                references: { assignment },
            });
        });
    }

    getTemplates() {
        const ownerIds = ["0"];
        if (this.owner?.owner?.id) ownerIds.push(this.owner.owner.id);
        if (this.owner?.program?.id) ownerIds.push(this.owner.program.id);
        const workflowId =
            this.owner instanceof Case ? this?.owner?.workflow_template?.id : undefined;
        const isAdmin = this.isOrganizationAdministrator || this.isOrganizationManager;
        ownerIds.push(...this.programCountries.map((c) => c.id as string));

        DataFormService.getTemplates(
            this.service,
            ownerIds,
            isAdmin,
            workflowId,
        ).subscribe((forms) => {
            this.templateForms = forms;
        });
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    get displayedColumns(): string[] {
        return ["form-name", "modified-at", "completed", "shared", "actions"];
    }

    protected objectDialogConfiguration(
        object: DataForm,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            minWidth: "75vw",
            maxHeight: "90vh",
            autoFocus: false,
        };
    }

    protected get ownerIds(): string {
        if (this.owner && this.owner.type == "program.case")
            return this.owner.id + "," + (this.owner as Case).shared.id;
        else if (this.owner && this.owner.type == "program.inquiry")
            return this.owner.id!;
        return "0";
    }
    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["owned"] = this.ownerIds;
        filters["version"] = "1";
        filters["is_template"] = "False";
        return filters;
    }

    canCreateForm(): boolean {
        return !!this.owner?.isPharmaStaff(this.currentAccount);
    }
    canEdit(form?: DataForm): boolean {
        let canEdit = this.owner?.isEditor(this.currentAccount) ?? false;
        if (
            !!form?.attributes?.intake &&
            this.owner instanceof Case &&
            !this.owner?.isPhysicianStaff(this.currentAccount)
        )
            canEdit = false; // MED-1146 Only physician staff can edit the intake after submission
        return canEdit && !this.editing;
    }
    canRename(form: DataForm): boolean {
        return this.canEdit(form);
    }
    canShare(form: DataForm): boolean {
        return (
            !form.isShared &&
            !!this.owner?.isPharmaStaff(this.currentAccount) &&
            this.canEdit(form)
        );
    }
    canUnshare(form: DataForm): boolean {
        return (
            form.isShared &&
            !!this.owner?.isPharmaStaff(this.currentAccount) &&
            this.canEdit(form)
        );
    }

    shareForm(form: DataForm): void {
        if (this.owner instanceof Case) {
            form.owner = this.owner.shared.asReference;
            this.service
                .update(form)
                .subscribe((f: DataForm | undefined) => form.update(f));
        }
    }
    unshareForm(form: DataForm): void {
        if (this.owner instanceof Case) {
            form.owner = this.owner.asReference;
            this.service
                .update(form)
                .subscribe((f: DataForm | undefined) => form.update(f));
        }
    }

    isRenamed(form: DataForm): boolean {
        return form.display_name != form.template?.displayName;
    }
    renameForm(form: DataForm): void {
        this.alias = form.display_name;
        this.editing = form;
    }
    cancelEditing(): void {
        this.alias = undefined;
        this.editing = undefined;
    }
    updateForm(form: DataForm): void {
        form.display_name = this.alias ?? form.display_name;
        this.service
            .update(form)
            .subscribe((updated: DataForm | undefined) => form.update(updated));
        this.alias = undefined;
        this.editing = undefined;
    }

    // override createObject so we can allow the user to select which form they want to instantiate
    createObject(
        event?: MouseEvent | undefined,
        asDialog?: boolean,
    ): ObjectViewEntryPoint<DataForm> | undefined {
        this.dialog
            .open(DataFormSelectComponent, {
                data: {
                    forms: this.formOptions,
                    canShare: this.owner instanceof Case,
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .subscribe((formDetails?: { form: DataForm; shared: boolean }) => {
                if (formDetails) {
                    const newForm = ObjectFactory.makeObject<DataForm>(
                        {
                            name: formDetails?.form.name,
                            is_template: false,
                            display_name: formDetails?.form.displayName,
                            description: formDetails?.form.description,
                            region: formDetails?.form.region,
                            template: formDetails?.form.asReference,
                            form_fields: formDetails.form.form_fields,
                            owner:
                                formDetails.shared ?
                                    (this.owner as Case)?.shared.asReference
                                :   this.owner?.asReference,
                            attributes: formDetails.form.attributes,
                        },
                        DataForm.object_type,
                    );
                    this.service.create(newForm).subscribe();
                }
            });
        return undefined;
    }

    viewObject(
        event: MouseEvent | undefined,
        object?: DataForm,
        asDialog?: boolean,
    ): ObjectViewEntryPoint<DataForm> | undefined {
        if (object && !this.isObject(object)) return;
        return this.editObject(event, object, asDialog, !this.canEdit(object));
    }
    editObject(
        event: MouseEvent | undefined,
        object?: DataForm,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<DataForm> | undefined {
        const instance = super.editObject(event, object, asDialog, viewOnly);
        (instance as DataFormComponent).productOptions = this.productOptions;
        if (object) {
            this.markNotificationAsRead(object);
        }
        return instance;
    }

    exportAsPdf(event: MouseEvent, form: DataForm) {
        this.terminateEvent(event);
        this.trigger?.closeMenu();
        let { display_name } = form;
        display_name = display_name.replaceAll(" ", "_");
        const reference =
            this.owner instanceof Case ?
                this.owner.shared.reference_identifier
            :   this.owner?.reference_identifier;
        const file_name = `${display_name}_Case_${reference}.pdf`;

        this.exporting = true;
        this.service.exportAsPdf(file_name, [form.id!]).subscribe();
        this.snackbar.open(
            "Your download may take a few minutes. You can find it in your downloads once completed.",
            undefined,
            { duration: 5000 },
        );
    }
    hasNotification(form: DataForm): boolean {
        const notification = this.getNotificationForForm(form);

        return !!notification;
    }
    matBadge(form: DataForm) {
        const a = this.hasNotification(form);
        if (!a) return undefined;
        return "!";
    }
    getNotificationForForm(form?: DataForm) {
        return this?.notifications?.find((n) => n.object.id === form?.id);
    }

    markNotificationAsRead(form: DataForm) {
        const notification = this.getNotificationForForm(form);
        if (!notification) return;
        this.notifications = this.notifications.filter((n) => n.id !== notification.id);
        this.appNotificationService.clear(notification).subscribe();
    }

    protected override postSearch(list: DataForm[]): DataForm[] {
        if (!this.templateForms.length && this.canCreateForm()) {
            this.getTemplates();
        }

        return list;
    }

    exporting = false;
    exportSubscription?: Subscription;
    accountService = inject(AccountService);
    documentService = inject(DocumentService);
    setupExportEventsHandler() {
        this.exportSubscription = this.accountService.objectEvent
            .pipe(
                filter(
                    (msg) =>
                        msg.object?.id === this.currentAccount?.id &&
                        msg.action === WebsocketObjectAction.EXPORT &&
                        this.exporting,
                ),
            )
            .subscribe((msg) => {
                const document = msg.data.file as Document;
                this.session
                    .$download(document.file, document.name, {
                        delete: "True",
                    })
                    .pipe(
                        catchError((err) => {
                            console.error(err);
                            return NEVER;
                        }),
                        finalize(() => {
                            this.exporting = false;
                            this.documentService.destroy(document).subscribe();
                        }),
                    )
                    .subscribe();
            });
    }
}
