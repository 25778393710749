<div class="list-header flex">
    <div class="title">Audit</div>
    <div class="spacer"></div>
    <div class="header-actions">
        <button
            [disabled]="!auditTrail.length"
            mat-flat-button
            class="brand-medium"
            (click)="toggleFilter($event)"
            matTooltip="Filter Audit Trail"
        >
            <mat-icon>filter_list</mat-icon>
            Filter
        </button>
    </div>
</div>
<div class="audit-filter content-card" *ngIf="showFilter" [formGroup]="filterFormGroup">
    <h3>Filter</h3>
    <div class="filters-container">
        <div class="filter-card">
            <mat-form-field color="primary">
                <mat-icon matPrefix class="search-icon">filter_list</mat-icon>
                <mat-label>Object Type</mat-label>
                <mat-select
                    placeholder="Object Type"
                    multiple
                    formControlName="filterObjectType"
                >
                    <mat-option
                        *ngFor="let objectType of objectTypes"
                        [disabled]="!objectType.count"
                        [value]="objectType.value"
                    >
                        {{ objectType.displayName || "Unspecified Type" }}
                        <span class="option-count secondary">
                            {{ objectType.count }}
                        </span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="filter-card">
            <mat-form-field color="primary">
                <mat-icon matPrefix class="search-icon">filter_list</mat-icon>
                <mat-label>Event</mat-label>
                <mat-select placeholder="Event" multiple formControlName="filterEvent">
                    <mat-option
                        *ngFor="let event of events"
                        [disabled]="!event.count"
                        [value]="event.value"
                    >
                        {{ event.displayName || "Event Not Specfied" }}
                        <span class="option-count secondary">{{ event.count }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="filter-card">
            <mat-form-field color="primary">
                <mat-icon matPrefix class="search-icon">filter_list</mat-icon>
                <mat-label>User</mat-label>
                <mat-select placeholder="User" multiple formControlName="filterUser">
                    <mat-option
                        *ngFor="let user of users"
                        [disabled]="!user.count"
                        [value]="user.value"
                        [class.italic]="!user.value"
                    >
                        {{ user.displayName || "MedaSystems" }}
                        <span class="option-count secondary">{{ user.count }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="filter-card">
            <mat-form-field color="primary">
                <mat-icon matPrefix class="search-icon">filter_list</mat-icon>
                <mat-label>Timestamp</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input
                        matStartDate
                        placeholder="Start Date"
                        formControlName="filterStartDate"
                    />
                    <input
                        matEndDate
                        placeholder="End Date"
                        formControlName="filterEndDate"
                    />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="filter-buttons-container">
        <button
            mat-flat-button
            color="primary"
            matTooltip="Close Filters"
            (click)="toggleFilter($event)"
        >
            Close Filters
        </button>
        <button
            mat-flat-button
            color="accent"
            matTooltip="Clear All"
            (click)="clearFilters($event)"
        >
            Clear All
        </button>
    </div>
</div>
<div class="audit-table">
    <mat-table [dataSource]="auditTrail" matSort (matSortChange)="onSortChange($event)">
        <mg-container matColumnDef="timestamp">
            <mat-header-cell *matHeaderCellDef>Timestamp</mat-header-cell>
            <mat-cell
                *matCellDef="let entry"
                class="pointer"
                [class.audit-top]="entry.expanded"
                (click)="showAuditDetail(entry)"
            >
                <div class="flex stack">
                    <div>{{ entry.timestamp | localizedDate }}</div>
                    <div class="secondary small">
                        {{ entry.timestamp | date: "mediumTime" }}
                    </div>
                </div>
            </mat-cell>
        </mg-container>
        <ng-container matColumnDef="account">
            <mat-header-cell *matHeaderCellDef>Account</mat-header-cell>
            <mat-cell
                *matCellDef="let entry"
                class="pointer"
                [class.audit-top]="entry.expanded"
                (click)="showAuditDetail(entry)"
            >
                <span *ngIf="entry.account">{{ entry.account.displayName }}</span>
                <span *ngIf="!entry.account" class="italic">MedaSystems</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reference_type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell
                *matCellDef="let entry"
                class="pointer"
                [class.audit-top]="entry.expanded"
                (click)="showAuditDetail(entry)"
            >
                <div class="flex stack">
                    <div>{{ entryType(entry) }}</div>
                    <div class="secondary small" *ngIf="entry.reference?.displayName">
                        {{ entry.reference.displayName }}
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="event">
            <mat-header-cell *matHeaderCellDef>Event</mat-header-cell>
            <mat-cell
                *matCellDef="let entry"
                class="pointer"
                class="flex stack center"
                [class.audit-top]="entry.expanded"
                (click)="showAuditDetail(entry)"
            >
                <div class="flex full center">
                    <div class="spacer">{{ entryEvent(entry) }}</div>
                    <button
                        mat-icon-button
                        *ngIf="hasGroupDetails(entry)"
                        (click)="entry.expanded = !entry.expanded"
                    >
                        <mat-icon>
                            {{ entry.expanded ? "expand_less" : "expand_more" }}
                        </mat-icon>
                    </button>
                </div>
                <ng-container *ngIf="entry.expanded">
                    <ng-container *ngFor="let detail of groupDetails(entry)">
                        <div
                            *ngFor="let event of groupEvent(entry, detail)"
                            class="flex full group-indent"
                        >
                            {{ event }}
                        </div>
                    </ng-container>
                </ng-container>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
        (page)="handlePageEvent($event)"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="perPage"
        [length]="paginatorLength"
    ></mat-paginator>
</div>
