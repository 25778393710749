<div
    class="wrapper flex stack full"
    *ngIf="mode == ObjectViewMode.Create && !docusignSenderView"
>
    <object-form [controller]="controller" [formGroup]="formGroup" [hideHeader]="false">
        <div class="wrapper flex stack">
            <div class="row flex">
                <ng-container formGroupName="task">
                    <mat-form-field class="flexible margin-right">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="task_type">
                            <mat-option
                                *ngFor="let type of taskTypes"
                                [value]="type?.task_type"
                                (click)="onTaskTypeSelect()"
                            >
                                {{ type.display_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <mat-form-field
                    class="flexible"
                    *ngIf="!isGroupTask && this.parentAssignments?.length !== 1"
                >
                    <mat-label>Workflow Group</mat-label>
                    <mat-select formControlName="parent">
                        <mat-option
                            *ngFor="let assignment of parentAssignments"
                            [value]="assignment.id"
                            (click)="onWorkflowSelect()"
                        >
                            {{ assignment.task.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="hint-row flex">
                <mat-hint *ngIf="taskHint">{{ taskHint }}</mat-hint>
            </div>

            <div class="row flex" *ngIf="isDocusignTask">
                <mat-form-field class="flexible">
                    <mat-label>
                        DocuSign
                        <sup>&reg;</sup>
                        Account
                    </mat-label>
                    <mat-select
                        [formControl]="docusignAccount"
                        [(value)]="selectedAccount"
                    >
                        <mat-option
                            *ngIf="!availableDocusignAccounts.length"
                            [value]="null"
                        >
                            No available DocuSign
                            <sup>&reg;</sup>
                            accounts.
                        </mat-option>

                        <mat-option
                            *ngFor="let account of availableDocusignAccounts"
                            [value]="account"
                        >
                            <span>{{ account.displayName }}</span>
                            <span
                                *ngIf="account.token?.requires_authorization"
                                class="red"
                            >
                                <sup>*</sup>
                            </span>
                        </mat-option>
                    </mat-select>

                    <mat-hint
                        *ngIf="docusignAccount.value?.token?.requires_authorization"
                        class="red"
                    >
                        * For security purposes, you must re-authenticate this DocuSign
                        <sup>&reg;</sup>
                        account.
                    </mat-hint>
                </mat-form-field>
                <button
                    mat-icon-button
                    color="primary"
                    (click)="linkDocusignAccount($event)"
                    matTooltip="Link DocuSign&reg; Account"
                >
                    <mat-icon>link</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="showDataForms">
                <mat-form-field class="flexible">
                    <mat-label>Form</mat-label>
                    <mat-select
                        [formControl]="dataFormControl"
                        class="tst-create-task-type"
                    >
                        <mat-option *ngFor="let form of dataForms" [value]="form.id">
                            {{ form.displayName }}
                            <span *ngIf="dataFormVersion(form)" class="secondary small">
                                version {{ dataFormVersion(form) }}
                            </span>
                        </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="taskHint">{{ taskHint }}</mat-hint>
                    <mat-progress-spinner
                        *ngIf="!dataForms"
                        matSuffix
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                </mat-form-field>
            </ng-container>
            <ng-container formGroupName="task">
                <mat-form-field class="flexible">
                    <mat-label>Name of {{ isGroupTask ? "Group" : "To-Do" }}</mat-label>
                    <input
                        matInput
                        formControlName="name"
                        class="tst-task-create-name"
                    />
                </mat-form-field>
                <mat-form-field class="flexible">
                    <mat-label>
                        {{ isGroupTask ? "Instructions" : "Description" }}
                    </mat-label>
                    <textarea
                        matInput
                        formControlName="description"
                        [cdkTextareaAutosize]="true"
                    ></textarea>
                </mat-form-field>
            </ng-container>

            <mat-form-field class="column" *ngIf="!isGroupTask">
                <mat-label *ngIf="isDocusignTask">Assignee</mat-label>
                <mat-label *ngIf="!isDocusignTask">Assignee</mat-label>
                <mat-select formControlName="member">
                    <mat-select-trigger>
                        <div>
                            {{ formGroup.controls.member.value?.account.displayName }}
                        </div>
                        <div class="small">
                            {{ memberContext(formGroup.controls.member.value) }}
                        </div>
                    </mat-select-trigger>
                    <mat-option
                        *ngFor="let member of availableAssignees"
                        [value]="member"
                    >
                        {{ member.account.displayName }}
                        <span class="small option-spacer">
                            {{ memberContext(member) }}
                        </span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="!isGroupTask && !isDocusignTask">
                <div id="task-frequency-section">
                    <h3>Task Frequency</h3>
                    <mat-radio-group
                        aria-label="Select an option"
                        color="primary"
                        [formControl]="frequencyTypeControl"
                        (change)="setFrequencyInputs($event.value, true)"
                    >
                        <mat-radio-button value="one_time">One Time</mat-radio-button>
                        <mat-radio-button value="recurring">Recurring</mat-radio-button>
                    </mat-radio-group>
                    <div
                        *ngIf="frequencyTypeValue == 'recurring'"
                        class="frequency-container"
                    >
                        <h4 class="frequency-section-header">Repeat Task every</h4>
                        <div class="frequency-row">
                            <mat-form-field class="frequency-amount">
                                <mat-label>Frequency Amount</mat-label>
                                <input
                                    matInput
                                    type="number"
                                    min="1"
                                    formControlName="frequency_amount"
                                />
                            </mat-form-field>
                            <mat-form-field class="frequency-unit">
                                <mat-label>Frequency Unit</mat-label>
                                <mat-select
                                    [value]="defaultTimeUnit"
                                    formControlName="frequency_unit"
                                >
                                    <mat-option
                                        *ngFor="let unit of timeUnits"
                                        [value]="unit"
                                    >
                                        @if (frequencyAmountControl?.value > 1) {
                                            {{ unit }}s
                                        } @else {
                                            {{ unit }}
                                        }
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="frequency-row">
                            <h4 class="frequency-section-header">End Repeat</h4>
                            <mat-radio-group
                                aria-label="Select an option"
                                color="primary"
                                [formControl]="frequencyEndTypeControl"
                                (change)="setFrequencyEndTypeInputs($event.value, true)"
                            >
                                <mat-radio-button value="repeat_forever">
                                    Repeat Forever
                                </mat-radio-button>
                                <mat-radio-button value="end_repeat_date">
                                    End Repeat Date
                                </mat-radio-button>
                            </mat-radio-group>
                            <mat-form-field
                                class="frequency-end-repeat-date"
                                *ngIf="frequencyEndTypeValue == 'end_repeat_date'"
                            >
                                <mat-label>End Repeat Date</mat-label>
                                <input
                                    matInput
                                    placeholder="Choose a Date"
                                    [matDatepicker]="frequencyEndDatePicker"
                                    formControlName="frequency_end_date"
                                    [min]="minDateForDueDate"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="frequencyEndDatePicker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker
                                    #frequencyEndDatePicker
                                ></mat-datepicker>
                                <mat-error>
                                    Please enter a valid date in the future.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <h3>Task Triggers</h3>
                    <mat-radio-group
                        [formControl]="triggerControl"
                        aria-label="Select an option"
                        class="column"
                        color="primary"
                        (change)="setTriggerInputs($event.value, true)"
                    >
                        <mat-radio-button selected value="none">None</mat-radio-button>
                        <mat-radio-button value="due_date">Date Based</mat-radio-button>
                        <mat-radio-button value="dependencies">
                            Task Based
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <mat-form-field class="flexible" *ngIf="triggerValue === 'dependencies'">
                <mat-label>Dependencies</mat-label>
                <mat-select formControlName="dependencies" multiple>
                    <mat-option
                        *ngIf="!otherAssignments.length"
                        [value]="null"
                        [disabled]="true"
                    >
                        No available tasks.
                    </mat-option>
                    <mat-option
                        *ngFor="let assignment of otherAssignments"
                        [value]="assignment"
                    >
                        {{ taskDisplayName(assignment) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                class="column"
                *ngIf="!isGroupTask && triggerValue === 'due_date'"
            >
                <mat-label>Date</mat-label>
                <input
                    matInput
                    placeholder="Choose a Date"
                    [matDatepicker]="picker"
                    formControlName="due_date"
                    [min]="minDateForDueDate"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error>Please enter a valid date in the future.</mat-error>
            </mat-form-field>
        </div>
    </object-form>
</div>
<ng-container *ngIf="mode != ObjectViewMode.Create && !docusignSenderView">
    <form [formGroup]="formGroup" class="wrapper flex stack flexible full" novalidate>
        <h3 mat-dialog-title *ngIf="formGroup.controls.task">
            <div class="">
                {{ asFormGroup(formGroup.controls.task).controls.name.value }}
            </div>
            <div class="spacer"></div>
            <div class="">
                <span
                    class="secondary small"
                    *ngIf="fullObject?.task?.taskType == 'generic'"
                >
                    Generic Task
                </span>
                <span class="secondary small" *ngIf="isDocusignTask">
                    DocuSign
                    <sup>&reg;</sup>
                    Task
                </span>
            </div>
        </h3>
        <mat-dialog-content class="mat-dialog-content">
            <div class="row flex flexible">
                <div class="column flex flexible" *ngIf="mode == ObjectViewMode.View">
                    <div class="info field-header">Assigner:</div>
                    <div [class.secondary]="!fullObject?.assigned_by">
                        {{
                            fullObject?.assigned_by?.displayName ||
                                "Automatically Assigned"
                        }}
                    </div>
                </div>
                <div class="column flex flexible" *ngIf="!canEdit">
                    <div class="info field-header">Assignee:</div>
                    <div class="flexible" *ngIf="fullObject?.assignee">
                        {{ fullObject?.assignee?.displayName }}
                    </div>
                    <div class="secondary flexible" *ngIf="!fullObject?.assignee">
                        Not Yet Assigned
                    </div>
                </div>
                <mat-form-field class="column flexible" *ngIf="canEdit">
                    <mat-label *ngIf="isDocusignTask">Assignee</mat-label>
                    <mat-label *ngIf="!isDocusignTask">Assignee (optional)</mat-label>
                    <mat-select formControlName="member" [compareWith]="compareObjects">
                        <mat-option
                            *ngFor="let account of availableAssignees"
                            [value]="account"
                        >
                            {{ account.displayName }}
                        </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="isDocusignTask">
                        Assignees can be edited in the DocuSign&reg; envelope interface.
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="column" *ngIf="canEdit && !isGroupTask">
                    <mat-label>Date (optional)</mat-label>
                    <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="due_date"
                        [min]="minDateForDueDate"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>Please enter a valid date in the future.</mat-error>
                </mat-form-field>
            </div>
            <div class="wrapper full" *ngIf="isDocusignTask && canEdit">
                <div class="row flex">
                    <mat-form-field class="flexible">
                        <mat-label>
                            DocuSign
                            <sup>&reg;</sup>
                            Account
                        </mat-label>
                        <mat-select [formControl]="docusignAccount">
                            <mat-option
                                *ngIf="!availableDocusignAccounts.length"
                                [value]="null"
                            >
                                No available DocuSign
                                <sup>&reg;</sup>
                                accounts.
                            </mat-option>
                            <mat-option
                                *ngFor="let account of availableDocusignAccounts"
                                [value]="account"
                            >
                                {{ account.displayName }}
                                <span
                                    *ngIf="account.token?.requires_authorization"
                                    class="red"
                                >
                                    <sup>*</sup>
                                </span>
                            </mat-option>
                        </mat-select>
                        <mat-hint
                            *ngIf="docusignAccount.value?.token?.requires_authorization"
                            class="red"
                        >
                            * For security purposes, you must re-authenticate this
                            DocuSign
                            <sup>&reg;</sup>
                            account.
                        </mat-hint>
                    </mat-form-field>
                    <button
                        mat-icon-button
                        color="primary"
                        (click)="linkDocusignAccount($event)"
                        matTooltip="Link DocuSign&reg; Account"
                    >
                        <mat-icon>link</mat-icon>
                    </button>
                </div>
            </div>
            <mat-form-field class="full" *ngIf="canEdit" formGroupName="task">
                <mat-label>Task Name</mat-label>
                <input matInput formControlName="name" />
            </mat-form-field>
            <mat-form-field class="full" *ngIf="canEdit" formGroupName="task">
                <mat-label>Description (optional)</mat-label>
                <textarea
                    matInput
                    formControlName="description"
                    [cdkTextareaAutosize]="true"
                ></textarea>
            </mat-form-field>
            <div class="row flex full" *ngIf="!canEdit">
                <div class="info field-header">Description:</div>
                <div class="row flex flexible stack detail-stack">
                    <div class="flexible wrap" *ngIf="fullObject?.taskDescription">
                        {{ fullObject?.taskDescription }}
                    </div>
                    <div
                        class="flexible secondary"
                        *ngIf="
                            !fullObject?.taskDescription &&
                            (!isDocusignTask || !isAssignee)
                        "
                    >
                        No Description provided
                    </div>
                    <div
                        class="flexible wrap"
                        *ngIf="!fullObject?.completed && isAssignee && isDocusignTask"
                    >
                        Please check the email associated with this account for a
                        message from DocuSign&reg; in order to complete this task.
                    </div>
                </div>
            </div>

            <mat-form-field class="full" *ngIf="canEdit">
                <mat-label>Dependencies (optional)</mat-label>
                <mat-select
                    formControlName="dependencies"
                    [compareWith]="compareObjects"
                    multiple
                >
                    <mat-option
                        *ngIf="!availableDependencies.length"
                        [value]="null"
                        [disabled]="true"
                    >
                        No available assignments.
                    </mat-option>
                    <mat-option
                        *ngFor="let assignment of availableDependencies"
                        [value]="assignment"
                    >
                        {{ taskDisplayName(assignment) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="row flex full" *ngIf="!canEdit && dependencies?.length">
                <div class="info field-header">Dependencies:</div>
                <div class="row flex flexible stack detail-stack">
                    <div class="flexible" *ngFor="let dependency of dependencies">
                        {{ dependency.displayName }}
                    </div>
                </div>
            </div>
            <div class="row flex full" *ngIf="dependantTasks.length">
                <div class="info field-header">Dependant Tasks:</div>
                <div class="row flex flexible stack detail-stack">
                    <div class="flexible" *ngFor="let dependant of dependantTasks">
                        {{ dependant.displayName }}
                    </div>
                </div>
            </div>
            <div class="row flex full">
                <div class="info field-header">Completed:</div>
                <div class="flexible" *ngIf="fullObject?.completed">
                    {{ fullObject?.completed | localizedDate }} by
                    {{ fullObject?.completed_by?.displayName }}
                </div>
                <div class="flexible secondary" *ngIf="!fullObject?.completed">
                    Not Completed
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions
            class="mat-dialog-actions"
            *ngIf="controller.dialogReference"
        >
            <button
                type="button"
                mat-flat-button
                color="warn"
                (click)="deleteTask($event)"
                [disabled]="!!docusignSenderView"
                matTooltip="Delete Task"
                *ngIf="canEdit && !isDocusignTask"
            >
                <mat-icon>delete</mat-icon>
                Delete
            </button>
            <button
                type="button"
                mat-flat-button
                color="primary"
                [disabled]="!!docusignSenderView"
                (click)="completeTask($event)"
                matTooltip="Complete Task"
                *ngIf="canEdit && !isDocusignTask"
            >
                Mark Completed
            </button>
            <button
                type="button"
                mat-flat-button
                color="primary"
                [disabled]="!!docusignSenderView"
                (click)="delegateTask($event)"
                matTooltip="Reassign Task"
                *ngIf="canEdit && !isDocusignTask"
            >
                Reassign
            </button>
            <div class="spacer"></div>
            <button
                type="button"
                class="fixed"
                mat-flat-button
                mat-dialog-close
                [disabled]="!!docusignSenderView"
                *ngIf="canEdit"
            >
                Cancel
            </button>
            <button
                type="button"
                class="fixed"
                color="accent"
                [disabled]="!!docusignSenderView"
                mat-flat-button
                *ngIf="!canEdit"
                mat-dialog-close
            >
                Close
            </button>
            <button
                type="button"
                *ngIf="canEdit && isDialog && hasChanged"
                [disabled]="!!docusignSenderView"
                mat-flat-button
                (click)="canEdit && hasChanged && isDialog && controller!.onRevert()"
            >
                Undo
            </button>
            <button
                type="button"
                color="primary"
                *ngIf="canEdit && isDialog && isDocusignTask && envelope"
                mat-flat-button
                [disabled]="!!docusignSenderView"
                (click)="
                    canEdit &&
                        isDialog &&
                        isDocusignTask &&
                        envelope &&
                        editDocusignEnvelope(envelope)
                "
            >
                Edit Envelope
            </button>
            <button
                type="button"
                mat-flat-button
                color="accent"
                [disabled]="!hasChanged || !isValid || !!docusignSenderView"
                *ngIf="canEdit"
                (click)="canEdit && hasChanged && isValid && controller.onSave()"
            >
                {{ controller.formButtonText }}
            </button>
        </mat-dialog-actions>
    </form>
</ng-container>
<ng-container *ngIf="!!docusignSenderView">
    <h3 mat-dialog-title *ngIf="formGroup.controls.task">
        <div class="">
            {{ asFormGroup(formGroup.controls.task).controls.name.value }}
        </div>
        <div class="spacer"></div>
        <div class="">
            <span class="secondary small">DocuSign&reg; Task</span>
        </div>
        <button mat-icon-button mat-dialog-close matTooltip="Cancel">
            <mat-icon>close</mat-icon>
        </button>
    </h3>
    <iframe
        title="DocuSign&reg; Envelope"
        *ngIf="!!docusignSenderView"
        [src]="docusignSenderView | safeUrl"
        class="docusign-iframe"
    ></iframe>
</ng-container>
