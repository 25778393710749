<div class="container">
    <div class="flex">
        <div class="title" [id]="list.listTitle">Programs</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-checkbox [formControl]="showDeleted">Show Deleted</mat-checkbox>

            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search margin-right"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Programs</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Programs"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
                [disabled]="!isOrganizationAdministrator"
            >
                <mat-icon>open_in_new</mat-icon>
                Create Program
            </button>
        </div>
    </div>

    <div *ngIf="list.hasLoaded" class="card-grid">
        <mat-card *ngFor="let program of programs" class="example-card card-row">
            <div class="card-column">
                <div class="card-row">
                    <div class="card-column card-header" matColumnDef="display_name">
                        Name
                    </div>
                    <div class="card-column card-header" matColumnDef="deleted">
                        Type
                    </div>
                    <div class="card-column card-header" matColumnDef="deleted">
                        Status
                    </div>
                    <div class="card-column card-header" matColumnDef="created_at">
                        Created On
                    </div>
                    <div class="card-column card-header" matColumnDef="modified_at">
                        Modified At
                    </div>
                </div>
                <div class="card-row">
                    <div
                        class="card-column card-text-content"
                        matColumnDef="display_name"
                    >
                        {{ program.displayName }}
                    </div>
                    <div
                        class="card-column card-text-content"
                        matColumnDef="program_type"
                    >
                        {{ program.program_type }}
                    </div>
                    <div class="card-column card-text-content" matColumnDef="status">
                        <mat-chip
                            *ngIf="program.status === 'Active' && !program.deleted"
                            color="accent"
                            selected
                        >
                            Active
                        </mat-chip>
                        <mat-chip
                            *ngIf="program.status === 'Pending' && !program.deleted"
                            color="primary"
                        >
                            Pending
                        </mat-chip>
                        <mat-chip
                            *ngIf="program.status === 'Closed' || program.deleted"
                            color="warn"
                        >
                            Closed
                        </mat-chip>
                    </div>
                    <div
                        class="card-column card-text-content"
                        matColumnDef="created_at"
                    >
                        {{ program.created_at | localizedDate }}
                    </div>
                    <div
                        class="card-column card-text-content"
                        matColumnDef="modified_at"
                    >
                        {{ program.modified_at | localizedDate }}
                    </div>
                </div>
            </div>
            <div class="card-column card-button">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ program: program }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #overflow="matMenu">
                    <ng-template matMenuContent let-program="program">
                        <button
                            mat-menu-item
                            (click)="editObject($event, program, true)"
                            [disabled]="!canEdit(program)"
                        >
                            Edit
                        </button>

                        <button
                            *ngIf="!program.deleted"
                            mat-menu-item
                            (click)="disableObject($event, program)"
                            [disabled]="!isOrganizationAdministrator"
                        >
                            Delete
                        </button>
                        <button
                            *ngIf="program.deleted"
                            mat-menu-item
                            (click)="reopenProgram($event, program)"
                            [disabled]="!isOrganizationAdministrator"
                        >
                            Un-delete
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </mat-card>
    </div>
    <div *ngIf="list.loadingProgress" class="error not-found text-center">
        <span *ngIf="list.searching && !list.hasLoaded">
            <mat-progress-spinner
                diameter="20"
                class="inline"
                mode="indeterminate"
            ></mat-progress-spinner>
            Loading...
        </span>
        <span *ngIf="list.initialized && !list.list.items.length">
            No Products found.
        </span>
    </div>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
