import { Component, Input } from "@angular/core";
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from "@angular/forms";
import { DataForm, DataType } from "src/services/models/data";
import { ObjectViewMode } from "../object.component";
import { OptionalObjectOrReference } from "src/services/models/api-object";
import { DocumentRepository } from "src/services/models/document";

@Component({
    selector: "data-type",
    template: `
        <dt-text-short
            *ngIf="!hide && !isCompound && displayType == 'text.short' && !multiple"
            [class.flexible]="inline"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-text-short>
        <dt-text-long
            *ngIf="!hide && !isCompound && displayType == 'text.long' && !multiple"
            [class.flexible]="inline"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-text-long>
        <dt-lookup
            *ngIf="!hide && !isCompound && displayType == 'lookup'"
            [class.flexible]="inline"
            [options]="options"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [multiple]="multiple"
            [compoundIndex]="compoundIndex"
        ></dt-lookup>
        <dt-program
            *ngIf="!hide && !isCompound && displayType == 'program' && !multiple"
            [class.flexible]="inline"
            [productOptions]="options"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-program>
        <dt-date
            *ngIf="!hide && !isCompound && displayType == 'date' && !multiple"
            [class.flexible]="inline"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-date>
        <dt-checkbox
            *ngIf="!hide && !isCompound && displayType == 'checkbox' && !multiple"
            [class.flexible]="inline"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-checkbox>
        <dt-document
            *ngIf="isDocumentType"
            [repository]="documentRepository!"
            [class.flexible]="inline"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [multiple]="multiple"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-document>
        <dt-radio
            *ngIf="!hide && !isCompound && displayType == 'radio' && !multiple"
            [class.flexible]="inline"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-radio>
        <dt-toggle
            *ngIf="!hide && !isCompound && displayType == 'toggle' && !multiple"
            [class.flexible]="inline"
            [type]="type"
            [mode]="mode"
            [label]="label"
            [control]="formControl"
            [attributes]="attributes"
            [required]="required"
            [compoundIndex]="compoundIndex"
        ></dt-toggle>

        <quill-view-html
            *ngIf="!hide && !isCompound && displayType == 'instructions' && !multiple"
            [content]="instructions || label!"
        ></quill-view-html>
        <dt-compound
            *ngIf="!hide && isCompound && !multiple"
            class="flex flexible wrap"
            [class.stack]="!inline"
            [form]="form"
            [class.form-inline]="inline"
            [mode]="mode"
            [fields]="type?.compound || []"
            [formGroup]="formGroup"
            [attributes]="attributes"
            [required]="required"
            [inline]="inline"
        ></dt-compound>
        <dt-multiple
            *ngIf="isMultipleType"
            [form]="form"
            [type]="type"
            [attributes]="attributes"
            [label]="label"
            [type]="type"
            [mode]="mode"
            [control]="control"
        ></dt-multiple>
    `,
    styleUrls: ["./data-form.component.scss"],
})
export class DataTypeComponent {
    @Input() type?: DataType;
    @Input() mode: ObjectViewMode = ObjectViewMode.View;
    @Input() control?: AbstractControl;
    @Input() attributes: any = {};
    @Input() required: boolean = false;
    @Input() multiple: boolean = false;
    @Input() options: any[] = [];
    @Input() compoundIndex: number = 0;
    @Input() form?: DataForm;
    @Input() label?: string;
    @Input() inline: boolean = false;

    get isDocumentType(): boolean {
        return !this.hide && this.displayType == "document";
    }

    get documentRepository(): OptionalObjectOrReference<DocumentRepository> {
        return this.form?.owner;
    }

    get isMultipleType() {
        return (
            !this.hide &&
            this.multiple &&
            this.displayType != "lookup" &&
            this.displayType !== "document"
        );
    }

    get displayType(): string {
        return this.type?.displayType(this.attributes) ?? "text.short";
    }

    get instructions(): string {
        return this.attributes?.instructions;
    }
    get isCompound(): boolean {
        return this.type?.isCompound ?? false;
    }
    get formGroup(): UntypedFormGroup {
        return this.control as UntypedFormGroup;
    }
    get formControl(): UntypedFormControl {
        return this.control as UntypedFormControl;
    }
    get hide(): boolean {
        return !this.canEdit && !this.control?.value && !this.required;
    }
    get canEdit(): boolean {
        return this.mode != ObjectViewMode.View;
    }
    get values(): AbstractControl[] {
        return (this.control as UntypedFormArray).controls;
    }
}
