import { AfterViewInit, Component, inject, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged, finalize } from "rxjs";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { InstantErrorStateMatcher } from "src/common/utilities/validators";
import { DataFormService } from "src/services/data.services";
import { ObjectOrReference } from "src/services/models/api-object";
import { DataForm } from "src/services/models/data";
import { Organization } from "src/services/models/organization";
import { Product } from "src/services/models/product";
import { Program } from "src/services/models/program";
import { ProductService, ProgramService } from "src/services/program.services";

@Component({
    selector: "program-details",
    templateUrl: "./program-details.component.html",
    styleUrl: "./program-details.component.scss",
})
export class ProgramDetailsComponent
    extends ObjectComponent<Program>
    implements AfterViewInit
{
    objectName = "Add Program";
    validatingName = false;
    constructor(protected service: ProgramService) {
        super(service);
        this.productService = inject(ProductService);
        this.dataFormService = inject(DataFormService);

        if (this.object?.id) {
            this.objectName = `Edit Program ${this.fullObject?.displayName}`;
        }
    }

    dataFormService: DataFormService;
    productService: ProductService;
    availableForms: DataForm[] = [];

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        let owners: string = "";

        if (this.organization?.id) {
            owners += this.organization.id;
        }

        if (this.fullObject?.id) {
            owners += (owners ? "," : "") + this.fullObject.id;
        }

        if (!owners) {
            owners = "0";
        }

        this.dataFormService
            .list({
                next: "0",
                is_template: "True",
                owned: owners,
                use_reference: "True",
            })
            .subscribe((forms) => {
                this.availableForms = forms as DataForm[];
            });
    }
    get today() {
        return new Date();
    }
    availableStatuses = Program.avaialbleStatuses;
    availableProgramTypes = Program.avaialbleProgramTypes;

    private _organization?: Organization;

    @Input()
    get organization(): Organization | undefined {
        return this._organization;
    }

    set organization(value: Organization | undefined) {
        this._organization = value;
        if (!value?.id) return;

        this.productService
            .list({ owner: value.id, published: "True" })
            .subscribe((products) => {
                this.availableProducts = products as Product[];
            });

        if (value) {
            this.validateProgramName(value);
        }
    }

    validateProgramName(organization: ObjectOrReference<Organization>) {
        this.formGroup
            ?.get("name")
            ?.valueChanges.pipe(debounceTime(750), distinctUntilChanged())
            .subscribe((v) => {
                this.validatingName = true;
                this.service
                    .validateProgramName(v, organization)
                    .pipe(finalize(() => (this.validatingName = false)))
                    .subscribe((nameExists) => {
                        const nameControl = this.formGroup?.get("name");
                        if (nameControl) {
                            if (nameExists) {
                                nameControl.setErrors({ nameExists });
                            } else {
                                nameControl.setErrors(null);
                            }
                        }
                    });
            });
    }
    errorMatcher = new InstantErrorStateMatcher();
    get programNameExists() {
        return this.formGroup?.get("name")?.hasError("nameExists");
    }
    private _availableProducts: Product[] = [];

    get availableProducts() {
        return this._availableProducts;
    }

    set availableProducts(value: Product[]) {
        this._availableProducts = value;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            name: [null, Validators.required],
            products: [null, Validators.required],
            program_type: [null, Validators.required],
            status: [null, Validators.required],
            open_date: [null],
            intake_form: [null],
        });
    }

    protected precommitTransform(v: any) {
        const program = super.precommitTransform(v);
        if (!program.organization) {
            program.organization = this.organization;
        }

        return program;
    }
}
