<div class="wrapper absolute dashboard" cdkScrollable>
    <div
        class="content-card wrapper open-inquiries"
        *ngIf="dashboardRole == 'pharma' || programs.length > 1"
    >
        <paginated-patients
            mode="recent"
            title="Pending Inquiries"
            [enablePagination]="true"
        ></paginated-patients>
    </div>
    <div class="content-card pending-tasks">
        <todos></todos>
    </div>
    <div class="top-graphs flex" *ngIf="canSeeCharts">
        <div class="content-card left-graph">
            <div
                echarts
                [options]="dailyInquiryOptions"
                class="echart"
                *ngIf="dailyInquiryOptions"
            ></div>
            <div class="filters">
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By Date:</mat-label>
                    <mat-select
                        [value]="dropDownFilterOptions[filtersState.inquriyCount.index]"
                        (selectionChange)="onFilterChange($event.value, 'inquriyCount')"
                    >
                        <mat-option
                            *ngFor="let option of dropDownFilterOptions"
                            [value]="option"
                        >
                            {{ option.displayName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By Product:</mat-label>
                    <mat-select
                        (selectionChange)="
                            onFilterChange(
                                null,
                                'inquriyCount',
                                $event.value.id,
                                'program.product'
                            )
                        "
                    >
                        <mat-option *ngFor="let product of products" [value]="product">
                            {{ product.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="content-card right-graph">
            <div
                echarts
                [options]="institutionListOptions"
                class="echart"
                *ngIf="institutionListOptions"
            ></div>
            <div class="filters">
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By:</mat-label>
                    <mat-select
                        [value]="
                            dropDownFilterOptions[filtersState.inquiryInstituions.index]
                        "
                        (selectionChange)="
                            onFilterChange($event.value, 'inquiryInstituions')
                        "
                    >
                        <mat-option
                            *ngFor="let option of dropDownFilterOptions"
                            [value]="option"
                        >
                            {{ option.displayName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By Product:</mat-label>
                    <mat-select
                        (selectionChange)="
                            onFilterChange(
                                null,
                                'inquiryInstituions',
                                $event.value.id,
                                'program.product'
                            )
                        "
                    >
                        <mat-option *ngFor="let product of products" [value]="product">
                            {{ product.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="top-graphs flex" *ngIf="canSeeCharts">
        <div class="content-card left-graph">
            <div
                echarts
                [options]="inquiryStatusOptions"
                class="echart"
                *ngIf="inquiryStatusOptions"
            ></div>
            <div class="filters">
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By:</mat-label>
                    <mat-select
                        [value]="
                            dropDownFilterOptions[filtersState.inquiryStatus.index]
                        "
                        (selectionChange)="
                            onFilterChange($event.value, 'inquiryStatus')
                        "
                    >
                        <mat-option
                            *ngFor="let option of dropDownFilterOptions"
                            [value]="option"
                        >
                            {{ option.displayName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By Product:</mat-label>
                    <mat-select
                        (selectionChange)="
                            onFilterChange(
                                null,
                                'inquiryStatus',
                                $event.value.id,
                                'program.product'
                            )
                        "
                    >
                        <mat-option *ngFor="let product of products" [value]="product">
                            {{ product.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="content-card right-graph">
            <div
                echarts
                [options]="caseStatusOptions"
                class="echart"
                *ngIf="caseStatusOptions"
            ></div>
            <div class="filters">
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By:</mat-label>
                    <mat-select
                        [value]="dropDownFilterOptions[filtersState.caseStatus.index]"
                        (selectionChange)="onFilterChange($event.value, 'caseStatus')"
                    >
                        <mat-option
                            *ngFor="let option of dropDownFilterOptions"
                            [value]="option"
                        >
                            {{ option.displayName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By Program:</mat-label>
                    <mat-select
                        (selectionChange)="
                            onFilterChange(
                                null,
                                'caseStatus',
                                $event.value.id,
                                'program.program'
                            )
                        "
                    >
                        <mat-option *ngFor="let program of programs" [value]="program">
                            {{ program.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="top-graphs flex" *ngIf="canSeeCharts">
        <div class="content-card full-graph">
            <div
                echarts
                [options]="productCountryOptions"
                class="echart world"
                *ngIf="productCountryOptions"
            ></div>
            <div class="filters">
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By:</mat-label>
                    <mat-select
                        [value]="
                            dropDownFilterOptions[filtersState.productCountry.index]
                        "
                        (selectionChange)="
                            onFilterChange($event.value, 'productCountry')
                        "
                    >
                        <mat-option
                            *ngFor="let option of dropDownFilterOptions"
                            [value]="option"
                        >
                            {{ option.displayName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="dropdown">
                    <mat-label>Filter By Product:</mat-label>
                    <mat-select
                        (selectionChange)="
                            onFilterChange(
                                null,
                                'productCountry',
                                $event.value.id,
                                'program.product'
                            )
                        "
                    >
                        <mat-option *ngFor="let product of products" [value]="product">
                            {{ product.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
