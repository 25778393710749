import { DocusignEnvelopeService } from "./../../../services/docusign.service";
import { ChangeDetectorRef, Component, Input, ViewChild, inject } from "@angular/core";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import {
    ObjectViewEntryPoint,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Assignment, AssignmentReference } from "src/services/models/assignment";
import { Case } from "src/services/models/case";
import {
    AssignmentReferenceFactory,
    AssignmentService,
    TaskFactory,
} from "src/services/program.services";
import { AssignmentComponent } from "./assignment.component";
import { MatStepper } from "@angular/material/stepper";
import { RequestFilter } from "src/common/utilities/request";
import { ObjectFactory, ObjectOrReference } from "src/services/models/api-object";
import { AppNotification } from "src/services/models/appNotification";
import { MatDialogConfig } from "@angular/material/dialog";
import { WorkflowInstance } from "src/services/models/workflow";

export interface AssignmentParent extends Assignment {
    assignments: Assignment[];
}

@Component({
    selector: "assignment-group",
    templateUrl: "./assignment-group.component.html",
    styleUrls: ["./assignment.component.scss"],
})
export class AssignmentGroupComponent extends ObjectAdminComponent<Assignment> {
    objectView = AssignmentComponent;
    firstUncompletedTask?: Assignment;
    removedSummaryTask: { [key: string]: Assignment } = {};
    docusignEnvelopeService: DocusignEnvelopeService;

    @ViewChild("checklistStepper") stepper?: MatStepper;
    @Input() viewOnly = false;
    @Input() case?: ObjectOrReference<Case>;
    @Input() notifications: AppNotification[] = [];
    @Input() workflow?: ObjectOrReference<WorkflowInstance>;

    get assignments(): AssignmentParent[] {
        return this.list.items as AssignmentParent[];
    }

    protected _selectedGroup: number = 0;

    get selected(): number {
        return this._selectedGroup;
    }
    constructor(
        protected service: AssignmentService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, -1, "assignment-group");
        this.docusignEnvelopeService = inject(DocusignEnvelopeService);
        inject(TaskFactory);
        inject(AssignmentReferenceFactory);
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["workflow"] = this.workflow?.id ?? this.case?.id ?? "0";
        filters["parent"] = "null";
        filters["ordering"] = "order";
        return filters;
    }

    findFirstUncompletedTask(assignments: Assignment[]): Assignment | undefined {
        for (const assignment of assignments) {
            if (!assignment.completed) {
                return assignment;
            }
        }
        return undefined;
    }
    summarizeAssignments(assignments: Assignment[]): Assignment[] {
        const _case = this.case instanceof Case ? this.case : undefined;
        const summarize = _case?.isMemberOfTeam(this.currentAccount, "provider");
        const removedIds: string[] = [];
        assignments
            .filter((a: Assignment) => a.task.taskType == "summary")
            .forEach((summary: Assignment) => {
                if (summarize) {
                    summary.references
                        ?.filter(
                            (ref: AssignmentReference) => ref.item_type == "summary",
                        )
                        .forEach((ref: AssignmentReference) => {
                            if (ref.reference?.id !== undefined)
                                removedIds.push(ref.reference.id);
                        });
                } else if (summary.id !== undefined) {
                    removedIds.push(summary.id);
                    if (summary.parent?.id) {
                        this.removedSummaryTask[summary.parent.id] = summary;
                    }
                }
            });
        const summarized = assignments.filter(
            (a: Assignment) => removedIds.indexOf(a.id ?? "0") == -1,
        );
        return summarized;
    }

    protected override postSearch(items: Assignment[]): Assignment[] {
        items = super.postSearch(items);
        items = this.summarizeAssignments(items);
        this.firstUncompletedTask = this.findFirstUncompletedTask(items);
        return items;
    }

    protected sortAssignments(
        assignments: Assignment[],
        summarize: boolean = false,
    ): Assignment[] {
        const groups: { [key: string]: AssignmentParent } = {};

        // check for legacy assignment lists - ie no parents
        const noParent = assignments.filter((a: Assignment) => !a?.parent);

        if (noParent.length === assignments.length) {
            groups["legacy"] = ObjectFactory.makeObject<Assignment>(
                {
                    id: "legacy",
                    task: {
                        name: "Legacy Workflow",
                    },
                },
                Assignment.object_type,
            ) as AssignmentParent;
            if (groups["legacy"]) groups["legacy"].assignments = noParent;
        } else {
            assignments
                .filter((a: Assignment) => !a.parent)
                .forEach((a) => {
                    groups[a.id!] = a as AssignmentParent;
                    groups[a.id!].assignments = [];
                });
            assignments
                .filter((a: Assignment) => !!a.parent)
                .forEach((a) => {
                    groups[a.parent!.id!]?.assignments.push(a);
                });
        }

        Object.values(groups).forEach((a: AssignmentParent) => {
            a.assignments.sort((a: Assignment, b: Assignment) => a.order - b.order);
        });

        return Object.values(groups).sort((a, b) => a.order - b.order);
    }

    editObject(
        event: MouseEvent,
        object: Assignment,
        asDialog: boolean = false,
        viewOnly: boolean = false,
    ): ObjectViewEntryPoint<Assignment> | undefined {
        const instance = super.editObject(
            event,
            object,
            asDialog,
            viewOnly,
        ) as AssignmentComponent;
        instance.case = this.case as Case;
        instance.parentAssignments = this.assignments;
        instance.workflowInstance = this.workflow as WorkflowInstance;
        return instance;
    }

    protected objectDialogConfiguration(
        object: Assignment | undefined,
        mode: ObjectViewMode,
    ): MatDialogConfig {
        const config = super.objectDialogConfiguration(object, mode);
        config.width = "75vw";
        config.height = "60vh";
        return config;
    }

    protected onAfterCreate(
        o: Assignment,
        instance: ObjectViewEntryPoint<Assignment>,
    ): void {
        super.onAfterCreate(o, instance);
        // if we created a docusign envelope and sent on create, we need to update the envelope from the server
        // so that we get the correct status
        const docusignEnvelope = o?.references?.find(
            (ref: AssignmentReference) => ref.item_type == "docusign.envelope",
        )?.reference;
        if (docusignEnvelope?.id) {
            this.docusignEnvelopeService
                .retrieve(docusignEnvelope.id, undefined, true)
                .subscribe();
        }
    }
}
