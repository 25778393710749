<div
    mat-dialog-title
    class="flex"
    cdkDrag
    cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-global-overlay-wrapper"
>
    <div class="title-1">{{ displayName }} Properties</div>
    <div class="spacer"></div>
    <div class="secondary small">{{ dataType.displayName }}</div>
</div>
<div mat-dialog-content class="form-field-properties flex" [formGroup]="formGroup">
    <div class="wrapper flex stack flexible">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="General">
                <div class="wrapper flex stack property-tab">
                    <mat-form-field class="full" *ngIf="hasPrompt">
                        <mat-label *ngIf="!isControlField">Prompt</mat-label>
                        <mat-label *ngIf="isControlField">Group Name</mat-label>
                        <input matInput formControlName="prompt" />
                    </mat-form-field>
                    <div class="row flex">
                        <mat-form-field class="column" *ngIf="hasLabel">
                            <mat-label>Label</mat-label>
                            <input matInput formControlName="label" />
                        </mat-form-field>
                        <mat-form-field class="column" *ngIf="hasPlaceholder">
                            <mat-label>Placeholder</mat-label>
                            <input matInput formControlName="placeholder" />
                        </mat-form-field>
                    </div>
                    <!--The mouse down is necessary, otherwise cdk drag will capture the click event, preventing the focus of the rte -->
                    <mat-form-field
                        *ngIf="hasInstructions"
                        class="full"
                        (mousedown)="$event.stopPropagation()"
                    >
                        <mat-label>{{ instructionsLabel }}</mat-label>
                        <rich-text-editor
                            matInput
                            [bindings]="bindings"
                            [customToolbar]="toolBar"
                            formControlName="instructions"
                            [placeholder]="instructionsLabel"
                        ></rich-text-editor>
                    </mat-form-field>

                    <div class="property-toggles flex stack" *ngIf="!isControlField">
                        <mat-slide-toggle
                            [checked]="required"
                            (toggleChange)="toggleRequired()"
                        >
                            This field is required for the form to be considered
                            complete.
                        </mat-slide-toggle>
                        <mat-slide-toggle
                            [checked]="multiple"
                            (toggleChange)="toggleMultiple()"
                        >
                            This field can have multiple values.
                        </mat-slide-toggle>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Layout">
                <div class="wrapper flex stack property-tab">
                    <div class="text-center" *ngIf="!hasLayoutProperties">
                        This data type does not have any layout properties.
                    </div>
                    <mat-slide-toggle
                        *ngIf="isCompound"
                        class="slide-toggle-padding"
                        [checked]="isInline"
                        (toggleChange)="toggleInline()"
                    >
                        Display compound properties inline.
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        *ngIf="hasPrompt"
                        class="slide-toggle-padding"
                        [checked]="isPromptHidden"
                        (toggleChange)="toggleHidePrompt()"
                    >
                        Hide prompt
                    </mat-slide-toggle>
                    <mat-form-field class="full" *ngIf="hasWidth">
                        <mat-label>Width</mat-label>
                        <mat-select
                            formControlName="width"
                            [compareWith]="compareObjects"
                        >
                            <mat-option
                                *ngFor="let option of widthOptions"
                                [value]="option.value"
                            >
                                {{ option.display_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="isLongText">
                        <mat-slide-toggle
                            [checked]="autoresize"
                            (toggleChange)="toggleAutoresize()"
                        >
                            Autoresize the text entry area
                        </mat-slide-toggle>
                        <div class="row flex">
                            <mat-form-field class="column">
                                <mat-label>Minimum Lines</mat-label>
                                <input matInput formControlName="minLines" />
                            </mat-form-field>
                            <mat-form-field class="column">
                                <mat-label>Maximum Lines</mat-label>
                                <input matInput formControlName="maxLines" />
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </mat-tab>
            <mat-tab label="Advanced">
                <div class="wrapper flex stack property-tab">
                    <div class="text-center" *ngIf="!hasAdvancedProperties">
                        This data type does not have any advanced properties.
                    </div>
                    <div *ngIf="isShortText">
                        <mat-form-field class="full">
                            <mat-label>Validation Pattern</mat-label>
                            <input matInput formControlName="pattern" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="isNumber">
                        <mat-form-field class="full">
                            <mat-label>Validation Type</mat-label>
                            <mat-select formControlName="numberValidationType">
                                <mat-option value="">None</mat-option>
                                <mat-option value="min">Minimum Value</mat-option>
                                <mat-option value="max">Maximum Value</mat-option>
                                <mat-option value="range">Range</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div
                            *ngIf="numberValidationTypeValue !== ''"
                            class="flex stack"
                        >
                            <mat-form-field *ngIf="renderMinField" class="flexbile">
                                <mat-label>Minimum Value Allowed</mat-label>
                                <input matInput formControlName="min" type="number" />
                            </mat-form-field>
                            <mat-form-field *ngIf="renderMaxField" class="flexbile">
                                <mat-label>Maximum Value Allowed</mat-label>
                                <input matInput formControlName="max" type="number" />
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-form-field class="full" *ngIf="isLongText">
                        <mat-label>Character Limit</mat-label>
                        <input matInput formControlName="maxLength" />
                    </mat-form-field>
                    <mat-form-field class="full" *ngIf="hasMultipleLabel">
                        <mat-label>Multiple Value Column Header</mat-label>
                        <input matInput formControlName="multipleLabel" />
                    </mat-form-field>
                    <mat-form-field class="full" *ngIf="hasSingular">
                        <mat-label>Singular</mat-label>
                        <input matInput formControlName="singular" />
                    </mat-form-field>
                    <ng-container *ngIf="hasAlternateControl">
                        <p class="title-1">Alternate Input Control</p>
                        <mat-checkbox
                            class="slide-toggle-padding"
                            formControlName="alternateCheckbox"
                            (change)="toggleAlternateCheckbox($event)"
                        >
                            Checkbox
                        </mat-checkbox>
                        <mat-form-field class="full" *ngIf="isAlternateCheckbox">
                            <mat-label>Checkbox Instructions</mat-label>
                            <input matInput formControlName="alternateCheckboxText" />
                            <mat-hint>
                                For rich-text, clear this field and use the instructions
                                field in the General tab.
                            </mat-hint>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="isLookup">
                        <mat-slide-toggle
                            [checked]="specifyOptions"
                            (toggleChange)="toggleSpecifyOptions()"
                        >
                            Specify which lookup values are allowed
                        </mat-slide-toggle>
                        <mat-form-field class="full">
                            <mat-select
                                placeholder="Select allowed values"
                                formControlName="allowedOptions"
                                [multiple]="true"
                            >
                                <mat-option
                                    *ngFor="let option of options"
                                    [value]="option.value"
                                >
                                    {{ option.display_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button
                            mat-flat-button
                            type="button"
                            (click)="specifyOptions && selectAllOptions($event)"
                            [disabled]="!specifyOptions"
                        >
                            <mat-icon>done</mat-icon>
                            Allow All
                        </button>
                    </ng-container>
                </div>
            </mat-tab>
            <mat-tab label="Condition" *ngIf="availableDependents?.length">
                <div
                    class="wrapper flex stack property-tab"
                    [formGroup]="conditionFormGroup"
                >
                    <div class="text-center" *ngIf="!formField.conditions?.length">
                        No Condition Defined
                    </div>
                    <mat-form-field>
                        <mat-label>Dependent Field</mat-label>
                        <mat-select
                            formControlName="dependent_field"
                            [compareWith]="compareFn"
                        >
                            <mat-option
                                *ngFor="let field of availableDependents"
                                [value]="field"
                            >
                                {{ field.displayName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Condition Type</mat-label>
                        <mat-select formControlName="condition_type">
                            <mat-option value="equals">Equals</mat-option>
                            <mat-option value="not_equals">Not equals</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <data-type
                        label="Expected Value"
                        *ngIf="dependentFieldControl.value"
                        [options]="expectedValueOptions"
                        [attributes]="formField.attributes"
                        [mode]="expectedValueViewMode"
                        [multiple]="false"
                        [type]="expectedValueDataType"
                        [control]="expectedValueControl"
                    ></data-type>
                    <button
                        (click)="removeCondtion($event)"
                        *ngIf="formField.conditions?.length"
                        mat-raised-button
                        color="warn"
                    >
                        <mat-icon matSuffix>delete</mat-icon>
                        Remove
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Fields" *ngIf="isCompound">
                <div class="wrapper flex stack property-tab">
                    <mat-form-field class="full">
                        <mat-select formControlName="compoundField">
                            <mat-option
                                *ngFor="let compound of compoundFields"
                                [value]="compound"
                            >
                                {{ compoundLabel(compound) }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container [formGroup]="compoundFormGroup">
                        <mat-form-field
                            class="full"
                            *ngIf="compoundHasLabel(selectedCompoundField)"
                        >
                            <mat-label>Label</mat-label>
                            <input matInput formControlName="label" />
                        </mat-form-field>
                        <mat-form-field
                            class="full"
                            *ngIf="compoundHasPlaceholder(selectedCompoundField)"
                        >
                            <mat-label>Placeholder</mat-label>
                            <input matInput formControlName="placeholder" />
                        </mat-form-field>
                        <mat-form-field
                            class="full"
                            *ngIf="compoundHasWidth(selectedCompoundField)"
                        >
                            <mat-label>Width</mat-label>
                            <mat-select
                                formControlName="width"
                                [compareWith]="compareObjects"
                            >
                                <mat-option
                                    *ngFor="let option of widthOptions"
                                    [value]="option.value"
                                >
                                    {{ option.display_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <div class="row flex">
                        <div class="spacer"></div>
                        <button
                            mat-flat-button
                            color="accent"
                            type="button"
                            (click)="showCompoundProperties(selectedCompoundField)"
                        >
                            More Properties
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div mat-dialog-actions>
    <div class="spacer"></div>
    <button
        mat-flat-button
        type="button"
        color="primary"
        [mat-dialog-close]="formGroup.dirty"
    >
        Dismiss
    </button>
</div>
