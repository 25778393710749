<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div class="flex stack" style="padding-top: 1rem">
        <mat-form-field class="flexible">
            <mat-label>Product Name</mat-label>
            <input matInput formControlName="display_name" placeholder="Product Name" />
        </mat-form-field>
    </div>
</object-form>
