import { EnvironmentService } from "src/app/environment.service";
import { Injectable, inject } from "@angular/core";
import { APIService } from "./api.service";
import {
    DocusignAccount,
    DocusignEnvelope,
    DocusignToken,
    DocusignTab,
    DocusignSigner,
} from "./models/docusign";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ObjectFactory, ObjectOrReference } from "./models/api-object";
import { HttpErrorResponse } from "@angular/common/http";
import { Assignment } from "./models/assignment";

@Injectable()
export class DocusignService extends APIService<DocusignToken> {
    environment: EnvironmentService;

    constructor() {
        super(DocusignToken, ["docusign", "token"]);
        this.environment = inject(EnvironmentService);
    }

    find(state: string): Observable<DocusignToken | undefined> {
        return this.action<DocusignToken>("find", { state: state }).pipe(
            map((r: any) => this.makeObject(r)),
            mergeMap((o: ObjectOrReference<DocusignToken>) =>
                ObjectFactory.objectObservable(o),
            ),
        );
    }

    override create(
        object: ObjectOrReference<DocusignToken>,
        withFormData: boolean = false,
    ): Observable<DocusignToken | undefined> {
        const endpoint = this.endpoint;
        const data = withFormData ? object.formData() : object.serialize(this.nested);
        const options = withFormData ? this.formDataOptions : undefined;
        return this.mutateObject<DocusignToken>(
            endpoint,
            undefined,
            "post",
            data,
            options,
            false,
        ).pipe(
            map((o: any) => this.makeObject(o, true)),
            mergeMap((o: ObjectOrReference<DocusignToken>) =>
                ObjectFactory.objectObservable(o),
            ),
            catchError((err: HttpErrorResponse) => this.handleError(err)),
        );
    }

    get isDocusignEnabled(): boolean {
        return this.environment.docusignClientId != "";
    }
}

@Injectable()
export class DocusignEnvelopeService extends APIService<DocusignEnvelope> {
    constructor() {
        super(DocusignEnvelope, ["docusign", "envelope"]);
    }

    getSenderView(envelope: ObjectOrReference<DocusignEnvelope>): Observable<string> {
        return this.request([this.endpoint, envelope.id, "sender_view"].join("/")).pipe(
            map((res: any) => res as string),
        );
    }

    checkToken(envelope: ObjectOrReference<DocusignEnvelope>): Observable<boolean> {
        return this.request([this.endpoint, envelope.id, "check_token"].join("/")).pipe(
            map((res: any) => res as boolean),
        );
    }
}

@Injectable()
export class DocusignAccountFactory extends ObjectFactory<DocusignAccount> {
    constructor() {
        super(DocusignAccount);
    }
}

@Injectable()
export class DocusignSignerFactory extends ObjectFactory<DocusignSigner> {
    constructor() {
        super(DocusignSigner);
    }
}

@Injectable()
export class DocusignTabFactory extends ObjectFactory<DocusignTab> {
    constructor() {
        super(DocusignTab);
    }
}
