import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { DocumentService } from "src/services/program.services";
import {
    Document,
    MedalinkDocumentRepository,
} from "../../../../services/models/document";
import { RequestFilter } from "src/common/utilities/request";
import { Validators } from "@angular/forms";
import { SearchableListComponent } from "src/common/components/searchable-list.component";
import { Organization } from "src/services/models/organization";
import { InstantErrorStateMatcher } from "src/common/utilities/validators";
import { ObjectViewMode } from "src/common/components/object.component";
import { Program } from "src/services/models/program";
import { DocumentComponent } from "../document.component";
import { FileUploaderCustom } from "src/common/utilities/FileUploaderCustom";
import { ObjectOrReference } from "src/services/models/api-object";
import { FileUploaderOptions } from "ng2-file-upload";

@Component({
    selector: "medalink-document-repository",
    templateUrl: "./medalink-document-repository.component.html",
    styleUrl: "./medalink-document-repository.component.scss",
})
export class MedalinkDocumentRepositoryComponent extends SearchableListComponent<Document> {
    @Input()
    mode: ObjectViewMode = ObjectViewMode.Create;

    get isCreate() {
        return this.mode === ObjectViewMode.Create;
    }
    objectView = DocumentComponent;
    fileOver: boolean = false;
    uploader?: FileUploaderCustom;

    @Input()
    step: number | undefined = undefined;

    @Input()
    organization: ObjectOrReference<Organization> | undefined = undefined;

    @Input()
    repositoryName?: string;

    constructor(
        protected service: DocumentService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, -1);
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.searchTermControl.addValidators(
            Validators.minLength(this.list.minimumFilterLength),
        );
        this.list.ordering = [{ field: "repository_type", ascending: false }];
    }
    errorStateMatcher = new InstantErrorStateMatcher();
    protected repo_?: MedalinkDocumentRepository;
    get repository(): MedalinkDocumentRepository | undefined {
        return this.repo_;
    }

    @Input() set repository(v: MedalinkDocumentRepository | undefined) {
        if (v?.id !== this.repo_?.id) {
            this.repo_ = v;
            this.list.refresh();
            const options: FileUploaderOptions = {
                url: "",
                additionalParameter: {
                    redactionEnabledOrg:
                        v instanceof Program ?
                            v.organization?.asReference
                        :   this.organization?.asReference,
                },
            };
            this.initializeUploader(options);
        }
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);

        if (this.repository?.id && !this.organization) {
            filters["repo"] = this.repository.id;
        } else if (this?.organization && this.repository?.id) {
            filters["repo"] = `${this.repository.id},${this.organization.id}`;
        } else if (this.organization?.id && !this?.repository?.id) {
            filters["repo"] = this.organization.id;
        } else delete filters["repo"];

        return filters;
    }

    get documents() {
        return this.list.items as Document[];
    }

    uploadedDocuments: Document[] = [];

    initializeUploader(options?: FileUploaderOptions) {
        if (!this.repository || !this.currentAccount?.id) return;

        this.service
            .fileUploader(
                this.organization ? this.organization : this.repository,
                this.repository,
                this.currentAccount,
                (docs: Document[]) => {
                    if (!this.uploader) return;
                    this.uploadedDocuments.push(...docs);
                    this.uploader.queue = [];
                },
            )
            .subscribe((uploader) => {
                this.uploader = uploader;
                if (options) {
                    this.uploader.setOptions(options);
                }
            });
    }
    get pendingUploads() {
        return this.uploader?.queue.filter((x) => x.progress < 100) ?? [];
    }
    handleFileDrop(event: any) {
        this.uploader?.uploadAllOneRequest();
    }
    get inherited() {
        if (this?.repository?.type === Program.object_type) {
            return [Organization.object_type];
        }

        return [];
    }
    isInherited(document: Document) {
        if (!document?.repository?.type) return true;

        return this.inherited.includes(document?.repository?.type);
    }
    inheritedText(document: Document) {
        if (document?.repository?.type === Organization.object_type) {
            return "Organization";
        }

        return "";
    }

    getDeleteTooltip(document: Document) {
        if (this.isInherited(document)) {
            return "Cannot delete an inherited document";
        }

        return "Delete document";
    }
}
