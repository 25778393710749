import { APIObject, ObjectReference, OptionalObjectOrReference } from "./api-object";

export class Product extends APIObject {
    static object_type: string = "program.product";
    name!: string;
    display_name?: string;
    owner?: OptionalObjectOrReference<ObjectReference>;
    get displayName(): string | undefined {
        return this.display_name;
    }
    published!: boolean;
    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["owner"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "published");
    }

    static invalidProductInquiryToolTip =
        "This product is not available for the selected country .";

    static invalidProductCaseToolTip =
        "This product is not available for the selected country and/or program.";
}
