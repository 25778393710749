<h3 mat-dialog-title>{{dialogTitle}}</h3>
<object-form [controller]="controller" [formGroup]="formGroup" [hideControls]="false">
    <mat-dialog-content>
        @if( triggerEdit ) {
            <div class="flex stack">
                <h3>Task Triggers</h3>

                <mat-radio-group
                    [formControl]="triggerControl"
                    aria-label="Select an option"
                    class="column"
                    color="primary"
                    (change)="setTriggerInputs($event.value, true)"
                >
                    <mat-radio-button selected value="none">
                        None
                    </mat-radio-button>
                    <mat-radio-button value="due_date">
                        Date Based
                    </mat-radio-button>
                    <mat-radio-button value="dependencies">
                        Task Based
                    </mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="flexible" *ngIf="triggerValue === 'dependencies'">
                    <mat-label>Dependencies</mat-label>
                    <mat-select formControlName="dependencies" multiple>
                        <mat-option
                            *ngIf="!dependencyOptions.length"
                            [value]="null"
                            [disabled]="true"
                        >
                            No available assignments.
                        </mat-option>
                        <mat-option
                            *ngFor="let assignment of dependencyOptions"
                            [value]="assignment"
                        >
                            {{ taskDisplayName(assignment) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf=" triggerValue === 'due_date'">
                    <mat-form-field class="column">
                        <mat-label>Date</mat-label>
                        <input
                            matInput
                            placeholder="Choose a Date"
                            [matDatepicker]="picker"
                            formControlName="due_date"
                            [min]="minDateForDueDate"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>Please enter a valid date in the future.</mat-error>
                    </mat-form-field>
                </ng-container>
            </div>
        } @else {
            <mat-radio-group
                [formControl]="frequencyTypeControl"
                aria-label="Select an option"
                class="frequency-type-radio-group"
                color="primary"
                (change)="setFrequencyInputs($event.value, true)"
            >
                <mat-radio-button value="one_time">One Time</mat-radio-button>
                <mat-radio-button value="recurring">Recurring</mat-radio-button>
            </mat-radio-group>

            <div *ngIf="frequencyTypeValue == 'recurring'" class="frequency-container">
                <h4 class="frequency-section-header">Repeat Task every</h4>
                <div class="frequency-row">
                    <mat-form-field class="frequency-amount">
                        <mat-label>Frequency Amount</mat-label>
                        <input matInput type="number" min="1" formControlName="frequency_amount" />
                    </mat-form-field>
                    <mat-form-field class="frequency-unit">
                        <mat-label>Frequency Unit</mat-label>
                        <mat-select [value]="defaultTimeUnit" formControlName="frequency_unit">
                            <mat-option *ngFor="let unit of timeUnits" [value]="unit">
                                @if (formGroup.get("frequency_amount")?.value > 1) {
                                    {{ unit }}s
                                } @else {
                                    {{ unit }}
                                }
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="frequency-row">
                    <h4 class="frequency-section-header">End Repeat</h4>
                    <mat-radio-group
                        aria-label="Select an option"
                        color="primary"
                        class="frequency-type-radio-group"
                        [formControl]="frequencyEndTypeControl"
                        (change)="setFrequencyEndTypeInputs($event.value, true)"                    
                    >
                        <mat-radio-button value="repeat_forever">
                            Repeat Forever
                        </mat-radio-button>
                        <mat-radio-button value="end_repeat_date">
                            End Repeat Date
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-form-field class="frequency-end-repeat-date" *ngIf="frequencyEndTypeValue == 'end_repeat_date'">                            
                        <mat-label>End Repeat Date</mat-label>
                        <input
                            matInput
                            placeholder="Choose a Date"
                            [matDatepicker]="frequencyEndDatePicker"
                            formControlName="frequency_end_date"
                            [min]="minDateForDueDate"
                        />
                        <mat-datepicker-toggle matSuffix [for]="frequencyEndDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #frequencyEndDatePicker></mat-datepicker>
                        <mat-error>Please enter a valid date in the future.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        }
    </mat-dialog-content>
</object-form>
