import { SearchableListComponent } from "./../../../common/components/searchable-list.component";
import { ChangeDetectorRef, Component, inject, Input } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { ObjectViewMode } from "src/common/components/object.component";
import { Organization } from "src/services/models/organization";
import { Program, ProgramCountry } from "../../../services/models/program";
import { CountryService, ProgramCountryService } from "../../../services/program.services";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";
import { ProgramCountryComponent } from "./program-country.component";
import { Country } from "src/services/models/country";

@Component({
    selector: "program-country-admin",
    templateUrl: "./program-country-admin.component.html",
    styleUrls: ["./program.component.scss"],
})
export class ProgramCountryAdminComponent extends SearchableListComponent<ProgramCountry> {
    objectView = ProgramCountryComponent;
    
    private countryService = inject(CountryService);    
    get fallbackFlagUrl(): string {
        return this.countryService.fallbackFlagUrl;
    }

    protected _organization?: Organization;
    @Input() set organization(v: Organization | undefined) {
        this._organization = v;
    }
    get organization(): Organization | undefined {
        return this._organization;
    }

    protected _program?: Program;
    get program(): Program | undefined {
        return this._program;
    }
    @Input() set program(v: Program | undefined) {
        this._program = v;
        this.list.refresh();
    }
    
    get isOrganizationAdministrator(): boolean {
        return (
            !!this.currentAccount?.hasRole("object.admin", this.organization) ||
            !!this.currentAccount?.isSystemAdministrator ||
            false
        );
    }

    get displayedColumns(): string[] {
        return ["country__display_name", "status", "actions"];
    }

    constructor(
        protected service: ProgramCountryService,
        protected changeDetection: ChangeDetectorRef
    ) {
        super(service, changeDetection);            
    }
    
    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["program"] = this._program?.id ? this._program.id : "0";
        return filters;
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else {
            this.list.ordering = [];
        }
    }
    
    newObject(data?: any): ProgramCountry | undefined {
        // Set the program reference and status to "pending" when adding a new country to the program
        data = {
            ...data,
            program: this.program?.asReference,
            status: "pending"
        };
        return super.newObject(data);
    }

    protected objectDialogConfiguration(
        object: ProgramCountry,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        if (mode != ObjectViewMode.Create) {
            return {
                ...config,
                width: "90%",
                minHeight: "75%",
            };
        }
        return config;
    }

    handleCountryFlagError(programCountry: ProgramCountry) {
        if (programCountry.country instanceof Country) {
            programCountry.country.flag_url = this.fallbackFlagUrl;
        }        
    }

    canCreate(): boolean {
        return this.currentAccount?.hasDerivedPermission("object.admin", this.program as Program) ?? false;
    }

    canEdit(prgCountry: ProgramCountry): boolean {
        return this.currentAccount?.hasDerivedPermission("object.admin", prgCountry) ?? false;
    }
}
