import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, ViewChild, inject } from "@angular/core";
import {
    DataFieldDefinition,
    DataType,
    Organization,
    OrganizationSettings,
} from "../../../services/models/organization";
import { Account } from "../../../services/models/account";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
    AccountService,
    OrganizationService,
    OrganizationSettingsFactory,
} from "../../../services/iam.services";
import {
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
} from "../../../services/models/api-object";
import {
    ObjectComponent,
    ObjectViewMode,
} from "../../../common/components/object.component";
import { OptionalEmailValidator } from "../../../common/utilities/validators";
import { mergeMap } from "rxjs/operators";
import { FileUploader } from "ng2-file-upload";
import { ConfirmDialog } from "../../../common/components/confirm.dialog";
import { of } from "rxjs";
import { TeamMemberService, TeamService } from "src/services/program.services";
import { MatTabGroup } from "@angular/material/tabs";
import { DerivedPermission } from "src/services/models/role";

@Component({
    selector: "organization-settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./organization.component.scss"],
})
export class OrganizationSettingsComponent extends ObjectComponent<Organization> {
    get isHcpStaff() {
        return this.dashboardRole === "provider";
    }
    accountService: AccountService;

    @ViewChild(MatTabGroup) tabGroup?: MatTabGroup;
    protected tabToSelect?: number;

    currentOrganizationControl: UntypedFormControl = new UntypedFormControl();
    organizations: ObjectOrReference<Organization>[] = [];

    domainControl: UntypedFormControl = new UntypedFormControl(
        null,
        Validators.required,
    );
    siteUrl!: string;
    integrateHead!: string;
    integrateBody!: string;
    showHeadCode: boolean = false;
    showBodyCode: boolean = false;

    autosave = true;
    mode = ObjectViewMode.Edit;

    templateLogo?: string;
    uploader?: FileUploader;
    fileOver: boolean = false;

    get intakeAddress(): string {
        const id = this.object?.id?.replace(/-/g, "");
        return id ? id + "@" + this.service.session.environment.intakeDomain : "";
    }
    get intakeWeb(): string {
        const orgSlug = this.fullObject?.slug;
        return window.location.origin + "/intake/" + orgSlug;
    }
    get brandingFormGroup(): UntypedFormGroup {
        return this.formGroup.get("settings") as UntypedFormGroup;
    }
    constructor(protected service: OrganizationService) {
        super(service);
        inject(OrganizationSettingsFactory);
        inject(TeamService);
        inject(TeamMemberService);
        this.accountService = inject(AccountService);
        this.currentOrganizationControl.valueChanges.subscribe(
            (value: ObjectReference) => {
                if (value.id != this.object?.id) this.setObjectOrReference(value);
            },
        );
        this.initializeIntegrationCode();
    }
    ngOnInit(): void {
        this.updateDashboardRole();
    }
    selectTab(tab: number): void {
        if (this.tabGroup) {
            this.tabGroup.selectedIndex = tab;
            this.tabToSelect = undefined;
        } else this.tabToSelect = tab;
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.tabToSelect !== undefined && this.tabGroup) {
            this.tabGroup.selectedIndex = this.tabToSelect;
            this.tabToSelect = undefined;
        }
    }

    updateDashboardRole(): void {
        this.accountService
            .dashboardRole(this.currentAccount)
            .subscribe(
                (role: any) => (this.dashboardRole = role ? role["role"] : "none"),
            );
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            name: [null, Validators.required],
            settings: this.formBuilder.group({
                contact_address: [null],
                contact_phone: [null],
                contact_email: [null, OptionalEmailValidator],
            }),
            slug: [null, Validators.required],
        });
    }

    get organizationId(): string {
        return (this.object?.id ?? "").replace(/-/g, "");
    }
    initializeIntegrationCode(): void {
        this.siteUrl = window.location.origin;
        this.integrateHead =
            '<link rel="stylesheet" href="' +
            this.siteUrl +
            '/assets/intake.css">\n<script type="application/javascript" src="' +
            this.siteUrl +
            '/assets/intake.js"></script>';
        this.integrateBody =
            '<div class="eap-request">\n' +
            '  <p class="eap-instructions">To submit a request for access, please fill out the form below</p>\n' +
            '    <form id="eap-inquiry" name="eap-inquiry">\n' +
            "      <-- This hidden input field must remain -->\n" +
            '      <input type="hidden" name="cors_object" value="iam.organization:' +
            this.organizationId +
            '" />\n' +
            '      <div class="eap-form-section full">Clinic Information</div>\n' +
            '      <div class="eap-form-field full">\n' +
            '        <label for="sender">Requesting Physician:</label>\n' +
            '        <input class="field-value" type="text" id="sender" name="sender" placeholder="Email Address" />\n' +
            "      </div>\n" +
            '      <div class="eap-form-field full">\n' +
            '        <label for="clinic.address">Clinic Address:</label>\n' +
            '        <textarea class="field-value" id="clinic.address" name="clinic.address" placeholder="123 Main St.\nSome Town, CA, 12345"></textarea>\n' +
            "      </div>\n" +
            '      <div class="eap-form-field full">\n' +
            '        <label for="physician.phone">Physician Telephone:</label>\n' +
            '        <input class="field-value" type="text" id="physician.phone" name="physician.phone" placeholder="(201) 555-1234 x5678" />\n' +
            "      </div>\n" +
            '      <div class="eap-form-section full">Patient Information</div>\n' +
            '      <div class="eap-form-field full">\n' +
            '        <label for="patient.initials">Initials:</label>\n' +
            '        <div class="field-value eap-form-field">\n' +
            '          <input style="flex: 0 0 auto; width: 10rem; margin-right: 1rem;" type="text" id="patient.initials" name="patient.initials" placeholder="AZ">\n' +
            '          <label for="patient.age" style="width: auto;">Age:</label>\n' +
            '          <input style="flex: 0 0 auto; width: 10rem; margin-right: 1rem;" type="text" id="patient.age" name="patient.age" placeholder="0-99+">\n' +
            '          <label for="patient.sex" style="width: auto;">Sex:</label>\n' +
            '          <input style="flex: 0 0 auto; width: 10rem; margin-right: 1rem;" type="text" id="patient.sex" name="patient.sex" placeholder="M/F">\n' +
            "        </div>\n" +
            "      </div>\n" +
            '      <div class="eap-form-field full">\n' +
            '        <label for="patient.history">Medical History:</label>\n' +
            '        <textarea class="field-value" id="patient.history" name="patient.history" placeholder="Brief medical history"></textarea>\n' +
            "      </div>\n" +
            '      <div class="eap-form-field full eap-form-submit">\n' +
            "        <label></label>\n" +
            '        <button class="field-value" type="button" onclick="submitInquiry(\'success.html\', \'error.html\')">Submit Request</button>\n' +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</div>";
    }

    removeCORSDomain(event: MouseEvent, domain: string): void {
        this.terminateEvent(event);
        if (this.object) {
            this.service
                .setCORSAccessDomain(this.object, domain, true)
                .subscribe((domains: string[]) => {
                    if (this.fullObject) this.fullObject.cors_domains = domains;
                });
        }
    }
    addCORSDomain(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.domainControl.value && this.object) {
            this.service
                .setCORSAccessDomain(this.object, this.domainControl.value)
                .subscribe((domains: string[]) => {
                    if (this.fullObject) this.fullObject.cors_domains = domains;
                    this.domainControl.reset();
                });
        }
    }

    get templateLogoName(): string | undefined {
        const index =
            (this.fullObject?.settings?.template_logo?.lastIndexOf("/") ?? 0) + 1;
        return this.fullObject?.settings?.template_logo?.substring(index);
    }

    get templateLogoSource(): string | undefined {
        if (this.fullObject?.settings?.template_logo)
            return (
                this.service.session.environment.services +
                "/media/" +
                this.fullObject.settings.template_logo
            );
        return "";
    }

    removeLogo(event: MouseEvent): void {
        this.terminateEvent(event);
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: "Are you sure you want to delete the branding logo?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) =>
                    confirm ? this.service.removeLogo(this.fullObject!) : of(undefined),
                ),
            )
            .subscribe((logo?: string) => {
                if (logo !== undefined && this.fullObject?.settings)
                    this.fullObject.settings.template_logo = logo;
            });
    }

    get availableDataTypes(): DataType[] {
        return OrganizationSettings.DataTypes;
    }
    get dataFields(): DataFieldDefinition[] {
        return this.dataFields_;
    }
    set dataFields(v: DataFieldDefinition[]) {
        this.dataFields_ = v;
        const fg = this.formGroup.value;
        fg.settings.data_types = v;
        this.onAutosave(fg);
    }
    dataFields_: DataFieldDefinition[] = [];
    dataField?: DataFieldDefinition;
    dataFieldIndex?: number;
    dataFieldAdding: boolean = false;
    editDataField(event: MouseEvent, index: number): void {
        this.terminateEvent(event);
        const field = this.dataFields[index];
        this.dataField = { ...field };
        this.dataFieldIndex = index;
    }
    saveDataFields(event?: MouseEvent): void {
        if (this.dataField && this.dataFieldIndex !== undefined)
            this.dataFields_[this.dataFieldIndex] = this.dataField;
        this.dataFields = this.dataFields_;
        this.dataFieldAdding = false;
        this.cancelEditDataField(event);
    }
    createDataField(event: MouseEvent): void {
        const field = {
            field: undefined,
            displayName: undefined,
            type: OrganizationSettings.DataTypes[0].type,
        };
        const index = this.dataFields_.length;
        this.dataFields_ = [...this.dataFields_, field];
        this.dataFieldAdding = true;
        this.editDataField(event, index);
    }
    removeDataField(event: MouseEvent, index: number): void {
        this.terminateEvent(event);
        this.dataFields_.splice(index, 1);
        this.dataFields = this.dataFields_;
    }
    cancelEditDataField(event?: MouseEvent): void {
        this.terminateEvent(event);
        if (this.dataFieldAdding) {
            this.dataFields_.splice(this.dataFields_.length - 1, 1);
            this.dataFieldAdding = false;
        }
        this.dataFieldIndex = undefined;
        this.dataField = undefined;
    }
    drop(event: CdkDragDrop<DataFieldDefinition[]>) {
        moveItemInArray(this.dataFields_, event.previousIndex, event.currentIndex);
        this.dataFields_ = this.dataFields_.map(
            (f: DataFieldDefinition, index: number) => {
                return { ...f, order: index };
            },
        );
        this.saveDataFields();
    }
    displayNameForDataType(type: string): string | undefined {
        return OrganizationSettings.displayNameForDataType(type);
    }
    get dataFieldIsValid(): boolean {
        return (
            !!this.dataField?.field &&
            !!this.dataField?.displayName &&
            !!this.dataField?.type
        );
    }

    protected precommitTransform(v: any): any {
        // TODO: Consider doing this generally for any optional field in an object
        if (v.settings.contact_address == "") v.settings.contact_address = null;
        else if (!v.settings.contact_address) delete v.settings.contact_address;
        if (v.settings.contact_phone == "") v.settings.contact_phone = null;
        else if (!v.settings.contact_phone) delete v.settings.contact_phone;
        if (v.settings.contact_email == "") v.settings.contact_email = null;
        else if (!v.settings.contact_email) delete v.settings.contact_email;
        return v;
    }

    protected resetObject(v: Organization) {
        if (!v.settings) {
            const t: any = { ...v };
            delete t.settings;
            super.resetObject(t);
        } else super.resetObject(v);
    }
    protected setObject(v?: Organization) {
        super.setObject(v);
        this.initializeIntegrationCode();
        const selectedOrg = this.organizations.find(
            (o: ObjectReference) => o.id === this.object?.id,
        );
        this.currentOrganizationControl.setValue(selectedOrg);
        if (this.fullObject?.settings?.data_types) {
            this.dataFields_ = this.fullObject.settings.data_types
                .map((dt: DataFieldDefinition) => {
                    return { ...dt, order: dt.order ?? -1 };
                })
                .sort((a: DataFieldDefinition, b: DataFieldDefinition) => {
                    if (a.order == -1 && b.order == -1) {
                        if ((a.field ?? "") < (b.field ?? "")) return -1;
                        if ((a.field ?? "") > (b.field ?? "")) return 1;
                        return 0;
                    }
                    return (a.order ?? 0) - (b.order ?? 0);
                });
        }

        this.initializeUploader();
    }

    protected onCurrentAccountChanged(a: Account | undefined): void {
        super.onCurrentAccountChanged(a);
        if (a) {
            let orgs = a.objectsForRole<Organization>(
                "object.admin",
                "iam.organization",
            );

            const processOrgs = (orgs: ObjectOrReference<Organization>[]) => {
                const selectedOrg = orgs.length ? orgs[0] : undefined;

                setTimeout(() => {
                    this.organizations = orgs;
                    this.setObjectOrReference(selectedOrg);
                });

                this.updateDashboardRole();
            }

            if (orgs.length < 1) {  //User is not an org admin but may be a program or program country admin trying to access those settings
                const adminPermission = this.currentAccount?.derived_permissions.find(
                    (p: DerivedPermission) => p.permission == "object.admin" && (p.object_type == "program.program" || p.object_type == "program.programcountry")
                );
                const orgReference = ObjectFactory.makeObject<Organization>(adminPermission?.root_organization);
                this.service.resolveReference(orgReference).subscribe((fullOrganization: Organization | undefined) => {
                    orgs = fullOrganization ? [fullOrganization] : [];
                    processOrgs(orgs);
                });                
            } else {
                processOrgs(orgs);
            }           
            
        } else {
            this.dashboardRole = "none";
        }
    }

    protected initializeUploader(): void {
        this.service
            .logoUploader(this.object)
            .subscribe((uploader?: FileUploader) => (this.uploader = uploader));
    }
}
