import {
    NamedObject,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
} from "./api-object";
import { Country } from "./country";
import { DataForm } from "./data";
import { Product } from "./product";
import { Role } from "./role";

export type ProgramStatus = "Active" | "Pending" | "Closed";
export type ProgramType =
    | "CUP"
    | "NPU"
    | "PTA"
    | "Compassionate Use"
    | "Named Patient"
    | "Post-Trial Access"
    | "Managed Access";

/**
 * A Program represents a specific configuration of drug for which an Organization is providing Early Access to.
 * @param organization ObjectReference - The organization that is running this program
 * @param ind number - The IND number for this program
 * @param slug srting - The short-code for this program
 * @param drug_name - The specific drug for which this program is being run
 * @param status string - The status of the program (ie. running, completed, etc)
 * @param cors_domains string[] - readOnly - list of domains from which an intake form will be accepted for this program
 * @param roles Role[] - read-only - List of roles on this object
 * @param org_slug string - read-only - the slug for the organization
 * @param deleted boolean - whether or not this program was deleted by an admin
 * @param response_time string - User configured value for the estimated time of response of an inquiry for this program
 */
export class Program extends NamedObject {
    static object_type: string = "program.program";
    organization!: ObjectReference; // The organization that is running this program
    ind!: number; // The IND number for this program
    slug!: string; // The short-code for this program
    drug_name?: string; // The specific drug for which this program is being run
    status?: ProgramStatus; // The status of the program (ie. running, completed, etc)
    cors_domains?: string[]; // readOnly - list of domains from which an intake form will be accepted for this program
    roles?: Role[]; // read-only - List of roles on this object
    org_slug!: string; // read-only - the slug for the organization
    deleted!: boolean; // whether or not this program was deleted by an admin
    response_time!: string; //User configured value for the estimated time of response of an inquiry for this program
    products?: Product[];
    open_date?: Date;
    program_type?: ProgramType;
    intake_form?: OptionalObjectOrReference<DataForm>;

    get intakeForm() {
        return this.intake_form;
    }
    get programType() {
        return this.program_type;
    }
    get openDate() {
        return this.open_date;
    }
    get isComplete(): boolean {
        return super.isComplete && this.roles !== undefined;
    }
    static get avaialbleStatuses(): ProgramStatus[] {
        return ["Active", "Pending", "Closed"];
    }
    static get avaialbleProgramTypes(): ProgramType[] {
        return [
            "Compassionate Use",
            "Named Patient",
            "Post-Trial Access",
            "Managed Access",
        ];
    }
    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["status", "drug_name"]);
        this._readOnly.push(...["cors_domains", "roles", "org_slug"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "organization", ObjectReference);
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "drug_name");
        this.setMember(data, patch, "slug");
        this.setMember(data, patch, "ind");
        this.setMember(data, patch, "status");
        this.setMember(data, patch, "org_slug");
        this.setMember(data, patch, "deleted");
        this.setMember(data, patch, "response_time");
        this.setMember(data, patch, "products", Product, true);
        this.setMember(data, patch, "open_date", Date);
        this.setMember(data, patch, "program_type");
        this.setMember(data, patch, "intake_form", DataForm);
        if (data?.hasOwnProperty("roles")) {
            this.setMember(data, patch, "cors_domains", undefined, true);
            this.setMember(data, patch, "roles", Role, true);
        }
    }
}

export class ProgramCountry extends NamedObject {
    static object_type: string = "program.programcountry";
    program!: ObjectOrReference<Program>;
    country!: ObjectOrReference<Country>;
    status!: string;
    intake_form?: OptionalObjectOrReference<DataForm>;

    initialize(data?: any, patch: boolean = false): void {
        this._references.push(...["program", "country", "intake_form"]);
        this._optional.push(...["intake_form"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "program", Program);
        this.setMember(data, patch, "country", Country);
        this.setMember(data, patch, "status");
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "intake_form", DataForm);
    }
    static invalidToolTip = "This program is not avaialble for the selected country.";
}
