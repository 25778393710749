import {
    APIObject,
    NamedObject,
    ObjectOrReference,
    ObjectReference,
} from "./api-object";
import { Case } from "./case";
import { DataForm } from "./data";
import { Inquiry } from "./inquiry";

export class WorkflowReference extends APIObject {
    static object_type: string = "program.workflowreference";
    item_type!: string;
    reference_object_type?: string;
    reference_identifier?: string;

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["reference_object_type"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "item_type");
        this.setMember(data, patch, "reference_object_type");
        this.setMember(data, patch, "reference_identifier");
    }
}

export class Workflow extends NamedObject {
    static readonly object_type: string = "program.workflow";
    name!: string;
    get displayName(): string | undefined {
        return this.display_name;
    }
    display_name!: string;
    description!: string;
    region?: string;
    references!: WorkflowReference[]; // read-only

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["references"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "region");
        this.setMember(data, patch, "references", WorkflowReference, true);
    }
}

export class WorkflowInstance extends NamedObject {
    static readonly object_type: string = "program.workflowinstance";
    workflow!: ObjectOrReference<Workflow>;
    owner!: ObjectOrReference<Case | Inquiry>;
    order!: number;
    shipment_form?: ObjectOrReference<DataForm>; // read-only
    is_subworkflow!: boolean; // read-only

    override get displayName(): string | undefined {
        return super.displayName ?? this.workflow?.displayName;
    }

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["shipment_form", "is_subworkflow"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "workflow", Workflow);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "order");
        this.setMember(data, patch, "shipment_form", DataForm);
        this.setMember(data, patch, "is_subworkflow");
    }
}
