import { ObjectReference } from "./../../../../services/models/api-object";
import { FormControl } from "@angular/forms";
import {
    Component,
    ViewChild,
    ElementRef,
    Input,
    ChangeDetectorRef,
    inject,
} from "@angular/core";
import { SearchableListComponent } from "../../searchable-list.component";
import { ObjectViewEntryPoint, ObjectViewMode } from "../../object.component";
import { Sort } from "@angular/material/sort";
import { Case } from "src/services/models/case";
import { Program, ProgramCountry } from "src/services/models/program";
import { ObjectView } from "../../object-admin.component";
import { DataFormEditorComponent } from "./data-form.component";
import { DataFormService } from "src/services/data.services";
import { Organization } from "src/services/models/organization";
import { DataForm, DataFormReference } from "src/services/models/data";
import { OrganizationService } from "src/services/iam.services";
import { Inquiry } from "src/services/models/inquiry";
import { ObjectRepository } from "src/services/models/compound";
import { MatTable } from "@angular/material/table";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";
import { PaginatedList } from "src/services/models/api-object";
import { DocumentRepository } from "src/services/models/document";
import { ProgramCountryService, ProgramService } from "src/services/program.services";

@Component({
    selector: "data-form-admin",
    templateUrl: "./data-form-admin.component.html",
    styleUrls: ["../data-admin.component.scss"],
})
export class DataFormAdminComponent extends SearchableListComponent<DataForm> {
    objectView: ObjectView<DataForm> | undefined = DataFormEditorComponent;
    @ViewChild("search") searchElement: ElementRef<any> | undefined;
    @ViewChild(MatTable) _matTable?: MatTable<any>;

    @Input() set repository(v: ObjectRepository | undefined) {
        this._repository = v;
        this.list.refresh();

        if (this.repository instanceof Organization)
            this._organization = this.repository;
        else if (this.repository instanceof Program) {
            this.organizationService
                .retrieve(this.repository.organization.id ?? "0")
                .subscribe(
                    (org: Organization | undefined) => (this._organization = org),
                );
        } else if (this.repository instanceof Case) {
            this.organizationService
                .retrieve(this.repository.shared.organization?.id ?? "0")
                .subscribe(
                    (org: Organization | undefined) => (this._organization = org),
                );
        } else if (this.repository instanceof Inquiry) {
            this.organizationService
                .retrieve(this.repository.organization?.id ?? "0")
                .subscribe(
                    (org: Organization | undefined) => (this._organization = org),
                );
        }
    }
    get repository(): ObjectRepository | undefined {
        return this._repository;
    }
    get organization(): Organization | undefined {
        return this._organization;
    }
    protected _repository?: ObjectRepository;
    protected _organization?: Organization;
    protected organizationService: OrganizationService;

    programService: ProgramService;
    programCountryService: ProgramCountryService;

    programs: Program[] = [];
    programCountries: ProgramCountry[] = [];

    searchTermControl: FormControl = new FormControl();
    showSearch: boolean = false;
    get loading(): boolean {
        return !this.list.hasLoaded;
    }

    get displayedColumns(): string[] {
        return ["display_name", "owner_type", "owner_id", "actions"];
    }

    constructor(
        protected service: DataFormService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);

        this.organizationService = inject(OrganizationService);
        this.programService = inject(ProgramService);
        this.programCountryService = inject(ProgramCountryService);
    }

    ngOnInit(): void {
        if (this.repository?.type == "iam.organization") {
            this.updatePrograms();
        }
    }

    protected updatePrograms(): void {
        if (!!this.repository?.id) {
            this.programService
                .list({
                    organization: this.repository?.id,
                })
                .subscribe((program) => {
                    this.programs = program as Program[];
                    this.updateProgramCountries();
                });
        } else {
            this.programs = [];
        }
    }

    protected updateProgramCountries(): void {
        if (!!this.programs.length && !!this.repository?.id) {
            this.programCountryService
                .list({
                    program: this.programs.map((p) => p.id).join(","),
                })
                .subscribe((countries) => {
                    this.programCountries = countries as ProgramCountry[];
                });
        } else {
            this.programCountries = [];
        }
    }

    isSystemOwned(form: DataForm | DataFormReference | ObjectReference): boolean {
        return (
            (form instanceof DataForm || form instanceof DataFormReference) &&
            !form.owner
        );
    }

    hasEditPermission(formOwner: DocumentRepository | undefined): boolean {
        if (!formOwner) return false;

        // TODO: Confirm list of valid editor permissions after Program Staff implementation
        const editorPermissions = ["object.admin"];
        return editorPermissions.some((permission) =>
            this.currentAccount?.hasDerivedPermission(permission, formOwner),
        );
    }

    canCreate(form: DataForm): boolean {
        return true;
    }

    canEdit(form: DataForm): boolean {
        // sys admin can always edit
        if (this.currentAccount?.isSystemAdministrator) return true;

        // form is only editible if the form owner is at the same level as the repository (e.g. an organization owned form should not be editable within program settings)
        // AND user has edit rights on the object that owns the form (org, program, or program country)
        const repoLevel = this.repository?.type;
        return repoLevel === form.owner?.type && this.hasEditPermission(form.owner);
    }

    canDelete(form: DataForm): boolean {
        return this.canEdit(form);
    }

    editObject(
        event: MouseEvent | undefined,
        object: DataForm,
        asDialog?: boolean,
        viewOnly?: boolean,
        isDuplicate = false,
    ): ObjectViewEntryPoint<DataForm> | undefined {
        const instance = super.editObject(
            event,
            object,
            asDialog,
            viewOnly,
        ) as DataFormEditorComponent;
        instance.autosave = false;
        instance.organization = this.organization;
        instance.duplicating = isDuplicate;
        return instance;
    }
    duplicateObject(
        event: MouseEvent,
        object: DataForm,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<DataForm> | undefined {
        const duplicate = object.duplicate(object.owner ?? this.repository);
        const instance = this.editObject(event, duplicate, asDialog, undefined, true);
        if (asDialog && instance?.dialogReference)
            instance.dialogReference
                .afterClosed()
                .subscribe((form: DataForm) => this.onAfterCreate(form, instance));
        return instance;
    }
    newObject(data?: any): DataForm | undefined {
        return super.newObject({
            ...data,
            display_name: "New Form",
            owner: this.repository,
        });
    }
    protected objectDialogConfiguration(
        object: DataForm,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            width: "90vw",
            height: "90vh",
        };
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["next"] = "0";
        filters["is_template"] = "true";
        filters["use_reference"] = "True";
        const allowSystemForms = !this.organization?.hideSystemForms;

        if (this.repository?.type == "program.case")
            filters["owned"] =
                this.repository.id + "," + (this.repository as Case).shared.id;
        else if (this.repository?.type == "iam.organization") {
            filters["owned"] = this.repository?.id ?? "";
            if (this.programs?.length) {
                filters["owned"] += "," + this.programs.map((p) => p.id).join(",");
            }
            if (this.programCountries?.length) {
                filters["owned"] +=
                    "," + this.programCountries.map((p) => p.id).join(",");
            }
        } else if (this.repository?.type == "program.programcountry") {
            const programCountryRepository = this.repository as ProgramCountry;
            const program = programCountryRepository.program as Program;
            filters["owned"] =
                programCountryRepository.id +
                "," +
                programCountryRepository.program.id +
                "," +
                program.organization.id;
        } else if (this.repository?.type == "program.program")
            filters["owned"] =
                this.repository.id + "," + (this.repository as Program).organization.id;
        else filters["owned"] = this.repository?.id ?? "";

        if (allowSystemForms) {
            filters["owned"] += ",0";
        }

        return filters;
    }
    protected resolveReferences(list: PaginatedList<any>): PaginatedList<any> {
        return list;
    }

    getFormOwnerName(form: DataForm | DataFormReference | ObjectReference): string {
        if (form instanceof DataForm) return form.owner?.displayName ?? "Medasystems";
        if (form instanceof DataFormReference) return form.owner?.name ?? "Medasystems";
        return "Medasystems";
    }

    getFormRepoName(form: any): string {
        switch (form?.owner?.type) {
            case "program.program":
                return "Program";
            case "program.programcountry":
                return "Country";
            case "iam.organization":
                return "Organization";
            case "program.case":
                return "Case";
        }
        return "Medasystems";
    }
}
