import * as CryptoJS from "crypto-js";
import { MatDialog } from "@angular/material/dialog";

const ShowDebugMessages: boolean = true;

export type DataPoint = { name: string; value: number };
export type DisplayNameMap = { value: any; displayName: string };
export type FunctionCall = { fn: Function; args: any[] };

export const DATEPICKER_FORMATS = {
    parse: {
        dateInput: "LL",
    },
    display: {
        dateInput: "DD MMM YYYY",
        monthYearLabel: "YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "YYYY",
    },
};

/**
 * Adds any existing query params, that ONLY the front end needs, to local storage under the given key.
 * The param key name is unique, so it checks if an existing one is present,
 * and overwrites it if it does. The keys 'code' and 'state' are ignored since that is for backend auth. If nothing exists under the given key in local storage,
 * it sets it to window.location.search.
 * @param {string} storageKey - The key to use for storing the params in local storage.
 */
export function addParamsToLocalStorage(storageKey: string) {
    const existing = localStorage.getItem(storageKey);
    const existingParams = existing ? JSON.parse(existing) : {};
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.forEach((value, key) => {
        if (key !== "code" && key !== "state") {
            // front end does not need this
            existingParams[key] = value;
        }
    });

    localStorage.setItem("params", JSON.stringify(existingParams));
}

export function compareArrayValues(arr1: any[], arr2: any[]): boolean {
    return (
        Array.isArray(arr1) &&
        Array.isArray(arr2) &&
        arr1.length === arr2.length &&
        arr1.every((val, index) => val === arr2[index])
    );
}

export function contrast(hex: string): "light" | "dark" {
    const { r, g, b } = hexToRgb(hex);
    const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);

    return brightness <= 180 ? "dark" : "light";
}

/**
 * Convert a Date object to an array [year, month, day].
 *
 * @param {Date} d - The input date.
 * @param {boolean} addOneDay - If true, add one day to the input date. This is useful when you want to
 *                               include the end date in a range filter, for example.
 * @returns {number[]} An array containing the year, month, and day of the input date.
 */

export function convertDate(d: Date, addOneDay = false) {
    const date = new Date(d);
    if (addOneDay) {
        date.setDate(date.getDate() + 1);
    }

    const month = date.getMonth() + 1; // Month is zero-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    return [year, month, day];
}

export function DebugLog(msg: string): void {
    const ts = FormatDateTime();
    if (ShowDebugMessages) console.info(`${ts} ${msg}`);
}

export function EnumValues(type: any): number[] {
    const list: number[] = [];
    for (const value in type) {
        if (type.hasOwnProperty(value) && parseInt(value, 10) >= 0)
            list.push(parseInt(value));
    }
    return list;
}

export function flatten(arr: any[]): any[] {
    return arr.reduce((flat, toFlatten) => {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

export function FormatDateTime(date?: Date): string {
    const d = date ?? new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const ti = d.toTimeString();
    return `${da}-${mo}-${ye} ${ti}`;
}

export function generateCode(
    length: number,
    characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!$^*()",
): string {
    let code = "";
    for (let i = 0; i < length; i++)
        code += characters.charAt(Math.floor(Math.random() * characters.length));
    return code;
}

export function generateCodeChallenge(verifier: string): string {
    return CryptoJS.SHA256(verifier)
        .toString(CryptoJS.enc.Base64)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/g, "");
}

export function GenerateUniqueIdentifier(
    name: string,
    siblings: string[],
    separator: string = ".",
): string {
    let index = 1;
    const original = name?.split(" ").join(separator).trim().toLowerCase();
    let id = original;
    while (siblings.find((n: string) => n === id)) {
        id = `${original}${separator}${index.toString()}`;
        index += 1;
    }
    return id;
}

export function getDefaultBgColor(): string {
    return getComputedStyle(document.documentElement)
        .getPropertyValue("--dark-blue")
        .trim();
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
    hex = hex.replace("#", "");

    if (hex.length !== 3 && hex.length !== 6) {
        throw new Error("Invalid hex color code");
    }

    if (hex.length === 3) {
        hex = hex
            .split("")
            .map((char) => char + char)
            .join("");
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
}

export function htmlToText(s: string): string | undefined {
    s = s.replace(/\n/g, "");
    s = s.replace(/\t/g, "");
    s = s.replace(/<\/td>/g, "\t");
    s = s.replace(/<\/table>/g, "\n");
    s = s.replace(/<\/tr>/g, "\n");
    s = s.replace(/<\/p>/g, "\n");
    s = s.replace(/<\/div>/g, "\n");
    s = s.replace(/<\/h>/g, "\n");
    s = s.replace(/<br( )*\/?>/g, "\n");
    s = "<!doctype html><body></body>" + s;
    const dom = new DOMParser().parseFromString(s, "text/html");
    return dom.body.textContent ?? undefined;
}

export function isElementInViewport(el: Element) {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function isHTML(s?: string): boolean {
    if (!s) return false;
    const doc = new DOMParser().parseFromString(s, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

/**
 * Will not return anything until element is in the DOM.
 * To get most accurate selector:
 * 1. right click the component in the browser, click 'inspect'.
 * 2. Right click the element in dev tools, click 'Copy'
 * 3. Click 'Copy selector'
 * 4. Call the function (with either await or .then syntax) and paste the selector as a string
 */
export async function waitUntilElementLoaded(selector: any) {
    while (document.querySelector(selector) === null) {
        await new Promise((resolve) => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
}

export function capitalizeFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function isDialogOpen(dialog: MatDialog, dialogType: any): boolean {
    return dialog.openDialogs.some(
        (dialogRef) => dialogRef.componentInstance instanceof dialogType,
    );
}
